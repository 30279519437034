// Code generated using graphql-codegen - DO NOT EDIT.
/*eslint-disable*/
import type * as GeoJSON from 'geojson';
export type JSON = string | number | boolean | {[key: string]: JSON;} | JSON[] | null;
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: JSON; output: JSON; }
  bigint: { input: number; output: number; }
  bit: { input: any; output: any; }
  bytea: { input: string; output: string; }
  date: { input: string; output: string; }
  float8: { input: number; output: number; }
  geography: { input: GeoJSON.GeoJsonObject; output: GeoJSON.GeoJsonObject; }
  geometry: { input: GeoJSON.GeometryObject; output: GeoJSON.GeometryObject; }
  json: { input: JSON; output: JSON; }
  jsonb: { input: JSON; output: JSON; }
  numeric: { input: number; output: number; }
  push_token_type: { input: 'expo' | 'apns' | 'fcm' | 'onesignal'; output: 'expo' | 'apns' | 'fcm' | 'onesignal'; }
  seed_float: { input: string; output: string; }
  timestamp: { input: string; output: string; }
  timestamptz: { input: string; output: string; }
  uuid: { input: string; output: string; }
};

export type Author = Document & {
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  bioRaw?: Maybe<Scalars['JSON']['output']>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Slug>;
  team?: Maybe<Team>;
};

export type AuthorFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  image?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<SlugFilter>;
  team?: InputMaybe<TeamFilter>;
};

export type AuthorSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SlugSorting>;
};

export type BooleanFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type Campaign = Document & {
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  goal?: Maybe<Scalars['Float']['output']>;
  goalAchievedMessage?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  team?: Maybe<Team>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CampaignFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  goal?: InputMaybe<FloatFilter>;
  goalAchievedMessage?: InputMaybe<StringFilter>;
  publishedAt?: InputMaybe<DatetimeFilter>;
  team?: InputMaybe<TeamFilter>;
  title?: InputMaybe<StringFilter>;
};

export type CampaignFilter_Remote_Rel_Teamscampaigns = {
  /** Apply filters on document level */
  _?: InputMaybe<DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  description?: InputMaybe<StringFilter>;
  goal?: InputMaybe<FloatFilter>;
  goalAchievedMessage?: InputMaybe<StringFilter>;
  publishedAt?: InputMaybe<DatetimeFilter>;
  team?: InputMaybe<TeamFilter_Remote_Rel_Teamscampaigns>;
  title?: InputMaybe<StringFilter>;
};

export type CampaignSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  goal?: InputMaybe<SortOrder>;
  goalAchievedMessage?: InputMaybe<SortOrder>;
  publishedAt?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type CancelUniversalCheckoutOutput = {
  checkout_intent_id: Scalars['Int']['output'];
  payment_intent_id: Scalars['String']['output'];
  result: Scalars['String']['output'];
};

export type CancelUniversalCheckoutParams = {
  checkout_intent_id: Scalars['Int']['input'];
};

export type CheckoutSessionOutput = {
  checkout_session_id: Scalars['String']['output'];
  checkout_url: Scalars['String']['output'];
  transaction?: Maybe<Transaction>;
};

export type CreateDonateCheckoutOutput = {
  /** Purposity checkout payment intents.id */
  checkout_intent_id: Scalars['String']['output'];
  /** stripe.ephemeralKeys.create({ customer }).secret, */
  ephemeral_key_secret: Scalars['String']['output'];
  /** stripe.paymentIntents.create({ ... }).client_secret */
  payment_intent_client_secret: Scalars['String']['output'];
  /** stripe.customer.id */
  stripe_customer_id: Scalars['String']['output'];
  /** Purposity: Transaction Summary */
  transaction?: Maybe<Transaction>;
};

export type CreateDonateCheckoutParams = {
  /** Amount to apply to the transaction, represented with decimal places; i.e., `100.15` */
  amount: Scalars['Float']['input'];
  designee: DesigneeParams;
};

export type CreateUniversalCheckoutOutput = {
  checkout_intent?: Maybe<Checkout_Intents>;
  /** Purposity checkout_intents.id */
  checkout_intent_id: Scalars['Int']['output'];
  /** stripe.ephemeralKeys.create({ customer }).secret, */
  ephemeral_key_secret: Scalars['String']['output'];
  /** stripe.paymentIntents.create({ ... }).client_secret */
  payment_intent_client_secret: Scalars['String']['output'];
  /** stripe.customer.id */
  stripe_customer_id: Scalars['String']['output'];
  /** Purposity: Transaction Summary */
  transaction?: Maybe<Transaction>;
};

export type CreateUniversalCheckoutParams = {
  need_ids: Array<Scalars['Int']['input']>;
  /** Percent of tip to apply to the transaction, represented as a decimal; i.e., `0.15` */
  tip_percentage: Scalars['Float']['input'];
};

export type CurrentUserOutput = {
  legacy_token: Scalars['String']['output'];
  user?: Maybe<Users>;
  user_id: Scalars['Int']['output'];
};

export type DatetimeFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DesigneeParams = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  type: DonateType;
};

/** A Sanity document */
export type Document = {
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DocumentFilter = {
  /** All documents that are drafts. */
  is_draft?: InputMaybe<Scalars['Boolean']['input']>;
  /** All documents referencing the given document ID. */
  references?: InputMaybe<Scalars['ID']['input']>;
};

export enum DonateType {
  Org = 'org',
  Team = 'team'
}

export type ExchangeLegacyTokenOutput = {
  clerk_ticket: Scalars['String']['output'];
};

export type ExchangeLegacyTokenParams = {
  legacy_token: Scalars['String']['input'];
};

export type FloatFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is greater than the given input. */
  gt?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is greater than or equal to the given input. */
  gte?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is lesser than the given input. */
  lt?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is lesser than or equal to the given input. */
  lte?: InputMaybe<Scalars['Float']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['Float']['input']>;
};

export type Geopoint = {
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  alt?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type GeopointFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  alt?: InputMaybe<FloatFilter>;
  lat?: InputMaybe<FloatFilter>;
  lng?: InputMaybe<FloatFilter>;
};

export type GeopointSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  alt?: InputMaybe<SortOrder>;
  lat?: InputMaybe<SortOrder>;
  lng?: InputMaybe<SortOrder>;
};

export type IdFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Checks if the value matches the given word/words. */
  matches?: InputMaybe<Scalars['ID']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['ID']['input']>;
  nin?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Image = {
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  asset?: Maybe<SanityImageAsset>;
  crop?: Maybe<SanityImageCrop>;
  hotspot?: Maybe<SanityImageHotspot>;
};

export type ImageFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  asset?: InputMaybe<SanityImageAssetFilter>;
  crop?: InputMaybe<SanityImageCropFilter>;
  hotspot?: InputMaybe<SanityImageHotspotFilter>;
};

export type ImageSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  crop?: InputMaybe<SanityImageCropSorting>;
  hotspot?: InputMaybe<SanityImageHotspotSorting>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum LineItemType {
  Donation = 'donation',
  Need = 'need',
  Tip = 'tip'
}

export type Location = {
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  point?: Maybe<Scalars['json']['output']>;
  /** state */
  region?: Maybe<Scalars['String']['output']>;
};

export type RequestDeleteUserOutput = {
  deleted: Scalars['Boolean']['output'];
};

export type RequestLoginTicketOutput = {
  login_ticket: Scalars['String']['output'];
};

export type RequestLoginTicketParams = {
  ttl?: InputMaybe<Scalars['Int']['input']>;
};

export type RunSignupTasksOutput = {
  clerk_id?: Maybe<Scalars['String']['output']>;
  signup_attempt: SignupAttempt;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type RunSignupTasksParams = {
  /** `clerk3-web` || `clerk3-native` */
  app_version: Scalars['String']['input'];
  /** Clerk signUp.id */
  sua_id: Scalars['String']['input'];
};

export type SanityAssetSourceData = {
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  /** The unique ID for the asset within the originating source so you can programatically find back to it */
  id?: Maybe<Scalars['String']['output']>;
  /** A canonical name for the source this asset is originating from */
  name?: Maybe<Scalars['String']['output']>;
  /** A URL to find more information about this asset in the originating source */
  url?: Maybe<Scalars['String']['output']>;
};

export type SanityAssetSourceDataFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityAssetSourceDataSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityFileAsset = Document & {
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  assetId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  originalFilename?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  sha1hash?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<SanityAssetSourceData>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SanityFileAssetFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  assetId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  extension?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  mimeType?: InputMaybe<StringFilter>;
  originalFilename?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  sha1hash?: InputMaybe<StringFilter>;
  size?: InputMaybe<FloatFilter>;
  source?: InputMaybe<SanityAssetSourceDataFilter>;
  title?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityFileAssetSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  extension?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  originalFilename?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  sha1hash?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  source?: InputMaybe<SanityAssetSourceDataSorting>;
  title?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityImageAsset = Document & {
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  assetId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<SanityImageMetadata>;
  mimeType?: Maybe<Scalars['String']['output']>;
  originalFilename?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  sha1hash?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<SanityAssetSourceData>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SanityImageAssetFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  assetId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  extension?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<SanityImageMetadataFilter>;
  mimeType?: InputMaybe<StringFilter>;
  originalFilename?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  sha1hash?: InputMaybe<StringFilter>;
  size?: InputMaybe<FloatFilter>;
  source?: InputMaybe<SanityAssetSourceDataFilter>;
  title?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type SanityImageAssetSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  assetId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  extension?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SanityImageMetadataSorting>;
  mimeType?: InputMaybe<SortOrder>;
  originalFilename?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  sha1hash?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  source?: InputMaybe<SanityAssetSourceDataSorting>;
  title?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SanityImageCrop = {
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  bottom?: Maybe<Scalars['Float']['output']>;
  left?: Maybe<Scalars['Float']['output']>;
  right?: Maybe<Scalars['Float']['output']>;
  top?: Maybe<Scalars['Float']['output']>;
};

export type SanityImageCropFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  bottom?: InputMaybe<FloatFilter>;
  left?: InputMaybe<FloatFilter>;
  right?: InputMaybe<FloatFilter>;
  top?: InputMaybe<FloatFilter>;
};

export type SanityImageCropSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  bottom?: InputMaybe<SortOrder>;
  left?: InputMaybe<SortOrder>;
  right?: InputMaybe<SortOrder>;
  top?: InputMaybe<SortOrder>;
};

export type SanityImageDimensions = {
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type SanityImageDimensionsFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  aspectRatio?: InputMaybe<FloatFilter>;
  height?: InputMaybe<FloatFilter>;
  width?: InputMaybe<FloatFilter>;
};

export type SanityImageDimensionsSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  aspectRatio?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
};

export type SanityImageHotspot = {
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

export type SanityImageHotspotFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  height?: InputMaybe<FloatFilter>;
  width?: InputMaybe<FloatFilter>;
  x?: InputMaybe<FloatFilter>;
  y?: InputMaybe<FloatFilter>;
};

export type SanityImageHotspotSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  height?: InputMaybe<SortOrder>;
  width?: InputMaybe<SortOrder>;
  x?: InputMaybe<SortOrder>;
  y?: InputMaybe<SortOrder>;
};

export type SanityImageMetadata = {
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  dimensions?: Maybe<SanityImageDimensions>;
  hasAlpha?: Maybe<Scalars['Boolean']['output']>;
  isOpaque?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Geopoint>;
  lqip?: Maybe<Scalars['String']['output']>;
  palette?: Maybe<SanityImagePalette>;
};

export type SanityImageMetadataFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  dimensions?: InputMaybe<SanityImageDimensionsFilter>;
  hasAlpha?: InputMaybe<BooleanFilter>;
  isOpaque?: InputMaybe<BooleanFilter>;
  location?: InputMaybe<GeopointFilter>;
  lqip?: InputMaybe<StringFilter>;
  palette?: InputMaybe<SanityImagePaletteFilter>;
};

export type SanityImageMetadataSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  dimensions?: InputMaybe<SanityImageDimensionsSorting>;
  hasAlpha?: InputMaybe<SortOrder>;
  isOpaque?: InputMaybe<SortOrder>;
  location?: InputMaybe<GeopointSorting>;
  lqip?: InputMaybe<SortOrder>;
  palette?: InputMaybe<SanityImagePaletteSorting>;
};

export type SanityImagePalette = {
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  darkMuted?: Maybe<SanityImagePaletteSwatch>;
  darkVibrant?: Maybe<SanityImagePaletteSwatch>;
  dominant?: Maybe<SanityImagePaletteSwatch>;
  lightMuted?: Maybe<SanityImagePaletteSwatch>;
  lightVibrant?: Maybe<SanityImagePaletteSwatch>;
  muted?: Maybe<SanityImagePaletteSwatch>;
  vibrant?: Maybe<SanityImagePaletteSwatch>;
};

export type SanityImagePaletteFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  darkMuted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  darkVibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  dominant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  lightMuted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  lightVibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
  muted?: InputMaybe<SanityImagePaletteSwatchFilter>;
  vibrant?: InputMaybe<SanityImagePaletteSwatchFilter>;
};

export type SanityImagePaletteSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  darkMuted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  darkVibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  dominant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  lightMuted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  lightVibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
  muted?: InputMaybe<SanityImagePaletteSwatchSorting>;
  vibrant?: InputMaybe<SanityImagePaletteSwatchSorting>;
};

export type SanityImagePaletteSwatch = {
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  foreground?: Maybe<Scalars['String']['output']>;
  population?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SanityImagePaletteSwatchFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  background?: InputMaybe<StringFilter>;
  foreground?: InputMaybe<StringFilter>;
  population?: InputMaybe<FloatFilter>;
  title?: InputMaybe<StringFilter>;
};

export type SanityImagePaletteSwatchSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  background?: InputMaybe<SortOrder>;
  foreground?: InputMaybe<SortOrder>;
  population?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type SignupAttempt = {
  abandon_at?: Maybe<Scalars['Int']['output']>;
  created_session_id?: Maybe<Scalars['String']['output']>;
  created_user_id?: Maybe<Scalars['String']['output']>;
  custom_action?: Maybe<Scalars['Boolean']['output']>;
  email_address?: Maybe<Scalars['String']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  missing_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Literal string 'sign_up_attempt' */
  object?: Maybe<Scalars['String']['output']>;
  optional_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  password_enabled?: Maybe<Scalars['Boolean']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  required_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<SignupAttemptStatus>;
  unverified_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  username?: Maybe<Scalars['String']['output']>;
  verifications?: Maybe<Verifications>;
  web3_wallet?: Maybe<Scalars['String']['output']>;
};

export enum SignupAttemptStatus {
  Abandoned = 'abandoned',
  Complete = 'complete',
  MissingRequirements = 'missing_requirements'
}

export type Slug = {
  _key?: Maybe<Scalars['String']['output']>;
  _type?: Maybe<Scalars['String']['output']>;
  current?: Maybe<Scalars['String']['output']>;
};

export type SlugFilter = {
  _key?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  current?: InputMaybe<StringFilter>;
};

export type SlugSorting = {
  _key?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  current?: InputMaybe<SortOrder>;
};

export enum SortOrder {
  /** Sorts on the value in ascending order. */
  Asc = 'ASC',
  /** Sorts on the value in descending order. */
  Desc = 'DESC'
}

export type StringFilter = {
  /** Checks if the value is equal to the given input. */
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Checks if the value matches the given word/words. */
  matches?: InputMaybe<Scalars['String']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type StringFilter_Remote_Rel_Teamscampaigns = {
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Checks if the value matches the given word/words. */
  matches?: InputMaybe<Scalars['String']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type StringFilter_Remote_Rel_Teamsupdates = {
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Checks if the value matches the given word/words. */
  matches?: InputMaybe<Scalars['String']['input']>;
  /** Checks if the value is not equal to the given input. */
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type Team = Document & {
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  authors?: Maybe<Array<Maybe<Author>>>;
  campaign?: Maybe<Campaign>;
  description?: Maybe<Scalars['String']['output']>;
  idString?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TeamFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  campaign?: InputMaybe<CampaignFilter>;
  description?: InputMaybe<StringFilter>;
  idString?: InputMaybe<StringFilter>;
  image?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
};

export type TeamFilter_Remote_Rel_Teamscampaigns = {
  /** Apply filters on document level */
  _?: InputMaybe<DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  campaign?: InputMaybe<CampaignFilter>;
  description?: InputMaybe<StringFilter>;
  idString?: InputMaybe<StringFilter_Remote_Rel_Teamscampaigns>;
  image?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
};

export type TeamFilter_Remote_Rel_Teamsupdates = {
  /** Apply filters on document level */
  _?: InputMaybe<DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  campaign?: InputMaybe<CampaignFilter>;
  description?: InputMaybe<StringFilter>;
  idString?: InputMaybe<StringFilter_Remote_Rel_Teamsupdates>;
  image?: InputMaybe<ImageFilter>;
  name?: InputMaybe<StringFilter>;
};

export type TeamSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  idString?: InputMaybe<SortOrder>;
  image?: InputMaybe<ImageSorting>;
  name?: InputMaybe<SortOrder>;
};

export type Transaction = {
  line_items?: Maybe<Array<Maybe<TransactionLineItem>>>;
  summary?: Maybe<TransactionSummary>;
};

export type TransactionLineItem = {
  amount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['json']['output']>;
  type: LineItemType;
};

export type TransactionSummary = {
  count: Scalars['Int']['output'];
  sum: Scalars['Int']['output'];
};

export type Update = Document & {
  /** Date the document was created */
  _createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Document ID */
  _id?: Maybe<Scalars['ID']['output']>;
  _key?: Maybe<Scalars['String']['output']>;
  /** Current document revision */
  _rev?: Maybe<Scalars['String']['output']>;
  /** Document type */
  _type?: Maybe<Scalars['String']['output']>;
  /** Date the document was last modified */
  _updatedAt?: Maybe<Scalars['DateTime']['output']>;
  author?: Maybe<Author>;
  bodyRaw?: Maybe<Scalars['JSON']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  team?: Maybe<Team>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UpdateFilter = {
  /** Apply filters on document level */
  _?: InputMaybe<DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  author?: InputMaybe<AuthorFilter>;
  publishedAt?: InputMaybe<DatetimeFilter>;
  team?: InputMaybe<TeamFilter>;
  title?: InputMaybe<StringFilter>;
};

export type UpdateFilter_Remote_Rel_Teamsupdates = {
  /** Apply filters on document level */
  _?: InputMaybe<DocumentFilter>;
  _createdAt?: InputMaybe<DatetimeFilter>;
  _id?: InputMaybe<IdFilter>;
  _key?: InputMaybe<StringFilter>;
  _rev?: InputMaybe<StringFilter>;
  _type?: InputMaybe<StringFilter>;
  _updatedAt?: InputMaybe<DatetimeFilter>;
  author?: InputMaybe<AuthorFilter>;
  publishedAt?: InputMaybe<DatetimeFilter>;
  team?: InputMaybe<TeamFilter_Remote_Rel_Teamsupdates>;
  title?: InputMaybe<StringFilter>;
};

export type UpdateSorting = {
  _createdAt?: InputMaybe<SortOrder>;
  _id?: InputMaybe<SortOrder>;
  _key?: InputMaybe<SortOrder>;
  _rev?: InputMaybe<SortOrder>;
  _type?: InputMaybe<SortOrder>;
  _updatedAt?: InputMaybe<SortOrder>;
  publishedAt?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
};

export type Verifications = {
  email_address?: Maybe<Scalars['String']['output']>;
  external_account?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  web3_wallet?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "addresses" */
export type Addresses = {
  addressable_id: Scalars['Int']['output'];
  addressable_type: Scalars['String']['output'];
  attention_to: Scalars['String']['output'];
  city: Scalars['String']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  /** A computed field, executes function "hello_world" */
  hw?: Maybe<Scalars['json']['output']>;
  id: Scalars['Int']['output'];
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  point?: Maybe<Scalars['geography']['output']>;
  sha: Scalars['bytea']['output'];
  state: Scalars['String']['output'];
  street_1: Scalars['String']['output'];
  street_2: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode: Scalars['String']['output'];
};


/** columns and relationships of "addresses" */
export type AddressesDistanceArgs = {
  args: Distance_Addresses_Args;
};


/** columns and relationships of "addresses" */
export type AddressesHwArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "addresses" */
export type Addresses_Aggregate = {
  aggregate?: Maybe<Addresses_Aggregate_Fields>;
  nodes: Array<Addresses>;
};

/** aggregate fields of "addresses" */
export type Addresses_Aggregate_Fields = {
  avg?: Maybe<Addresses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Addresses_Max_Fields>;
  min?: Maybe<Addresses_Min_Fields>;
  stddev?: Maybe<Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Addresses_Sum_Fields>;
  var_pop?: Maybe<Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Addresses_Var_Samp_Fields>;
  variance?: Maybe<Addresses_Variance_Fields>;
};


/** aggregate fields of "addresses" */
export type Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Addresses_Avg_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};


/** aggregate avg on columns */
export type Addresses_Avg_FieldsDistanceArgs = {
  args: Distance_Addresses_Args;
};

/** Boolean expression to filter rows from the table "addresses". All fields are combined with a logical 'AND'. */
export type Addresses_Bool_Exp = {
  _and?: InputMaybe<Array<Addresses_Bool_Exp>>;
  _not?: InputMaybe<Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<Addresses_Bool_Exp>>;
  addressable_id?: InputMaybe<Int_Comparison_Exp>;
  addressable_type?: InputMaybe<String_Comparison_Exp>;
  attention_to?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hw?: InputMaybe<Json_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Numeric_Comparison_Exp>;
  point?: InputMaybe<Geography_Comparison_Exp>;
  sha?: InputMaybe<Bytea_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street_1?: InputMaybe<String_Comparison_Exp>;
  street_2?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zipcode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "addresses" */
export enum Addresses_Constraint {
  /** unique or primary key constraint on columns "addressable_type", "addressable_id" */
  AddressesAddressableIdAddressableTypeKey = 'addresses_addressable_id_addressable_type_key',
  /** unique or primary key constraint on columns "addressable_type", "addressable_id" */
  AddressesAddressableIdIdx = 'addresses_addressable_id_idx',
  /** unique or primary key constraint on columns "id" */
  AddressesPkey = 'addresses_pkey'
}

/** input type for incrementing numeric columns in table "addresses" */
export type Addresses_Inc_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "addresses" */
export type Addresses_Insert_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Addresses_Max_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};


/** aggregate max on columns */
export type Addresses_Max_FieldsDistanceArgs = {
  args: Distance_Addresses_Args;
};

/** aggregate min on columns */
export type Addresses_Min_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};


/** aggregate min on columns */
export type Addresses_Min_FieldsDistanceArgs = {
  args: Distance_Addresses_Args;
};

/** response of any mutation on the table "addresses" */
export type Addresses_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Addresses>;
};

/** columns and relationships of "addresses_need_informations" */
export type Addresses_Need_Informations = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  need_information?: Maybe<Need_Informations>;
  point?: Maybe<Scalars['geography']['output']>;
  sha?: Maybe<Scalars['bytea']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "addresses_need_informations" */
export type Addresses_Need_Informations_Aggregate = {
  aggregate?: Maybe<Addresses_Need_Informations_Aggregate_Fields>;
  nodes: Array<Addresses_Need_Informations>;
};

/** aggregate fields of "addresses_need_informations" */
export type Addresses_Need_Informations_Aggregate_Fields = {
  avg?: Maybe<Addresses_Need_Informations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Addresses_Need_Informations_Max_Fields>;
  min?: Maybe<Addresses_Need_Informations_Min_Fields>;
  stddev?: Maybe<Addresses_Need_Informations_Stddev_Fields>;
  stddev_pop?: Maybe<Addresses_Need_Informations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Addresses_Need_Informations_Stddev_Samp_Fields>;
  sum?: Maybe<Addresses_Need_Informations_Sum_Fields>;
  var_pop?: Maybe<Addresses_Need_Informations_Var_Pop_Fields>;
  var_samp?: Maybe<Addresses_Need_Informations_Var_Samp_Fields>;
  variance?: Maybe<Addresses_Need_Informations_Variance_Fields>;
};


/** aggregate fields of "addresses_need_informations" */
export type Addresses_Need_Informations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Addresses_Need_Informations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Addresses_Need_Informations_Avg_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "addresses_need_informations". All fields are combined with a logical 'AND'. */
export type Addresses_Need_Informations_Bool_Exp = {
  _and?: InputMaybe<Array<Addresses_Need_Informations_Bool_Exp>>;
  _not?: InputMaybe<Addresses_Need_Informations_Bool_Exp>;
  _or?: InputMaybe<Array<Addresses_Need_Informations_Bool_Exp>>;
  addressable_id?: InputMaybe<Int_Comparison_Exp>;
  addressable_type?: InputMaybe<String_Comparison_Exp>;
  attention_to?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Numeric_Comparison_Exp>;
  need_information?: InputMaybe<Need_Informations_Bool_Exp>;
  point?: InputMaybe<Geography_Comparison_Exp>;
  sha?: InputMaybe<Bytea_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street_1?: InputMaybe<String_Comparison_Exp>;
  street_2?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zipcode?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "addresses_need_informations" */
export type Addresses_Need_Informations_Inc_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "addresses_need_informations" */
export type Addresses_Need_Informations_Insert_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  need_information?: InputMaybe<Need_Informations_Obj_Rel_Insert_Input>;
  point?: InputMaybe<Scalars['geography']['input']>;
  sha?: InputMaybe<Scalars['bytea']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Addresses_Need_Informations_Max_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Addresses_Need_Informations_Min_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "addresses_need_informations" */
export type Addresses_Need_Informations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Addresses_Need_Informations>;
};

/** input type for inserting object relation for remote table "addresses_need_informations" */
export type Addresses_Need_Informations_Obj_Rel_Insert_Input = {
  data: Addresses_Need_Informations_Insert_Input;
};

/** Ordering options when selecting data from "addresses_need_informations". */
export type Addresses_Need_Informations_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  addressable_type?: InputMaybe<Order_By>;
  attention_to?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_information?: InputMaybe<Need_Informations_Order_By>;
  point?: InputMaybe<Order_By>;
  sha?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street_1?: InputMaybe<Order_By>;
  street_2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** select columns of table "addresses_need_informations" */
export enum Addresses_Need_Informations_Select_Column {
  /** column name */
  AddressableId = 'addressable_id',
  /** column name */
  AddressableType = 'addressable_type',
  /** column name */
  AttentionTo = 'attention_to',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  Point = 'point',
  /** column name */
  Sha = 'sha',
  /** column name */
  State = 'state',
  /** column name */
  Street_1 = 'street_1',
  /** column name */
  Street_2 = 'street_2',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zipcode = 'zipcode'
}

/** input type for updating data in table "addresses_need_informations" */
export type Addresses_Need_Informations_Set_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  sha?: InputMaybe<Scalars['bytea']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Addresses_Need_Informations_Stddev_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Addresses_Need_Informations_Stddev_Pop_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Addresses_Need_Informations_Stddev_Samp_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "addresses_need_informations" */
export type Addresses_Need_Informations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Addresses_Need_Informations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Addresses_Need_Informations_Stream_Cursor_Value_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  sha?: InputMaybe<Scalars['bytea']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Addresses_Need_Informations_Sum_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
};

export type Addresses_Need_Informations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Addresses_Need_Informations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Addresses_Need_Informations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Addresses_Need_Informations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Addresses_Need_Informations_Var_Pop_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Addresses_Need_Informations_Var_Samp_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Addresses_Need_Informations_Variance_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** on_conflict condition type for table "addresses" */
export type Addresses_On_Conflict = {
  constraint: Addresses_Constraint;
  update_columns?: Array<Addresses_Update_Column>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "addresses". */
export type Addresses_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  addressable_type?: InputMaybe<Order_By>;
  attention_to?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  hw?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  sha?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street_1?: InputMaybe<Order_By>;
  street_2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** columns and relationships of "addresses_organizations" */
export type Addresses_Organizations = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  point?: Maybe<Scalars['geography']['output']>;
  sha?: Maybe<Scalars['bytea']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "addresses_organizations" */
export type Addresses_Organizations_Aggregate = {
  aggregate?: Maybe<Addresses_Organizations_Aggregate_Fields>;
  nodes: Array<Addresses_Organizations>;
};

/** aggregate fields of "addresses_organizations" */
export type Addresses_Organizations_Aggregate_Fields = {
  avg?: Maybe<Addresses_Organizations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Addresses_Organizations_Max_Fields>;
  min?: Maybe<Addresses_Organizations_Min_Fields>;
  stddev?: Maybe<Addresses_Organizations_Stddev_Fields>;
  stddev_pop?: Maybe<Addresses_Organizations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Addresses_Organizations_Stddev_Samp_Fields>;
  sum?: Maybe<Addresses_Organizations_Sum_Fields>;
  var_pop?: Maybe<Addresses_Organizations_Var_Pop_Fields>;
  var_samp?: Maybe<Addresses_Organizations_Var_Samp_Fields>;
  variance?: Maybe<Addresses_Organizations_Variance_Fields>;
};


/** aggregate fields of "addresses_organizations" */
export type Addresses_Organizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Addresses_Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Addresses_Organizations_Avg_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "addresses_organizations". All fields are combined with a logical 'AND'. */
export type Addresses_Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Addresses_Organizations_Bool_Exp>>;
  _not?: InputMaybe<Addresses_Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Addresses_Organizations_Bool_Exp>>;
  addressable_id?: InputMaybe<Int_Comparison_Exp>;
  addressable_type?: InputMaybe<String_Comparison_Exp>;
  attention_to?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Numeric_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  point?: InputMaybe<Geography_Comparison_Exp>;
  sha?: InputMaybe<Bytea_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street_1?: InputMaybe<String_Comparison_Exp>;
  street_2?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zipcode?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "addresses_organizations" */
export type Addresses_Organizations_Inc_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "addresses_organizations" */
export type Addresses_Organizations_Insert_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  point?: InputMaybe<Scalars['geography']['input']>;
  sha?: InputMaybe<Scalars['bytea']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Addresses_Organizations_Max_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Addresses_Organizations_Min_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "addresses_organizations" */
export type Addresses_Organizations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Addresses_Organizations>;
};

/** input type for inserting object relation for remote table "addresses_organizations" */
export type Addresses_Organizations_Obj_Rel_Insert_Input = {
  data: Addresses_Organizations_Insert_Input;
};

/** Ordering options when selecting data from "addresses_organizations". */
export type Addresses_Organizations_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  addressable_type?: InputMaybe<Order_By>;
  attention_to?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  point?: InputMaybe<Order_By>;
  sha?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street_1?: InputMaybe<Order_By>;
  street_2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** select columns of table "addresses_organizations" */
export enum Addresses_Organizations_Select_Column {
  /** column name */
  AddressableId = 'addressable_id',
  /** column name */
  AddressableType = 'addressable_type',
  /** column name */
  AttentionTo = 'attention_to',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  Point = 'point',
  /** column name */
  Sha = 'sha',
  /** column name */
  State = 'state',
  /** column name */
  Street_1 = 'street_1',
  /** column name */
  Street_2 = 'street_2',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zipcode = 'zipcode'
}

/** input type for updating data in table "addresses_organizations" */
export type Addresses_Organizations_Set_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  sha?: InputMaybe<Scalars['bytea']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Addresses_Organizations_Stddev_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Addresses_Organizations_Stddev_Pop_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Addresses_Organizations_Stddev_Samp_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "addresses_organizations" */
export type Addresses_Organizations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Addresses_Organizations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Addresses_Organizations_Stream_Cursor_Value_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  sha?: InputMaybe<Scalars['bytea']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Addresses_Organizations_Sum_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
};

export type Addresses_Organizations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Addresses_Organizations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Addresses_Organizations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Addresses_Organizations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Addresses_Organizations_Var_Pop_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Addresses_Organizations_Var_Samp_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Addresses_Organizations_Variance_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** primary key columns input for table: addresses */
export type Addresses_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "addresses" */
export enum Addresses_Select_Column {
  /** column name */
  AddressableId = 'addressable_id',
  /** column name */
  AddressableType = 'addressable_type',
  /** column name */
  AttentionTo = 'attention_to',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  Point = 'point',
  /** column name */
  Sha = 'sha',
  /** column name */
  State = 'state',
  /** column name */
  Street_1 = 'street_1',
  /** column name */
  Street_2 = 'street_2',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zipcode = 'zipcode'
}

/** input type for updating data in table "addresses" */
export type Addresses_Set_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Addresses_Stddev_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};


/** aggregate stddev on columns */
export type Addresses_Stddev_FieldsDistanceArgs = {
  args: Distance_Addresses_Args;
};

/** aggregate stddev_pop on columns */
export type Addresses_Stddev_Pop_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};


/** aggregate stddev_pop on columns */
export type Addresses_Stddev_Pop_FieldsDistanceArgs = {
  args: Distance_Addresses_Args;
};

/** aggregate stddev_samp on columns */
export type Addresses_Stddev_Samp_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};


/** aggregate stddev_samp on columns */
export type Addresses_Stddev_Samp_FieldsDistanceArgs = {
  args: Distance_Addresses_Args;
};

/** Streaming cursor of the table "addresses" */
export type Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Addresses_Stream_Cursor_Value_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  sha?: InputMaybe<Scalars['bytea']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Addresses_Sum_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
};


/** aggregate sum on columns */
export type Addresses_Sum_FieldsDistanceArgs = {
  args: Distance_Addresses_Args;
};

/** update columns of table "addresses" */
export enum Addresses_Update_Column {
  /** column name */
  AddressableId = 'addressable_id',
  /** column name */
  AddressableType = 'addressable_type',
  /** column name */
  AttentionTo = 'attention_to',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Point = 'point',
  /** column name */
  State = 'state',
  /** column name */
  Street_1 = 'street_1',
  /** column name */
  Street_2 = 'street_2',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zipcode = 'zipcode'
}

export type Addresses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Addresses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Addresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Addresses_Bool_Exp;
};

/** columns and relationships of "addresses_users" */
export type Addresses_Users = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  point?: Maybe<Scalars['geography']['output']>;
  sha?: Maybe<Scalars['bytea']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "addresses_users" */
export type Addresses_Users_Aggregate = {
  aggregate?: Maybe<Addresses_Users_Aggregate_Fields>;
  nodes: Array<Addresses_Users>;
};

export type Addresses_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Addresses_Users_Aggregate_Bool_Exp_Count>;
};

export type Addresses_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Addresses_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Addresses_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "addresses_users" */
export type Addresses_Users_Aggregate_Fields = {
  avg?: Maybe<Addresses_Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Addresses_Users_Max_Fields>;
  min?: Maybe<Addresses_Users_Min_Fields>;
  stddev?: Maybe<Addresses_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Addresses_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Addresses_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Addresses_Users_Sum_Fields>;
  var_pop?: Maybe<Addresses_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Addresses_Users_Var_Samp_Fields>;
  variance?: Maybe<Addresses_Users_Variance_Fields>;
};


/** aggregate fields of "addresses_users" */
export type Addresses_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Addresses_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "addresses_users" */
export type Addresses_Users_Aggregate_Order_By = {
  avg?: InputMaybe<Addresses_Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Addresses_Users_Max_Order_By>;
  min?: InputMaybe<Addresses_Users_Min_Order_By>;
  stddev?: InputMaybe<Addresses_Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Addresses_Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Addresses_Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Addresses_Users_Sum_Order_By>;
  var_pop?: InputMaybe<Addresses_Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Addresses_Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Addresses_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "addresses_users" */
export type Addresses_Users_Arr_Rel_Insert_Input = {
  data: Array<Addresses_Users_Insert_Input>;
};

/** aggregate avg on columns */
export type Addresses_Users_Avg_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "addresses_users" */
export type Addresses_Users_Avg_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "addresses_users". All fields are combined with a logical 'AND'. */
export type Addresses_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Addresses_Users_Bool_Exp>>;
  _not?: InputMaybe<Addresses_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Addresses_Users_Bool_Exp>>;
  addressable_id?: InputMaybe<Int_Comparison_Exp>;
  addressable_type?: InputMaybe<String_Comparison_Exp>;
  attention_to?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Numeric_Comparison_Exp>;
  point?: InputMaybe<Geography_Comparison_Exp>;
  sha?: InputMaybe<Bytea_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street_1?: InputMaybe<String_Comparison_Exp>;
  street_2?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  zipcode?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "addresses_users" */
export type Addresses_Users_Inc_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "addresses_users" */
export type Addresses_Users_Insert_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  sha?: InputMaybe<Scalars['bytea']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Addresses_Users_Max_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "addresses_users" */
export type Addresses_Users_Max_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  addressable_type?: InputMaybe<Order_By>;
  attention_to?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street_1?: InputMaybe<Order_By>;
  street_2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Addresses_Users_Min_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  addressable_type?: Maybe<Scalars['String']['output']>;
  attention_to?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street_1?: Maybe<Scalars['String']['output']>;
  street_2?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "addresses_users" */
export type Addresses_Users_Min_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  addressable_type?: InputMaybe<Order_By>;
  attention_to?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street_1?: InputMaybe<Order_By>;
  street_2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "addresses_users" */
export type Addresses_Users_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Addresses_Users>;
};

/** Ordering options when selecting data from "addresses_users". */
export type Addresses_Users_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  addressable_type?: InputMaybe<Order_By>;
  attention_to?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  sha?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street_1?: InputMaybe<Order_By>;
  street_2?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** select columns of table "addresses_users" */
export enum Addresses_Users_Select_Column {
  /** column name */
  AddressableId = 'addressable_id',
  /** column name */
  AddressableType = 'addressable_type',
  /** column name */
  AttentionTo = 'attention_to',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  Point = 'point',
  /** column name */
  Sha = 'sha',
  /** column name */
  State = 'state',
  /** column name */
  Street_1 = 'street_1',
  /** column name */
  Street_2 = 'street_2',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zipcode = 'zipcode'
}

/** input type for updating data in table "addresses_users" */
export type Addresses_Users_Set_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  sha?: InputMaybe<Scalars['bytea']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Addresses_Users_Stddev_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "addresses_users" */
export type Addresses_Users_Stddev_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Addresses_Users_Stddev_Pop_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "addresses_users" */
export type Addresses_Users_Stddev_Pop_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Addresses_Users_Stddev_Samp_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "addresses_users" */
export type Addresses_Users_Stddev_Samp_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "addresses_users" */
export type Addresses_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Addresses_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Addresses_Users_Stream_Cursor_Value_Input = {
  addressable_id?: InputMaybe<Scalars['Int']['input']>;
  addressable_type?: InputMaybe<Scalars['String']['input']>;
  attention_to?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  sha?: InputMaybe<Scalars['bytea']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street_1?: InputMaybe<Scalars['String']['input']>;
  street_2?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Addresses_Users_Sum_Fields = {
  addressable_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "addresses_users" */
export type Addresses_Users_Sum_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
};

export type Addresses_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Addresses_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Addresses_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Addresses_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Addresses_Users_Var_Pop_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "addresses_users" */
export type Addresses_Users_Var_Pop_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Addresses_Users_Var_Samp_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "addresses_users" */
export type Addresses_Users_Var_Samp_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Addresses_Users_Variance_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "addresses_users" */
export type Addresses_Users_Variance_Order_By = {
  addressable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Addresses_Var_Pop_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};


/** aggregate var_pop on columns */
export type Addresses_Var_Pop_FieldsDistanceArgs = {
  args: Distance_Addresses_Args;
};

/** aggregate var_samp on columns */
export type Addresses_Var_Samp_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};


/** aggregate var_samp on columns */
export type Addresses_Var_Samp_FieldsDistanceArgs = {
  args: Distance_Addresses_Args;
};

/** aggregate variance on columns */
export type Addresses_Variance_Fields = {
  addressable_id?: Maybe<Scalars['Float']['output']>;
  /** A computed field, executes function "f_point_dist_addresses" */
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};


/** aggregate variance on columns */
export type Addresses_Variance_FieldsDistanceArgs = {
  args: Distance_Addresses_Args;
};

/** columns and relationships of "aliases" */
export type Aliases = {
  alias?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  global_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  v_alias?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "aliases" */
export type Aliases_Aggregate = {
  aggregate?: Maybe<Aliases_Aggregate_Fields>;
  nodes: Array<Aliases>;
};

/** aggregate fields of "aliases" */
export type Aliases_Aggregate_Fields = {
  avg?: Maybe<Aliases_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Aliases_Max_Fields>;
  min?: Maybe<Aliases_Min_Fields>;
  stddev?: Maybe<Aliases_Stddev_Fields>;
  stddev_pop?: Maybe<Aliases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Aliases_Stddev_Samp_Fields>;
  sum?: Maybe<Aliases_Sum_Fields>;
  var_pop?: Maybe<Aliases_Var_Pop_Fields>;
  var_samp?: Maybe<Aliases_Var_Samp_Fields>;
  variance?: Maybe<Aliases_Variance_Fields>;
};


/** aggregate fields of "aliases" */
export type Aliases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Aliases_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Aliases_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "aliases". All fields are combined with a logical 'AND'. */
export type Aliases_Bool_Exp = {
  _and?: InputMaybe<Array<Aliases_Bool_Exp>>;
  _not?: InputMaybe<Aliases_Bool_Exp>;
  _or?: InputMaybe<Array<Aliases_Bool_Exp>>;
  alias?: InputMaybe<String_Comparison_Exp>;
  entity?: InputMaybe<String_Comparison_Exp>;
  global_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  v_alias?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Aliases_Max_Fields = {
  alias?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  global_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  v_alias?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Aliases_Min_Fields = {
  alias?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  global_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  v_alias?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "aliases". */
export type Aliases_Order_By = {
  alias?: InputMaybe<Order_By>;
  entity?: InputMaybe<Order_By>;
  global_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  v_alias?: InputMaybe<Order_By>;
};

/** select columns of table "aliases" */
export enum Aliases_Select_Column {
  /** column name */
  Alias = 'alias',
  /** column name */
  Entity = 'entity',
  /** column name */
  GlobalId = 'global_id',
  /** column name */
  Id = 'id',
  /** column name */
  VAlias = 'v_alias'
}

/** aggregate stddev on columns */
export type Aliases_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Aliases_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Aliases_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "aliases" */
export type Aliases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Aliases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Aliases_Stream_Cursor_Value_Input = {
  alias?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  global_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  v_alias?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Aliases_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Aliases_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Aliases_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Aliases_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** Boolean expression to compare columns of type "bit". All fields are combined with logical 'AND'. */
export type Bit_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bit']['input']>;
  _gt?: InputMaybe<Scalars['bit']['input']>;
  _gte?: InputMaybe<Scalars['bit']['input']>;
  _in?: InputMaybe<Array<Scalars['bit']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bit']['input']>;
  _lte?: InputMaybe<Scalars['bit']['input']>;
  _neq?: InputMaybe<Scalars['bit']['input']>;
  _nin?: InputMaybe<Array<Scalars['bit']['input']>>;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type Bytea_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bytea']['input']>;
  _gt?: InputMaybe<Scalars['bytea']['input']>;
  _gte?: InputMaybe<Scalars['bytea']['input']>;
  _in?: InputMaybe<Array<Scalars['bytea']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bytea']['input']>;
  _lte?: InputMaybe<Scalars['bytea']['input']>;
  _neq?: InputMaybe<Scalars['bytea']['input']>;
  _nin?: InputMaybe<Array<Scalars['bytea']['input']>>;
};

/** snaplet@skip */
export type Checkout_Intents = {
  checkout_session_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  /** An object relationship */
  need: Needs;
  need_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamp']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "checkout_intents" */
export type Checkout_Intents_Aggregate = {
  aggregate?: Maybe<Checkout_Intents_Aggregate_Fields>;
  nodes: Array<Checkout_Intents>;
};

export type Checkout_Intents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Checkout_Intents_Aggregate_Bool_Exp_Count>;
};

export type Checkout_Intents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Checkout_Intents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Checkout_Intents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "checkout_intents" */
export type Checkout_Intents_Aggregate_Fields = {
  avg?: Maybe<Checkout_Intents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Checkout_Intents_Max_Fields>;
  min?: Maybe<Checkout_Intents_Min_Fields>;
  stddev?: Maybe<Checkout_Intents_Stddev_Fields>;
  stddev_pop?: Maybe<Checkout_Intents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Checkout_Intents_Stddev_Samp_Fields>;
  sum?: Maybe<Checkout_Intents_Sum_Fields>;
  var_pop?: Maybe<Checkout_Intents_Var_Pop_Fields>;
  var_samp?: Maybe<Checkout_Intents_Var_Samp_Fields>;
  variance?: Maybe<Checkout_Intents_Variance_Fields>;
};


/** aggregate fields of "checkout_intents" */
export type Checkout_Intents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Checkout_Intents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "checkout_intents" */
export type Checkout_Intents_Aggregate_Order_By = {
  avg?: InputMaybe<Checkout_Intents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Checkout_Intents_Max_Order_By>;
  min?: InputMaybe<Checkout_Intents_Min_Order_By>;
  stddev?: InputMaybe<Checkout_Intents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Checkout_Intents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Checkout_Intents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Checkout_Intents_Sum_Order_By>;
  var_pop?: InputMaybe<Checkout_Intents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Checkout_Intents_Var_Samp_Order_By>;
  variance?: InputMaybe<Checkout_Intents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "checkout_intents" */
export type Checkout_Intents_Arr_Rel_Insert_Input = {
  data: Array<Checkout_Intents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Checkout_Intents_On_Conflict>;
};

/** aggregate avg on columns */
export type Checkout_Intents_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "checkout_intents" */
export type Checkout_Intents_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "checkout_intents". All fields are combined with a logical 'AND'. */
export type Checkout_Intents_Bool_Exp = {
  _and?: InputMaybe<Array<Checkout_Intents_Bool_Exp>>;
  _not?: InputMaybe<Checkout_Intents_Bool_Exp>;
  _or?: InputMaybe<Array<Checkout_Intents_Bool_Exp>>;
  checkout_session_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  need?: InputMaybe<Needs_Bool_Exp>;
  need_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "checkout_intents" */
export enum Checkout_Intents_Constraint {
  /** unique or primary key constraint on columns "checkout_session_id" */
  CheckoutIntentsCheckoutSessionIdKey = 'checkout_intents_checkout_session_id_key',
  /** unique or primary key constraint on columns "need_id" */
  CheckoutIntentsNeedIdKey = 'checkout_intents_need_id_key',
  /** unique or primary key constraint on columns "id" */
  CheckoutIntentsPkey = 'checkout_intents_pkey'
}

/** input type for incrementing numeric columns in table "checkout_intents" */
export type Checkout_Intents_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "checkout_intents" */
export type Checkout_Intents_Insert_Input = {
  checkout_session_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need?: InputMaybe<Needs_Obj_Rel_Insert_Input>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Checkout_Intents_Max_Fields = {
  checkout_session_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "checkout_intents" */
export type Checkout_Intents_Max_Order_By = {
  checkout_session_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Checkout_Intents_Min_Fields = {
  checkout_session_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "checkout_intents" */
export type Checkout_Intents_Min_Order_By = {
  checkout_session_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "checkout_intents" */
export type Checkout_Intents_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Checkout_Intents>;
};

/** input type for inserting object relation for remote table "checkout_intents" */
export type Checkout_Intents_Obj_Rel_Insert_Input = {
  data: Checkout_Intents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Checkout_Intents_On_Conflict>;
};

/** on_conflict condition type for table "checkout_intents" */
export type Checkout_Intents_On_Conflict = {
  constraint: Checkout_Intents_Constraint;
  update_columns?: Array<Checkout_Intents_Update_Column>;
  where?: InputMaybe<Checkout_Intents_Bool_Exp>;
};

/** Ordering options when selecting data from "checkout_intents". */
export type Checkout_Intents_Order_By = {
  checkout_session_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need?: InputMaybe<Needs_Order_By>;
  need_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: checkout_intents */
export type Checkout_Intents_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "checkout_intents" */
export enum Checkout_Intents_Select_Column {
  /** column name */
  CheckoutSessionId = 'checkout_session_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "checkout_intents" */
export type Checkout_Intents_Set_Input = {
  checkout_session_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Checkout_Intents_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "checkout_intents" */
export type Checkout_Intents_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Checkout_Intents_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "checkout_intents" */
export type Checkout_Intents_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Checkout_Intents_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "checkout_intents" */
export type Checkout_Intents_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "checkout_intents" */
export type Checkout_Intents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Checkout_Intents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Checkout_Intents_Stream_Cursor_Value_Input = {
  checkout_session_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Checkout_Intents_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "checkout_intents" */
export type Checkout_Intents_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "checkout_intents" */
export enum Checkout_Intents_Update_Column {
  /** column name */
  CheckoutSessionId = 'checkout_session_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Checkout_Intents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Checkout_Intents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Checkout_Intents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Checkout_Intents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Checkout_Intents_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "checkout_intents" */
export type Checkout_Intents_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Checkout_Intents_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "checkout_intents" */
export type Checkout_Intents_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Checkout_Intents_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "checkout_intents" */
export type Checkout_Intents_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "devices" */
export type Devices = {
  /** @example `com.purposity.app`, `com.purposity.app.dev`, etc. */
  bundle_id: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  /** A combination of platform and device types (expo-device DeviceType) @example `ios:phone`, `ios:tablet`, `macos:desktop`, `web:phone`, `web:desktop`, etc. */
  device_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** A persistent, cross-install unique device id generated locally on the client; Can only be modified manually in the keychain, or if Apple changes Keychain/NSDefaults APIs; When a backup is restored on a new device, can temporarily reflect the old device’s id – call resync method before storing @example `123e4567-e89b-12d3-a456-426614174000` */
  local_id: Scalars['uuid']['output'];
  token?: Maybe<Scalars['String']['output']>;
  token_type?: Maybe<Scalars['push_token_type']['output']>;
  updated_at: Scalars['timestamp']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "devices" */
export type Devices_Aggregate = {
  aggregate?: Maybe<Devices_Aggregate_Fields>;
  nodes: Array<Devices>;
};

export type Devices_Aggregate_Bool_Exp = {
  count?: InputMaybe<Devices_Aggregate_Bool_Exp_Count>;
};

export type Devices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Devices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "devices" */
export type Devices_Aggregate_Fields = {
  avg?: Maybe<Devices_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Devices_Max_Fields>;
  min?: Maybe<Devices_Min_Fields>;
  stddev?: Maybe<Devices_Stddev_Fields>;
  stddev_pop?: Maybe<Devices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Devices_Stddev_Samp_Fields>;
  sum?: Maybe<Devices_Sum_Fields>;
  var_pop?: Maybe<Devices_Var_Pop_Fields>;
  var_samp?: Maybe<Devices_Var_Samp_Fields>;
  variance?: Maybe<Devices_Variance_Fields>;
};


/** aggregate fields of "devices" */
export type Devices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "devices" */
export type Devices_Aggregate_Order_By = {
  avg?: InputMaybe<Devices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Devices_Max_Order_By>;
  min?: InputMaybe<Devices_Min_Order_By>;
  stddev?: InputMaybe<Devices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Devices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Devices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Devices_Sum_Order_By>;
  var_pop?: InputMaybe<Devices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Devices_Var_Samp_Order_By>;
  variance?: InputMaybe<Devices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "devices" */
export type Devices_Arr_Rel_Insert_Input = {
  data: Array<Devices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};

/** aggregate avg on columns */
export type Devices_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "devices" */
export type Devices_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "devices". All fields are combined with a logical 'AND'. */
export type Devices_Bool_Exp = {
  _and?: InputMaybe<Array<Devices_Bool_Exp>>;
  _not?: InputMaybe<Devices_Bool_Exp>;
  _or?: InputMaybe<Array<Devices_Bool_Exp>>;
  bundle_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  device_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  local_id?: InputMaybe<Uuid_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  token_type?: InputMaybe<Push_Token_Type_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "devices" */
export enum Devices_Constraint {
  /** unique or primary key constraint on columns "id" */
  DevicesIdKey = 'devices_id_key',
  /** unique or primary key constraint on columns "bundle_id", "local_id" */
  DevicesPkey = 'devices_pkey'
}

/** input type for incrementing numeric columns in table "devices" */
export type Devices_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "devices" */
export type Devices_Insert_Input = {
  /** @example `com.purposity.app`, `com.purposity.app.dev`, etc. */
  bundle_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  /** A combination of platform and device types (expo-device DeviceType) @example `ios:phone`, `ios:tablet`, `macos:desktop`, `web:phone`, `web:desktop`, etc. */
  device_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  /** A persistent, cross-install unique device id generated locally on the client; Can only be modified manually in the keychain, or if Apple changes Keychain/NSDefaults APIs; When a backup is restored on a new device, can temporarily reflect the old device’s id – call resync method before storing @example `123e4567-e89b-12d3-a456-426614174000` */
  local_id?: InputMaybe<Scalars['uuid']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_type?: InputMaybe<Scalars['push_token_type']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Devices_Max_Fields = {
  /** @example `com.purposity.app`, `com.purposity.app.dev`, etc. */
  bundle_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  /** A combination of platform and device types (expo-device DeviceType) @example `ios:phone`, `ios:tablet`, `macos:desktop`, `web:phone`, `web:desktop`, etc. */
  device_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** A persistent, cross-install unique device id generated locally on the client; Can only be modified manually in the keychain, or if Apple changes Keychain/NSDefaults APIs; When a backup is restored on a new device, can temporarily reflect the old device’s id – call resync method before storing @example `123e4567-e89b-12d3-a456-426614174000` */
  local_id?: Maybe<Scalars['uuid']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  token_type?: Maybe<Scalars['push_token_type']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "devices" */
export type Devices_Max_Order_By = {
  /** @example `com.purposity.app`, `com.purposity.app.dev`, etc. */
  bundle_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** A combination of platform and device types (expo-device DeviceType) @example `ios:phone`, `ios:tablet`, `macos:desktop`, `web:phone`, `web:desktop`, etc. */
  device_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** A persistent, cross-install unique device id generated locally on the client; Can only be modified manually in the keychain, or if Apple changes Keychain/NSDefaults APIs; When a backup is restored on a new device, can temporarily reflect the old device’s id – call resync method before storing @example `123e4567-e89b-12d3-a456-426614174000` */
  local_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  token_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Devices_Min_Fields = {
  /** @example `com.purposity.app`, `com.purposity.app.dev`, etc. */
  bundle_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  /** A combination of platform and device types (expo-device DeviceType) @example `ios:phone`, `ios:tablet`, `macos:desktop`, `web:phone`, `web:desktop`, etc. */
  device_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** A persistent, cross-install unique device id generated locally on the client; Can only be modified manually in the keychain, or if Apple changes Keychain/NSDefaults APIs; When a backup is restored on a new device, can temporarily reflect the old device’s id – call resync method before storing @example `123e4567-e89b-12d3-a456-426614174000` */
  local_id?: Maybe<Scalars['uuid']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  token_type?: Maybe<Scalars['push_token_type']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "devices" */
export type Devices_Min_Order_By = {
  /** @example `com.purposity.app`, `com.purposity.app.dev`, etc. */
  bundle_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** A combination of platform and device types (expo-device DeviceType) @example `ios:phone`, `ios:tablet`, `macos:desktop`, `web:phone`, `web:desktop`, etc. */
  device_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** A persistent, cross-install unique device id generated locally on the client; Can only be modified manually in the keychain, or if Apple changes Keychain/NSDefaults APIs; When a backup is restored on a new device, can temporarily reflect the old device’s id – call resync method before storing @example `123e4567-e89b-12d3-a456-426614174000` */
  local_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  token_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "devices" */
export type Devices_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Devices>;
};

/** on_conflict condition type for table "devices" */
export type Devices_On_Conflict = {
  constraint: Devices_Constraint;
  update_columns?: Array<Devices_Update_Column>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

/** Ordering options when selecting data from "devices". */
export type Devices_Order_By = {
  bundle_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  device_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  local_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  token_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: devices */
export type Devices_Pk_Columns_Input = {
  /** @example `com.purposity.app`, `com.purposity.app.dev`, etc. */
  bundle_id: Scalars['String']['input'];
  /** A persistent, cross-install unique device id generated locally on the client; Can only be modified manually in the keychain, or if Apple changes Keychain/NSDefaults APIs; When a backup is restored on a new device, can temporarily reflect the old device’s id – call resync method before storing @example `123e4567-e89b-12d3-a456-426614174000` */
  local_id: Scalars['uuid']['input'];
};

/** select columns of table "devices" */
export enum Devices_Select_Column {
  /** column name */
  BundleId = 'bundle_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceType = 'device_type',
  /** column name */
  Id = 'id',
  /** column name */
  LocalId = 'local_id',
  /** column name */
  Token = 'token',
  /** column name */
  TokenType = 'token_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "devices" */
export type Devices_Set_Input = {
  /** @example `com.purposity.app`, `com.purposity.app.dev`, etc. */
  bundle_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  /** A combination of platform and device types (expo-device DeviceType) @example `ios:phone`, `ios:tablet`, `macos:desktop`, `web:phone`, `web:desktop`, etc. */
  device_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  /** A persistent, cross-install unique device id generated locally on the client; Can only be modified manually in the keychain, or if Apple changes Keychain/NSDefaults APIs; When a backup is restored on a new device, can temporarily reflect the old device’s id – call resync method before storing @example `123e4567-e89b-12d3-a456-426614174000` */
  local_id?: InputMaybe<Scalars['uuid']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_type?: InputMaybe<Scalars['push_token_type']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Devices_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "devices" */
export type Devices_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Devices_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "devices" */
export type Devices_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Devices_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "devices" */
export type Devices_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "devices" */
export type Devices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Devices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Devices_Stream_Cursor_Value_Input = {
  /** @example `com.purposity.app`, `com.purposity.app.dev`, etc. */
  bundle_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  /** A combination of platform and device types (expo-device DeviceType) @example `ios:phone`, `ios:tablet`, `macos:desktop`, `web:phone`, `web:desktop`, etc. */
  device_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  /** A persistent, cross-install unique device id generated locally on the client; Can only be modified manually in the keychain, or if Apple changes Keychain/NSDefaults APIs; When a backup is restored on a new device, can temporarily reflect the old device’s id – call resync method before storing @example `123e4567-e89b-12d3-a456-426614174000` */
  local_id?: InputMaybe<Scalars['uuid']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  token_type?: InputMaybe<Scalars['push_token_type']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Devices_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "devices" */
export type Devices_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "devices" */
export enum Devices_Update_Column {
  /** column name */
  BundleId = 'bundle_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeviceType = 'device_type',
  /** column name */
  Id = 'id',
  /** column name */
  LocalId = 'local_id',
  /** column name */
  Token = 'token',
  /** column name */
  TokenType = 'token_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Devices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Devices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Devices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Devices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Devices_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "devices" */
export type Devices_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Devices_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "devices" */
export type Devices_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Devices_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "devices" */
export type Devices_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Distance_Addresses_Args = {
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
};

/** snaplet@skip */
export type Feed_Items = {
  body?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  /** An object relationship */
  feed_post?: Maybe<Feed_Posts>;
  feed_post_id?: Maybe<Scalars['Int']['output']>;
  feedable_id?: Maybe<Scalars['Int']['output']>;
  feedable_type: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  /** An object relationship */
  need?: Maybe<Needs>;
  need_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  post?: Maybe<Posts>;
  post_id?: Maybe<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  /** An object relationship */
  story?: Maybe<Stories>;
  story_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  thank_you?: Maybe<Thank_Yous>;
  thank_you_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "feed_items" */
export type Feed_Items_Aggregate = {
  aggregate?: Maybe<Feed_Items_Aggregate_Fields>;
  nodes: Array<Feed_Items>;
};

export type Feed_Items_Aggregate_Bool_Exp = {
  count?: InputMaybe<Feed_Items_Aggregate_Bool_Exp_Count>;
};

export type Feed_Items_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Feed_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Feed_Items_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "feed_items" */
export type Feed_Items_Aggregate_Fields = {
  avg?: Maybe<Feed_Items_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Feed_Items_Max_Fields>;
  min?: Maybe<Feed_Items_Min_Fields>;
  stddev?: Maybe<Feed_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Feed_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Feed_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Feed_Items_Sum_Fields>;
  var_pop?: Maybe<Feed_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Feed_Items_Var_Samp_Fields>;
  variance?: Maybe<Feed_Items_Variance_Fields>;
};


/** aggregate fields of "feed_items" */
export type Feed_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feed_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "feed_items" */
export type Feed_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Feed_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Feed_Items_Max_Order_By>;
  min?: InputMaybe<Feed_Items_Min_Order_By>;
  stddev?: InputMaybe<Feed_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Feed_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Feed_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Feed_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Feed_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Feed_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Feed_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "feed_items" */
export type Feed_Items_Arr_Rel_Insert_Input = {
  data: Array<Feed_Items_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Feed_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Feed_Items_Avg_Fields = {
  feed_post_id?: Maybe<Scalars['Float']['output']>;
  feedable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
  thank_you_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "feed_items" */
export type Feed_Items_Avg_Order_By = {
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "feed_items". All fields are combined with a logical 'AND'. */
export type Feed_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Feed_Items_Bool_Exp>>;
  _not?: InputMaybe<Feed_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Feed_Items_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  feed_post?: InputMaybe<Feed_Posts_Bool_Exp>;
  feed_post_id?: InputMaybe<Int_Comparison_Exp>;
  feedable_id?: InputMaybe<Int_Comparison_Exp>;
  feedable_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  need?: InputMaybe<Needs_Bool_Exp>;
  need_id?: InputMaybe<Int_Comparison_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Int_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  story?: InputMaybe<Stories_Bool_Exp>;
  story_id?: InputMaybe<Int_Comparison_Exp>;
  thank_you?: InputMaybe<Thank_Yous_Bool_Exp>;
  thank_you_id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "feed_items" */
export enum Feed_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeedItemsPkey = 'feed_items_pkey'
}

/** input type for incrementing numeric columns in table "feed_items" */
export type Feed_Items_Inc_Input = {
  feed_post_id?: InputMaybe<Scalars['Int']['input']>;
  feedable_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  story_id?: InputMaybe<Scalars['Int']['input']>;
  thank_you_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "feed_items" */
export type Feed_Items_Insert_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  feed_post?: InputMaybe<Feed_Posts_Obj_Rel_Insert_Input>;
  feed_post_id?: InputMaybe<Scalars['Int']['input']>;
  feedable_id?: InputMaybe<Scalars['Int']['input']>;
  feedable_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need?: InputMaybe<Needs_Obj_Rel_Insert_Input>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  story?: InputMaybe<Stories_Obj_Rel_Insert_Input>;
  story_id?: InputMaybe<Scalars['Int']['input']>;
  thank_you?: InputMaybe<Thank_Yous_Obj_Rel_Insert_Input>;
  thank_you_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Feed_Items_Max_Fields = {
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  feed_post_id?: Maybe<Scalars['Int']['output']>;
  feedable_id?: Maybe<Scalars['Int']['output']>;
  feedable_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  story_id?: Maybe<Scalars['Int']['output']>;
  thank_you_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "feed_items" */
export type Feed_Items_Max_Order_By = {
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  feedable_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Feed_Items_Min_Fields = {
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  feed_post_id?: Maybe<Scalars['Int']['output']>;
  feedable_id?: Maybe<Scalars['Int']['output']>;
  feedable_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  story_id?: Maybe<Scalars['Int']['output']>;
  thank_you_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "feed_items" */
export type Feed_Items_Min_Order_By = {
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  feedable_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "feed_items" */
export type Feed_Items_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Feed_Items>;
};

/** on_conflict condition type for table "feed_items" */
export type Feed_Items_On_Conflict = {
  constraint: Feed_Items_Constraint;
  update_columns?: Array<Feed_Items_Update_Column>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "feed_items". */
export type Feed_Items_Order_By = {
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feed_post?: InputMaybe<Feed_Posts_Order_By>;
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  feedable_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need?: InputMaybe<Needs_Order_By>;
  need_id?: InputMaybe<Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  story?: InputMaybe<Stories_Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you?: InputMaybe<Thank_Yous_Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feed_items */
export type Feed_Items_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "feed_items" */
export enum Feed_Items_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedPostId = 'feed_post_id',
  /** column name */
  FeedableId = 'feedable_id',
  /** column name */
  FeedableType = 'feedable_type',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  State = 'state',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  ThankYouId = 'thank_you_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "feed_items" */
export type Feed_Items_Set_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  feed_post_id?: InputMaybe<Scalars['Int']['input']>;
  feedable_id?: InputMaybe<Scalars['Int']['input']>;
  feedable_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  story_id?: InputMaybe<Scalars['Int']['input']>;
  thank_you_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Feed_Items_Stddev_Fields = {
  feed_post_id?: Maybe<Scalars['Float']['output']>;
  feedable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
  thank_you_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "feed_items" */
export type Feed_Items_Stddev_Order_By = {
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Feed_Items_Stddev_Pop_Fields = {
  feed_post_id?: Maybe<Scalars['Float']['output']>;
  feedable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
  thank_you_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "feed_items" */
export type Feed_Items_Stddev_Pop_Order_By = {
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Feed_Items_Stddev_Samp_Fields = {
  feed_post_id?: Maybe<Scalars['Float']['output']>;
  feedable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
  thank_you_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "feed_items" */
export type Feed_Items_Stddev_Samp_Order_By = {
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "feed_items" */
export type Feed_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feed_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feed_Items_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  feed_post_id?: InputMaybe<Scalars['Int']['input']>;
  feedable_id?: InputMaybe<Scalars['Int']['input']>;
  feedable_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  post_id?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  story_id?: InputMaybe<Scalars['Int']['input']>;
  thank_you_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Feed_Items_Sum_Fields = {
  feed_post_id?: Maybe<Scalars['Int']['output']>;
  feedable_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  story_id?: Maybe<Scalars['Int']['output']>;
  thank_you_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "feed_items" */
export type Feed_Items_Sum_Order_By = {
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "feed_items" */
export enum Feed_Items_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedPostId = 'feed_post_id',
  /** column name */
  FeedableId = 'feedable_id',
  /** column name */
  FeedableType = 'feedable_type',
  /** column name */
  Id = 'id',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  State = 'state',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  ThankYouId = 'thank_you_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

export type Feed_Items_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Feed_Items_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feed_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feed_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Feed_Items_Var_Pop_Fields = {
  feed_post_id?: Maybe<Scalars['Float']['output']>;
  feedable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
  thank_you_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "feed_items" */
export type Feed_Items_Var_Pop_Order_By = {
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Feed_Items_Var_Samp_Fields = {
  feed_post_id?: Maybe<Scalars['Float']['output']>;
  feedable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
  thank_you_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "feed_items" */
export type Feed_Items_Var_Samp_Order_By = {
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Feed_Items_Variance_Fields = {
  feed_post_id?: Maybe<Scalars['Float']['output']>;
  feedable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  post_id?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
  thank_you_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "feed_items" */
export type Feed_Items_Variance_Order_By = {
  feed_post_id?: InputMaybe<Order_By>;
  feedable_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  thank_you_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "feed_posts" */
export type Feed_Posts = {
  content: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  /** An array relationship */
  feed_items: Array<Feed_Items>;
  /** An aggregate relationship */
  feed_items_aggregate: Feed_Items_Aggregate;
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  list_ids?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  lists: Array<Lists>;
  /** An aggregate relationship */
  lists_aggregate: Lists_Aggregate;
  notification_type: Scalars['Int']['output'];
  org_ids?: Maybe<Scalars['String']['output']>;
  provider_job_id?: Maybe<Scalars['String']['output']>;
  send_at: Scalars['timestamp']['output'];
  story_id?: Maybe<Scalars['Int']['output']>;
  team_ids?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type_label: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "feed_posts" */
export type Feed_PostsFeed_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


/** columns and relationships of "feed_posts" */
export type Feed_PostsFeed_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


/** columns and relationships of "feed_posts" */
export type Feed_PostsListsArgs = {
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


/** columns and relationships of "feed_posts" */
export type Feed_PostsLists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};

/** aggregated selection of "feed_posts" */
export type Feed_Posts_Aggregate = {
  aggregate?: Maybe<Feed_Posts_Aggregate_Fields>;
  nodes: Array<Feed_Posts>;
};

/** aggregate fields of "feed_posts" */
export type Feed_Posts_Aggregate_Fields = {
  avg?: Maybe<Feed_Posts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Feed_Posts_Max_Fields>;
  min?: Maybe<Feed_Posts_Min_Fields>;
  stddev?: Maybe<Feed_Posts_Stddev_Fields>;
  stddev_pop?: Maybe<Feed_Posts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Feed_Posts_Stddev_Samp_Fields>;
  sum?: Maybe<Feed_Posts_Sum_Fields>;
  var_pop?: Maybe<Feed_Posts_Var_Pop_Fields>;
  var_samp?: Maybe<Feed_Posts_Var_Samp_Fields>;
  variance?: Maybe<Feed_Posts_Variance_Fields>;
};


/** aggregate fields of "feed_posts" */
export type Feed_Posts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feed_Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Feed_Posts_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  notification_type?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "feed_posts". All fields are combined with a logical 'AND'. */
export type Feed_Posts_Bool_Exp = {
  _and?: InputMaybe<Array<Feed_Posts_Bool_Exp>>;
  _not?: InputMaybe<Feed_Posts_Bool_Exp>;
  _or?: InputMaybe<Array<Feed_Posts_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  feed_items?: InputMaybe<Feed_Items_Bool_Exp>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  list_ids?: InputMaybe<String_Comparison_Exp>;
  lists?: InputMaybe<Lists_Bool_Exp>;
  lists_aggregate?: InputMaybe<Lists_Aggregate_Bool_Exp>;
  notification_type?: InputMaybe<Int_Comparison_Exp>;
  org_ids?: InputMaybe<String_Comparison_Exp>;
  provider_job_id?: InputMaybe<String_Comparison_Exp>;
  send_at?: InputMaybe<Timestamp_Comparison_Exp>;
  story_id?: InputMaybe<Int_Comparison_Exp>;
  team_ids?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type_label?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "feed_posts" */
export enum Feed_Posts_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeedPostsPkey = 'feed_posts_pkey'
}

/** input type for incrementing numeric columns in table "feed_posts" */
export type Feed_Posts_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  notification_type?: InputMaybe<Scalars['Int']['input']>;
  story_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "feed_posts" */
export type Feed_Posts_Insert_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  feed_items?: InputMaybe<Feed_Items_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  list_ids?: InputMaybe<Scalars['String']['input']>;
  lists?: InputMaybe<Lists_Arr_Rel_Insert_Input>;
  notification_type?: InputMaybe<Scalars['Int']['input']>;
  org_ids?: InputMaybe<Scalars['String']['input']>;
  provider_job_id?: InputMaybe<Scalars['String']['input']>;
  send_at?: InputMaybe<Scalars['timestamp']['input']>;
  story_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type_label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Feed_Posts_Max_Fields = {
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  list_ids?: Maybe<Scalars['String']['output']>;
  notification_type?: Maybe<Scalars['Int']['output']>;
  org_ids?: Maybe<Scalars['String']['output']>;
  provider_job_id?: Maybe<Scalars['String']['output']>;
  send_at?: Maybe<Scalars['timestamp']['output']>;
  story_id?: Maybe<Scalars['Int']['output']>;
  team_ids?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type_label?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Feed_Posts_Min_Fields = {
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  list_ids?: Maybe<Scalars['String']['output']>;
  notification_type?: Maybe<Scalars['Int']['output']>;
  org_ids?: Maybe<Scalars['String']['output']>;
  provider_job_id?: Maybe<Scalars['String']['output']>;
  send_at?: Maybe<Scalars['timestamp']['output']>;
  story_id?: Maybe<Scalars['Int']['output']>;
  team_ids?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type_label?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "feed_posts" */
export type Feed_Posts_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Feed_Posts>;
};

/** input type for inserting object relation for remote table "feed_posts" */
export type Feed_Posts_Obj_Rel_Insert_Input = {
  data: Feed_Posts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Feed_Posts_On_Conflict>;
};

/** on_conflict condition type for table "feed_posts" */
export type Feed_Posts_On_Conflict = {
  constraint: Feed_Posts_Constraint;
  update_columns?: Array<Feed_Posts_Update_Column>;
  where?: InputMaybe<Feed_Posts_Bool_Exp>;
};

/** Ordering options when selecting data from "feed_posts". */
export type Feed_Posts_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_ids?: InputMaybe<Order_By>;
  lists_aggregate?: InputMaybe<Lists_Aggregate_Order_By>;
  notification_type?: InputMaybe<Order_By>;
  org_ids?: InputMaybe<Order_By>;
  provider_job_id?: InputMaybe<Order_By>;
  send_at?: InputMaybe<Order_By>;
  story_id?: InputMaybe<Order_By>;
  team_ids?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type_label?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feed_posts */
export type Feed_Posts_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "feed_posts" */
export enum Feed_Posts_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  ListIds = 'list_ids',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  OrgIds = 'org_ids',
  /** column name */
  ProviderJobId = 'provider_job_id',
  /** column name */
  SendAt = 'send_at',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Title = 'title',
  /** column name */
  TypeLabel = 'type_label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "feed_posts" */
export type Feed_Posts_Set_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  list_ids?: InputMaybe<Scalars['String']['input']>;
  notification_type?: InputMaybe<Scalars['Int']['input']>;
  org_ids?: InputMaybe<Scalars['String']['input']>;
  provider_job_id?: InputMaybe<Scalars['String']['input']>;
  send_at?: InputMaybe<Scalars['timestamp']['input']>;
  story_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type_label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Feed_Posts_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  notification_type?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Feed_Posts_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  notification_type?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Feed_Posts_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  notification_type?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "feed_posts" */
export type Feed_Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feed_Posts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feed_Posts_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  list_ids?: InputMaybe<Scalars['String']['input']>;
  notification_type?: InputMaybe<Scalars['Int']['input']>;
  org_ids?: InputMaybe<Scalars['String']['input']>;
  provider_job_id?: InputMaybe<Scalars['String']['input']>;
  send_at?: InputMaybe<Scalars['timestamp']['input']>;
  story_id?: InputMaybe<Scalars['Int']['input']>;
  team_ids?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type_label?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Feed_Posts_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  notification_type?: Maybe<Scalars['Int']['output']>;
  story_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "feed_posts" */
export enum Feed_Posts_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ListIds = 'list_ids',
  /** column name */
  NotificationType = 'notification_type',
  /** column name */
  OrgIds = 'org_ids',
  /** column name */
  ProviderJobId = 'provider_job_id',
  /** column name */
  SendAt = 'send_at',
  /** column name */
  StoryId = 'story_id',
  /** column name */
  TeamIds = 'team_ids',
  /** column name */
  Title = 'title',
  /** column name */
  TypeLabel = 'type_label',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Feed_Posts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Feed_Posts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feed_Posts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feed_Posts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Feed_Posts_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  notification_type?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Feed_Posts_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  notification_type?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Feed_Posts_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  notification_type?: Maybe<Scalars['Float']['output']>;
  story_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']['input']>;
  _gt?: InputMaybe<Scalars['geography']['input']>;
  _gte?: InputMaybe<Scalars['geography']['input']>;
  _in?: InputMaybe<Array<Scalars['geography']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['geography']['input']>;
  _lte?: InputMaybe<Scalars['geography']['input']>;
  _neq?: InputMaybe<Scalars['geography']['input']>;
  _nin?: InputMaybe<Array<Scalars['geography']['input']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']['input']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']['input']>;
  _gt?: InputMaybe<Scalars['geometry']['input']>;
  _gte?: InputMaybe<Scalars['geometry']['input']>;
  _in?: InputMaybe<Array<Scalars['geometry']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['geometry']['input']>;
  _lte?: InputMaybe<Scalars['geometry']['input']>;
  _neq?: InputMaybe<Scalars['geometry']['input']>;
  _nin?: InputMaybe<Array<Scalars['geometry']['input']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']['input']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']['input']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']['input']>;
};

/** columns and relationships of "goals" */
export type Goals = {
  created_at: Scalars['timestamp']['output'];
  date?: Maybe<Scalars['timestamp']['output']>;
  goalable_id: Scalars['Int']['output'];
  goalable_type: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  number: Scalars['String']['output'];
  regularity?: Maybe<Scalars['Int']['output']>;
  type_name: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};

/** aggregated selection of "goals" */
export type Goals_Aggregate = {
  aggregate?: Maybe<Goals_Aggregate_Fields>;
  nodes: Array<Goals>;
};

/** aggregate fields of "goals" */
export type Goals_Aggregate_Fields = {
  avg?: Maybe<Goals_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Goals_Max_Fields>;
  min?: Maybe<Goals_Min_Fields>;
  stddev?: Maybe<Goals_Stddev_Fields>;
  stddev_pop?: Maybe<Goals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Goals_Stddev_Samp_Fields>;
  sum?: Maybe<Goals_Sum_Fields>;
  var_pop?: Maybe<Goals_Var_Pop_Fields>;
  var_samp?: Maybe<Goals_Var_Samp_Fields>;
  variance?: Maybe<Goals_Variance_Fields>;
};


/** aggregate fields of "goals" */
export type Goals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Goals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Goals_Avg_Fields = {
  goalable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  regularity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "goals". All fields are combined with a logical 'AND'. */
export type Goals_Bool_Exp = {
  _and?: InputMaybe<Array<Goals_Bool_Exp>>;
  _not?: InputMaybe<Goals_Bool_Exp>;
  _or?: InputMaybe<Array<Goals_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  date?: InputMaybe<Timestamp_Comparison_Exp>;
  goalable_id?: InputMaybe<Int_Comparison_Exp>;
  goalable_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  regularity?: InputMaybe<Int_Comparison_Exp>;
  type_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "goals" */
export enum Goals_Constraint {
  /** unique or primary key constraint on columns "goalable_type", "goalable_id", "type_name" */
  GoalsGoalableIdGoalableTypeTypeNameKey = 'goals_goalable_id_goalable_type_type_name_key',
  /** unique or primary key constraint on columns "id" */
  GoalsPkey = 'goals_pkey'
}

/** input type for incrementing numeric columns in table "goals" */
export type Goals_Inc_Input = {
  goalable_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  regularity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "goals" */
export type Goals_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  goalable_id?: InputMaybe<Scalars['Int']['input']>;
  goalable_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  regularity?: InputMaybe<Scalars['Int']['input']>;
  type_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Goals_Max_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['timestamp']['output']>;
  goalable_id?: Maybe<Scalars['Int']['output']>;
  goalable_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  regularity?: Maybe<Scalars['Int']['output']>;
  type_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Goals_Min_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['timestamp']['output']>;
  goalable_id?: Maybe<Scalars['Int']['output']>;
  goalable_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  regularity?: Maybe<Scalars['Int']['output']>;
  type_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "goals" */
export type Goals_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Goals>;
};

/** on_conflict condition type for table "goals" */
export type Goals_On_Conflict = {
  constraint: Goals_Constraint;
  update_columns?: Array<Goals_Update_Column>;
  where?: InputMaybe<Goals_Bool_Exp>;
};

/** Ordering options when selecting data from "goals". */
export type Goals_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  goalable_id?: InputMaybe<Order_By>;
  goalable_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  regularity?: InputMaybe<Order_By>;
  type_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: goals */
export type Goals_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "goals" */
export enum Goals_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  GoalableId = 'goalable_id',
  /** column name */
  GoalableType = 'goalable_type',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  Number = 'number',
  /** column name */
  Regularity = 'regularity',
  /** column name */
  TypeName = 'type_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "goals" */
export type Goals_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  goalable_id?: InputMaybe<Scalars['Int']['input']>;
  goalable_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  regularity?: InputMaybe<Scalars['Int']['input']>;
  type_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Goals_Stddev_Fields = {
  goalable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  regularity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Goals_Stddev_Pop_Fields = {
  goalable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  regularity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Goals_Stddev_Samp_Fields = {
  goalable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  regularity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "goals" */
export type Goals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Goals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Goals_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  goalable_id?: InputMaybe<Scalars['Int']['input']>;
  goalable_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  regularity?: InputMaybe<Scalars['Int']['input']>;
  type_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Goals_Sum_Fields = {
  goalable_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  regularity?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "goals" */
export enum Goals_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  GoalableId = 'goalable_id',
  /** column name */
  GoalableType = 'goalable_type',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  Regularity = 'regularity',
  /** column name */
  TypeName = 'type_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Goals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Goals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Goals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Goals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Goals_Var_Pop_Fields = {
  goalable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  regularity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Goals_Var_Samp_Fields = {
  goalable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  regularity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Goals_Variance_Fields = {
  goalable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  regularity?: Maybe<Scalars['Float']['output']>;
};

/** Used to track lag/lead measures */
export type Internal_Data_Organization_Goals = {
  complete_at: Scalars['timestamptz']['output'];
  complete_value?: Maybe<Scalars['numeric']['output']>;
  created_at: Scalars['timestamptz']['output'];
  current_value?: Maybe<Scalars['numeric']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_currency: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  start_at: Scalars['timestamptz']['output'];
  start_value?: Maybe<Scalars['numeric']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "internal_data.organization_goals" */
export type Internal_Data_Organization_Goals_Aggregate = {
  aggregate?: Maybe<Internal_Data_Organization_Goals_Aggregate_Fields>;
  nodes: Array<Internal_Data_Organization_Goals>;
};

/** aggregate fields of "internal_data.organization_goals" */
export type Internal_Data_Organization_Goals_Aggregate_Fields = {
  avg?: Maybe<Internal_Data_Organization_Goals_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Internal_Data_Organization_Goals_Max_Fields>;
  min?: Maybe<Internal_Data_Organization_Goals_Min_Fields>;
  stddev?: Maybe<Internal_Data_Organization_Goals_Stddev_Fields>;
  stddev_pop?: Maybe<Internal_Data_Organization_Goals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Internal_Data_Organization_Goals_Stddev_Samp_Fields>;
  sum?: Maybe<Internal_Data_Organization_Goals_Sum_Fields>;
  var_pop?: Maybe<Internal_Data_Organization_Goals_Var_Pop_Fields>;
  var_samp?: Maybe<Internal_Data_Organization_Goals_Var_Samp_Fields>;
  variance?: Maybe<Internal_Data_Organization_Goals_Variance_Fields>;
};


/** aggregate fields of "internal_data.organization_goals" */
export type Internal_Data_Organization_Goals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Internal_Data_Organization_Goals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Internal_Data_Organization_Goals_Avg_Fields = {
  complete_value?: Maybe<Scalars['Float']['output']>;
  current_value?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  start_value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "internal_data.organization_goals". All fields are combined with a logical 'AND'. */
export type Internal_Data_Organization_Goals_Bool_Exp = {
  _and?: InputMaybe<Array<Internal_Data_Organization_Goals_Bool_Exp>>;
  _not?: InputMaybe<Internal_Data_Organization_Goals_Bool_Exp>;
  _or?: InputMaybe<Array<Internal_Data_Organization_Goals_Bool_Exp>>;
  complete_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  complete_value?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_value?: InputMaybe<Numeric_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_currency?: InputMaybe<Boolean_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  start_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  start_value?: InputMaybe<Numeric_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "internal_data.organization_goals" */
export enum Internal_Data_Organization_Goals_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationGoalsPkey = 'organization_goals_pkey',
  /** unique or primary key constraint on columns "slug" */
  OrganizationGoalsSlugKey = 'organization_goals_slug_key'
}

/** input type for incrementing numeric columns in table "internal_data.organization_goals" */
export type Internal_Data_Organization_Goals_Inc_Input = {
  complete_value?: InputMaybe<Scalars['numeric']['input']>;
  current_value?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  start_value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "internal_data.organization_goals" */
export type Internal_Data_Organization_Goals_Insert_Input = {
  complete_at?: InputMaybe<Scalars['timestamptz']['input']>;
  complete_value?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  current_value?: InputMaybe<Scalars['numeric']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_currency?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  start_at?: InputMaybe<Scalars['timestamptz']['input']>;
  start_value?: InputMaybe<Scalars['numeric']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Internal_Data_Organization_Goals_Max_Fields = {
  complete_at?: Maybe<Scalars['timestamptz']['output']>;
  complete_value?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  current_value?: Maybe<Scalars['numeric']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  start_at?: Maybe<Scalars['timestamptz']['output']>;
  start_value?: Maybe<Scalars['numeric']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Internal_Data_Organization_Goals_Min_Fields = {
  complete_at?: Maybe<Scalars['timestamptz']['output']>;
  complete_value?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  current_value?: Maybe<Scalars['numeric']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  start_at?: Maybe<Scalars['timestamptz']['output']>;
  start_value?: Maybe<Scalars['numeric']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "internal_data.organization_goals" */
export type Internal_Data_Organization_Goals_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Internal_Data_Organization_Goals>;
};

/** on_conflict condition type for table "internal_data.organization_goals" */
export type Internal_Data_Organization_Goals_On_Conflict = {
  constraint: Internal_Data_Organization_Goals_Constraint;
  update_columns?: Array<Internal_Data_Organization_Goals_Update_Column>;
  where?: InputMaybe<Internal_Data_Organization_Goals_Bool_Exp>;
};

/** Ordering options when selecting data from "internal_data.organization_goals". */
export type Internal_Data_Organization_Goals_Order_By = {
  complete_at?: InputMaybe<Order_By>;
  complete_value?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_value?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_currency?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  start_at?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: internal_data.organization_goals */
export type Internal_Data_Organization_Goals_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "internal_data.organization_goals" */
export enum Internal_Data_Organization_Goals_Select_Column {
  /** column name */
  CompleteAt = 'complete_at',
  /** column name */
  CompleteValue = 'complete_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentValue = 'current_value',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsCurrency = 'is_currency',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  StartValue = 'start_value',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "internal_data.organization_goals" */
export type Internal_Data_Organization_Goals_Set_Input = {
  complete_at?: InputMaybe<Scalars['timestamptz']['input']>;
  complete_value?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  current_value?: InputMaybe<Scalars['numeric']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_currency?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  start_at?: InputMaybe<Scalars['timestamptz']['input']>;
  start_value?: InputMaybe<Scalars['numeric']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Internal_Data_Organization_Goals_Stddev_Fields = {
  complete_value?: Maybe<Scalars['Float']['output']>;
  current_value?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  start_value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Internal_Data_Organization_Goals_Stddev_Pop_Fields = {
  complete_value?: Maybe<Scalars['Float']['output']>;
  current_value?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  start_value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Internal_Data_Organization_Goals_Stddev_Samp_Fields = {
  complete_value?: Maybe<Scalars['Float']['output']>;
  current_value?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  start_value?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "internal_data_organization_goals" */
export type Internal_Data_Organization_Goals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Internal_Data_Organization_Goals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Internal_Data_Organization_Goals_Stream_Cursor_Value_Input = {
  complete_at?: InputMaybe<Scalars['timestamptz']['input']>;
  complete_value?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  current_value?: InputMaybe<Scalars['numeric']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_currency?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  start_at?: InputMaybe<Scalars['timestamptz']['input']>;
  start_value?: InputMaybe<Scalars['numeric']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Internal_Data_Organization_Goals_Sum_Fields = {
  complete_value?: Maybe<Scalars['numeric']['output']>;
  current_value?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  start_value?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "internal_data.organization_goals" */
export enum Internal_Data_Organization_Goals_Update_Column {
  /** column name */
  CompleteAt = 'complete_at',
  /** column name */
  CompleteValue = 'complete_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentValue = 'current_value',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsCurrency = 'is_currency',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  StartValue = 'start_value',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Internal_Data_Organization_Goals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Internal_Data_Organization_Goals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Internal_Data_Organization_Goals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Internal_Data_Organization_Goals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Internal_Data_Organization_Goals_Var_Pop_Fields = {
  complete_value?: Maybe<Scalars['Float']['output']>;
  current_value?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  start_value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Internal_Data_Organization_Goals_Var_Samp_Fields = {
  complete_value?: Maybe<Scalars['Float']['output']>;
  current_value?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  start_value?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Internal_Data_Organization_Goals_Variance_Fields = {
  complete_value?: Maybe<Scalars['Float']['output']>;
  current_value?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  start_value?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "list_locations" */
export type List_Locations = {
  id?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  point?: Maybe<Scalars['geography']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  utc_offset?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "list_locations" */
export type List_Locations_Aggregate = {
  aggregate?: Maybe<List_Locations_Aggregate_Fields>;
  nodes: Array<List_Locations>;
};

/** aggregate fields of "list_locations" */
export type List_Locations_Aggregate_Fields = {
  avg?: Maybe<List_Locations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<List_Locations_Max_Fields>;
  min?: Maybe<List_Locations_Min_Fields>;
  stddev?: Maybe<List_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<List_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<List_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<List_Locations_Sum_Fields>;
  var_pop?: Maybe<List_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<List_Locations_Var_Samp_Fields>;
  variance?: Maybe<List_Locations_Variance_Fields>;
};


/** aggregate fields of "list_locations" */
export type List_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<List_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type List_Locations_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "list_locations". All fields are combined with a logical 'AND'. */
export type List_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<List_Locations_Bool_Exp>>;
  _not?: InputMaybe<List_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<List_Locations_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  point?: InputMaybe<Geography_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  utc_offset?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "list_locations" */
export type List_Locations_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "list_locations" */
export type List_Locations_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  utc_offset?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type List_Locations_Max_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  utc_offset?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type List_Locations_Min_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  utc_offset?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "list_locations" */
export type List_Locations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<List_Locations>;
};

/** input type for inserting object relation for remote table "list_locations" */
export type List_Locations_Obj_Rel_Insert_Input = {
  data: List_Locations_Insert_Input;
};

/** Ordering options when selecting data from "list_locations". */
export type List_Locations_Order_By = {
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  utc_offset?: InputMaybe<Order_By>;
};

/** select columns of table "list_locations" */
export enum List_Locations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Point = 'point',
  /** column name */
  State = 'state',
  /** column name */
  UtcOffset = 'utc_offset'
}

/** input type for updating data in table "list_locations" */
export type List_Locations_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  utc_offset?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type List_Locations_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type List_Locations_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type List_Locations_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "list_locations" */
export type List_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: List_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type List_Locations_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  utc_offset?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type List_Locations_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
};

export type List_Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<List_Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<List_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: List_Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type List_Locations_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type List_Locations_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type List_Locations_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "list_subscriptions" */
export type List_Subscriptions = {
  created_at: Scalars['timestamp']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  list?: Maybe<Lists>;
  list_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamp']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "list_subscriptions" */
export type List_Subscriptions_Aggregate = {
  aggregate?: Maybe<List_Subscriptions_Aggregate_Fields>;
  nodes: Array<List_Subscriptions>;
};

export type List_Subscriptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<List_Subscriptions_Aggregate_Bool_Exp_Count>;
};

export type List_Subscriptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<List_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<List_Subscriptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "list_subscriptions" */
export type List_Subscriptions_Aggregate_Fields = {
  avg?: Maybe<List_Subscriptions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<List_Subscriptions_Max_Fields>;
  min?: Maybe<List_Subscriptions_Min_Fields>;
  stddev?: Maybe<List_Subscriptions_Stddev_Fields>;
  stddev_pop?: Maybe<List_Subscriptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<List_Subscriptions_Stddev_Samp_Fields>;
  sum?: Maybe<List_Subscriptions_Sum_Fields>;
  var_pop?: Maybe<List_Subscriptions_Var_Pop_Fields>;
  var_samp?: Maybe<List_Subscriptions_Var_Samp_Fields>;
  variance?: Maybe<List_Subscriptions_Variance_Fields>;
};


/** aggregate fields of "list_subscriptions" */
export type List_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<List_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "list_subscriptions" */
export type List_Subscriptions_Aggregate_Order_By = {
  avg?: InputMaybe<List_Subscriptions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<List_Subscriptions_Max_Order_By>;
  min?: InputMaybe<List_Subscriptions_Min_Order_By>;
  stddev?: InputMaybe<List_Subscriptions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<List_Subscriptions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<List_Subscriptions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<List_Subscriptions_Sum_Order_By>;
  var_pop?: InputMaybe<List_Subscriptions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<List_Subscriptions_Var_Samp_Order_By>;
  variance?: InputMaybe<List_Subscriptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "list_subscriptions" */
export type List_Subscriptions_Arr_Rel_Insert_Input = {
  data: Array<List_Subscriptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<List_Subscriptions_On_Conflict>;
};

/** aggregate avg on columns */
export type List_Subscriptions_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "list_subscriptions" */
export type List_Subscriptions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "list_subscriptions". All fields are combined with a logical 'AND'. */
export type List_Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<List_Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<List_Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<List_Subscriptions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  list?: InputMaybe<Lists_Bool_Exp>;
  list_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "list_subscriptions" */
export enum List_Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ListSubscriptionsPkey = 'list_subscriptions_pkey'
}

/** input type for incrementing numeric columns in table "list_subscriptions" */
export type List_Subscriptions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "list_subscriptions" */
export type List_Subscriptions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  list?: InputMaybe<Lists_Obj_Rel_Insert_Input>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type List_Subscriptions_Max_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "list_subscriptions" */
export type List_Subscriptions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type List_Subscriptions_Min_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "list_subscriptions" */
export type List_Subscriptions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "list_subscriptions" */
export type List_Subscriptions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<List_Subscriptions>;
};

/** on_conflict condition type for table "list_subscriptions" */
export type List_Subscriptions_On_Conflict = {
  constraint: List_Subscriptions_Constraint;
  update_columns?: Array<List_Subscriptions_Update_Column>;
  where?: InputMaybe<List_Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "list_subscriptions". */
export type List_Subscriptions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  list?: InputMaybe<Lists_Order_By>;
  list_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: list_subscriptions */
export type List_Subscriptions_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "list_subscriptions" */
export enum List_Subscriptions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ListId = 'list_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "list_subscriptions" */
export type List_Subscriptions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type List_Subscriptions_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "list_subscriptions" */
export type List_Subscriptions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type List_Subscriptions_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "list_subscriptions" */
export type List_Subscriptions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type List_Subscriptions_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "list_subscriptions" */
export type List_Subscriptions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "list_subscriptions" */
export type List_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: List_Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type List_Subscriptions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type List_Subscriptions_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "list_subscriptions" */
export type List_Subscriptions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "list_subscriptions" */
export enum List_Subscriptions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ListId = 'list_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type List_Subscriptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<List_Subscriptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<List_Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: List_Subscriptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type List_Subscriptions_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "list_subscriptions" */
export type List_Subscriptions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type List_Subscriptions_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "list_subscriptions" */
export type List_Subscriptions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type List_Subscriptions_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "list_subscriptions" */
export type List_Subscriptions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lists" */
export type Lists = {
  about?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  cover_image_content_type?: Maybe<Scalars['String']['output']>;
  cover_image_file_name?: Maybe<Scalars['String']['output']>;
  cover_image_file_size?: Maybe<Scalars['Int']['output']>;
  cover_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  created_at: Scalars['timestamp']['output'];
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  is_state?: Maybe<Scalars['Boolean']['output']>;
  is_team?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  list_subscriptions: Array<List_Subscriptions>;
  /** An aggregate relationship */
  list_subscriptions_aggregate: List_Subscriptions_Aggregate;
  /** An object relationship */
  location?: Maybe<List_Locations>;
  longitude?: Maybe<Scalars['float8']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  needs: Array<Needs>;
  /** An aggregate relationship */
  needs_aggregate: Needs_Aggregate;
  /** An array relationship */
  organizations: Array<Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Organizations_Aggregate;
  rank_distance?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  related: Array<Lists>;
  /** An aggregate relationship */
  related_aggregate: Lists_Aggregate;
  slug?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  sponsorships?: Maybe<Sponsorships>;
  state_code?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Aggregate;
  updated_at: Scalars['timestamp']['output'];
  utc_offset?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "lists" */
export type ListsList_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<List_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Subscriptions_Order_By>>;
  where?: InputMaybe<List_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "lists" */
export type ListsList_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Subscriptions_Order_By>>;
  where?: InputMaybe<List_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "lists" */
export type ListsNeedsArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


/** columns and relationships of "lists" */
export type ListsNeeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


/** columns and relationships of "lists" */
export type ListsOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "lists" */
export type ListsOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "lists" */
export type ListsRelatedArgs = {
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


/** columns and relationships of "lists" */
export type ListsRelated_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


/** columns and relationships of "lists" */
export type ListsTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


/** columns and relationships of "lists" */
export type ListsTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};

/** aggregated selection of "lists" */
export type Lists_Aggregate = {
  aggregate?: Maybe<Lists_Aggregate_Fields>;
  nodes: Array<Lists>;
};

export type Lists_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Lists_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Lists_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Lists_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Lists_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Lists_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Lists_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Lists_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Lists_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Lists_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Lists_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Lists_Aggregate_Bool_Exp_Var_Samp>;
};

export type Lists_Aggregate_Bool_Exp_Avg = {
  arguments: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Lists_Aggregate_Bool_Exp_Bool_And = {
  arguments: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lists_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lists_Aggregate_Bool_Exp_Corr = {
  arguments: Lists_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Lists_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Lists_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Lists_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Lists_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Lists_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Lists_Aggregate_Bool_Exp_Max = {
  arguments: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Lists_Aggregate_Bool_Exp_Min = {
  arguments: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Lists_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Lists_Aggregate_Bool_Exp_Sum = {
  arguments: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Lists_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Lists_Select_Column_Lists_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lists_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "lists" */
export type Lists_Aggregate_Fields = {
  avg?: Maybe<Lists_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Lists_Max_Fields>;
  min?: Maybe<Lists_Min_Fields>;
  stddev?: Maybe<Lists_Stddev_Fields>;
  stddev_pop?: Maybe<Lists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lists_Stddev_Samp_Fields>;
  sum?: Maybe<Lists_Sum_Fields>;
  var_pop?: Maybe<Lists_Var_Pop_Fields>;
  var_samp?: Maybe<Lists_Var_Samp_Fields>;
  variance?: Maybe<Lists_Variance_Fields>;
};


/** aggregate fields of "lists" */
export type Lists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "lists" */
export type Lists_Aggregate_Order_By = {
  avg?: InputMaybe<Lists_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lists_Max_Order_By>;
  min?: InputMaybe<Lists_Min_Order_By>;
  stddev?: InputMaybe<Lists_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lists_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lists_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lists_Sum_Order_By>;
  var_pop?: InputMaybe<Lists_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lists_Var_Samp_Order_By>;
  variance?: InputMaybe<Lists_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lists" */
export type Lists_Arr_Rel_Insert_Input = {
  data: Array<Lists_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lists_On_Conflict>;
};

/** aggregate avg on columns */
export type Lists_Avg_Fields = {
  cover_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "lists" */
export type Lists_Avg_Order_By = {
  cover_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lists". All fields are combined with a logical 'AND'. */
export type Lists_Bool_Exp = {
  _and?: InputMaybe<Array<Lists_Bool_Exp>>;
  _not?: InputMaybe<Lists_Bool_Exp>;
  _or?: InputMaybe<Array<Lists_Bool_Exp>>;
  about?: InputMaybe<String_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  cover_image_content_type?: InputMaybe<String_Comparison_Exp>;
  cover_image_file_name?: InputMaybe<String_Comparison_Exp>;
  cover_image_file_size?: InputMaybe<Int_Comparison_Exp>;
  cover_image_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  is_state?: InputMaybe<Boolean_Comparison_Exp>;
  is_team?: InputMaybe<Boolean_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  list_subscriptions?: InputMaybe<List_Subscriptions_Bool_Exp>;
  list_subscriptions_aggregate?: InputMaybe<List_Subscriptions_Aggregate_Bool_Exp>;
  location?: InputMaybe<List_Locations_Bool_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  needs?: InputMaybe<Needs_Bool_Exp>;
  needs_aggregate?: InputMaybe<Needs_Aggregate_Bool_Exp>;
  organizations?: InputMaybe<Organizations_Bool_Exp>;
  organizations_aggregate?: InputMaybe<Organizations_Aggregate_Bool_Exp>;
  rank_distance?: InputMaybe<Float8_Comparison_Exp>;
  related?: InputMaybe<Lists_Bool_Exp>;
  related_aggregate?: InputMaybe<Lists_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  sponsorships?: InputMaybe<Sponsorships_Bool_Exp>;
  state_code?: InputMaybe<String_Comparison_Exp>;
  teams?: InputMaybe<Teams_Bool_Exp>;
  teams_aggregate?: InputMaybe<Teams_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  utc_offset?: InputMaybe<String_Comparison_Exp>;
  zipcode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lists" */
export enum Lists_Constraint {
  /** unique or primary key constraint on columns "id" */
  ListsPkey = 'lists_pkey'
}

export type Lists_Geo_Args = {
  lat?: InputMaybe<Scalars['float8']['input']>;
  long?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for incrementing numeric columns in table "lists" */
export type Lists_Inc_Input = {
  cover_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "lists" */
export type Lists_Insert_Input = {
  about?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  cover_image_content_type?: InputMaybe<Scalars['String']['input']>;
  cover_image_file_name?: InputMaybe<Scalars['String']['input']>;
  cover_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  cover_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_state?: InputMaybe<Scalars['Boolean']['input']>;
  is_team?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  list_subscriptions?: InputMaybe<List_Subscriptions_Arr_Rel_Insert_Input>;
  location?: InputMaybe<List_Locations_Obj_Rel_Insert_Input>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needs?: InputMaybe<Needs_Arr_Rel_Insert_Input>;
  organizations?: InputMaybe<Organizations_Arr_Rel_Insert_Input>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
  related?: InputMaybe<Lists_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sponsorships?: InputMaybe<Sponsorships_Obj_Rel_Insert_Input>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Teams_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  utc_offset?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Lists_Max_Fields = {
  about?: Maybe<Scalars['String']['output']>;
  cover_image_content_type?: Maybe<Scalars['String']['output']>;
  cover_image_file_name?: Maybe<Scalars['String']['output']>;
  cover_image_file_size?: Maybe<Scalars['Int']['output']>;
  cover_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  state_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  utc_offset?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "lists" */
export type Lists_Max_Order_By = {
  about?: InputMaybe<Order_By>;
  cover_image_content_type?: InputMaybe<Order_By>;
  cover_image_file_name?: InputMaybe<Order_By>;
  cover_image_file_size?: InputMaybe<Order_By>;
  cover_image_updated_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  state_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  utc_offset?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lists_Min_Fields = {
  about?: Maybe<Scalars['String']['output']>;
  cover_image_content_type?: Maybe<Scalars['String']['output']>;
  cover_image_file_name?: Maybe<Scalars['String']['output']>;
  cover_image_file_size?: Maybe<Scalars['Int']['output']>;
  cover_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  state_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  utc_offset?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "lists" */
export type Lists_Min_Order_By = {
  about?: InputMaybe<Order_By>;
  cover_image_content_type?: InputMaybe<Order_By>;
  cover_image_file_name?: InputMaybe<Order_By>;
  cover_image_file_size?: InputMaybe<Order_By>;
  cover_image_updated_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  state_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  utc_offset?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lists" */
export type Lists_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Lists>;
};

/** input type for inserting object relation for remote table "lists" */
export type Lists_Obj_Rel_Insert_Input = {
  data: Lists_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lists_On_Conflict>;
};

/** on_conflict condition type for table "lists" */
export type Lists_On_Conflict = {
  constraint: Lists_Constraint;
  update_columns?: Array<Lists_Update_Column>;
  where?: InputMaybe<Lists_Bool_Exp>;
};

/** Ordering options when selecting data from "lists". */
export type Lists_Order_By = {
  about?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  cover_image_content_type?: InputMaybe<Order_By>;
  cover_image_file_name?: InputMaybe<Order_By>;
  cover_image_file_size?: InputMaybe<Order_By>;
  cover_image_updated_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  is_state?: InputMaybe<Order_By>;
  is_team?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_subscriptions_aggregate?: InputMaybe<List_Subscriptions_Aggregate_Order_By>;
  location?: InputMaybe<List_Locations_Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  needs_aggregate?: InputMaybe<Needs_Aggregate_Order_By>;
  organizations_aggregate?: InputMaybe<Organizations_Aggregate_Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  related_aggregate?: InputMaybe<Lists_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  sponsorships?: InputMaybe<Sponsorships_Order_By>;
  state_code?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Teams_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  utc_offset?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lists */
export type Lists_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "lists" */
export enum Lists_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  Active = 'active',
  /** column name */
  CoverImageContentType = 'cover_image_content_type',
  /** column name */
  CoverImageFileName = 'cover_image_file_name',
  /** column name */
  CoverImageFileSize = 'cover_image_file_size',
  /** column name */
  CoverImageUpdatedAt = 'cover_image_updated_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  IsState = 'is_state',
  /** column name */
  IsTeam = 'is_team',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  RankDistance = 'rank_distance',
  /** column name */
  Slug = 'slug',
  /** column name */
  StateCode = 'state_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtcOffset = 'utc_offset',
  /** column name */
  Zipcode = 'zipcode'
}

/** select "lists_aggregate_bool_exp_avg_arguments_columns" columns of table "lists" */
export enum Lists_Select_Column_Lists_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "lists_aggregate_bool_exp_bool_and_arguments_columns" columns of table "lists" */
export enum Lists_Select_Column_Lists_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsState = 'is_state',
  /** column name */
  IsTeam = 'is_team'
}

/** select "lists_aggregate_bool_exp_bool_or_arguments_columns" columns of table "lists" */
export enum Lists_Select_Column_Lists_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsState = 'is_state',
  /** column name */
  IsTeam = 'is_team'
}

/** select "lists_aggregate_bool_exp_corr_arguments_columns" columns of table "lists" */
export enum Lists_Select_Column_Lists_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "lists_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "lists" */
export enum Lists_Select_Column_Lists_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "lists_aggregate_bool_exp_max_arguments_columns" columns of table "lists" */
export enum Lists_Select_Column_Lists_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "lists_aggregate_bool_exp_min_arguments_columns" columns of table "lists" */
export enum Lists_Select_Column_Lists_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "lists_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "lists" */
export enum Lists_Select_Column_Lists_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "lists_aggregate_bool_exp_sum_arguments_columns" columns of table "lists" */
export enum Lists_Select_Column_Lists_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "lists_aggregate_bool_exp_var_samp_arguments_columns" columns of table "lists" */
export enum Lists_Select_Column_Lists_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** input type for updating data in table "lists" */
export type Lists_Set_Input = {
  about?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  cover_image_content_type?: InputMaybe<Scalars['String']['input']>;
  cover_image_file_name?: InputMaybe<Scalars['String']['input']>;
  cover_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  cover_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_state?: InputMaybe<Scalars['Boolean']['input']>;
  is_team?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  utc_offset?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Lists_Stddev_Fields = {
  cover_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "lists" */
export type Lists_Stddev_Order_By = {
  cover_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lists_Stddev_Pop_Fields = {
  cover_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "lists" */
export type Lists_Stddev_Pop_Order_By = {
  cover_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lists_Stddev_Samp_Fields = {
  cover_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "lists" */
export type Lists_Stddev_Samp_Order_By = {
  cover_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lists" */
export type Lists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lists_Stream_Cursor_Value_Input = {
  about?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  cover_image_content_type?: InputMaybe<Scalars['String']['input']>;
  cover_image_file_name?: InputMaybe<Scalars['String']['input']>;
  cover_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  cover_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  is_state?: InputMaybe<Scalars['Boolean']['input']>;
  is_team?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  state_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  utc_offset?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Lists_Sum_Fields = {
  cover_image_file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "lists" */
export type Lists_Sum_Order_By = {
  cover_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** update columns of table "lists" */
export enum Lists_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  Active = 'active',
  /** column name */
  CoverImageContentType = 'cover_image_content_type',
  /** column name */
  CoverImageFileName = 'cover_image_file_name',
  /** column name */
  CoverImageFileSize = 'cover_image_file_size',
  /** column name */
  CoverImageUpdatedAt = 'cover_image_updated_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsState = 'is_state',
  /** column name */
  IsTeam = 'is_team',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  RankDistance = 'rank_distance',
  /** column name */
  Slug = 'slug',
  /** column name */
  StateCode = 'state_code',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UtcOffset = 'utc_offset',
  /** column name */
  Zipcode = 'zipcode'
}

export type Lists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lists_Var_Pop_Fields = {
  cover_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "lists" */
export type Lists_Var_Pop_Order_By = {
  cover_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lists_Var_Samp_Fields = {
  cover_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "lists" */
export type Lists_Var_Samp_Order_By = {
  cover_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lists_Variance_Fields = {
  cover_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "lists" */
export type Lists_Variance_Order_By = {
  cover_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  cancel_universal_checkout?: Maybe<CancelUniversalCheckoutOutput>;
  create_checkout_session?: Maybe<CheckoutSessionOutput>;
  /** Generates the values needed for Stripe's client-side payments flow for donation */
  create_donate_checkout?: Maybe<CreateDonateCheckoutOutput>;
  /** Generates the values needed for Stripe's client-side payments flow */
  create_universal_checkout?: Maybe<CreateUniversalCheckoutOutput>;
  /** delete data from the table: "addresses" */
  delete_addresses?: Maybe<Addresses_Mutation_Response>;
  /** delete single row from the table: "addresses" */
  delete_addresses_by_pk?: Maybe<Addresses>;
  /** delete data from the table: "addresses_need_informations" */
  delete_addresses_need_informations?: Maybe<Addresses_Need_Informations_Mutation_Response>;
  /** delete data from the table: "addresses_organizations" */
  delete_addresses_organizations?: Maybe<Addresses_Organizations_Mutation_Response>;
  /** delete data from the table: "addresses_users" */
  delete_addresses_users?: Maybe<Addresses_Users_Mutation_Response>;
  /** delete data from the table: "checkout_intents" */
  delete_checkout_intents?: Maybe<Checkout_Intents_Mutation_Response>;
  /** delete single row from the table: "checkout_intents" */
  delete_checkout_intents_by_pk?: Maybe<Checkout_Intents>;
  /** delete data from the table: "devices" */
  delete_devices?: Maybe<Devices_Mutation_Response>;
  /** delete single row from the table: "devices" */
  delete_devices_by_pk?: Maybe<Devices>;
  /** delete data from the table: "feed_items" */
  delete_feed_items?: Maybe<Feed_Items_Mutation_Response>;
  /** delete single row from the table: "feed_items" */
  delete_feed_items_by_pk?: Maybe<Feed_Items>;
  /** delete data from the table: "feed_posts" */
  delete_feed_posts?: Maybe<Feed_Posts_Mutation_Response>;
  /** delete single row from the table: "feed_posts" */
  delete_feed_posts_by_pk?: Maybe<Feed_Posts>;
  /** delete data from the table: "goals" */
  delete_goals?: Maybe<Goals_Mutation_Response>;
  /** delete single row from the table: "goals" */
  delete_goals_by_pk?: Maybe<Goals>;
  /** delete data from the table: "internal_data.organization_goals" */
  delete_internal_data_organization_goals?: Maybe<Internal_Data_Organization_Goals_Mutation_Response>;
  /** delete single row from the table: "internal_data.organization_goals" */
  delete_internal_data_organization_goals_by_pk?: Maybe<Internal_Data_Organization_Goals>;
  /** delete data from the table: "list_locations" */
  delete_list_locations?: Maybe<List_Locations_Mutation_Response>;
  /** delete data from the table: "list_subscriptions" */
  delete_list_subscriptions?: Maybe<List_Subscriptions_Mutation_Response>;
  /** delete single row from the table: "list_subscriptions" */
  delete_list_subscriptions_by_pk?: Maybe<List_Subscriptions>;
  /** delete data from the table: "lists" */
  delete_lists?: Maybe<Lists_Mutation_Response>;
  /** delete single row from the table: "lists" */
  delete_lists_by_pk?: Maybe<Lists>;
  /** delete data from the table: "need_fulfill_intents" */
  delete_need_fulfill_intents?: Maybe<Need_Fulfill_Intents_Mutation_Response>;
  /** delete single row from the table: "need_fulfill_intents" */
  delete_need_fulfill_intents_by_pk?: Maybe<Need_Fulfill_Intents>;
  /** delete data from the table: "need_informations" */
  delete_need_informations?: Maybe<Need_Informations_Mutation_Response>;
  /** delete single row from the table: "need_informations" */
  delete_need_informations_by_pk?: Maybe<Need_Informations>;
  /** delete data from the table: "need_tags" */
  delete_need_tags?: Maybe<Need_Tags_Mutation_Response>;
  /** delete single row from the table: "need_tags" */
  delete_need_tags_by_pk?: Maybe<Need_Tags>;
  /** delete data from the table: "needs" */
  delete_needs?: Maybe<Needs_Mutation_Response>;
  /** delete single row from the table: "needs" */
  delete_needs_by_pk?: Maybe<Needs>;
  /** delete data from the table: "organization_locations" */
  delete_organization_locations?: Maybe<Organization_Locations_Mutation_Response>;
  /** delete data from the table: "organization_subscriptions" */
  delete_organization_subscriptions?: Maybe<Organization_Subscriptions_Mutation_Response>;
  /** delete single row from the table: "organization_subscriptions" */
  delete_organization_subscriptions_by_pk?: Maybe<Organization_Subscriptions>;
  /** delete data from the table: "organization_tags" */
  delete_organization_tags?: Maybe<Organization_Tags_Mutation_Response>;
  /** delete single row from the table: "organization_tags" */
  delete_organization_tags_by_pk?: Maybe<Organization_Tags>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "parent_organizations" */
  delete_parent_organizations?: Maybe<Parent_Organizations_Mutation_Response>;
  /** delete single row from the table: "parent_organizations" */
  delete_parent_organizations_by_pk?: Maybe<Parent_Organizations>;
  /** delete data from the table: "posts" */
  delete_posts?: Maybe<Posts_Mutation_Response>;
  /** delete single row from the table: "posts" */
  delete_posts_by_pk?: Maybe<Posts>;
  /** delete data from the table: "private.modified_data" */
  delete_private_modified_data?: Maybe<Private_Modified_Data_Mutation_Response>;
  /** delete single row from the table: "private.modified_data" */
  delete_private_modified_data_by_pk?: Maybe<Private_Modified_Data>;
  /** delete data from the table: "sponsors" */
  delete_sponsors?: Maybe<Sponsors_Mutation_Response>;
  /** delete single row from the table: "sponsors" */
  delete_sponsors_by_pk?: Maybe<Sponsors>;
  /** delete data from the table: "sponsorships" */
  delete_sponsorships?: Maybe<Sponsorships_Mutation_Response>;
  /** delete single row from the table: "sponsorships" */
  delete_sponsorships_by_pk?: Maybe<Sponsorships>;
  /** delete data from the table: "stories" */
  delete_stories?: Maybe<Stories_Mutation_Response>;
  /** delete single row from the table: "stories" */
  delete_stories_by_pk?: Maybe<Stories>;
  /** delete data from the table: "stripe_charges" */
  delete_stripe_charges?: Maybe<Stripe_Charges_Mutation_Response>;
  /** delete single row from the table: "stripe_charges" */
  delete_stripe_charges_by_pk?: Maybe<Stripe_Charges>;
  /** delete data from the table: "stripe_checkout_sessions" */
  delete_stripe_checkout_sessions?: Maybe<Stripe_Checkout_Sessions_Mutation_Response>;
  /** delete single row from the table: "stripe_checkout_sessions" */
  delete_stripe_checkout_sessions_by_pk?: Maybe<Stripe_Checkout_Sessions>;
  /** delete data from the table: "stripe_customers" */
  delete_stripe_customers?: Maybe<Stripe_Customers_Mutation_Response>;
  /** delete single row from the table: "stripe_customers" */
  delete_stripe_customers_by_pk?: Maybe<Stripe_Customers>;
  /** delete data from the table: "stripe_invoice_items" */
  delete_stripe_invoice_items?: Maybe<Stripe_Invoice_Items_Mutation_Response>;
  /** delete single row from the table: "stripe_invoice_items" */
  delete_stripe_invoice_items_by_pk?: Maybe<Stripe_Invoice_Items>;
  /** delete data from the table: "stripe_invoices" */
  delete_stripe_invoices?: Maybe<Stripe_Invoices_Mutation_Response>;
  /** delete single row from the table: "stripe_invoices" */
  delete_stripe_invoices_by_pk?: Maybe<Stripe_Invoices>;
  /** delete data from the table: "stripe_payment_intents" */
  delete_stripe_payment_intents?: Maybe<Stripe_Payment_Intents_Mutation_Response>;
  /** delete single row from the table: "stripe_payment_intents" */
  delete_stripe_payment_intents_by_pk?: Maybe<Stripe_Payment_Intents>;
  /** delete data from the table: "stripe_payment_links" */
  delete_stripe_payment_links?: Maybe<Stripe_Payment_Links_Mutation_Response>;
  /** delete single row from the table: "stripe_payment_links" */
  delete_stripe_payment_links_by_pk?: Maybe<Stripe_Payment_Links>;
  /** delete data from the table: "stripe_payment_methods" */
  delete_stripe_payment_methods?: Maybe<Stripe_Payment_Methods_Mutation_Response>;
  /** delete single row from the table: "stripe_payment_methods" */
  delete_stripe_payment_methods_by_pk?: Maybe<Stripe_Payment_Methods>;
  /** delete data from the table: "stripe_prices" */
  delete_stripe_prices?: Maybe<Stripe_Prices_Mutation_Response>;
  /** delete single row from the table: "stripe_prices" */
  delete_stripe_prices_by_pk?: Maybe<Stripe_Prices>;
  /** delete data from the table: "stripe_products" */
  delete_stripe_products?: Maybe<Stripe_Products_Mutation_Response>;
  /** delete single row from the table: "stripe_products" */
  delete_stripe_products_by_pk?: Maybe<Stripe_Products>;
  /** delete data from the table: "stripe_subscriptions" */
  delete_stripe_subscriptions?: Maybe<Stripe_Subscriptions_Mutation_Response>;
  /** delete single row from the table: "stripe_subscriptions" */
  delete_stripe_subscriptions_by_pk?: Maybe<Stripe_Subscriptions>;
  /** delete data from the table: "tax_receipts" */
  delete_tax_receipts?: Maybe<Tax_Receipts_Mutation_Response>;
  /** delete single row from the table: "tax_receipts" */
  delete_tax_receipts_by_pk?: Maybe<Tax_Receipts>;
  /** delete data from the table: "memberships" */
  delete_team_memberships?: Maybe<Team_Memberships_Mutation_Response>;
  /** delete single row from the table: "memberships" */
  delete_team_memberships_by_pk?: Maybe<Team_Memberships>;
  /** delete data from the table: "team_needs" */
  delete_team_needs?: Maybe<Team_Needs_Mutation_Response>;
  /** delete single row from the table: "team_needs" */
  delete_team_needs_by_pk?: Maybe<Team_Needs>;
  /** delete data from the table: "team_tags" */
  delete_team_tags?: Maybe<Team_Tags_Mutation_Response>;
  /** delete single row from the table: "team_tags" */
  delete_team_tags_by_pk?: Maybe<Team_Tags>;
  /** delete data from the table: "teams" */
  delete_teams?: Maybe<Teams_Mutation_Response>;
  /** delete single row from the table: "teams" */
  delete_teams_by_pk?: Maybe<Teams>;
  /** delete data from the table: "thank_yous" */
  delete_thank_yous?: Maybe<Thank_Yous_Mutation_Response>;
  /** delete single row from the table: "thank_yous" */
  delete_thank_yous_by_pk?: Maybe<Thank_Yous>;
  delete_user?: Maybe<RequestDeleteUserOutput>;
  /** delete data from the table: "user_goals" */
  delete_user_goals?: Maybe<User_Goals_Mutation_Response>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  exchange_legacy_token?: Maybe<ExchangeLegacyTokenOutput>;
  expire_checkout_session?: Maybe<CheckoutSessionOutput>;
  /** insert data into the table: "addresses" */
  insert_addresses?: Maybe<Addresses_Mutation_Response>;
  /** insert data into the table: "addresses_need_informations" */
  insert_addresses_need_informations?: Maybe<Addresses_Need_Informations_Mutation_Response>;
  /** insert a single row into the table: "addresses_need_informations" */
  insert_addresses_need_informations_one?: Maybe<Addresses_Need_Informations>;
  /** insert a single row into the table: "addresses" */
  insert_addresses_one?: Maybe<Addresses>;
  /** insert data into the table: "addresses_organizations" */
  insert_addresses_organizations?: Maybe<Addresses_Organizations_Mutation_Response>;
  /** insert a single row into the table: "addresses_organizations" */
  insert_addresses_organizations_one?: Maybe<Addresses_Organizations>;
  /** insert data into the table: "addresses_users" */
  insert_addresses_users?: Maybe<Addresses_Users_Mutation_Response>;
  /** insert a single row into the table: "addresses_users" */
  insert_addresses_users_one?: Maybe<Addresses_Users>;
  /** insert data into the table: "checkout_intents" */
  insert_checkout_intents?: Maybe<Checkout_Intents_Mutation_Response>;
  /** insert a single row into the table: "checkout_intents" */
  insert_checkout_intents_one?: Maybe<Checkout_Intents>;
  /** insert data into the table: "devices" */
  insert_devices?: Maybe<Devices_Mutation_Response>;
  /** insert a single row into the table: "devices" */
  insert_devices_one?: Maybe<Devices>;
  /** insert data into the table: "feed_items" */
  insert_feed_items?: Maybe<Feed_Items_Mutation_Response>;
  /** insert a single row into the table: "feed_items" */
  insert_feed_items_one?: Maybe<Feed_Items>;
  /** insert data into the table: "feed_posts" */
  insert_feed_posts?: Maybe<Feed_Posts_Mutation_Response>;
  /** insert a single row into the table: "feed_posts" */
  insert_feed_posts_one?: Maybe<Feed_Posts>;
  /** insert data into the table: "goals" */
  insert_goals?: Maybe<Goals_Mutation_Response>;
  /** insert a single row into the table: "goals" */
  insert_goals_one?: Maybe<Goals>;
  /** insert data into the table: "internal_data.organization_goals" */
  insert_internal_data_organization_goals?: Maybe<Internal_Data_Organization_Goals_Mutation_Response>;
  /** insert a single row into the table: "internal_data.organization_goals" */
  insert_internal_data_organization_goals_one?: Maybe<Internal_Data_Organization_Goals>;
  /** insert data into the table: "list_locations" */
  insert_list_locations?: Maybe<List_Locations_Mutation_Response>;
  /** insert a single row into the table: "list_locations" */
  insert_list_locations_one?: Maybe<List_Locations>;
  /** insert data into the table: "list_subscriptions" */
  insert_list_subscriptions?: Maybe<List_Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "list_subscriptions" */
  insert_list_subscriptions_one?: Maybe<List_Subscriptions>;
  /** insert data into the table: "lists" */
  insert_lists?: Maybe<Lists_Mutation_Response>;
  /** insert a single row into the table: "lists" */
  insert_lists_one?: Maybe<Lists>;
  /** insert data into the table: "need_fulfill_intents" */
  insert_need_fulfill_intents?: Maybe<Need_Fulfill_Intents_Mutation_Response>;
  /** insert a single row into the table: "need_fulfill_intents" */
  insert_need_fulfill_intents_one?: Maybe<Need_Fulfill_Intents>;
  /** insert data into the table: "need_informations" */
  insert_need_informations?: Maybe<Need_Informations_Mutation_Response>;
  /** insert a single row into the table: "need_informations" */
  insert_need_informations_one?: Maybe<Need_Informations>;
  /** insert data into the table: "need_tags" */
  insert_need_tags?: Maybe<Need_Tags_Mutation_Response>;
  /** insert a single row into the table: "need_tags" */
  insert_need_tags_one?: Maybe<Need_Tags>;
  /** insert data into the table: "needs" */
  insert_needs?: Maybe<Needs_Mutation_Response>;
  /** insert a single row into the table: "needs" */
  insert_needs_one?: Maybe<Needs>;
  /** insert data into the table: "organization_locations" */
  insert_organization_locations?: Maybe<Organization_Locations_Mutation_Response>;
  /** insert a single row into the table: "organization_locations" */
  insert_organization_locations_one?: Maybe<Organization_Locations>;
  /** insert data into the table: "organization_subscriptions" */
  insert_organization_subscriptions?: Maybe<Organization_Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "organization_subscriptions" */
  insert_organization_subscriptions_one?: Maybe<Organization_Subscriptions>;
  /** insert data into the table: "organization_tags" */
  insert_organization_tags?: Maybe<Organization_Tags_Mutation_Response>;
  /** insert a single row into the table: "organization_tags" */
  insert_organization_tags_one?: Maybe<Organization_Tags>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<Organizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "parent_organizations" */
  insert_parent_organizations?: Maybe<Parent_Organizations_Mutation_Response>;
  /** insert a single row into the table: "parent_organizations" */
  insert_parent_organizations_one?: Maybe<Parent_Organizations>;
  /** insert data into the table: "posts" */
  insert_posts?: Maybe<Posts_Mutation_Response>;
  /** insert a single row into the table: "posts" */
  insert_posts_one?: Maybe<Posts>;
  /** insert data into the table: "private.modified_data" */
  insert_private_modified_data?: Maybe<Private_Modified_Data_Mutation_Response>;
  /** insert a single row into the table: "private.modified_data" */
  insert_private_modified_data_one?: Maybe<Private_Modified_Data>;
  /** insert data into the table: "sponsors" */
  insert_sponsors?: Maybe<Sponsors_Mutation_Response>;
  /** insert a single row into the table: "sponsors" */
  insert_sponsors_one?: Maybe<Sponsors>;
  /** insert data into the table: "sponsorships" */
  insert_sponsorships?: Maybe<Sponsorships_Mutation_Response>;
  /** insert a single row into the table: "sponsorships" */
  insert_sponsorships_one?: Maybe<Sponsorships>;
  /** insert data into the table: "stories" */
  insert_stories?: Maybe<Stories_Mutation_Response>;
  /** insert a single row into the table: "stories" */
  insert_stories_one?: Maybe<Stories>;
  /** insert data into the table: "stripe_charges" */
  insert_stripe_charges?: Maybe<Stripe_Charges_Mutation_Response>;
  /** insert a single row into the table: "stripe_charges" */
  insert_stripe_charges_one?: Maybe<Stripe_Charges>;
  /** insert data into the table: "stripe_checkout_sessions" */
  insert_stripe_checkout_sessions?: Maybe<Stripe_Checkout_Sessions_Mutation_Response>;
  /** insert a single row into the table: "stripe_checkout_sessions" */
  insert_stripe_checkout_sessions_one?: Maybe<Stripe_Checkout_Sessions>;
  /** insert data into the table: "stripe_customers" */
  insert_stripe_customers?: Maybe<Stripe_Customers_Mutation_Response>;
  /** insert a single row into the table: "stripe_customers" */
  insert_stripe_customers_one?: Maybe<Stripe_Customers>;
  /** insert data into the table: "stripe_invoice_items" */
  insert_stripe_invoice_items?: Maybe<Stripe_Invoice_Items_Mutation_Response>;
  /** insert a single row into the table: "stripe_invoice_items" */
  insert_stripe_invoice_items_one?: Maybe<Stripe_Invoice_Items>;
  /** insert data into the table: "stripe_invoices" */
  insert_stripe_invoices?: Maybe<Stripe_Invoices_Mutation_Response>;
  /** insert a single row into the table: "stripe_invoices" */
  insert_stripe_invoices_one?: Maybe<Stripe_Invoices>;
  /** insert data into the table: "stripe_payment_intents" */
  insert_stripe_payment_intents?: Maybe<Stripe_Payment_Intents_Mutation_Response>;
  /** insert a single row into the table: "stripe_payment_intents" */
  insert_stripe_payment_intents_one?: Maybe<Stripe_Payment_Intents>;
  /** insert data into the table: "stripe_payment_links" */
  insert_stripe_payment_links?: Maybe<Stripe_Payment_Links_Mutation_Response>;
  /** insert a single row into the table: "stripe_payment_links" */
  insert_stripe_payment_links_one?: Maybe<Stripe_Payment_Links>;
  /** insert data into the table: "stripe_payment_methods" */
  insert_stripe_payment_methods?: Maybe<Stripe_Payment_Methods_Mutation_Response>;
  /** insert a single row into the table: "stripe_payment_methods" */
  insert_stripe_payment_methods_one?: Maybe<Stripe_Payment_Methods>;
  /** insert data into the table: "stripe_prices" */
  insert_stripe_prices?: Maybe<Stripe_Prices_Mutation_Response>;
  /** insert a single row into the table: "stripe_prices" */
  insert_stripe_prices_one?: Maybe<Stripe_Prices>;
  /** insert data into the table: "stripe_products" */
  insert_stripe_products?: Maybe<Stripe_Products_Mutation_Response>;
  /** insert a single row into the table: "stripe_products" */
  insert_stripe_products_one?: Maybe<Stripe_Products>;
  /** insert data into the table: "stripe_subscriptions" */
  insert_stripe_subscriptions?: Maybe<Stripe_Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "stripe_subscriptions" */
  insert_stripe_subscriptions_one?: Maybe<Stripe_Subscriptions>;
  /** insert data into the table: "tax_receipts" */
  insert_tax_receipts?: Maybe<Tax_Receipts_Mutation_Response>;
  /** insert a single row into the table: "tax_receipts" */
  insert_tax_receipts_one?: Maybe<Tax_Receipts>;
  /** insert data into the table: "memberships" */
  insert_team_memberships?: Maybe<Team_Memberships_Mutation_Response>;
  /** insert a single row into the table: "memberships" */
  insert_team_memberships_one?: Maybe<Team_Memberships>;
  /** insert data into the table: "team_needs" */
  insert_team_needs?: Maybe<Team_Needs_Mutation_Response>;
  /** insert a single row into the table: "team_needs" */
  insert_team_needs_one?: Maybe<Team_Needs>;
  /** insert data into the table: "team_tags" */
  insert_team_tags?: Maybe<Team_Tags_Mutation_Response>;
  /** insert a single row into the table: "team_tags" */
  insert_team_tags_one?: Maybe<Team_Tags>;
  /** insert data into the table: "teams" */
  insert_teams?: Maybe<Teams_Mutation_Response>;
  /** insert a single row into the table: "teams" */
  insert_teams_one?: Maybe<Teams>;
  /** insert data into the table: "thank_yous" */
  insert_thank_yous?: Maybe<Thank_Yous_Mutation_Response>;
  /** insert a single row into the table: "thank_yous" */
  insert_thank_yous_one?: Maybe<Thank_Yous>;
  /** insert data into the table: "user_goals" */
  insert_user_goals?: Maybe<User_Goals_Mutation_Response>;
  /** insert a single row into the table: "user_goals" */
  insert_user_goals_one?: Maybe<User_Goals>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  request_login_ticket?: Maybe<RequestLoginTicketOutput>;
  run_signup_tasks?: Maybe<RunSignupTasksOutput>;
  /** update data of the table: "addresses" */
  update_addresses?: Maybe<Addresses_Mutation_Response>;
  /** update single row of the table: "addresses" */
  update_addresses_by_pk?: Maybe<Addresses>;
  /** update multiples rows of table: "addresses" */
  update_addresses_many?: Maybe<Array<Maybe<Addresses_Mutation_Response>>>;
  /** update data of the table: "addresses_need_informations" */
  update_addresses_need_informations?: Maybe<Addresses_Need_Informations_Mutation_Response>;
  /** update multiples rows of table: "addresses_need_informations" */
  update_addresses_need_informations_many?: Maybe<Array<Maybe<Addresses_Need_Informations_Mutation_Response>>>;
  /** update data of the table: "addresses_organizations" */
  update_addresses_organizations?: Maybe<Addresses_Organizations_Mutation_Response>;
  /** update multiples rows of table: "addresses_organizations" */
  update_addresses_organizations_many?: Maybe<Array<Maybe<Addresses_Organizations_Mutation_Response>>>;
  /** update data of the table: "addresses_users" */
  update_addresses_users?: Maybe<Addresses_Users_Mutation_Response>;
  /** update multiples rows of table: "addresses_users" */
  update_addresses_users_many?: Maybe<Array<Maybe<Addresses_Users_Mutation_Response>>>;
  /** update data of the table: "checkout_intents" */
  update_checkout_intents?: Maybe<Checkout_Intents_Mutation_Response>;
  /** update single row of the table: "checkout_intents" */
  update_checkout_intents_by_pk?: Maybe<Checkout_Intents>;
  /** update multiples rows of table: "checkout_intents" */
  update_checkout_intents_many?: Maybe<Array<Maybe<Checkout_Intents_Mutation_Response>>>;
  /** update data of the table: "devices" */
  update_devices?: Maybe<Devices_Mutation_Response>;
  /** update single row of the table: "devices" */
  update_devices_by_pk?: Maybe<Devices>;
  /** update multiples rows of table: "devices" */
  update_devices_many?: Maybe<Array<Maybe<Devices_Mutation_Response>>>;
  /** update data of the table: "feed_items" */
  update_feed_items?: Maybe<Feed_Items_Mutation_Response>;
  /** update single row of the table: "feed_items" */
  update_feed_items_by_pk?: Maybe<Feed_Items>;
  /** update multiples rows of table: "feed_items" */
  update_feed_items_many?: Maybe<Array<Maybe<Feed_Items_Mutation_Response>>>;
  /** update data of the table: "feed_posts" */
  update_feed_posts?: Maybe<Feed_Posts_Mutation_Response>;
  /** update single row of the table: "feed_posts" */
  update_feed_posts_by_pk?: Maybe<Feed_Posts>;
  /** update multiples rows of table: "feed_posts" */
  update_feed_posts_many?: Maybe<Array<Maybe<Feed_Posts_Mutation_Response>>>;
  /** update data of the table: "goals" */
  update_goals?: Maybe<Goals_Mutation_Response>;
  /** update single row of the table: "goals" */
  update_goals_by_pk?: Maybe<Goals>;
  /** update multiples rows of table: "goals" */
  update_goals_many?: Maybe<Array<Maybe<Goals_Mutation_Response>>>;
  /** update data of the table: "internal_data.organization_goals" */
  update_internal_data_organization_goals?: Maybe<Internal_Data_Organization_Goals_Mutation_Response>;
  /** update single row of the table: "internal_data.organization_goals" */
  update_internal_data_organization_goals_by_pk?: Maybe<Internal_Data_Organization_Goals>;
  /** update multiples rows of table: "internal_data.organization_goals" */
  update_internal_data_organization_goals_many?: Maybe<Array<Maybe<Internal_Data_Organization_Goals_Mutation_Response>>>;
  /** update data of the table: "list_locations" */
  update_list_locations?: Maybe<List_Locations_Mutation_Response>;
  /** update multiples rows of table: "list_locations" */
  update_list_locations_many?: Maybe<Array<Maybe<List_Locations_Mutation_Response>>>;
  /** update data of the table: "list_subscriptions" */
  update_list_subscriptions?: Maybe<List_Subscriptions_Mutation_Response>;
  /** update single row of the table: "list_subscriptions" */
  update_list_subscriptions_by_pk?: Maybe<List_Subscriptions>;
  /** update multiples rows of table: "list_subscriptions" */
  update_list_subscriptions_many?: Maybe<Array<Maybe<List_Subscriptions_Mutation_Response>>>;
  /** update data of the table: "lists" */
  update_lists?: Maybe<Lists_Mutation_Response>;
  /** update single row of the table: "lists" */
  update_lists_by_pk?: Maybe<Lists>;
  /** update multiples rows of table: "lists" */
  update_lists_many?: Maybe<Array<Maybe<Lists_Mutation_Response>>>;
  /** update data of the table: "need_fulfill_intents" */
  update_need_fulfill_intents?: Maybe<Need_Fulfill_Intents_Mutation_Response>;
  /** update single row of the table: "need_fulfill_intents" */
  update_need_fulfill_intents_by_pk?: Maybe<Need_Fulfill_Intents>;
  /** update multiples rows of table: "need_fulfill_intents" */
  update_need_fulfill_intents_many?: Maybe<Array<Maybe<Need_Fulfill_Intents_Mutation_Response>>>;
  /** update data of the table: "need_informations" */
  update_need_informations?: Maybe<Need_Informations_Mutation_Response>;
  /** update single row of the table: "need_informations" */
  update_need_informations_by_pk?: Maybe<Need_Informations>;
  /** update multiples rows of table: "need_informations" */
  update_need_informations_many?: Maybe<Array<Maybe<Need_Informations_Mutation_Response>>>;
  /** update data of the table: "need_tags" */
  update_need_tags?: Maybe<Need_Tags_Mutation_Response>;
  /** update single row of the table: "need_tags" */
  update_need_tags_by_pk?: Maybe<Need_Tags>;
  /** update multiples rows of table: "need_tags" */
  update_need_tags_many?: Maybe<Array<Maybe<Need_Tags_Mutation_Response>>>;
  /** update data of the table: "needs" */
  update_needs?: Maybe<Needs_Mutation_Response>;
  /** update single row of the table: "needs" */
  update_needs_by_pk?: Maybe<Needs>;
  /** update multiples rows of table: "needs" */
  update_needs_many?: Maybe<Array<Maybe<Needs_Mutation_Response>>>;
  /** update data of the table: "organization_locations" */
  update_organization_locations?: Maybe<Organization_Locations_Mutation_Response>;
  /** update multiples rows of table: "organization_locations" */
  update_organization_locations_many?: Maybe<Array<Maybe<Organization_Locations_Mutation_Response>>>;
  /** update data of the table: "organization_subscriptions" */
  update_organization_subscriptions?: Maybe<Organization_Subscriptions_Mutation_Response>;
  /** update single row of the table: "organization_subscriptions" */
  update_organization_subscriptions_by_pk?: Maybe<Organization_Subscriptions>;
  /** update multiples rows of table: "organization_subscriptions" */
  update_organization_subscriptions_many?: Maybe<Array<Maybe<Organization_Subscriptions_Mutation_Response>>>;
  /** update data of the table: "organization_tags" */
  update_organization_tags?: Maybe<Organization_Tags_Mutation_Response>;
  /** update single row of the table: "organization_tags" */
  update_organization_tags_by_pk?: Maybe<Organization_Tags>;
  /** update multiples rows of table: "organization_tags" */
  update_organization_tags_many?: Maybe<Array<Maybe<Organization_Tags_Mutation_Response>>>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<Organizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update multiples rows of table: "organizations" */
  update_organizations_many?: Maybe<Array<Maybe<Organizations_Mutation_Response>>>;
  /** update data of the table: "parent_organizations" */
  update_parent_organizations?: Maybe<Parent_Organizations_Mutation_Response>;
  /** update single row of the table: "parent_organizations" */
  update_parent_organizations_by_pk?: Maybe<Parent_Organizations>;
  /** update multiples rows of table: "parent_organizations" */
  update_parent_organizations_many?: Maybe<Array<Maybe<Parent_Organizations_Mutation_Response>>>;
  /** update data of the table: "posts" */
  update_posts?: Maybe<Posts_Mutation_Response>;
  /** update single row of the table: "posts" */
  update_posts_by_pk?: Maybe<Posts>;
  /** update multiples rows of table: "posts" */
  update_posts_many?: Maybe<Array<Maybe<Posts_Mutation_Response>>>;
  /** update data of the table: "private.modified_data" */
  update_private_modified_data?: Maybe<Private_Modified_Data_Mutation_Response>;
  /** update single row of the table: "private.modified_data" */
  update_private_modified_data_by_pk?: Maybe<Private_Modified_Data>;
  /** update multiples rows of table: "private.modified_data" */
  update_private_modified_data_many?: Maybe<Array<Maybe<Private_Modified_Data_Mutation_Response>>>;
  /** update data of the table: "sponsors" */
  update_sponsors?: Maybe<Sponsors_Mutation_Response>;
  /** update single row of the table: "sponsors" */
  update_sponsors_by_pk?: Maybe<Sponsors>;
  /** update multiples rows of table: "sponsors" */
  update_sponsors_many?: Maybe<Array<Maybe<Sponsors_Mutation_Response>>>;
  /** update data of the table: "sponsorships" */
  update_sponsorships?: Maybe<Sponsorships_Mutation_Response>;
  /** update single row of the table: "sponsorships" */
  update_sponsorships_by_pk?: Maybe<Sponsorships>;
  /** update multiples rows of table: "sponsorships" */
  update_sponsorships_many?: Maybe<Array<Maybe<Sponsorships_Mutation_Response>>>;
  /** update data of the table: "stories" */
  update_stories?: Maybe<Stories_Mutation_Response>;
  /** update single row of the table: "stories" */
  update_stories_by_pk?: Maybe<Stories>;
  /** update multiples rows of table: "stories" */
  update_stories_many?: Maybe<Array<Maybe<Stories_Mutation_Response>>>;
  /** update data of the table: "stripe_charges" */
  update_stripe_charges?: Maybe<Stripe_Charges_Mutation_Response>;
  /** update single row of the table: "stripe_charges" */
  update_stripe_charges_by_pk?: Maybe<Stripe_Charges>;
  /** update multiples rows of table: "stripe_charges" */
  update_stripe_charges_many?: Maybe<Array<Maybe<Stripe_Charges_Mutation_Response>>>;
  /** update data of the table: "stripe_checkout_sessions" */
  update_stripe_checkout_sessions?: Maybe<Stripe_Checkout_Sessions_Mutation_Response>;
  /** update single row of the table: "stripe_checkout_sessions" */
  update_stripe_checkout_sessions_by_pk?: Maybe<Stripe_Checkout_Sessions>;
  /** update multiples rows of table: "stripe_checkout_sessions" */
  update_stripe_checkout_sessions_many?: Maybe<Array<Maybe<Stripe_Checkout_Sessions_Mutation_Response>>>;
  /** update data of the table: "stripe_customers" */
  update_stripe_customers?: Maybe<Stripe_Customers_Mutation_Response>;
  /** update single row of the table: "stripe_customers" */
  update_stripe_customers_by_pk?: Maybe<Stripe_Customers>;
  /** update multiples rows of table: "stripe_customers" */
  update_stripe_customers_many?: Maybe<Array<Maybe<Stripe_Customers_Mutation_Response>>>;
  /** update data of the table: "stripe_invoice_items" */
  update_stripe_invoice_items?: Maybe<Stripe_Invoice_Items_Mutation_Response>;
  /** update single row of the table: "stripe_invoice_items" */
  update_stripe_invoice_items_by_pk?: Maybe<Stripe_Invoice_Items>;
  /** update multiples rows of table: "stripe_invoice_items" */
  update_stripe_invoice_items_many?: Maybe<Array<Maybe<Stripe_Invoice_Items_Mutation_Response>>>;
  /** update data of the table: "stripe_invoices" */
  update_stripe_invoices?: Maybe<Stripe_Invoices_Mutation_Response>;
  /** update single row of the table: "stripe_invoices" */
  update_stripe_invoices_by_pk?: Maybe<Stripe_Invoices>;
  /** update multiples rows of table: "stripe_invoices" */
  update_stripe_invoices_many?: Maybe<Array<Maybe<Stripe_Invoices_Mutation_Response>>>;
  /** update data of the table: "stripe_payment_intents" */
  update_stripe_payment_intents?: Maybe<Stripe_Payment_Intents_Mutation_Response>;
  /** update single row of the table: "stripe_payment_intents" */
  update_stripe_payment_intents_by_pk?: Maybe<Stripe_Payment_Intents>;
  /** update multiples rows of table: "stripe_payment_intents" */
  update_stripe_payment_intents_many?: Maybe<Array<Maybe<Stripe_Payment_Intents_Mutation_Response>>>;
  /** update data of the table: "stripe_payment_links" */
  update_stripe_payment_links?: Maybe<Stripe_Payment_Links_Mutation_Response>;
  /** update single row of the table: "stripe_payment_links" */
  update_stripe_payment_links_by_pk?: Maybe<Stripe_Payment_Links>;
  /** update multiples rows of table: "stripe_payment_links" */
  update_stripe_payment_links_many?: Maybe<Array<Maybe<Stripe_Payment_Links_Mutation_Response>>>;
  /** update data of the table: "stripe_payment_methods" */
  update_stripe_payment_methods?: Maybe<Stripe_Payment_Methods_Mutation_Response>;
  /** update single row of the table: "stripe_payment_methods" */
  update_stripe_payment_methods_by_pk?: Maybe<Stripe_Payment_Methods>;
  /** update multiples rows of table: "stripe_payment_methods" */
  update_stripe_payment_methods_many?: Maybe<Array<Maybe<Stripe_Payment_Methods_Mutation_Response>>>;
  /** update data of the table: "stripe_prices" */
  update_stripe_prices?: Maybe<Stripe_Prices_Mutation_Response>;
  /** update single row of the table: "stripe_prices" */
  update_stripe_prices_by_pk?: Maybe<Stripe_Prices>;
  /** update multiples rows of table: "stripe_prices" */
  update_stripe_prices_many?: Maybe<Array<Maybe<Stripe_Prices_Mutation_Response>>>;
  /** update data of the table: "stripe_products" */
  update_stripe_products?: Maybe<Stripe_Products_Mutation_Response>;
  /** update single row of the table: "stripe_products" */
  update_stripe_products_by_pk?: Maybe<Stripe_Products>;
  /** update multiples rows of table: "stripe_products" */
  update_stripe_products_many?: Maybe<Array<Maybe<Stripe_Products_Mutation_Response>>>;
  /** update data of the table: "stripe_subscriptions" */
  update_stripe_subscriptions?: Maybe<Stripe_Subscriptions_Mutation_Response>;
  /** update single row of the table: "stripe_subscriptions" */
  update_stripe_subscriptions_by_pk?: Maybe<Stripe_Subscriptions>;
  /** update multiples rows of table: "stripe_subscriptions" */
  update_stripe_subscriptions_many?: Maybe<Array<Maybe<Stripe_Subscriptions_Mutation_Response>>>;
  /** update data of the table: "tax_receipts" */
  update_tax_receipts?: Maybe<Tax_Receipts_Mutation_Response>;
  /** update single row of the table: "tax_receipts" */
  update_tax_receipts_by_pk?: Maybe<Tax_Receipts>;
  /** update multiples rows of table: "tax_receipts" */
  update_tax_receipts_many?: Maybe<Array<Maybe<Tax_Receipts_Mutation_Response>>>;
  /** update data of the table: "memberships" */
  update_team_memberships?: Maybe<Team_Memberships_Mutation_Response>;
  /** update single row of the table: "memberships" */
  update_team_memberships_by_pk?: Maybe<Team_Memberships>;
  /** update multiples rows of table: "memberships" */
  update_team_memberships_many?: Maybe<Array<Maybe<Team_Memberships_Mutation_Response>>>;
  /** update data of the table: "team_needs" */
  update_team_needs?: Maybe<Team_Needs_Mutation_Response>;
  /** update single row of the table: "team_needs" */
  update_team_needs_by_pk?: Maybe<Team_Needs>;
  /** update multiples rows of table: "team_needs" */
  update_team_needs_many?: Maybe<Array<Maybe<Team_Needs_Mutation_Response>>>;
  /** update data of the table: "team_tags" */
  update_team_tags?: Maybe<Team_Tags_Mutation_Response>;
  /** update single row of the table: "team_tags" */
  update_team_tags_by_pk?: Maybe<Team_Tags>;
  /** update multiples rows of table: "team_tags" */
  update_team_tags_many?: Maybe<Array<Maybe<Team_Tags_Mutation_Response>>>;
  /** update data of the table: "teams" */
  update_teams?: Maybe<Teams_Mutation_Response>;
  /** update single row of the table: "teams" */
  update_teams_by_pk?: Maybe<Teams>;
  /** update multiples rows of table: "teams" */
  update_teams_many?: Maybe<Array<Maybe<Teams_Mutation_Response>>>;
  /** update data of the table: "thank_yous" */
  update_thank_yous?: Maybe<Thank_Yous_Mutation_Response>;
  /** update single row of the table: "thank_yous" */
  update_thank_yous_by_pk?: Maybe<Thank_Yous>;
  /** update multiples rows of table: "thank_yous" */
  update_thank_yous_many?: Maybe<Array<Maybe<Thank_Yous_Mutation_Response>>>;
  /** update data of the table: "user_goals" */
  update_user_goals?: Maybe<User_Goals_Mutation_Response>;
  /** update multiples rows of table: "user_goals" */
  update_user_goals_many?: Maybe<Array<Maybe<User_Goals_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootCancel_Universal_CheckoutArgs = {
  params: CancelUniversalCheckoutParams;
};


/** mutation root */
export type Mutation_RootCreate_Checkout_SessionArgs = {
  need_ids: Array<InputMaybe<Scalars['Int']['input']>>;
  tip_percentage?: InputMaybe<Scalars['Float']['input']>;
};


/** mutation root */
export type Mutation_RootCreate_Donate_CheckoutArgs = {
  params: CreateDonateCheckoutParams;
};


/** mutation root */
export type Mutation_RootCreate_Universal_CheckoutArgs = {
  params: CreateUniversalCheckoutParams;
};


/** mutation root */
export type Mutation_RootDelete_AddressesArgs = {
  where: Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Addresses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Addresses_Need_InformationsArgs = {
  where: Addresses_Need_Informations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Addresses_OrganizationsArgs = {
  where: Addresses_Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Addresses_UsersArgs = {
  where: Addresses_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Checkout_IntentsArgs = {
  where: Checkout_Intents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Checkout_Intents_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_DevicesArgs = {
  where: Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Devices_By_PkArgs = {
  bundle_id: Scalars['String']['input'];
  local_id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Feed_ItemsArgs = {
  where: Feed_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feed_Items_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Feed_PostsArgs = {
  where: Feed_Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feed_Posts_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_GoalsArgs = {
  where: Goals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Goals_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Internal_Data_Organization_GoalsArgs = {
  where: Internal_Data_Organization_Goals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Internal_Data_Organization_Goals_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_List_LocationsArgs = {
  where: List_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_List_SubscriptionsArgs = {
  where: List_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_List_Subscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ListsArgs = {
  where: Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lists_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Need_Fulfill_IntentsArgs = {
  where: Need_Fulfill_Intents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Need_Fulfill_Intents_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Need_InformationsArgs = {
  where: Need_Informations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Need_Informations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Need_TagsArgs = {
  where: Need_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Need_Tags_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NeedsArgs = {
  where: Needs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Needs_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Organization_LocationsArgs = {
  where: Organization_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organization_SubscriptionsArgs = {
  where: Organization_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organization_Subscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Organization_TagsArgs = {
  where: Organization_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organization_Tags_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OrganizationsArgs = {
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Parent_OrganizationsArgs = {
  where: Parent_Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Parent_Organizations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PostsArgs = {
  where: Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Posts_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Private_Modified_DataArgs = {
  where: Private_Modified_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Private_Modified_Data_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SponsorsArgs = {
  where: Sponsors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sponsors_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SponsorshipsArgs = {
  where: Sponsorships_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sponsorships_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_StoriesArgs = {
  where: Stories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stories_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_ChargesArgs = {
  where: Stripe_Charges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Charges_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Checkout_SessionsArgs = {
  where: Stripe_Checkout_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Checkout_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_CustomersArgs = {
  where: Stripe_Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Customers_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Invoice_ItemsArgs = {
  where: Stripe_Invoice_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Invoice_Items_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_InvoicesArgs = {
  where: Stripe_Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Invoices_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Payment_IntentsArgs = {
  where: Stripe_Payment_Intents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Payment_Intents_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Payment_LinksArgs = {
  where: Stripe_Payment_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Payment_Links_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Payment_MethodsArgs = {
  where: Stripe_Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Payment_Methods_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_PricesArgs = {
  where: Stripe_Prices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Prices_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_ProductsArgs = {
  where: Stripe_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Products_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_SubscriptionsArgs = {
  where: Stripe_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Subscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Tax_ReceiptsArgs = {
  where: Tax_Receipts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tax_Receipts_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Team_MembershipsArgs = {
  where: Team_Memberships_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Memberships_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Team_NeedsArgs = {
  where: Team_Needs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Needs_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Team_TagsArgs = {
  where: Team_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Tags_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TeamsArgs = {
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Thank_YousArgs = {
  where: Thank_Yous_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Thank_Yous_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_GoalsArgs = {
  where: User_Goals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootExchange_Legacy_TokenArgs = {
  params: ExchangeLegacyTokenParams;
};


/** mutation root */
export type Mutation_RootExpire_Checkout_SessionArgs = {
  checkout_session_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsert_AddressesArgs = {
  objects: Array<Addresses_Insert_Input>;
  on_conflict?: InputMaybe<Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_Need_InformationsArgs = {
  objects: Array<Addresses_Need_Informations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_Need_Informations_OneArgs = {
  object: Addresses_Need_Informations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_OneArgs = {
  object: Addresses_Insert_Input;
  on_conflict?: InputMaybe<Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_OrganizationsArgs = {
  objects: Array<Addresses_Organizations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_Organizations_OneArgs = {
  object: Addresses_Organizations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_UsersArgs = {
  objects: Array<Addresses_Users_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_Users_OneArgs = {
  object: Addresses_Users_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Checkout_IntentsArgs = {
  objects: Array<Checkout_Intents_Insert_Input>;
  on_conflict?: InputMaybe<Checkout_Intents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Checkout_Intents_OneArgs = {
  object: Checkout_Intents_Insert_Input;
  on_conflict?: InputMaybe<Checkout_Intents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DevicesArgs = {
  objects: Array<Devices_Insert_Input>;
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Devices_OneArgs = {
  object: Devices_Insert_Input;
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feed_ItemsArgs = {
  objects: Array<Feed_Items_Insert_Input>;
  on_conflict?: InputMaybe<Feed_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feed_Items_OneArgs = {
  object: Feed_Items_Insert_Input;
  on_conflict?: InputMaybe<Feed_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feed_PostsArgs = {
  objects: Array<Feed_Posts_Insert_Input>;
  on_conflict?: InputMaybe<Feed_Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feed_Posts_OneArgs = {
  object: Feed_Posts_Insert_Input;
  on_conflict?: InputMaybe<Feed_Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoalsArgs = {
  objects: Array<Goals_Insert_Input>;
  on_conflict?: InputMaybe<Goals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Goals_OneArgs = {
  object: Goals_Insert_Input;
  on_conflict?: InputMaybe<Goals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Internal_Data_Organization_GoalsArgs = {
  objects: Array<Internal_Data_Organization_Goals_Insert_Input>;
  on_conflict?: InputMaybe<Internal_Data_Organization_Goals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Internal_Data_Organization_Goals_OneArgs = {
  object: Internal_Data_Organization_Goals_Insert_Input;
  on_conflict?: InputMaybe<Internal_Data_Organization_Goals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_List_LocationsArgs = {
  objects: Array<List_Locations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_List_Locations_OneArgs = {
  object: List_Locations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_List_SubscriptionsArgs = {
  objects: Array<List_Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<List_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_List_Subscriptions_OneArgs = {
  object: List_Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<List_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ListsArgs = {
  objects: Array<Lists_Insert_Input>;
  on_conflict?: InputMaybe<Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lists_OneArgs = {
  object: Lists_Insert_Input;
  on_conflict?: InputMaybe<Lists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Need_Fulfill_IntentsArgs = {
  objects: Array<Need_Fulfill_Intents_Insert_Input>;
  on_conflict?: InputMaybe<Need_Fulfill_Intents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Need_Fulfill_Intents_OneArgs = {
  object: Need_Fulfill_Intents_Insert_Input;
  on_conflict?: InputMaybe<Need_Fulfill_Intents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Need_InformationsArgs = {
  objects: Array<Need_Informations_Insert_Input>;
  on_conflict?: InputMaybe<Need_Informations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Need_Informations_OneArgs = {
  object: Need_Informations_Insert_Input;
  on_conflict?: InputMaybe<Need_Informations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Need_TagsArgs = {
  objects: Array<Need_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Need_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Need_Tags_OneArgs = {
  object: Need_Tags_Insert_Input;
  on_conflict?: InputMaybe<Need_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NeedsArgs = {
  objects: Array<Needs_Insert_Input>;
  on_conflict?: InputMaybe<Needs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Needs_OneArgs = {
  object: Needs_Insert_Input;
  on_conflict?: InputMaybe<Needs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_LocationsArgs = {
  objects: Array<Organization_Locations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_Locations_OneArgs = {
  object: Organization_Locations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Organization_SubscriptionsArgs = {
  objects: Array<Organization_Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_Subscriptions_OneArgs = {
  object: Organization_Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Organization_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_TagsArgs = {
  objects: Array<Organization_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_Tags_OneArgs = {
  object: Organization_Tags_Insert_Input;
  on_conflict?: InputMaybe<Organization_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganizationsArgs = {
  objects: Array<Organizations_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_OneArgs = {
  object: Organizations_Insert_Input;
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parent_OrganizationsArgs = {
  objects: Array<Parent_Organizations_Insert_Input>;
  on_conflict?: InputMaybe<Parent_Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parent_Organizations_OneArgs = {
  object: Parent_Organizations_Insert_Input;
  on_conflict?: InputMaybe<Parent_Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PostsArgs = {
  objects: Array<Posts_Insert_Input>;
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Posts_OneArgs = {
  object: Posts_Insert_Input;
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Private_Modified_DataArgs = {
  objects: Array<Private_Modified_Data_Insert_Input>;
  on_conflict?: InputMaybe<Private_Modified_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Private_Modified_Data_OneArgs = {
  object: Private_Modified_Data_Insert_Input;
  on_conflict?: InputMaybe<Private_Modified_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SponsorsArgs = {
  objects: Array<Sponsors_Insert_Input>;
  on_conflict?: InputMaybe<Sponsors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sponsors_OneArgs = {
  object: Sponsors_Insert_Input;
  on_conflict?: InputMaybe<Sponsors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SponsorshipsArgs = {
  objects: Array<Sponsorships_Insert_Input>;
  on_conflict?: InputMaybe<Sponsorships_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sponsorships_OneArgs = {
  object: Sponsorships_Insert_Input;
  on_conflict?: InputMaybe<Sponsorships_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StoriesArgs = {
  objects: Array<Stories_Insert_Input>;
  on_conflict?: InputMaybe<Stories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stories_OneArgs = {
  object: Stories_Insert_Input;
  on_conflict?: InputMaybe<Stories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_ChargesArgs = {
  objects: Array<Stripe_Charges_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Charges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Charges_OneArgs = {
  object: Stripe_Charges_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Charges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Checkout_SessionsArgs = {
  objects: Array<Stripe_Checkout_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Checkout_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Checkout_Sessions_OneArgs = {
  object: Stripe_Checkout_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Checkout_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_CustomersArgs = {
  objects: Array<Stripe_Customers_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Customers_OneArgs = {
  object: Stripe_Customers_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Invoice_ItemsArgs = {
  objects: Array<Stripe_Invoice_Items_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Invoice_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Invoice_Items_OneArgs = {
  object: Stripe_Invoice_Items_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Invoice_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_InvoicesArgs = {
  objects: Array<Stripe_Invoices_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Invoices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Invoices_OneArgs = {
  object: Stripe_Invoices_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Invoices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Payment_IntentsArgs = {
  objects: Array<Stripe_Payment_Intents_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Payment_Intents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Payment_Intents_OneArgs = {
  object: Stripe_Payment_Intents_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Payment_Intents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Payment_LinksArgs = {
  objects: Array<Stripe_Payment_Links_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Payment_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Payment_Links_OneArgs = {
  object: Stripe_Payment_Links_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Payment_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Payment_MethodsArgs = {
  objects: Array<Stripe_Payment_Methods_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Payment_Methods_OneArgs = {
  object: Stripe_Payment_Methods_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Payment_Methods_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_PricesArgs = {
  objects: Array<Stripe_Prices_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Prices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Prices_OneArgs = {
  object: Stripe_Prices_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Prices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_ProductsArgs = {
  objects: Array<Stripe_Products_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Products_OneArgs = {
  object: Stripe_Products_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_SubscriptionsArgs = {
  objects: Array<Stripe_Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Subscriptions_OneArgs = {
  object: Stripe_Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tax_ReceiptsArgs = {
  objects: Array<Tax_Receipts_Insert_Input>;
  on_conflict?: InputMaybe<Tax_Receipts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tax_Receipts_OneArgs = {
  object: Tax_Receipts_Insert_Input;
  on_conflict?: InputMaybe<Tax_Receipts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_MembershipsArgs = {
  objects: Array<Team_Memberships_Insert_Input>;
  on_conflict?: InputMaybe<Team_Memberships_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Memberships_OneArgs = {
  object: Team_Memberships_Insert_Input;
  on_conflict?: InputMaybe<Team_Memberships_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_NeedsArgs = {
  objects: Array<Team_Needs_Insert_Input>;
  on_conflict?: InputMaybe<Team_Needs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Needs_OneArgs = {
  object: Team_Needs_Insert_Input;
  on_conflict?: InputMaybe<Team_Needs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_TagsArgs = {
  objects: Array<Team_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Team_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Tags_OneArgs = {
  object: Team_Tags_Insert_Input;
  on_conflict?: InputMaybe<Team_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamsArgs = {
  objects: Array<Teams_Insert_Input>;
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_OneArgs = {
  object: Teams_Insert_Input;
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Thank_YousArgs = {
  objects: Array<Thank_Yous_Insert_Input>;
  on_conflict?: InputMaybe<Thank_Yous_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Thank_Yous_OneArgs = {
  object: Thank_Yous_Insert_Input;
  on_conflict?: InputMaybe<Thank_Yous_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_GoalsArgs = {
  objects: Array<User_Goals_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_User_Goals_OneArgs = {
  object: User_Goals_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRequest_Login_TicketArgs = {
  params: RequestLoginTicketParams;
};


/** mutation root */
export type Mutation_RootRun_Signup_TasksArgs = {
  params: RunSignupTasksParams;
};


/** mutation root */
export type Mutation_RootUpdate_AddressesArgs = {
  _inc?: InputMaybe<Addresses_Inc_Input>;
  _set?: InputMaybe<Addresses_Set_Input>;
  where: Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_By_PkArgs = {
  _inc?: InputMaybe<Addresses_Inc_Input>;
  _set?: InputMaybe<Addresses_Set_Input>;
  pk_columns: Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_ManyArgs = {
  updates: Array<Addresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_Need_InformationsArgs = {
  _inc?: InputMaybe<Addresses_Need_Informations_Inc_Input>;
  _set?: InputMaybe<Addresses_Need_Informations_Set_Input>;
  where: Addresses_Need_Informations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_Need_Informations_ManyArgs = {
  updates: Array<Addresses_Need_Informations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_OrganizationsArgs = {
  _inc?: InputMaybe<Addresses_Organizations_Inc_Input>;
  _set?: InputMaybe<Addresses_Organizations_Set_Input>;
  where: Addresses_Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_Organizations_ManyArgs = {
  updates: Array<Addresses_Organizations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_UsersArgs = {
  _inc?: InputMaybe<Addresses_Users_Inc_Input>;
  _set?: InputMaybe<Addresses_Users_Set_Input>;
  where: Addresses_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_Users_ManyArgs = {
  updates: Array<Addresses_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Checkout_IntentsArgs = {
  _inc?: InputMaybe<Checkout_Intents_Inc_Input>;
  _set?: InputMaybe<Checkout_Intents_Set_Input>;
  where: Checkout_Intents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Checkout_Intents_By_PkArgs = {
  _inc?: InputMaybe<Checkout_Intents_Inc_Input>;
  _set?: InputMaybe<Checkout_Intents_Set_Input>;
  pk_columns: Checkout_Intents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Checkout_Intents_ManyArgs = {
  updates: Array<Checkout_Intents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DevicesArgs = {
  _inc?: InputMaybe<Devices_Inc_Input>;
  _set?: InputMaybe<Devices_Set_Input>;
  where: Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Devices_By_PkArgs = {
  _inc?: InputMaybe<Devices_Inc_Input>;
  _set?: InputMaybe<Devices_Set_Input>;
  pk_columns: Devices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Devices_ManyArgs = {
  updates: Array<Devices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Feed_ItemsArgs = {
  _inc?: InputMaybe<Feed_Items_Inc_Input>;
  _set?: InputMaybe<Feed_Items_Set_Input>;
  where: Feed_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feed_Items_By_PkArgs = {
  _inc?: InputMaybe<Feed_Items_Inc_Input>;
  _set?: InputMaybe<Feed_Items_Set_Input>;
  pk_columns: Feed_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Feed_Items_ManyArgs = {
  updates: Array<Feed_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Feed_PostsArgs = {
  _inc?: InputMaybe<Feed_Posts_Inc_Input>;
  _set?: InputMaybe<Feed_Posts_Set_Input>;
  where: Feed_Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feed_Posts_By_PkArgs = {
  _inc?: InputMaybe<Feed_Posts_Inc_Input>;
  _set?: InputMaybe<Feed_Posts_Set_Input>;
  pk_columns: Feed_Posts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Feed_Posts_ManyArgs = {
  updates: Array<Feed_Posts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GoalsArgs = {
  _inc?: InputMaybe<Goals_Inc_Input>;
  _set?: InputMaybe<Goals_Set_Input>;
  where: Goals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Goals_By_PkArgs = {
  _inc?: InputMaybe<Goals_Inc_Input>;
  _set?: InputMaybe<Goals_Set_Input>;
  pk_columns: Goals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Goals_ManyArgs = {
  updates: Array<Goals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Internal_Data_Organization_GoalsArgs = {
  _inc?: InputMaybe<Internal_Data_Organization_Goals_Inc_Input>;
  _set?: InputMaybe<Internal_Data_Organization_Goals_Set_Input>;
  where: Internal_Data_Organization_Goals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Internal_Data_Organization_Goals_By_PkArgs = {
  _inc?: InputMaybe<Internal_Data_Organization_Goals_Inc_Input>;
  _set?: InputMaybe<Internal_Data_Organization_Goals_Set_Input>;
  pk_columns: Internal_Data_Organization_Goals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Internal_Data_Organization_Goals_ManyArgs = {
  updates: Array<Internal_Data_Organization_Goals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_List_LocationsArgs = {
  _inc?: InputMaybe<List_Locations_Inc_Input>;
  _set?: InputMaybe<List_Locations_Set_Input>;
  where: List_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_List_Locations_ManyArgs = {
  updates: Array<List_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_List_SubscriptionsArgs = {
  _inc?: InputMaybe<List_Subscriptions_Inc_Input>;
  _set?: InputMaybe<List_Subscriptions_Set_Input>;
  where: List_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_List_Subscriptions_By_PkArgs = {
  _inc?: InputMaybe<List_Subscriptions_Inc_Input>;
  _set?: InputMaybe<List_Subscriptions_Set_Input>;
  pk_columns: List_Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_List_Subscriptions_ManyArgs = {
  updates: Array<List_Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ListsArgs = {
  _inc?: InputMaybe<Lists_Inc_Input>;
  _set?: InputMaybe<Lists_Set_Input>;
  where: Lists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lists_By_PkArgs = {
  _inc?: InputMaybe<Lists_Inc_Input>;
  _set?: InputMaybe<Lists_Set_Input>;
  pk_columns: Lists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lists_ManyArgs = {
  updates: Array<Lists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Need_Fulfill_IntentsArgs = {
  _inc?: InputMaybe<Need_Fulfill_Intents_Inc_Input>;
  _set?: InputMaybe<Need_Fulfill_Intents_Set_Input>;
  where: Need_Fulfill_Intents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Need_Fulfill_Intents_By_PkArgs = {
  _inc?: InputMaybe<Need_Fulfill_Intents_Inc_Input>;
  _set?: InputMaybe<Need_Fulfill_Intents_Set_Input>;
  pk_columns: Need_Fulfill_Intents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Need_Fulfill_Intents_ManyArgs = {
  updates: Array<Need_Fulfill_Intents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Need_InformationsArgs = {
  _inc?: InputMaybe<Need_Informations_Inc_Input>;
  _set?: InputMaybe<Need_Informations_Set_Input>;
  where: Need_Informations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Need_Informations_By_PkArgs = {
  _inc?: InputMaybe<Need_Informations_Inc_Input>;
  _set?: InputMaybe<Need_Informations_Set_Input>;
  pk_columns: Need_Informations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Need_Informations_ManyArgs = {
  updates: Array<Need_Informations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Need_TagsArgs = {
  _inc?: InputMaybe<Need_Tags_Inc_Input>;
  _set?: InputMaybe<Need_Tags_Set_Input>;
  where: Need_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Need_Tags_By_PkArgs = {
  _inc?: InputMaybe<Need_Tags_Inc_Input>;
  _set?: InputMaybe<Need_Tags_Set_Input>;
  pk_columns: Need_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Need_Tags_ManyArgs = {
  updates: Array<Need_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NeedsArgs = {
  _append?: InputMaybe<Needs_Append_Input>;
  _delete_at_path?: InputMaybe<Needs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Needs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Needs_Delete_Key_Input>;
  _inc?: InputMaybe<Needs_Inc_Input>;
  _prepend?: InputMaybe<Needs_Prepend_Input>;
  _set?: InputMaybe<Needs_Set_Input>;
  where: Needs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Needs_By_PkArgs = {
  _append?: InputMaybe<Needs_Append_Input>;
  _delete_at_path?: InputMaybe<Needs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Needs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Needs_Delete_Key_Input>;
  _inc?: InputMaybe<Needs_Inc_Input>;
  _prepend?: InputMaybe<Needs_Prepend_Input>;
  _set?: InputMaybe<Needs_Set_Input>;
  pk_columns: Needs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Needs_ManyArgs = {
  updates: Array<Needs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_LocationsArgs = {
  _inc?: InputMaybe<Organization_Locations_Inc_Input>;
  _set?: InputMaybe<Organization_Locations_Set_Input>;
  where: Organization_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_Locations_ManyArgs = {
  updates: Array<Organization_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_SubscriptionsArgs = {
  _inc?: InputMaybe<Organization_Subscriptions_Inc_Input>;
  _set?: InputMaybe<Organization_Subscriptions_Set_Input>;
  where: Organization_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_Subscriptions_By_PkArgs = {
  _inc?: InputMaybe<Organization_Subscriptions_Inc_Input>;
  _set?: InputMaybe<Organization_Subscriptions_Set_Input>;
  pk_columns: Organization_Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_Subscriptions_ManyArgs = {
  updates: Array<Organization_Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_TagsArgs = {
  _inc?: InputMaybe<Organization_Tags_Inc_Input>;
  _set?: InputMaybe<Organization_Tags_Set_Input>;
  where: Organization_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_Tags_By_PkArgs = {
  _inc?: InputMaybe<Organization_Tags_Inc_Input>;
  _set?: InputMaybe<Organization_Tags_Set_Input>;
  pk_columns: Organization_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_Tags_ManyArgs = {
  updates: Array<Organization_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationsArgs = {
  _append?: InputMaybe<Organizations_Append_Input>;
  _delete_at_path?: InputMaybe<Organizations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organizations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organizations_Delete_Key_Input>;
  _inc?: InputMaybe<Organizations_Inc_Input>;
  _prepend?: InputMaybe<Organizations_Prepend_Input>;
  _set?: InputMaybe<Organizations_Set_Input>;
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_By_PkArgs = {
  _append?: InputMaybe<Organizations_Append_Input>;
  _delete_at_path?: InputMaybe<Organizations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Organizations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Organizations_Delete_Key_Input>;
  _inc?: InputMaybe<Organizations_Inc_Input>;
  _prepend?: InputMaybe<Organizations_Prepend_Input>;
  _set?: InputMaybe<Organizations_Set_Input>;
  pk_columns: Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_ManyArgs = {
  updates: Array<Organizations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Parent_OrganizationsArgs = {
  _inc?: InputMaybe<Parent_Organizations_Inc_Input>;
  _set?: InputMaybe<Parent_Organizations_Set_Input>;
  where: Parent_Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Parent_Organizations_By_PkArgs = {
  _inc?: InputMaybe<Parent_Organizations_Inc_Input>;
  _set?: InputMaybe<Parent_Organizations_Set_Input>;
  pk_columns: Parent_Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Parent_Organizations_ManyArgs = {
  updates: Array<Parent_Organizations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PostsArgs = {
  _inc?: InputMaybe<Posts_Inc_Input>;
  _set?: InputMaybe<Posts_Set_Input>;
  where: Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Posts_By_PkArgs = {
  _inc?: InputMaybe<Posts_Inc_Input>;
  _set?: InputMaybe<Posts_Set_Input>;
  pk_columns: Posts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Posts_ManyArgs = {
  updates: Array<Posts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Private_Modified_DataArgs = {
  _append?: InputMaybe<Private_Modified_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Private_Modified_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Private_Modified_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Private_Modified_Data_Delete_Key_Input>;
  _inc?: InputMaybe<Private_Modified_Data_Inc_Input>;
  _prepend?: InputMaybe<Private_Modified_Data_Prepend_Input>;
  _set?: InputMaybe<Private_Modified_Data_Set_Input>;
  where: Private_Modified_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Private_Modified_Data_By_PkArgs = {
  _append?: InputMaybe<Private_Modified_Data_Append_Input>;
  _delete_at_path?: InputMaybe<Private_Modified_Data_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Private_Modified_Data_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Private_Modified_Data_Delete_Key_Input>;
  _inc?: InputMaybe<Private_Modified_Data_Inc_Input>;
  _prepend?: InputMaybe<Private_Modified_Data_Prepend_Input>;
  _set?: InputMaybe<Private_Modified_Data_Set_Input>;
  pk_columns: Private_Modified_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Private_Modified_Data_ManyArgs = {
  updates: Array<Private_Modified_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SponsorsArgs = {
  _inc?: InputMaybe<Sponsors_Inc_Input>;
  _set?: InputMaybe<Sponsors_Set_Input>;
  where: Sponsors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sponsors_By_PkArgs = {
  _inc?: InputMaybe<Sponsors_Inc_Input>;
  _set?: InputMaybe<Sponsors_Set_Input>;
  pk_columns: Sponsors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sponsors_ManyArgs = {
  updates: Array<Sponsors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SponsorshipsArgs = {
  _inc?: InputMaybe<Sponsorships_Inc_Input>;
  _set?: InputMaybe<Sponsorships_Set_Input>;
  where: Sponsorships_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sponsorships_By_PkArgs = {
  _inc?: InputMaybe<Sponsorships_Inc_Input>;
  _set?: InputMaybe<Sponsorships_Set_Input>;
  pk_columns: Sponsorships_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sponsorships_ManyArgs = {
  updates: Array<Sponsorships_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_StoriesArgs = {
  _inc?: InputMaybe<Stories_Inc_Input>;
  _set?: InputMaybe<Stories_Set_Input>;
  where: Stories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stories_By_PkArgs = {
  _inc?: InputMaybe<Stories_Inc_Input>;
  _set?: InputMaybe<Stories_Set_Input>;
  pk_columns: Stories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stories_ManyArgs = {
  updates: Array<Stories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_ChargesArgs = {
  _append?: InputMaybe<Stripe_Charges_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Charges_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Charges_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Charges_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Charges_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Charges_Prepend_Input>;
  _set?: InputMaybe<Stripe_Charges_Set_Input>;
  where: Stripe_Charges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Charges_By_PkArgs = {
  _append?: InputMaybe<Stripe_Charges_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Charges_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Charges_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Charges_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Charges_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Charges_Prepend_Input>;
  _set?: InputMaybe<Stripe_Charges_Set_Input>;
  pk_columns: Stripe_Charges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Charges_ManyArgs = {
  updates: Array<Stripe_Charges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Checkout_SessionsArgs = {
  _append?: InputMaybe<Stripe_Checkout_Sessions_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Checkout_Sessions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Checkout_Sessions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Checkout_Sessions_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Checkout_Sessions_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Checkout_Sessions_Prepend_Input>;
  _set?: InputMaybe<Stripe_Checkout_Sessions_Set_Input>;
  where: Stripe_Checkout_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Checkout_Sessions_By_PkArgs = {
  _append?: InputMaybe<Stripe_Checkout_Sessions_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Checkout_Sessions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Checkout_Sessions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Checkout_Sessions_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Checkout_Sessions_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Checkout_Sessions_Prepend_Input>;
  _set?: InputMaybe<Stripe_Checkout_Sessions_Set_Input>;
  pk_columns: Stripe_Checkout_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Checkout_Sessions_ManyArgs = {
  updates: Array<Stripe_Checkout_Sessions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_CustomersArgs = {
  _append?: InputMaybe<Stripe_Customers_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Customers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Customers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Customers_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Customers_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Customers_Prepend_Input>;
  _set?: InputMaybe<Stripe_Customers_Set_Input>;
  where: Stripe_Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Customers_By_PkArgs = {
  _append?: InputMaybe<Stripe_Customers_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Customers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Customers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Customers_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Customers_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Customers_Prepend_Input>;
  _set?: InputMaybe<Stripe_Customers_Set_Input>;
  pk_columns: Stripe_Customers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Customers_ManyArgs = {
  updates: Array<Stripe_Customers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Invoice_ItemsArgs = {
  _append?: InputMaybe<Stripe_Invoice_Items_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Invoice_Items_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Invoice_Items_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Invoice_Items_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Invoice_Items_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Invoice_Items_Prepend_Input>;
  _set?: InputMaybe<Stripe_Invoice_Items_Set_Input>;
  where: Stripe_Invoice_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Invoice_Items_By_PkArgs = {
  _append?: InputMaybe<Stripe_Invoice_Items_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Invoice_Items_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Invoice_Items_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Invoice_Items_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Invoice_Items_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Invoice_Items_Prepend_Input>;
  _set?: InputMaybe<Stripe_Invoice_Items_Set_Input>;
  pk_columns: Stripe_Invoice_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Invoice_Items_ManyArgs = {
  updates: Array<Stripe_Invoice_Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_InvoicesArgs = {
  _append?: InputMaybe<Stripe_Invoices_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Invoices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Invoices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Invoices_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Invoices_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Invoices_Prepend_Input>;
  _set?: InputMaybe<Stripe_Invoices_Set_Input>;
  where: Stripe_Invoices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Invoices_By_PkArgs = {
  _append?: InputMaybe<Stripe_Invoices_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Invoices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Invoices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Invoices_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Invoices_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Invoices_Prepend_Input>;
  _set?: InputMaybe<Stripe_Invoices_Set_Input>;
  pk_columns: Stripe_Invoices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Invoices_ManyArgs = {
  updates: Array<Stripe_Invoices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Payment_IntentsArgs = {
  _append?: InputMaybe<Stripe_Payment_Intents_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Payment_Intents_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Payment_Intents_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Payment_Intents_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Payment_Intents_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Payment_Intents_Prepend_Input>;
  _set?: InputMaybe<Stripe_Payment_Intents_Set_Input>;
  where: Stripe_Payment_Intents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Payment_Intents_By_PkArgs = {
  _append?: InputMaybe<Stripe_Payment_Intents_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Payment_Intents_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Payment_Intents_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Payment_Intents_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Payment_Intents_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Payment_Intents_Prepend_Input>;
  _set?: InputMaybe<Stripe_Payment_Intents_Set_Input>;
  pk_columns: Stripe_Payment_Intents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Payment_Intents_ManyArgs = {
  updates: Array<Stripe_Payment_Intents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Payment_LinksArgs = {
  _append?: InputMaybe<Stripe_Payment_Links_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Payment_Links_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Payment_Links_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Payment_Links_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Payment_Links_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Payment_Links_Prepend_Input>;
  _set?: InputMaybe<Stripe_Payment_Links_Set_Input>;
  where: Stripe_Payment_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Payment_Links_By_PkArgs = {
  _append?: InputMaybe<Stripe_Payment_Links_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Payment_Links_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Payment_Links_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Payment_Links_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Payment_Links_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Payment_Links_Prepend_Input>;
  _set?: InputMaybe<Stripe_Payment_Links_Set_Input>;
  pk_columns: Stripe_Payment_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Payment_Links_ManyArgs = {
  updates: Array<Stripe_Payment_Links_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Payment_MethodsArgs = {
  _append?: InputMaybe<Stripe_Payment_Methods_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Payment_Methods_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Payment_Methods_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Payment_Methods_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Payment_Methods_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Payment_Methods_Prepend_Input>;
  _set?: InputMaybe<Stripe_Payment_Methods_Set_Input>;
  where: Stripe_Payment_Methods_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Payment_Methods_By_PkArgs = {
  _append?: InputMaybe<Stripe_Payment_Methods_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Payment_Methods_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Payment_Methods_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Payment_Methods_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Payment_Methods_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Payment_Methods_Prepend_Input>;
  _set?: InputMaybe<Stripe_Payment_Methods_Set_Input>;
  pk_columns: Stripe_Payment_Methods_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Payment_Methods_ManyArgs = {
  updates: Array<Stripe_Payment_Methods_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_PricesArgs = {
  _append?: InputMaybe<Stripe_Prices_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Prices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Prices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Prices_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Prices_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Prices_Prepend_Input>;
  _set?: InputMaybe<Stripe_Prices_Set_Input>;
  where: Stripe_Prices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Prices_By_PkArgs = {
  _append?: InputMaybe<Stripe_Prices_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Prices_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Prices_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Prices_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Prices_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Prices_Prepend_Input>;
  _set?: InputMaybe<Stripe_Prices_Set_Input>;
  pk_columns: Stripe_Prices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Prices_ManyArgs = {
  updates: Array<Stripe_Prices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_ProductsArgs = {
  _append?: InputMaybe<Stripe_Products_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Products_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Products_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Products_Prepend_Input>;
  _set?: InputMaybe<Stripe_Products_Set_Input>;
  where: Stripe_Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Products_By_PkArgs = {
  _append?: InputMaybe<Stripe_Products_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Products_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Products_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Products_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Products_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Products_Prepend_Input>;
  _set?: InputMaybe<Stripe_Products_Set_Input>;
  pk_columns: Stripe_Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Products_ManyArgs = {
  updates: Array<Stripe_Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_SubscriptionsArgs = {
  _append?: InputMaybe<Stripe_Subscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Subscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Subscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Subscriptions_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Subscriptions_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Subscriptions_Prepend_Input>;
  _set?: InputMaybe<Stripe_Subscriptions_Set_Input>;
  where: Stripe_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Subscriptions_By_PkArgs = {
  _append?: InputMaybe<Stripe_Subscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Subscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Subscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Subscriptions_Delete_Key_Input>;
  _inc?: InputMaybe<Stripe_Subscriptions_Inc_Input>;
  _prepend?: InputMaybe<Stripe_Subscriptions_Prepend_Input>;
  _set?: InputMaybe<Stripe_Subscriptions_Set_Input>;
  pk_columns: Stripe_Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Subscriptions_ManyArgs = {
  updates: Array<Stripe_Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tax_ReceiptsArgs = {
  _append?: InputMaybe<Tax_Receipts_Append_Input>;
  _delete_at_path?: InputMaybe<Tax_Receipts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tax_Receipts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tax_Receipts_Delete_Key_Input>;
  _inc?: InputMaybe<Tax_Receipts_Inc_Input>;
  _prepend?: InputMaybe<Tax_Receipts_Prepend_Input>;
  _set?: InputMaybe<Tax_Receipts_Set_Input>;
  where: Tax_Receipts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tax_Receipts_By_PkArgs = {
  _append?: InputMaybe<Tax_Receipts_Append_Input>;
  _delete_at_path?: InputMaybe<Tax_Receipts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tax_Receipts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tax_Receipts_Delete_Key_Input>;
  _inc?: InputMaybe<Tax_Receipts_Inc_Input>;
  _prepend?: InputMaybe<Tax_Receipts_Prepend_Input>;
  _set?: InputMaybe<Tax_Receipts_Set_Input>;
  pk_columns: Tax_Receipts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tax_Receipts_ManyArgs = {
  updates: Array<Tax_Receipts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Team_MembershipsArgs = {
  _inc?: InputMaybe<Team_Memberships_Inc_Input>;
  _set?: InputMaybe<Team_Memberships_Set_Input>;
  where: Team_Memberships_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Memberships_By_PkArgs = {
  _inc?: InputMaybe<Team_Memberships_Inc_Input>;
  _set?: InputMaybe<Team_Memberships_Set_Input>;
  pk_columns: Team_Memberships_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Memberships_ManyArgs = {
  updates: Array<Team_Memberships_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Team_NeedsArgs = {
  _inc?: InputMaybe<Team_Needs_Inc_Input>;
  _set?: InputMaybe<Team_Needs_Set_Input>;
  where: Team_Needs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Needs_By_PkArgs = {
  _inc?: InputMaybe<Team_Needs_Inc_Input>;
  _set?: InputMaybe<Team_Needs_Set_Input>;
  pk_columns: Team_Needs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Needs_ManyArgs = {
  updates: Array<Team_Needs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Team_TagsArgs = {
  _inc?: InputMaybe<Team_Tags_Inc_Input>;
  _set?: InputMaybe<Team_Tags_Set_Input>;
  where: Team_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Tags_By_PkArgs = {
  _inc?: InputMaybe<Team_Tags_Inc_Input>;
  _set?: InputMaybe<Team_Tags_Set_Input>;
  pk_columns: Team_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Tags_ManyArgs = {
  updates: Array<Team_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TeamsArgs = {
  _append?: InputMaybe<Teams_Append_Input>;
  _delete_at_path?: InputMaybe<Teams_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Teams_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Teams_Delete_Key_Input>;
  _inc?: InputMaybe<Teams_Inc_Input>;
  _prepend?: InputMaybe<Teams_Prepend_Input>;
  _set?: InputMaybe<Teams_Set_Input>;
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_By_PkArgs = {
  _append?: InputMaybe<Teams_Append_Input>;
  _delete_at_path?: InputMaybe<Teams_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Teams_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Teams_Delete_Key_Input>;
  _inc?: InputMaybe<Teams_Inc_Input>;
  _prepend?: InputMaybe<Teams_Prepend_Input>;
  _set?: InputMaybe<Teams_Set_Input>;
  pk_columns: Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_ManyArgs = {
  updates: Array<Teams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Thank_YousArgs = {
  _inc?: InputMaybe<Thank_Yous_Inc_Input>;
  _set?: InputMaybe<Thank_Yous_Set_Input>;
  where: Thank_Yous_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Thank_Yous_By_PkArgs = {
  _inc?: InputMaybe<Thank_Yous_Inc_Input>;
  _set?: InputMaybe<Thank_Yous_Set_Input>;
  pk_columns: Thank_Yous_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Thank_Yous_ManyArgs = {
  updates: Array<Thank_Yous_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_GoalsArgs = {
  _inc?: InputMaybe<User_Goals_Inc_Input>;
  _set?: InputMaybe<User_Goals_Set_Input>;
  where: User_Goals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Goals_ManyArgs = {
  updates: Array<User_Goals_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** columns and relationships of "need_fulfill_intents" */
export type Need_Fulfill_Intents = {
  created_at: Scalars['timestamp']['output'];
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  /** An object relationship */
  need?: Maybe<Needs>;
  need_id?: Maybe<Scalars['Int']['output']>;
  updated_at: Scalars['timestamp']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "need_fulfill_intents" */
export type Need_Fulfill_Intents_Aggregate = {
  aggregate?: Maybe<Need_Fulfill_Intents_Aggregate_Fields>;
  nodes: Array<Need_Fulfill_Intents>;
};

export type Need_Fulfill_Intents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Need_Fulfill_Intents_Aggregate_Bool_Exp_Count>;
};

export type Need_Fulfill_Intents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Need_Fulfill_Intents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "need_fulfill_intents" */
export type Need_Fulfill_Intents_Aggregate_Fields = {
  avg?: Maybe<Need_Fulfill_Intents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Need_Fulfill_Intents_Max_Fields>;
  min?: Maybe<Need_Fulfill_Intents_Min_Fields>;
  stddev?: Maybe<Need_Fulfill_Intents_Stddev_Fields>;
  stddev_pop?: Maybe<Need_Fulfill_Intents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Need_Fulfill_Intents_Stddev_Samp_Fields>;
  sum?: Maybe<Need_Fulfill_Intents_Sum_Fields>;
  var_pop?: Maybe<Need_Fulfill_Intents_Var_Pop_Fields>;
  var_samp?: Maybe<Need_Fulfill_Intents_Var_Samp_Fields>;
  variance?: Maybe<Need_Fulfill_Intents_Variance_Fields>;
};


/** aggregate fields of "need_fulfill_intents" */
export type Need_Fulfill_Intents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Need_Fulfill_Intents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Aggregate_Order_By = {
  avg?: InputMaybe<Need_Fulfill_Intents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Need_Fulfill_Intents_Max_Order_By>;
  min?: InputMaybe<Need_Fulfill_Intents_Min_Order_By>;
  stddev?: InputMaybe<Need_Fulfill_Intents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Need_Fulfill_Intents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Need_Fulfill_Intents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Need_Fulfill_Intents_Sum_Order_By>;
  var_pop?: InputMaybe<Need_Fulfill_Intents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Need_Fulfill_Intents_Var_Samp_Order_By>;
  variance?: InputMaybe<Need_Fulfill_Intents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Arr_Rel_Insert_Input = {
  data: Array<Need_Fulfill_Intents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Need_Fulfill_Intents_On_Conflict>;
};

/** aggregate avg on columns */
export type Need_Fulfill_Intents_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "need_fulfill_intents". All fields are combined with a logical 'AND'. */
export type Need_Fulfill_Intents_Bool_Exp = {
  _and?: InputMaybe<Array<Need_Fulfill_Intents_Bool_Exp>>;
  _not?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
  _or?: InputMaybe<Array<Need_Fulfill_Intents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  need?: InputMaybe<Needs_Bool_Exp>;
  need_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "need_fulfill_intents" */
export enum Need_Fulfill_Intents_Constraint {
  /** unique or primary key constraint on columns "id" */
  NeedFulfillIntentsPkey = 'need_fulfill_intents_pkey'
}

/** input type for incrementing numeric columns in table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need?: InputMaybe<Needs_Obj_Rel_Insert_Input>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Need_Fulfill_Intents_Max_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Need_Fulfill_Intents_Min_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Need_Fulfill_Intents>;
};

/** on_conflict condition type for table "need_fulfill_intents" */
export type Need_Fulfill_Intents_On_Conflict = {
  constraint: Need_Fulfill_Intents_Constraint;
  update_columns?: Array<Need_Fulfill_Intents_Update_Column>;
  where?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
};

/** Ordering options when selecting data from "need_fulfill_intents". */
export type Need_Fulfill_Intents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need?: InputMaybe<Needs_Order_By>;
  need_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: need_fulfill_intents */
export type Need_Fulfill_Intents_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "need_fulfill_intents" */
export enum Need_Fulfill_Intents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Need_Fulfill_Intents_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Need_Fulfill_Intents_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Need_Fulfill_Intents_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Need_Fulfill_Intents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Need_Fulfill_Intents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Need_Fulfill_Intents_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "need_fulfill_intents" */
export enum Need_Fulfill_Intents_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Need_Fulfill_Intents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Need_Fulfill_Intents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Need_Fulfill_Intents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Need_Fulfill_Intents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Need_Fulfill_Intents_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Need_Fulfill_Intents_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Need_Fulfill_Intents_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "need_fulfill_intents" */
export type Need_Fulfill_Intents_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "need_informations" */
export type Need_Informations = {
  /** An object relationship */
  address?: Maybe<Addresses_Need_Informations>;
  category?: Maybe<Scalars['String']['output']>;
  demographic?: Maybe<Scalars['String']['output']>;
  do_shopping: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  item_details: Scalars['String']['output'];
  /** An object relationship */
  need?: Maybe<Needs>;
  need_id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  primary_url?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  recipient: Scalars['String']['output'];
  secondary_url?: Maybe<Scalars['String']['output']>;
  short_description: Scalars['String']['output'];
  story?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "need_informations" */
export type Need_Informations_Aggregate = {
  aggregate?: Maybe<Need_Informations_Aggregate_Fields>;
  nodes: Array<Need_Informations>;
};

export type Need_Informations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Need_Informations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Need_Informations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Need_Informations_Aggregate_Bool_Exp_Count>;
};

export type Need_Informations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Need_Informations_Select_Column_Need_Informations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Need_Informations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Need_Informations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Need_Informations_Select_Column_Need_Informations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Need_Informations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Need_Informations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Need_Informations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Need_Informations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "need_informations" */
export type Need_Informations_Aggregate_Fields = {
  avg?: Maybe<Need_Informations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Need_Informations_Max_Fields>;
  min?: Maybe<Need_Informations_Min_Fields>;
  stddev?: Maybe<Need_Informations_Stddev_Fields>;
  stddev_pop?: Maybe<Need_Informations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Need_Informations_Stddev_Samp_Fields>;
  sum?: Maybe<Need_Informations_Sum_Fields>;
  var_pop?: Maybe<Need_Informations_Var_Pop_Fields>;
  var_samp?: Maybe<Need_Informations_Var_Samp_Fields>;
  variance?: Maybe<Need_Informations_Variance_Fields>;
};


/** aggregate fields of "need_informations" */
export type Need_Informations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Need_Informations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "need_informations" */
export type Need_Informations_Aggregate_Order_By = {
  avg?: InputMaybe<Need_Informations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Need_Informations_Max_Order_By>;
  min?: InputMaybe<Need_Informations_Min_Order_By>;
  stddev?: InputMaybe<Need_Informations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Need_Informations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Need_Informations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Need_Informations_Sum_Order_By>;
  var_pop?: InputMaybe<Need_Informations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Need_Informations_Var_Samp_Order_By>;
  variance?: InputMaybe<Need_Informations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "need_informations" */
export type Need_Informations_Arr_Rel_Insert_Input = {
  data: Array<Need_Informations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Need_Informations_On_Conflict>;
};

/** aggregate avg on columns */
export type Need_Informations_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "need_informations" */
export type Need_Informations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "need_informations". All fields are combined with a logical 'AND'. */
export type Need_Informations_Bool_Exp = {
  _and?: InputMaybe<Array<Need_Informations_Bool_Exp>>;
  _not?: InputMaybe<Need_Informations_Bool_Exp>;
  _or?: InputMaybe<Array<Need_Informations_Bool_Exp>>;
  address?: InputMaybe<Addresses_Need_Informations_Bool_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  demographic?: InputMaybe<String_Comparison_Exp>;
  do_shopping?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  item_details?: InputMaybe<String_Comparison_Exp>;
  need?: InputMaybe<Needs_Bool_Exp>;
  need_id?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  primary_url?: InputMaybe<String_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  recipient?: InputMaybe<String_Comparison_Exp>;
  secondary_url?: InputMaybe<String_Comparison_Exp>;
  short_description?: InputMaybe<String_Comparison_Exp>;
  story?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "need_informations" */
export enum Need_Informations_Constraint {
  /** unique or primary key constraint on columns "need_id" */
  IndexNeedInformationsOnNeedId = 'index_need_informations_on_need_id',
  /** unique or primary key constraint on columns "id" */
  NeedInformationsPkey = 'need_informations_pkey'
}

/** input type for incrementing numeric columns in table "need_informations" */
export type Need_Informations_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "need_informations" */
export type Need_Informations_Insert_Input = {
  address?: InputMaybe<Addresses_Need_Informations_Obj_Rel_Insert_Input>;
  category?: InputMaybe<Scalars['String']['input']>;
  demographic?: InputMaybe<Scalars['String']['input']>;
  do_shopping?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  item_details?: InputMaybe<Scalars['String']['input']>;
  need?: InputMaybe<Needs_Obj_Rel_Insert_Input>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  primary_url?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  secondary_url?: InputMaybe<Scalars['String']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  story?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Need_Informations_Max_Fields = {
  category?: Maybe<Scalars['String']['output']>;
  demographic?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  item_details?: Maybe<Scalars['String']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  primary_url?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  secondary_url?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  story?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "need_informations" */
export type Need_Informations_Max_Order_By = {
  category?: InputMaybe<Order_By>;
  demographic?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  item_details?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  primary_url?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  recipient?: InputMaybe<Order_By>;
  secondary_url?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  story?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Need_Informations_Min_Fields = {
  category?: Maybe<Scalars['String']['output']>;
  demographic?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  item_details?: Maybe<Scalars['String']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  primary_url?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  secondary_url?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  story?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "need_informations" */
export type Need_Informations_Min_Order_By = {
  category?: InputMaybe<Order_By>;
  demographic?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  item_details?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  primary_url?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  recipient?: InputMaybe<Order_By>;
  secondary_url?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  story?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "need_informations" */
export type Need_Informations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Need_Informations>;
};

/** input type for inserting object relation for remote table "need_informations" */
export type Need_Informations_Obj_Rel_Insert_Input = {
  data: Need_Informations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Need_Informations_On_Conflict>;
};

/** on_conflict condition type for table "need_informations" */
export type Need_Informations_On_Conflict = {
  constraint: Need_Informations_Constraint;
  update_columns?: Array<Need_Informations_Update_Column>;
  where?: InputMaybe<Need_Informations_Bool_Exp>;
};

/** Ordering options when selecting data from "need_informations". */
export type Need_Informations_Order_By = {
  address?: InputMaybe<Addresses_Need_Informations_Order_By>;
  category?: InputMaybe<Order_By>;
  demographic?: InputMaybe<Order_By>;
  do_shopping?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  item_details?: InputMaybe<Order_By>;
  need?: InputMaybe<Needs_Order_By>;
  need_id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  primary_url?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  recipient?: InputMaybe<Order_By>;
  secondary_url?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  story?: InputMaybe<Order_By>;
};

/** primary key columns input for table: need_informations */
export type Need_Informations_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "need_informations" */
export enum Need_Informations_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Demographic = 'demographic',
  /** column name */
  DoShopping = 'do_shopping',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  ItemDetails = 'item_details',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PrimaryUrl = 'primary_url',
  /** column name */
  Reason = 'reason',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  SecondaryUrl = 'secondary_url',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  Story = 'story'
}

/** select "need_informations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "need_informations" */
export enum Need_Informations_Select_Column_Need_Informations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DoShopping = 'do_shopping'
}

/** select "need_informations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "need_informations" */
export enum Need_Informations_Select_Column_Need_Informations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DoShopping = 'do_shopping'
}

/** input type for updating data in table "need_informations" */
export type Need_Informations_Set_Input = {
  category?: InputMaybe<Scalars['String']['input']>;
  demographic?: InputMaybe<Scalars['String']['input']>;
  do_shopping?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  item_details?: InputMaybe<Scalars['String']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  primary_url?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  secondary_url?: InputMaybe<Scalars['String']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  story?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Need_Informations_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "need_informations" */
export type Need_Informations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Need_Informations_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "need_informations" */
export type Need_Informations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Need_Informations_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "need_informations" */
export type Need_Informations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "need_informations" */
export type Need_Informations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Need_Informations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Need_Informations_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']['input']>;
  demographic?: InputMaybe<Scalars['String']['input']>;
  do_shopping?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  item_details?: InputMaybe<Scalars['String']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  primary_url?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  secondary_url?: InputMaybe<Scalars['String']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  story?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Need_Informations_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "need_informations" */
export type Need_Informations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
};

/** update columns of table "need_informations" */
export enum Need_Informations_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Demographic = 'demographic',
  /** column name */
  DoShopping = 'do_shopping',
  /** column name */
  Id = 'id',
  /** column name */
  ItemDetails = 'item_details',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PrimaryUrl = 'primary_url',
  /** column name */
  Reason = 'reason',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  SecondaryUrl = 'secondary_url',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  Story = 'story'
}

export type Need_Informations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Need_Informations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Need_Informations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Need_Informations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Need_Informations_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "need_informations" */
export type Need_Informations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Need_Informations_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "need_informations" */
export type Need_Informations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Need_Informations_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "need_informations" */
export type Need_Informations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "need_locations" */
export type Need_Locations = {
  city?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  need?: Maybe<Needs>;
  need_id?: Maybe<Scalars['Int']['output']>;
  point?: Maybe<Scalars['geography']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "need_locations" */
export type Need_Locations_Aggregate = {
  aggregate?: Maybe<Need_Locations_Aggregate_Fields>;
  nodes: Array<Need_Locations>;
};

/** aggregate fields of "need_locations" */
export type Need_Locations_Aggregate_Fields = {
  avg?: Maybe<Need_Locations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Need_Locations_Max_Fields>;
  min?: Maybe<Need_Locations_Min_Fields>;
  stddev?: Maybe<Need_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Need_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Need_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Need_Locations_Sum_Fields>;
  var_pop?: Maybe<Need_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Need_Locations_Var_Samp_Fields>;
  variance?: Maybe<Need_Locations_Variance_Fields>;
};


/** aggregate fields of "need_locations" */
export type Need_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Need_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Need_Locations_Avg_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "need_locations". All fields are combined with a logical 'AND'. */
export type Need_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Need_Locations_Bool_Exp>>;
  _not?: InputMaybe<Need_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Need_Locations_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  latitude?: InputMaybe<Numeric_Comparison_Exp>;
  longitude?: InputMaybe<Numeric_Comparison_Exp>;
  need?: InputMaybe<Needs_Bool_Exp>;
  need_id?: InputMaybe<Int_Comparison_Exp>;
  point?: InputMaybe<Geography_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "need_locations" */
export type Need_Locations_Insert_Input = {
  city?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  need?: InputMaybe<Needs_Obj_Rel_Insert_Input>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Need_Locations_Max_Fields = {
  city?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Need_Locations_Min_Fields = {
  city?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** input type for inserting object relation for remote table "need_locations" */
export type Need_Locations_Obj_Rel_Insert_Input = {
  data: Need_Locations_Insert_Input;
};

/** Ordering options when selecting data from "need_locations". */
export type Need_Locations_Order_By = {
  city?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  need?: InputMaybe<Needs_Order_By>;
  need_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** select columns of table "need_locations" */
export enum Need_Locations_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  Point = 'point',
  /** column name */
  State = 'state'
}

/** aggregate stddev on columns */
export type Need_Locations_Stddev_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Need_Locations_Stddev_Pop_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Need_Locations_Stddev_Samp_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "need_locations" */
export type Need_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Need_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Need_Locations_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Need_Locations_Sum_Fields = {
  latitude?: Maybe<Scalars['numeric']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Need_Locations_Var_Pop_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Need_Locations_Var_Samp_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Need_Locations_Variance_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "need_tags" */
export type Need_Tags = {
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  /** An object relationship */
  need: Needs;
  need_id: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
};

/** aggregated selection of "need_tags" */
export type Need_Tags_Aggregate = {
  aggregate?: Maybe<Need_Tags_Aggregate_Fields>;
  nodes: Array<Need_Tags>;
};

/** aggregate fields of "need_tags" */
export type Need_Tags_Aggregate_Fields = {
  avg?: Maybe<Need_Tags_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Need_Tags_Max_Fields>;
  min?: Maybe<Need_Tags_Min_Fields>;
  stddev?: Maybe<Need_Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Need_Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Need_Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Need_Tags_Sum_Fields>;
  var_pop?: Maybe<Need_Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Need_Tags_Var_Samp_Fields>;
  variance?: Maybe<Need_Tags_Variance_Fields>;
};


/** aggregate fields of "need_tags" */
export type Need_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Need_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Need_Tags_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "need_tags". All fields are combined with a logical 'AND'. */
export type Need_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Need_Tags_Bool_Exp>>;
  _not?: InputMaybe<Need_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Need_Tags_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  need?: InputMaybe<Needs_Bool_Exp>;
  need_id?: InputMaybe<Int_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "need_tags" */
export enum Need_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  NeedTagsPkey = 'need_tags_pkey'
}

/** input type for incrementing numeric columns in table "need_tags" */
export type Need_Tags_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "need_tags" */
export type Need_Tags_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  need?: InputMaybe<Needs_Obj_Rel_Insert_Input>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Need_Tags_Max_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Need_Tags_Min_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "need_tags" */
export type Need_Tags_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Need_Tags>;
};

/** input type for inserting object relation for remote table "need_tags" */
export type Need_Tags_Obj_Rel_Insert_Input = {
  data: Need_Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Need_Tags_On_Conflict>;
};

/** on_conflict condition type for table "need_tags" */
export type Need_Tags_On_Conflict = {
  constraint: Need_Tags_Constraint;
  update_columns?: Array<Need_Tags_Update_Column>;
  where?: InputMaybe<Need_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "need_tags". */
export type Need_Tags_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need?: InputMaybe<Needs_Order_By>;
  need_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** primary key columns input for table: need_tags */
export type Need_Tags_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "need_tags" */
export enum Need_Tags_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  Tag = 'tag'
}

/** input type for updating data in table "need_tags" */
export type Need_Tags_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Need_Tags_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Need_Tags_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Need_Tags_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "need_tags" */
export type Need_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Need_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Need_Tags_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Need_Tags_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "need_tags" */
export enum Need_Tags_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  Tag = 'tag'
}

export type Need_Tags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Need_Tags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Need_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Need_Tags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Need_Tags_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Need_Tags_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Need_Tags_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "needs" */
export type Needs = {
  /** An object relationship */
  checkout_intent?: Maybe<Checkout_Intents>;
  /** An array relationship */
  checkout_intents: Array<Checkout_Intents>;
  /** An aggregate relationship */
  checkout_intents_aggregate: Checkout_Intents_Aggregate;
  /** address field: municipality/third-level administrative division. @deprecated */
  city?: Maybe<Scalars['String']['output']>;
  completed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at: Scalars['timestamp']['output'];
  /** An object relationship */
  creator?: Maybe<Users>;
  creator_id?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Scalars['jsonb']['output']>;
  direct_checkout: Scalars['Boolean']['output'];
  /** An array relationship */
  feed_items: Array<Feed_Items>;
  /** An aggregate relationship */
  feed_items_aggregate: Feed_Items_Aggregate;
  full_description?: Maybe<Scalars['String']['output']>;
  has_tags?: Maybe<Scalars['bit']['output']>;
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  intent_url?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "user_met_this_need" */
  is_met_by_current_user?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  list?: Maybe<Lists>;
  list_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  location?: Maybe<Need_Locations>;
  /** initial thank you message, sent immediately after need is met */
  message_for_text?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  met_by?: Maybe<Users>;
  met_by_id?: Maybe<Scalars['Int']['output']>;
  met_on_platform?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  need_fulfill_intents: Array<Need_Fulfill_Intents>;
  /** An aggregate relationship */
  need_fulfill_intents_aggregate: Need_Fulfill_Intents_Aggregate;
  /** An object relationship */
  need_information?: Maybe<Need_Informations>;
  /** An array relationship */
  need_informations: Array<Need_Informations>;
  /** An aggregate relationship */
  need_informations_aggregate: Need_Informations_Aggregate;
  /** An object relationship */
  need_tag?: Maybe<Need_Tags>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['numeric']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['numeric']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
  /** @deprecated */
  recipient_title?: Maybe<Scalars['String']['output']>;
  revision_notes?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  shipping_address?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  shipping_attention_to?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  /** address field: first-level administrative division. @deprecated */
  st?: Maybe<Scalars['String']['output']>;
  /** current status in Purposity's pipeline */
  state?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  team_needs: Array<Team_Needs>;
  /** An aggregate relationship */
  team_needs_aggregate: Team_Needs_Aggregate;
  /** An array relationship */
  thank_yous: Array<Thank_Yous>;
  /** An aggregate relationship */
  thank_yous_aggregate: Thank_Yous_Aggregate;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  urgent?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated */
  wishlist_id?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "needs" */
export type NeedsCheckout_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Checkout_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Checkout_Intents_Order_By>>;
  where?: InputMaybe<Checkout_Intents_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsCheckout_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Checkout_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Checkout_Intents_Order_By>>;
  where?: InputMaybe<Checkout_Intents_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "needs" */
export type NeedsFeed_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsFeed_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsNeed_Fulfill_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Need_Fulfill_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Fulfill_Intents_Order_By>>;
  where?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsNeed_Fulfill_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Fulfill_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Fulfill_Intents_Order_By>>;
  where?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsNeed_InformationsArgs = {
  distinct_on?: InputMaybe<Array<Need_Informations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Informations_Order_By>>;
  where?: InputMaybe<Need_Informations_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsNeed_Informations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Informations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Informations_Order_By>>;
  where?: InputMaybe<Need_Informations_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsTeam_NeedsArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Order_By>>;
  where?: InputMaybe<Team_Needs_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsTeam_Needs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Order_By>>;
  where?: InputMaybe<Team_Needs_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsThank_YousArgs = {
  distinct_on?: InputMaybe<Array<Thank_Yous_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Thank_Yous_Order_By>>;
  where?: InputMaybe<Thank_Yous_Bool_Exp>;
};


/** columns and relationships of "needs" */
export type NeedsThank_Yous_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Thank_Yous_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Thank_Yous_Order_By>>;
  where?: InputMaybe<Thank_Yous_Bool_Exp>;
};

/** aggregated selection of "needs" */
export type Needs_Aggregate = {
  aggregate?: Maybe<Needs_Aggregate_Fields>;
  nodes: Array<Needs>;
};

export type Needs_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Needs_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Needs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Needs_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Needs_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Needs_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Needs_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Needs_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Needs_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Needs_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Needs_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Needs_Aggregate_Bool_Exp_Var_Samp>;
};

export type Needs_Aggregate_Bool_Exp_Avg = {
  arguments: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Needs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Needs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Needs_Aggregate_Bool_Exp_Corr = {
  arguments: Needs_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Needs_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Needs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Needs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Needs_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Needs_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Needs_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Needs_Aggregate_Bool_Exp_Max = {
  arguments: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Needs_Aggregate_Bool_Exp_Min = {
  arguments: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Needs_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Needs_Aggregate_Bool_Exp_Sum = {
  arguments: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Needs_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Needs_Select_Column_Needs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Needs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "needs" */
export type Needs_Aggregate_Fields = {
  avg?: Maybe<Needs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Needs_Max_Fields>;
  min?: Maybe<Needs_Min_Fields>;
  stddev?: Maybe<Needs_Stddev_Fields>;
  stddev_pop?: Maybe<Needs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Needs_Stddev_Samp_Fields>;
  sum?: Maybe<Needs_Sum_Fields>;
  var_pop?: Maybe<Needs_Var_Pop_Fields>;
  var_samp?: Maybe<Needs_Var_Samp_Fields>;
  variance?: Maybe<Needs_Variance_Fields>;
};


/** aggregate fields of "needs" */
export type Needs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Needs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "needs" */
export type Needs_Aggregate_Order_By = {
  avg?: InputMaybe<Needs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Needs_Max_Order_By>;
  min?: InputMaybe<Needs_Min_Order_By>;
  stddev?: InputMaybe<Needs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Needs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Needs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Needs_Sum_Order_By>;
  var_pop?: InputMaybe<Needs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Needs_Var_Samp_Order_By>;
  variance?: InputMaybe<Needs_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Needs_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "needs" */
export type Needs_Arr_Rel_Insert_Input = {
  data: Array<Needs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Needs_On_Conflict>;
};

/** aggregate avg on columns */
export type Needs_Avg_Fields = {
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  met_by_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['Float']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['Float']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "needs" */
export type Needs_Avg_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Order_By>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  /** @deprecated */
  status?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "needs". All fields are combined with a logical 'AND'. */
export type Needs_Bool_Exp = {
  _and?: InputMaybe<Array<Needs_Bool_Exp>>;
  _not?: InputMaybe<Needs_Bool_Exp>;
  _or?: InputMaybe<Array<Needs_Bool_Exp>>;
  checkout_intent?: InputMaybe<Checkout_Intents_Bool_Exp>;
  checkout_intents?: InputMaybe<Checkout_Intents_Bool_Exp>;
  checkout_intents_aggregate?: InputMaybe<Checkout_Intents_Aggregate_Bool_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  creator?: InputMaybe<Users_Bool_Exp>;
  creator_id?: InputMaybe<Int_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  direct_checkout?: InputMaybe<Boolean_Comparison_Exp>;
  feed_items?: InputMaybe<Feed_Items_Bool_Exp>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Bool_Exp>;
  full_description?: InputMaybe<String_Comparison_Exp>;
  has_tags?: InputMaybe<Bit_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  intent_url?: InputMaybe<String_Comparison_Exp>;
  is_met_by_current_user?: InputMaybe<Boolean_Comparison_Exp>;
  list?: InputMaybe<Lists_Bool_Exp>;
  list_id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Need_Locations_Bool_Exp>;
  message_for_text?: InputMaybe<String_Comparison_Exp>;
  met_by?: InputMaybe<Users_Bool_Exp>;
  met_by_id?: InputMaybe<Int_Comparison_Exp>;
  met_on_platform?: InputMaybe<String_Comparison_Exp>;
  need_fulfill_intents?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
  need_fulfill_intents_aggregate?: InputMaybe<Need_Fulfill_Intents_Aggregate_Bool_Exp>;
  need_information?: InputMaybe<Need_Informations_Bool_Exp>;
  need_informations?: InputMaybe<Need_Informations_Bool_Exp>;
  need_informations_aggregate?: InputMaybe<Need_Informations_Aggregate_Bool_Exp>;
  need_tag?: InputMaybe<Need_Tags_Bool_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  price_including_sales_tax?: InputMaybe<Numeric_Comparison_Exp>;
  price_including_tip?: InputMaybe<Numeric_Comparison_Exp>;
  rank_distance?: InputMaybe<Float8_Comparison_Exp>;
  recipient_title?: InputMaybe<String_Comparison_Exp>;
  revision_notes?: InputMaybe<String_Comparison_Exp>;
  shipping_address?: InputMaybe<String_Comparison_Exp>;
  shipping_attention_to?: InputMaybe<String_Comparison_Exp>;
  short_description?: InputMaybe<String_Comparison_Exp>;
  st?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  team_needs?: InputMaybe<Team_Needs_Bool_Exp>;
  team_needs_aggregate?: InputMaybe<Team_Needs_Aggregate_Bool_Exp>;
  thank_yous?: InputMaybe<Thank_Yous_Bool_Exp>;
  thank_yous_aggregate?: InputMaybe<Thank_Yous_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  urgent?: InputMaybe<Boolean_Comparison_Exp>;
  wishlist_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "needs" */
export enum Needs_Constraint {
  /** unique or primary key constraint on columns "id" */
  NeedsPkey = 'needs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Needs_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Needs_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Needs_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

export type Needs_Geo_Args = {
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for incrementing numeric columns in table "needs" */
export type Needs_Inc_Input = {
  creator_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  met_by_id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Scalars['numeric']['input']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Scalars['numeric']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
  /** @deprecated */
  status?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "needs" */
export type Needs_Insert_Input = {
  checkout_intent?: InputMaybe<Checkout_Intents_Obj_Rel_Insert_Input>;
  checkout_intents?: InputMaybe<Checkout_Intents_Arr_Rel_Insert_Input>;
  /** address field: municipality/third-level administrative division. @deprecated */
  city?: InputMaybe<Scalars['String']['input']>;
  completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  creator_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  direct_checkout?: InputMaybe<Scalars['Boolean']['input']>;
  feed_items?: InputMaybe<Feed_Items_Arr_Rel_Insert_Input>;
  full_description?: InputMaybe<Scalars['String']['input']>;
  has_tags?: InputMaybe<Scalars['bit']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  intent_url?: InputMaybe<Scalars['String']['input']>;
  list?: InputMaybe<Lists_Obj_Rel_Insert_Input>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Need_Locations_Obj_Rel_Insert_Input>;
  /** initial thank you message, sent immediately after need is met */
  message_for_text?: InputMaybe<Scalars['String']['input']>;
  met_by?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  met_by_id?: InputMaybe<Scalars['Int']['input']>;
  met_on_platform?: InputMaybe<Scalars['String']['input']>;
  need_fulfill_intents?: InputMaybe<Need_Fulfill_Intents_Arr_Rel_Insert_Input>;
  need_information?: InputMaybe<Need_Informations_Obj_Rel_Insert_Input>;
  need_informations?: InputMaybe<Need_Informations_Arr_Rel_Insert_Input>;
  need_tag?: InputMaybe<Need_Tags_Obj_Rel_Insert_Input>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Scalars['numeric']['input']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Scalars['numeric']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
  /** @deprecated */
  recipient_title?: InputMaybe<Scalars['String']['input']>;
  revision_notes?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  shipping_address?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  shipping_attention_to?: InputMaybe<Scalars['String']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  /** address field: first-level administrative division. @deprecated */
  st?: InputMaybe<Scalars['String']['input']>;
  /** current status in Purposity's pipeline */
  state?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  status?: InputMaybe<Scalars['Int']['input']>;
  team_needs?: InputMaybe<Team_Needs_Arr_Rel_Insert_Input>;
  thank_yous?: InputMaybe<Thank_Yous_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated */
  wishlist_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Needs_Max_Fields = {
  /** address field: municipality/third-level administrative division. @deprecated */
  city?: Maybe<Scalars['String']['output']>;
  completed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  creator_id?: Maybe<Scalars['Int']['output']>;
  full_description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  intent_url?: Maybe<Scalars['String']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  /** initial thank you message, sent immediately after need is met */
  message_for_text?: Maybe<Scalars['String']['output']>;
  met_by_id?: Maybe<Scalars['Int']['output']>;
  met_on_platform?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['numeric']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['numeric']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
  /** @deprecated */
  recipient_title?: Maybe<Scalars['String']['output']>;
  revision_notes?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  shipping_address?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  shipping_attention_to?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  /** address field: first-level administrative division. @deprecated */
  st?: Maybe<Scalars['String']['output']>;
  /** current status in Purposity's pipeline */
  state?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** @deprecated */
  wishlist_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "needs" */
export type Needs_Max_Order_By = {
  /** address field: municipality/third-level administrative division. @deprecated */
  city?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  full_description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  intent_url?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  /** initial thank you message, sent immediately after need is met */
  message_for_text?: InputMaybe<Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  met_on_platform?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Order_By>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  /** @deprecated */
  recipient_title?: InputMaybe<Order_By>;
  revision_notes?: InputMaybe<Order_By>;
  /** @deprecated */
  shipping_address?: InputMaybe<Order_By>;
  /** @deprecated */
  shipping_attention_to?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  /** address field: first-level administrative division. @deprecated */
  st?: InputMaybe<Order_By>;
  /** current status in Purposity's pipeline */
  state?: InputMaybe<Order_By>;
  /** @deprecated */
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** @deprecated */
  wishlist_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Needs_Min_Fields = {
  /** address field: municipality/third-level administrative division. @deprecated */
  city?: Maybe<Scalars['String']['output']>;
  completed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  creator_id?: Maybe<Scalars['Int']['output']>;
  full_description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  intent_url?: Maybe<Scalars['String']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  /** initial thank you message, sent immediately after need is met */
  message_for_text?: Maybe<Scalars['String']['output']>;
  met_by_id?: Maybe<Scalars['Int']['output']>;
  met_on_platform?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['numeric']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['numeric']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
  /** @deprecated */
  recipient_title?: Maybe<Scalars['String']['output']>;
  revision_notes?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  shipping_address?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  shipping_attention_to?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  /** address field: first-level administrative division. @deprecated */
  st?: Maybe<Scalars['String']['output']>;
  /** current status in Purposity's pipeline */
  state?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** @deprecated */
  wishlist_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "needs" */
export type Needs_Min_Order_By = {
  /** address field: municipality/third-level administrative division. @deprecated */
  city?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  full_description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  intent_url?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  /** initial thank you message, sent immediately after need is met */
  message_for_text?: InputMaybe<Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  met_on_platform?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Order_By>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  /** @deprecated */
  recipient_title?: InputMaybe<Order_By>;
  revision_notes?: InputMaybe<Order_By>;
  /** @deprecated */
  shipping_address?: InputMaybe<Order_By>;
  /** @deprecated */
  shipping_attention_to?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  /** address field: first-level administrative division. @deprecated */
  st?: InputMaybe<Order_By>;
  /** current status in Purposity's pipeline */
  state?: InputMaybe<Order_By>;
  /** @deprecated */
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** @deprecated */
  wishlist_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "needs" */
export type Needs_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Needs>;
};

/** input type for inserting object relation for remote table "needs" */
export type Needs_Obj_Rel_Insert_Input = {
  data: Needs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Needs_On_Conflict>;
};

/** on_conflict condition type for table "needs" */
export type Needs_On_Conflict = {
  constraint: Needs_Constraint;
  update_columns?: Array<Needs_Update_Column>;
  where?: InputMaybe<Needs_Bool_Exp>;
};

/** Ordering options when selecting data from "needs". */
export type Needs_Order_By = {
  checkout_intent?: InputMaybe<Checkout_Intents_Order_By>;
  checkout_intents_aggregate?: InputMaybe<Checkout_Intents_Aggregate_Order_By>;
  city?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator?: InputMaybe<Users_Order_By>;
  creator_id?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  direct_checkout?: InputMaybe<Order_By>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Order_By>;
  full_description?: InputMaybe<Order_By>;
  has_tags?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  intent_url?: InputMaybe<Order_By>;
  is_met_by_current_user?: InputMaybe<Order_By>;
  list?: InputMaybe<Lists_Order_By>;
  list_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Need_Locations_Order_By>;
  message_for_text?: InputMaybe<Order_By>;
  met_by?: InputMaybe<Users_Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  met_on_platform?: InputMaybe<Order_By>;
  need_fulfill_intents_aggregate?: InputMaybe<Need_Fulfill_Intents_Aggregate_Order_By>;
  need_information?: InputMaybe<Need_Informations_Order_By>;
  need_informations_aggregate?: InputMaybe<Need_Informations_Aggregate_Order_By>;
  need_tag?: InputMaybe<Need_Tags_Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_including_sales_tax?: InputMaybe<Order_By>;
  price_including_tip?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  recipient_title?: InputMaybe<Order_By>;
  revision_notes?: InputMaybe<Order_By>;
  shipping_address?: InputMaybe<Order_By>;
  shipping_attention_to?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  st?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_needs_aggregate?: InputMaybe<Team_Needs_Aggregate_Order_By>;
  thank_yous_aggregate?: InputMaybe<Thank_Yous_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  urgent?: InputMaybe<Order_By>;
  wishlist_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: needs */
export type Needs_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Needs_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "needs" */
export enum Needs_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Data = 'data',
  /** column name */
  DirectCheckout = 'direct_checkout',
  /** column name */
  FullDescription = 'full_description',
  /** column name */
  HasTags = 'has_tags',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  IntentUrl = 'intent_url',
  /** column name */
  ListId = 'list_id',
  /** column name */
  MessageForText = 'message_for_text',
  /** column name */
  MetById = 'met_by_id',
  /** column name */
  MetOnPlatform = 'met_on_platform',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Price = 'price',
  /** column name */
  PriceIncludingSalesTax = 'price_including_sales_tax',
  /** column name */
  RankDistance = 'rank_distance',
  /** column name */
  RecipientTitle = 'recipient_title',
  /** column name */
  RevisionNotes = 'revision_notes',
  /** column name */
  ShippingAddress = 'shipping_address',
  /** column name */
  ShippingAttentionTo = 'shipping_attention_to',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  St = 'st',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Urgent = 'urgent',
  /** column name */
  WishlistId = 'wishlist_id'
}

/** select "needs_aggregate_bool_exp_avg_arguments_columns" columns of table "needs" */
export enum Needs_Select_Column_Needs_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "needs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "needs" */
export enum Needs_Select_Column_Needs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DirectCheckout = 'direct_checkout',
  /** column name */
  Urgent = 'urgent'
}

/** select "needs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "needs" */
export enum Needs_Select_Column_Needs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DirectCheckout = 'direct_checkout',
  /** column name */
  Urgent = 'urgent'
}

/** select "needs_aggregate_bool_exp_corr_arguments_columns" columns of table "needs" */
export enum Needs_Select_Column_Needs_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "needs_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "needs" */
export enum Needs_Select_Column_Needs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "needs_aggregate_bool_exp_max_arguments_columns" columns of table "needs" */
export enum Needs_Select_Column_Needs_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "needs_aggregate_bool_exp_min_arguments_columns" columns of table "needs" */
export enum Needs_Select_Column_Needs_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "needs_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "needs" */
export enum Needs_Select_Column_Needs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "needs_aggregate_bool_exp_sum_arguments_columns" columns of table "needs" */
export enum Needs_Select_Column_Needs_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "needs_aggregate_bool_exp_var_samp_arguments_columns" columns of table "needs" */
export enum Needs_Select_Column_Needs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  RankDistance = 'rank_distance'
}

/** input type for updating data in table "needs" */
export type Needs_Set_Input = {
  /** address field: municipality/third-level administrative division. @deprecated */
  city?: InputMaybe<Scalars['String']['input']>;
  completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  creator_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  direct_checkout?: InputMaybe<Scalars['Boolean']['input']>;
  full_description?: InputMaybe<Scalars['String']['input']>;
  has_tags?: InputMaybe<Scalars['bit']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  intent_url?: InputMaybe<Scalars['String']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  /** initial thank you message, sent immediately after need is met */
  message_for_text?: InputMaybe<Scalars['String']['input']>;
  met_by_id?: InputMaybe<Scalars['Int']['input']>;
  met_on_platform?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Scalars['numeric']['input']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Scalars['numeric']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
  /** @deprecated */
  recipient_title?: InputMaybe<Scalars['String']['input']>;
  revision_notes?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  shipping_address?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  shipping_attention_to?: InputMaybe<Scalars['String']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  /** address field: first-level administrative division. @deprecated */
  st?: InputMaybe<Scalars['String']['input']>;
  /** current status in Purposity's pipeline */
  state?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  status?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated */
  wishlist_id?: InputMaybe<Scalars['String']['input']>;
};

export type Needs_Shuffled_Args = {
  seed?: InputMaybe<Scalars['seed_float']['input']>;
};

/** aggregate stddev on columns */
export type Needs_Stddev_Fields = {
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  met_by_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['Float']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['Float']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "needs" */
export type Needs_Stddev_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Order_By>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  /** @deprecated */
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Needs_Stddev_Pop_Fields = {
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  met_by_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['Float']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['Float']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "needs" */
export type Needs_Stddev_Pop_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Order_By>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  /** @deprecated */
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Needs_Stddev_Samp_Fields = {
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  met_by_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['Float']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['Float']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "needs" */
export type Needs_Stddev_Samp_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Order_By>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  /** @deprecated */
  status?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "needs" */
export type Needs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Needs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Needs_Stream_Cursor_Value_Input = {
  /** address field: municipality/third-level administrative division. @deprecated */
  city?: InputMaybe<Scalars['String']['input']>;
  completed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  creator_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  direct_checkout?: InputMaybe<Scalars['Boolean']['input']>;
  full_description?: InputMaybe<Scalars['String']['input']>;
  has_tags?: InputMaybe<Scalars['bit']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  intent_url?: InputMaybe<Scalars['String']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  /** initial thank you message, sent immediately after need is met */
  message_for_text?: InputMaybe<Scalars['String']['input']>;
  met_by_id?: InputMaybe<Scalars['Int']['input']>;
  met_on_platform?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Scalars['numeric']['input']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Scalars['numeric']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
  /** @deprecated */
  recipient_title?: InputMaybe<Scalars['String']['input']>;
  revision_notes?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  shipping_address?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  shipping_attention_to?: InputMaybe<Scalars['String']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  /** address field: first-level administrative division. @deprecated */
  st?: InputMaybe<Scalars['String']['input']>;
  /** current status in Purposity's pipeline */
  state?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  status?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated */
  wishlist_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Needs_Sum_Fields = {
  creator_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  met_by_id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['numeric']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['numeric']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "needs" */
export type Needs_Sum_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Order_By>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  /** @deprecated */
  status?: InputMaybe<Order_By>;
};

/** update columns of table "needs" */
export enum Needs_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Data = 'data',
  /** column name */
  DirectCheckout = 'direct_checkout',
  /** column name */
  FullDescription = 'full_description',
  /** column name */
  HasTags = 'has_tags',
  /** column name */
  Id = 'id',
  /** column name */
  IntentUrl = 'intent_url',
  /** column name */
  ListId = 'list_id',
  /** column name */
  MessageForText = 'message_for_text',
  /** column name */
  MetById = 'met_by_id',
  /** column name */
  MetOnPlatform = 'met_on_platform',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Price = 'price',
  /** column name */
  PriceIncludingSalesTax = 'price_including_sales_tax',
  /** column name */
  RankDistance = 'rank_distance',
  /** column name */
  RecipientTitle = 'recipient_title',
  /** column name */
  RevisionNotes = 'revision_notes',
  /** column name */
  ShippingAddress = 'shipping_address',
  /** column name */
  ShippingAttentionTo = 'shipping_attention_to',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  St = 'st',
  /** column name */
  State = 'state',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Urgent = 'urgent',
  /** column name */
  WishlistId = 'wishlist_id'
}

export type Needs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Needs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Needs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Needs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Needs_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Needs_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Needs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Needs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Needs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Needs_Var_Pop_Fields = {
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  met_by_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['Float']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['Float']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "needs" */
export type Needs_Var_Pop_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Order_By>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  /** @deprecated */
  status?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Needs_Var_Samp_Fields = {
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  met_by_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['Float']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['Float']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "needs" */
export type Needs_Var_Samp_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Order_By>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  /** @deprecated */
  status?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Needs_Variance_Fields = {
  creator_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  met_by_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  /** Base cost of the need, as listed on Amazon */
  price?: Maybe<Scalars['Float']['output']>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: Maybe<Scalars['Float']['output']>;
  /** Total list price including sales tax and 15% tip */
  price_including_tip?: Maybe<Scalars['numeric']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
  /** @deprecated */
  status?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "needs" */
export type Needs_Variance_Order_By = {
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  met_by_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** Base cost of the need, as listed on Amazon */
  price?: InputMaybe<Order_By>;
  /** Total cost, including sales tax we pay */
  price_including_sales_tax?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  /** @deprecated */
  status?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organization_locations" */
export type Organization_Locations = {
  city?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  point?: Maybe<Scalars['geography']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "organization_locations" */
export type Organization_Locations_Aggregate = {
  aggregate?: Maybe<Organization_Locations_Aggregate_Fields>;
  nodes: Array<Organization_Locations>;
};

/** aggregate fields of "organization_locations" */
export type Organization_Locations_Aggregate_Fields = {
  avg?: Maybe<Organization_Locations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Locations_Max_Fields>;
  min?: Maybe<Organization_Locations_Min_Fields>;
  stddev?: Maybe<Organization_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Organization_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organization_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Organization_Locations_Sum_Fields>;
  var_pop?: Maybe<Organization_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Organization_Locations_Var_Samp_Fields>;
  variance?: Maybe<Organization_Locations_Variance_Fields>;
};


/** aggregate fields of "organization_locations" */
export type Organization_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Organization_Locations_Avg_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "organization_locations". All fields are combined with a logical 'AND'. */
export type Organization_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Locations_Bool_Exp>>;
  _not?: InputMaybe<Organization_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Locations_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  latitude?: InputMaybe<Numeric_Comparison_Exp>;
  longitude?: InputMaybe<Numeric_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  point?: InputMaybe<Geography_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "organization_locations" */
export type Organization_Locations_Inc_Input = {
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "organization_locations" */
export type Organization_Locations_Insert_Input = {
  city?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Organization_Locations_Max_Fields = {
  city?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Organization_Locations_Min_Fields = {
  city?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['numeric']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "organization_locations" */
export type Organization_Locations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Locations>;
};

/** input type for inserting object relation for remote table "organization_locations" */
export type Organization_Locations_Obj_Rel_Insert_Input = {
  data: Organization_Locations_Insert_Input;
};

/** Ordering options when selecting data from "organization_locations". */
export type Organization_Locations_Order_By = {
  city?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** select columns of table "organization_locations" */
export enum Organization_Locations_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Point = 'point',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "organization_locations" */
export type Organization_Locations_Set_Input = {
  city?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Organization_Locations_Stddev_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Organization_Locations_Stddev_Pop_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Organization_Locations_Stddev_Samp_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "organization_locations" */
export type Organization_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Locations_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['numeric']['input']>;
  longitude?: InputMaybe<Scalars['numeric']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  point?: InputMaybe<Scalars['geography']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Organization_Locations_Sum_Fields = {
  latitude?: Maybe<Scalars['numeric']['output']>;
  longitude?: Maybe<Scalars['numeric']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

export type Organization_Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Locations_Var_Pop_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Organization_Locations_Var_Samp_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Organization_Locations_Variance_Fields = {
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "organization_stats" */
export type Organization_Stats = {
  follower_count?: Maybe<Scalars['Int']['output']>;
  needs_available_count?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "organization_stats" */
export type Organization_Stats_Aggregate = {
  aggregate?: Maybe<Organization_Stats_Aggregate_Fields>;
  nodes: Array<Organization_Stats>;
};

/** aggregate fields of "organization_stats" */
export type Organization_Stats_Aggregate_Fields = {
  avg?: Maybe<Organization_Stats_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Stats_Max_Fields>;
  min?: Maybe<Organization_Stats_Min_Fields>;
  stddev?: Maybe<Organization_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<Organization_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organization_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<Organization_Stats_Sum_Fields>;
  var_pop?: Maybe<Organization_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<Organization_Stats_Var_Samp_Fields>;
  variance?: Maybe<Organization_Stats_Variance_Fields>;
};


/** aggregate fields of "organization_stats" */
export type Organization_Stats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Stats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Organization_Stats_Avg_Fields = {
  follower_count?: Maybe<Scalars['Float']['output']>;
  needs_available_count?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "organization_stats". All fields are combined with a logical 'AND'. */
export type Organization_Stats_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Stats_Bool_Exp>>;
  _not?: InputMaybe<Organization_Stats_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Stats_Bool_Exp>>;
  follower_count?: InputMaybe<Int_Comparison_Exp>;
  needs_available_count?: InputMaybe<Int_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "organization_stats" */
export type Organization_Stats_Insert_Input = {
  follower_count?: InputMaybe<Scalars['Int']['input']>;
  needs_available_count?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Organization_Stats_Max_Fields = {
  follower_count?: Maybe<Scalars['Int']['output']>;
  needs_available_count?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Organization_Stats_Min_Fields = {
  follower_count?: Maybe<Scalars['Int']['output']>;
  needs_available_count?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "organization_stats" */
export type Organization_Stats_Obj_Rel_Insert_Input = {
  data: Organization_Stats_Insert_Input;
};

/** Ordering options when selecting data from "organization_stats". */
export type Organization_Stats_Order_By = {
  follower_count?: InputMaybe<Order_By>;
  needs_available_count?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** select columns of table "organization_stats" */
export enum Organization_Stats_Select_Column {
  /** column name */
  FollowerCount = 'follower_count',
  /** column name */
  NeedsAvailableCount = 'needs_available_count',
  /** column name */
  OrganizationId = 'organization_id'
}

/** aggregate stddev on columns */
export type Organization_Stats_Stddev_Fields = {
  follower_count?: Maybe<Scalars['Float']['output']>;
  needs_available_count?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Organization_Stats_Stddev_Pop_Fields = {
  follower_count?: Maybe<Scalars['Float']['output']>;
  needs_available_count?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Organization_Stats_Stddev_Samp_Fields = {
  follower_count?: Maybe<Scalars['Float']['output']>;
  needs_available_count?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "organization_stats" */
export type Organization_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Stats_Stream_Cursor_Value_Input = {
  follower_count?: InputMaybe<Scalars['Int']['input']>;
  needs_available_count?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Organization_Stats_Sum_Fields = {
  follower_count?: Maybe<Scalars['Int']['output']>;
  needs_available_count?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Organization_Stats_Var_Pop_Fields = {
  follower_count?: Maybe<Scalars['Float']['output']>;
  needs_available_count?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Organization_Stats_Var_Samp_Fields = {
  follower_count?: Maybe<Scalars['Float']['output']>;
  needs_available_count?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Organization_Stats_Variance_Fields = {
  follower_count?: Maybe<Scalars['Float']['output']>;
  needs_available_count?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "organization_subscriptions" */
export type Organization_Subscriptions = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "organization_subscriptions" */
export type Organization_Subscriptions_Aggregate = {
  aggregate?: Maybe<Organization_Subscriptions_Aggregate_Fields>;
  nodes: Array<Organization_Subscriptions>;
};

export type Organization_Subscriptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Subscriptions_Aggregate_Bool_Exp_Count>;
};

export type Organization_Subscriptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_subscriptions" */
export type Organization_Subscriptions_Aggregate_Fields = {
  avg?: Maybe<Organization_Subscriptions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Subscriptions_Max_Fields>;
  min?: Maybe<Organization_Subscriptions_Min_Fields>;
  stddev?: Maybe<Organization_Subscriptions_Stddev_Fields>;
  stddev_pop?: Maybe<Organization_Subscriptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organization_Subscriptions_Stddev_Samp_Fields>;
  sum?: Maybe<Organization_Subscriptions_Sum_Fields>;
  var_pop?: Maybe<Organization_Subscriptions_Var_Pop_Fields>;
  var_samp?: Maybe<Organization_Subscriptions_Var_Samp_Fields>;
  variance?: Maybe<Organization_Subscriptions_Variance_Fields>;
};


/** aggregate fields of "organization_subscriptions" */
export type Organization_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_subscriptions" */
export type Organization_Subscriptions_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_Subscriptions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Subscriptions_Max_Order_By>;
  min?: InputMaybe<Organization_Subscriptions_Min_Order_By>;
  stddev?: InputMaybe<Organization_Subscriptions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_Subscriptions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_Subscriptions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_Subscriptions_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_Subscriptions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_Subscriptions_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_Subscriptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organization_subscriptions" */
export type Organization_Subscriptions_Arr_Rel_Insert_Input = {
  data: Array<Organization_Subscriptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Subscriptions_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Subscriptions_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "organization_subscriptions" */
export type Organization_Subscriptions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization_subscriptions". All fields are combined with a logical 'AND'. */
export type Organization_Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Subscriptions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_subscriptions" */
export enum Organization_Subscriptions_Constraint {
  /** unique or primary key constraint on columns "user_id", "organization_id" */
  IndexOrganizationSubscriptionsOnUserIdAndOrganizationId = 'index_organization_subscriptions_on_user_id_and_organization_id',
  /** unique or primary key constraint on columns "id" */
  OrganizationSubscriptionsPkey = 'organization_subscriptions_pkey',
  /** unique or primary key constraint on columns "user_id", "organization_id" */
  OrganizationSubscriptionsUserIdOrganizationIdKey = 'organization_subscriptions_user_id_organization_id_key'
}

/** input type for incrementing numeric columns in table "organization_subscriptions" */
export type Organization_Subscriptions_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "organization_subscriptions" */
export type Organization_Subscriptions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Organization_Subscriptions_Max_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "organization_subscriptions" */
export type Organization_Subscriptions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Subscriptions_Min_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "organization_subscriptions" */
export type Organization_Subscriptions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_subscriptions" */
export type Organization_Subscriptions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Subscriptions>;
};

/** on_conflict condition type for table "organization_subscriptions" */
export type Organization_Subscriptions_On_Conflict = {
  constraint: Organization_Subscriptions_Constraint;
  update_columns?: Array<Organization_Subscriptions_Update_Column>;
  where?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_subscriptions". */
export type Organization_Subscriptions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_subscriptions */
export type Organization_Subscriptions_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "organization_subscriptions" */
export enum Organization_Subscriptions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "organization_subscriptions" */
export type Organization_Subscriptions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Organization_Subscriptions_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "organization_subscriptions" */
export type Organization_Subscriptions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Subscriptions_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "organization_subscriptions" */
export type Organization_Subscriptions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Subscriptions_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "organization_subscriptions" */
export type Organization_Subscriptions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization_subscriptions" */
export type Organization_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Subscriptions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Organization_Subscriptions_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "organization_subscriptions" */
export type Organization_Subscriptions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "organization_subscriptions" */
export enum Organization_Subscriptions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Organization_Subscriptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Subscriptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Subscriptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Subscriptions_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "organization_subscriptions" */
export type Organization_Subscriptions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Subscriptions_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "organization_subscriptions" */
export type Organization_Subscriptions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Subscriptions_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "organization_subscriptions" */
export type Organization_Subscriptions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "organization_tags" */
export type Organization_Tags = {
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
};

/** aggregated selection of "organization_tags" */
export type Organization_Tags_Aggregate = {
  aggregate?: Maybe<Organization_Tags_Aggregate_Fields>;
  nodes: Array<Organization_Tags>;
};

export type Organization_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Tags_Aggregate_Bool_Exp_Count>;
};

export type Organization_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_tags" */
export type Organization_Tags_Aggregate_Fields = {
  avg?: Maybe<Organization_Tags_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Tags_Max_Fields>;
  min?: Maybe<Organization_Tags_Min_Fields>;
  stddev?: Maybe<Organization_Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Organization_Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organization_Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Organization_Tags_Sum_Fields>;
  var_pop?: Maybe<Organization_Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Organization_Tags_Var_Samp_Fields>;
  variance?: Maybe<Organization_Tags_Variance_Fields>;
};


/** aggregate fields of "organization_tags" */
export type Organization_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_tags" */
export type Organization_Tags_Aggregate_Order_By = {
  avg?: InputMaybe<Organization_Tags_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Tags_Max_Order_By>;
  min?: InputMaybe<Organization_Tags_Min_Order_By>;
  stddev?: InputMaybe<Organization_Tags_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organization_Tags_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organization_Tags_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organization_Tags_Sum_Order_By>;
  var_pop?: InputMaybe<Organization_Tags_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organization_Tags_Var_Samp_Order_By>;
  variance?: InputMaybe<Organization_Tags_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organization_tags" */
export type Organization_Tags_Arr_Rel_Insert_Input = {
  data: Array<Organization_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Tags_On_Conflict>;
};

/** aggregate avg on columns */
export type Organization_Tags_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "organization_tags" */
export type Organization_Tags_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organization_tags". All fields are combined with a logical 'AND'. */
export type Organization_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Tags_Bool_Exp>>;
  _not?: InputMaybe<Organization_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Tags_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_tags" */
export enum Organization_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationTagsPkey = 'organization_tags_pkey'
}

/** input type for incrementing numeric columns in table "organization_tags" */
export type Organization_Tags_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "organization_tags" */
export type Organization_Tags_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Organization_Tags_Max_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "organization_tags" */
export type Organization_Tags_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Tags_Min_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "organization_tags" */
export type Organization_Tags_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_tags" */
export type Organization_Tags_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Tags>;
};

/** on_conflict condition type for table "organization_tags" */
export type Organization_Tags_On_Conflict = {
  constraint: Organization_Tags_Constraint;
  update_columns?: Array<Organization_Tags_Update_Column>;
  where?: InputMaybe<Organization_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_tags". */
export type Organization_Tags_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_tags */
export type Organization_Tags_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "organization_tags" */
export enum Organization_Tags_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Tag = 'tag'
}

/** input type for updating data in table "organization_tags" */
export type Organization_Tags_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Organization_Tags_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "organization_tags" */
export type Organization_Tags_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organization_Tags_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "organization_tags" */
export type Organization_Tags_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organization_Tags_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "organization_tags" */
export type Organization_Tags_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organization_tags" */
export type Organization_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Tags_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Organization_Tags_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "organization_tags" */
export type Organization_Tags_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** update columns of table "organization_tags" */
export enum Organization_Tags_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Tag = 'tag'
}

export type Organization_Tags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organization_Tags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Tags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organization_Tags_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "organization_tags" */
export type Organization_Tags_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organization_Tags_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "organization_tags" */
export type Organization_Tags_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organization_Tags_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "organization_tags" */
export type Organization_Tags_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "organizations" */
export type Organizations = {
  /** An object relationship */
  address_v2?: Maybe<Addresses_Organizations>;
  alias: Scalars['String']['output'];
  clerk_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamp']['output'];
  data: Scalars['jsonb']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fb_username?: Maybe<Scalars['String']['output']>;
  hero_image_content_type?: Maybe<Scalars['String']['output']>;
  hero_image_file_name?: Maybe<Scalars['String']['output']>;
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "organization_hero_image_path" */
  hero_image_path?: Maybe<Scalars['String']['output']>;
  hero_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  inactive: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  list?: Maybe<Lists>;
  list_id: Scalars['Int']['output'];
  /** An object relationship */
  location?: Maybe<Organization_Locations>;
  longitude?: Maybe<Scalars['float8']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  needs: Array<Needs>;
  /** An aggregate relationship */
  needs_aggregate: Needs_Aggregate;
  /** An array relationship */
  organization_tags: Array<Organization_Tags>;
  /** An aggregate relationship */
  organization_tags_aggregate: Organization_Tags_Aggregate;
  /** An array relationship */
  organizers: Array<Users>;
  /** An aggregate relationship */
  organizers_aggregate: Users_Aggregate;
  /** An object relationship */
  parentOrganization?: Maybe<Organizations>;
  parent_organization_id?: Maybe<Scalars['Int']['output']>;
  private?: Maybe<Scalars['Boolean']['output']>;
  profile_image_content_type?: Maybe<Scalars['String']['output']>;
  profile_image_file_name?: Maybe<Scalars['String']['output']>;
  profile_image_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "organization_profile_image_path" */
  profile_image_path?: Maybe<Scalars['String']['output']>;
  profile_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  stats?: Maybe<Organization_Stats>;
  /** An array relationship */
  subscriptions: Array<Organization_Subscriptions>;
  /** An aggregate relationship */
  subscriptions_aggregate: Organization_Subscriptions_Aggregate;
  updated_at: Scalars['timestamp']['output'];
  url?: Maybe<Scalars['String']['output']>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias: Scalars['String']['output'];
};


/** columns and relationships of "organizations" */
export type OrganizationsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "organizations" */
export type OrganizationsNeedsArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsNeeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsOrganization_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tags_Order_By>>;
  where?: InputMaybe<Organization_Tags_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsOrganization_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tags_Order_By>>;
  where?: InputMaybe<Organization_Tags_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsOrganizersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsOrganizers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Subscriptions_Order_By>>;
  where?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Subscriptions_Order_By>>;
  where?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
};

/** aggregated selection of "organizations" */
export type Organizations_Aggregate = {
  aggregate?: Maybe<Organizations_Aggregate_Fields>;
  nodes: Array<Organizations>;
};

export type Organizations_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Organizations_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Organizations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organizations_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Organizations_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Organizations_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Organizations_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Organizations_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Organizations_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Organizations_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Organizations_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Organizations_Aggregate_Bool_Exp_Var_Samp>;
};

export type Organizations_Aggregate_Bool_Exp_Avg = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Corr = {
  arguments: Organizations_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Organizations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Organizations_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Organizations_Aggregate_Bool_Exp_Max = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Min = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Sum = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_Fields = {
  avg?: Maybe<Organizations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Organizations_Max_Fields>;
  min?: Maybe<Organizations_Min_Fields>;
  stddev?: Maybe<Organizations_Stddev_Fields>;
  stddev_pop?: Maybe<Organizations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organizations_Stddev_Samp_Fields>;
  sum?: Maybe<Organizations_Sum_Fields>;
  var_pop?: Maybe<Organizations_Var_Pop_Fields>;
  var_samp?: Maybe<Organizations_Var_Samp_Fields>;
  variance?: Maybe<Organizations_Variance_Fields>;
};


/** aggregate fields of "organizations" */
export type Organizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organizations" */
export type Organizations_Aggregate_Order_By = {
  avg?: InputMaybe<Organizations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organizations_Max_Order_By>;
  min?: InputMaybe<Organizations_Min_Order_By>;
  stddev?: InputMaybe<Organizations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Organizations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Organizations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Organizations_Sum_Order_By>;
  var_pop?: InputMaybe<Organizations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Organizations_Var_Samp_Order_By>;
  variance?: InputMaybe<Organizations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Organizations_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "organizations" */
export type Organizations_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** aggregate avg on columns */
export type Organizations_Avg_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  parent_organization_id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "organizations" */
export type Organizations_Avg_Order_By = {
  hero_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Bool_Exp>>;
  address_v2?: InputMaybe<Addresses_Organizations_Bool_Exp>;
  alias?: InputMaybe<String_Comparison_Exp>;
  clerk_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  fb_username?: InputMaybe<String_Comparison_Exp>;
  hero_image_content_type?: InputMaybe<String_Comparison_Exp>;
  hero_image_file_name?: InputMaybe<String_Comparison_Exp>;
  hero_image_file_size?: InputMaybe<Int_Comparison_Exp>;
  hero_image_path?: InputMaybe<String_Comparison_Exp>;
  hero_image_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  inactive?: InputMaybe<Boolean_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  list?: InputMaybe<Lists_Bool_Exp>;
  list_id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Organization_Locations_Bool_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  needs?: InputMaybe<Needs_Bool_Exp>;
  needs_aggregate?: InputMaybe<Needs_Aggregate_Bool_Exp>;
  organization_tags?: InputMaybe<Organization_Tags_Bool_Exp>;
  organization_tags_aggregate?: InputMaybe<Organization_Tags_Aggregate_Bool_Exp>;
  organizers?: InputMaybe<Users_Bool_Exp>;
  organizers_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  parentOrganization?: InputMaybe<Organizations_Bool_Exp>;
  parent_organization_id?: InputMaybe<Int_Comparison_Exp>;
  private?: InputMaybe<Boolean_Comparison_Exp>;
  profile_image_content_type?: InputMaybe<String_Comparison_Exp>;
  profile_image_file_name?: InputMaybe<String_Comparison_Exp>;
  profile_image_file_size?: InputMaybe<Int_Comparison_Exp>;
  profile_image_path?: InputMaybe<String_Comparison_Exp>;
  profile_image_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  rank_distance?: InputMaybe<Float8_Comparison_Exp>;
  stats?: InputMaybe<Organization_Stats_Bool_Exp>;
  subscriptions?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Organization_Subscriptions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  v_alias?: InputMaybe<String_Comparison_Exp>;
};

export type Organizations_By_Alias_Args = {
  alias?: InputMaybe<Scalars['String']['input']>;
};

/** unique or primary key constraints on table "organizations" */
export enum Organizations_Constraint {
  /** unique or primary key constraint on columns "v_alias" */
  AliasBk = 'alias_bk',
  /** unique or primary key constraint on columns "alias" */
  IdxOrganizationsAlias = 'idx_organizations_alias',
  /** unique or primary key constraint on columns "v_alias" */
  IdxOrganizationsVAlias = 'idx_organizations_v_alias',
  /** unique or primary key constraint on columns "clerk_id" */
  IndexOrganizationsOnClerkId = 'index_organizations_on_clerk_id',
  /** unique or primary key constraint on columns "alias" */
  OrganizationsAliasKey = 'organizations_alias_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationsPkey = 'organizations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Organizations_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Organizations_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Organizations_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

export type Organizations_Geo_Args = {
  lat?: InputMaybe<Scalars['float8']['input']>;
  long?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for incrementing numeric columns in table "organizations" */
export type Organizations_Inc_Input = {
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  parent_organization_id?: InputMaybe<Scalars['Int']['input']>;
  profile_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "organizations" */
export type Organizations_Insert_Input = {
  address_v2?: InputMaybe<Addresses_Organizations_Obj_Rel_Insert_Input>;
  alias?: InputMaybe<Scalars['String']['input']>;
  clerk_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fb_username?: InputMaybe<Scalars['String']['input']>;
  hero_image_content_type?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_name?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  hero_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inactive?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  list?: InputMaybe<Lists_Obj_Rel_Insert_Input>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Organization_Locations_Obj_Rel_Insert_Input>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needs?: InputMaybe<Needs_Arr_Rel_Insert_Input>;
  organization_tags?: InputMaybe<Organization_Tags_Arr_Rel_Insert_Input>;
  organizers?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  parentOrganization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  parent_organization_id?: InputMaybe<Scalars['Int']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  profile_image_content_type?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_name?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  profile_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
  stats?: InputMaybe<Organization_Stats_Obj_Rel_Insert_Input>;
  subscriptions?: InputMaybe<Organization_Subscriptions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Organizations_Max_Fields = {
  alias?: Maybe<Scalars['String']['output']>;
  clerk_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fb_username?: Maybe<Scalars['String']['output']>;
  hero_image_content_type?: Maybe<Scalars['String']['output']>;
  hero_image_file_name?: Maybe<Scalars['String']['output']>;
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "organization_hero_image_path" */
  hero_image_path?: Maybe<Scalars['String']['output']>;
  hero_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_organization_id?: Maybe<Scalars['Int']['output']>;
  profile_image_content_type?: Maybe<Scalars['String']['output']>;
  profile_image_file_name?: Maybe<Scalars['String']['output']>;
  profile_image_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "organization_profile_image_path" */
  profile_image_path?: Maybe<Scalars['String']['output']>;
  profile_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "organizations" */
export type Organizations_Max_Order_By = {
  alias?: InputMaybe<Order_By>;
  clerk_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fb_username?: InputMaybe<Order_By>;
  hero_image_content_type?: InputMaybe<Order_By>;
  hero_image_file_name?: InputMaybe<Order_By>;
  hero_image_file_size?: InputMaybe<Order_By>;
  hero_image_updated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  profile_image_content_type?: InputMaybe<Order_By>;
  profile_image_file_name?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  profile_image_updated_at?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Min_Fields = {
  alias?: Maybe<Scalars['String']['output']>;
  clerk_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fb_username?: Maybe<Scalars['String']['output']>;
  hero_image_content_type?: Maybe<Scalars['String']['output']>;
  hero_image_file_name?: Maybe<Scalars['String']['output']>;
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "organization_hero_image_path" */
  hero_image_path?: Maybe<Scalars['String']['output']>;
  hero_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_organization_id?: Maybe<Scalars['Int']['output']>;
  profile_image_content_type?: Maybe<Scalars['String']['output']>;
  profile_image_file_name?: Maybe<Scalars['String']['output']>;
  profile_image_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "organization_profile_image_path" */
  profile_image_path?: Maybe<Scalars['String']['output']>;
  profile_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "organizations" */
export type Organizations_Min_Order_By = {
  alias?: InputMaybe<Order_By>;
  clerk_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fb_username?: InputMaybe<Order_By>;
  hero_image_content_type?: InputMaybe<Order_By>;
  hero_image_file_name?: InputMaybe<Order_By>;
  hero_image_file_size?: InputMaybe<Order_By>;
  hero_image_updated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  profile_image_content_type?: InputMaybe<Order_By>;
  profile_image_file_name?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  profile_image_updated_at?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organizations" */
export type Organizations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type Organizations_Obj_Rel_Insert_Input = {
  data: Organizations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** on_conflict condition type for table "organizations" */
export type Organizations_On_Conflict = {
  constraint: Organizations_Constraint;
  update_columns?: Array<Organizations_Update_Column>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations". */
export type Organizations_Order_By = {
  address_v2?: InputMaybe<Addresses_Organizations_Order_By>;
  alias?: InputMaybe<Order_By>;
  clerk_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fb_username?: InputMaybe<Order_By>;
  hero_image_content_type?: InputMaybe<Order_By>;
  hero_image_file_name?: InputMaybe<Order_By>;
  hero_image_file_size?: InputMaybe<Order_By>;
  hero_image_path?: InputMaybe<Order_By>;
  hero_image_updated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  inactive?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list?: InputMaybe<Lists_Order_By>;
  list_id?: InputMaybe<Order_By>;
  location?: InputMaybe<Organization_Locations_Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  needs_aggregate?: InputMaybe<Needs_Aggregate_Order_By>;
  organization_tags_aggregate?: InputMaybe<Organization_Tags_Aggregate_Order_By>;
  organizers_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  parentOrganization?: InputMaybe<Organizations_Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  private?: InputMaybe<Order_By>;
  profile_image_content_type?: InputMaybe<Order_By>;
  profile_image_file_name?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  profile_image_path?: InputMaybe<Order_By>;
  profile_image_updated_at?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
  stats?: InputMaybe<Organization_Stats_Order_By>;
  subscriptions_aggregate?: InputMaybe<Organization_Subscriptions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  v_alias?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizations */
export type Organizations_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Organizations_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  Alias = 'alias',
  /** column name */
  ClerkId = 'clerk_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Description = 'description',
  /** column name */
  FbUsername = 'fb_username',
  /** column name */
  HeroImageContentType = 'hero_image_content_type',
  /** column name */
  HeroImageFileName = 'hero_image_file_name',
  /** column name */
  HeroImageFileSize = 'hero_image_file_size',
  /** column name */
  HeroImageUpdatedAt = 'hero_image_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  Inactive = 'inactive',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  ListId = 'list_id',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  ParentOrganizationId = 'parent_organization_id',
  /** column name */
  Private = 'private',
  /** column name */
  ProfileImageContentType = 'profile_image_content_type',
  /** column name */
  ProfileImageFileName = 'profile_image_file_name',
  /** column name */
  ProfileImageFileSize = 'profile_image_file_size',
  /** column name */
  ProfileImageUpdatedAt = 'profile_image_updated_at',
  /** column name */
  RankDistance = 'rank_distance',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  VAlias = 'v_alias'
}

/** select "organizations_aggregate_bool_exp_avg_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "organizations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Inactive = 'inactive',
  /** column name */
  Private = 'private'
}

/** select "organizations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Inactive = 'inactive',
  /** column name */
  Private = 'private'
}

/** select "organizations_aggregate_bool_exp_corr_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "organizations_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "organizations_aggregate_bool_exp_max_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "organizations_aggregate_bool_exp_min_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "organizations_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "organizations_aggregate_bool_exp_sum_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** select "organizations_aggregate_bool_exp_var_samp_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  RankDistance = 'rank_distance'
}

/** input type for updating data in table "organizations" */
export type Organizations_Set_Input = {
  alias?: InputMaybe<Scalars['String']['input']>;
  clerk_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fb_username?: InputMaybe<Scalars['String']['input']>;
  hero_image_content_type?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_name?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  hero_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inactive?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_organization_id?: InputMaybe<Scalars['Int']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  profile_image_content_type?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_name?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  profile_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Organizations_Stddev_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  parent_organization_id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "organizations" */
export type Organizations_Stddev_Order_By = {
  hero_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organizations_Stddev_Pop_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  parent_organization_id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "organizations" */
export type Organizations_Stddev_Pop_Order_By = {
  hero_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organizations_Stddev_Samp_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  parent_organization_id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "organizations" */
export type Organizations_Stddev_Samp_Order_By = {
  hero_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "organizations" */
export type Organizations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organizations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organizations_Stream_Cursor_Value_Input = {
  alias?: InputMaybe<Scalars['String']['input']>;
  clerk_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fb_username?: InputMaybe<Scalars['String']['input']>;
  hero_image_content_type?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_name?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  hero_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  inactive?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_organization_id?: InputMaybe<Scalars['Int']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  profile_image_content_type?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_name?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  profile_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  rank_distance?: InputMaybe<Scalars['float8']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Organizations_Sum_Fields = {
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  parent_organization_id?: Maybe<Scalars['Int']['output']>;
  profile_image_file_size?: Maybe<Scalars['Int']['output']>;
  rank_distance?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "organizations" */
export type Organizations_Sum_Order_By = {
  hero_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** update columns of table "organizations" */
export enum Organizations_Update_Column {
  /** column name */
  Alias = 'alias',
  /** column name */
  ClerkId = 'clerk_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Description = 'description',
  /** column name */
  FbUsername = 'fb_username',
  /** column name */
  HeroImageContentType = 'hero_image_content_type',
  /** column name */
  HeroImageFileName = 'hero_image_file_name',
  /** column name */
  HeroImageFileSize = 'hero_image_file_size',
  /** column name */
  HeroImageUpdatedAt = 'hero_image_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  Inactive = 'inactive',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  ListId = 'list_id',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  ParentOrganizationId = 'parent_organization_id',
  /** column name */
  Private = 'private',
  /** column name */
  ProfileImageContentType = 'profile_image_content_type',
  /** column name */
  ProfileImageFileName = 'profile_image_file_name',
  /** column name */
  ProfileImageFileSize = 'profile_image_file_size',
  /** column name */
  ProfileImageUpdatedAt = 'profile_image_updated_at',
  /** column name */
  RankDistance = 'rank_distance',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Organizations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Organizations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Organizations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Organizations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Organizations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Organizations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Organizations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organizations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organizations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organizations_Var_Pop_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  parent_organization_id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "organizations" */
export type Organizations_Var_Pop_Order_By = {
  hero_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organizations_Var_Samp_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  parent_organization_id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "organizations" */
export type Organizations_Var_Samp_Order_By = {
  hero_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Organizations_Variance_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  parent_organization_id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
  rank_distance?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "organizations" */
export type Organizations_Variance_Order_By = {
  hero_image_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  rank_distance?: InputMaybe<Order_By>;
};

/** columns and relationships of "parent_organizations" */
export type Parent_Organizations = {
  /** An array relationship */
  childOrganizations: Array<Organizations>;
  /** An aggregate relationship */
  childOrganizations_aggregate: Organizations_Aggregate;
  created_at: Scalars['timestamp']['output'];
  hero_image_content_type?: Maybe<Scalars['String']['output']>;
  hero_image_file_name?: Maybe<Scalars['String']['output']>;
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  hero_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile_image_content_type?: Maybe<Scalars['String']['output']>;
  profile_image_file_name?: Maybe<Scalars['String']['output']>;
  profile_image_file_size?: Maybe<Scalars['Int']['output']>;
  profile_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "parent_organizations" */
export type Parent_OrganizationsChildOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "parent_organizations" */
export type Parent_OrganizationsChildOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** aggregated selection of "parent_organizations" */
export type Parent_Organizations_Aggregate = {
  aggregate?: Maybe<Parent_Organizations_Aggregate_Fields>;
  nodes: Array<Parent_Organizations>;
};

/** aggregate fields of "parent_organizations" */
export type Parent_Organizations_Aggregate_Fields = {
  avg?: Maybe<Parent_Organizations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Parent_Organizations_Max_Fields>;
  min?: Maybe<Parent_Organizations_Min_Fields>;
  stddev?: Maybe<Parent_Organizations_Stddev_Fields>;
  stddev_pop?: Maybe<Parent_Organizations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parent_Organizations_Stddev_Samp_Fields>;
  sum?: Maybe<Parent_Organizations_Sum_Fields>;
  var_pop?: Maybe<Parent_Organizations_Var_Pop_Fields>;
  var_samp?: Maybe<Parent_Organizations_Var_Samp_Fields>;
  variance?: Maybe<Parent_Organizations_Variance_Fields>;
};


/** aggregate fields of "parent_organizations" */
export type Parent_Organizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parent_Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Parent_Organizations_Avg_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "parent_organizations". All fields are combined with a logical 'AND'. */
export type Parent_Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Parent_Organizations_Bool_Exp>>;
  _not?: InputMaybe<Parent_Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Parent_Organizations_Bool_Exp>>;
  childOrganizations?: InputMaybe<Organizations_Bool_Exp>;
  childOrganizations_aggregate?: InputMaybe<Organizations_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  hero_image_content_type?: InputMaybe<String_Comparison_Exp>;
  hero_image_file_name?: InputMaybe<String_Comparison_Exp>;
  hero_image_file_size?: InputMaybe<Int_Comparison_Exp>;
  hero_image_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profile_image_content_type?: InputMaybe<String_Comparison_Exp>;
  profile_image_file_name?: InputMaybe<String_Comparison_Exp>;
  profile_image_file_size?: InputMaybe<Int_Comparison_Exp>;
  profile_image_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "parent_organizations" */
export enum Parent_Organizations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ParentOrganizationsPkey = 'parent_organizations_pkey'
}

/** input type for incrementing numeric columns in table "parent_organizations" */
export type Parent_Organizations_Inc_Input = {
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  profile_image_file_size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "parent_organizations" */
export type Parent_Organizations_Insert_Input = {
  childOrganizations?: InputMaybe<Organizations_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  hero_image_content_type?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_name?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  hero_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profile_image_content_type?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_name?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  profile_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Parent_Organizations_Max_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  hero_image_content_type?: Maybe<Scalars['String']['output']>;
  hero_image_file_name?: Maybe<Scalars['String']['output']>;
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  hero_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profile_image_content_type?: Maybe<Scalars['String']['output']>;
  profile_image_file_name?: Maybe<Scalars['String']['output']>;
  profile_image_file_size?: Maybe<Scalars['Int']['output']>;
  profile_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Parent_Organizations_Min_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  hero_image_content_type?: Maybe<Scalars['String']['output']>;
  hero_image_file_name?: Maybe<Scalars['String']['output']>;
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  hero_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profile_image_content_type?: Maybe<Scalars['String']['output']>;
  profile_image_file_name?: Maybe<Scalars['String']['output']>;
  profile_image_file_size?: Maybe<Scalars['Int']['output']>;
  profile_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "parent_organizations" */
export type Parent_Organizations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Parent_Organizations>;
};

/** on_conflict condition type for table "parent_organizations" */
export type Parent_Organizations_On_Conflict = {
  constraint: Parent_Organizations_Constraint;
  update_columns?: Array<Parent_Organizations_Update_Column>;
  where?: InputMaybe<Parent_Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "parent_organizations". */
export type Parent_Organizations_Order_By = {
  childOrganizations_aggregate?: InputMaybe<Organizations_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  hero_image_content_type?: InputMaybe<Order_By>;
  hero_image_file_name?: InputMaybe<Order_By>;
  hero_image_file_size?: InputMaybe<Order_By>;
  hero_image_updated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profile_image_content_type?: InputMaybe<Order_By>;
  profile_image_file_name?: InputMaybe<Order_By>;
  profile_image_file_size?: InputMaybe<Order_By>;
  profile_image_updated_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parent_organizations */
export type Parent_Organizations_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "parent_organizations" */
export enum Parent_Organizations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HeroImageContentType = 'hero_image_content_type',
  /** column name */
  HeroImageFileName = 'hero_image_file_name',
  /** column name */
  HeroImageFileSize = 'hero_image_file_size',
  /** column name */
  HeroImageUpdatedAt = 'hero_image_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProfileImageContentType = 'profile_image_content_type',
  /** column name */
  ProfileImageFileName = 'profile_image_file_name',
  /** column name */
  ProfileImageFileSize = 'profile_image_file_size',
  /** column name */
  ProfileImageUpdatedAt = 'profile_image_updated_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "parent_organizations" */
export type Parent_Organizations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  hero_image_content_type?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_name?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  hero_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profile_image_content_type?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_name?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  profile_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Parent_Organizations_Stddev_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Parent_Organizations_Stddev_Pop_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Parent_Organizations_Stddev_Samp_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "parent_organizations" */
export type Parent_Organizations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Parent_Organizations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Parent_Organizations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  hero_image_content_type?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_name?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  hero_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profile_image_content_type?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_name?: InputMaybe<Scalars['String']['input']>;
  profile_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  profile_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Parent_Organizations_Sum_Fields = {
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  profile_image_file_size?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "parent_organizations" */
export enum Parent_Organizations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HeroImageContentType = 'hero_image_content_type',
  /** column name */
  HeroImageFileName = 'hero_image_file_name',
  /** column name */
  HeroImageFileSize = 'hero_image_file_size',
  /** column name */
  HeroImageUpdatedAt = 'hero_image_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProfileImageContentType = 'profile_image_content_type',
  /** column name */
  ProfileImageFileName = 'profile_image_file_name',
  /** column name */
  ProfileImageFileSize = 'profile_image_file_size',
  /** column name */
  ProfileImageUpdatedAt = 'profile_image_updated_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Parent_Organizations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Parent_Organizations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Parent_Organizations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Parent_Organizations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Parent_Organizations_Var_Pop_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Parent_Organizations_Var_Samp_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Parent_Organizations_Variance_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  profile_image_file_size?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "posts" */
export type Posts = {
  content: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  /** An array relationship */
  feed_items: Array<Feed_Items>;
  /** An aggregate relationship */
  feed_items_aggregate: Feed_Items_Aggregate;
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "posts" */
export type PostsFeed_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsFeed_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};

/** aggregated selection of "posts" */
export type Posts_Aggregate = {
  aggregate?: Maybe<Posts_Aggregate_Fields>;
  nodes: Array<Posts>;
};

/** aggregate fields of "posts" */
export type Posts_Aggregate_Fields = {
  avg?: Maybe<Posts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Posts_Max_Fields>;
  min?: Maybe<Posts_Min_Fields>;
  stddev?: Maybe<Posts_Stddev_Fields>;
  stddev_pop?: Maybe<Posts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Posts_Stddev_Samp_Fields>;
  sum?: Maybe<Posts_Sum_Fields>;
  var_pop?: Maybe<Posts_Var_Pop_Fields>;
  var_samp?: Maybe<Posts_Var_Samp_Fields>;
  variance?: Maybe<Posts_Variance_Fields>;
};


/** aggregate fields of "posts" */
export type Posts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Posts_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "posts". All fields are combined with a logical 'AND'. */
export type Posts_Bool_Exp = {
  _and?: InputMaybe<Array<Posts_Bool_Exp>>;
  _not?: InputMaybe<Posts_Bool_Exp>;
  _or?: InputMaybe<Array<Posts_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  feed_items?: InputMaybe<Feed_Items_Bool_Exp>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "posts" */
export enum Posts_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostsPkey = 'posts_pkey'
}

/** input type for incrementing numeric columns in table "posts" */
export type Posts_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "posts" */
export type Posts_Insert_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  feed_items?: InputMaybe<Feed_Items_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Posts_Max_Fields = {
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Posts_Min_Fields = {
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "posts" */
export type Posts_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Posts>;
};

/** input type for inserting object relation for remote table "posts" */
export type Posts_Obj_Rel_Insert_Input = {
  data: Posts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};

/** on_conflict condition type for table "posts" */
export type Posts_On_Conflict = {
  constraint: Posts_Constraint;
  update_columns?: Array<Posts_Update_Column>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

/** Ordering options when selecting data from "posts". */
export type Posts_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: posts */
export type Posts_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "posts" */
export enum Posts_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "posts" */
export type Posts_Set_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Posts_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Posts_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Posts_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "posts" */
export type Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Posts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Posts_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Posts_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "posts" */
export enum Posts_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Posts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Posts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Posts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Posts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Posts_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Posts_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Posts_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "private.modified_data" */
export type Private_Modified_Data = {
  created_at: Scalars['timestamptz']['output'];
  data?: Maybe<Scalars['jsonb']['output']>;
  event: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  item_id: Scalars['Int']['output'];
  item_schema: Scalars['String']['output'];
  item_table: Scalars['String']['output'];
  user_id: Scalars['Int']['output'];
};


/** columns and relationships of "private.modified_data" */
export type Private_Modified_DataDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "private.modified_data" */
export type Private_Modified_Data_Aggregate = {
  aggregate?: Maybe<Private_Modified_Data_Aggregate_Fields>;
  nodes: Array<Private_Modified_Data>;
};

/** aggregate fields of "private.modified_data" */
export type Private_Modified_Data_Aggregate_Fields = {
  avg?: Maybe<Private_Modified_Data_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Private_Modified_Data_Max_Fields>;
  min?: Maybe<Private_Modified_Data_Min_Fields>;
  stddev?: Maybe<Private_Modified_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Private_Modified_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Private_Modified_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Private_Modified_Data_Sum_Fields>;
  var_pop?: Maybe<Private_Modified_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Private_Modified_Data_Var_Samp_Fields>;
  variance?: Maybe<Private_Modified_Data_Variance_Fields>;
};


/** aggregate fields of "private.modified_data" */
export type Private_Modified_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Private_Modified_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Private_Modified_Data_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Private_Modified_Data_Avg_Fields = {
  item_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "private.modified_data". All fields are combined with a logical 'AND'. */
export type Private_Modified_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Private_Modified_Data_Bool_Exp>>;
  _not?: InputMaybe<Private_Modified_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Private_Modified_Data_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item_id?: InputMaybe<Int_Comparison_Exp>;
  item_schema?: InputMaybe<String_Comparison_Exp>;
  item_table?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "private.modified_data" */
export enum Private_Modified_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  ModifiedDataPkey = 'modified_data_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Private_Modified_Data_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Private_Modified_Data_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Private_Modified_Data_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "private.modified_data" */
export type Private_Modified_Data_Inc_Input = {
  item_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "private.modified_data" */
export type Private_Modified_Data_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  item_id?: InputMaybe<Scalars['Int']['input']>;
  item_schema?: InputMaybe<Scalars['String']['input']>;
  item_table?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Private_Modified_Data_Max_Fields = {
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  item_id?: Maybe<Scalars['Int']['output']>;
  item_schema?: Maybe<Scalars['String']['output']>;
  item_table?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Private_Modified_Data_Min_Fields = {
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  item_id?: Maybe<Scalars['Int']['output']>;
  item_schema?: Maybe<Scalars['String']['output']>;
  item_table?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "private.modified_data" */
export type Private_Modified_Data_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Private_Modified_Data>;
};

/** on_conflict condition type for table "private.modified_data" */
export type Private_Modified_Data_On_Conflict = {
  constraint: Private_Modified_Data_Constraint;
  update_columns?: Array<Private_Modified_Data_Update_Column>;
  where?: InputMaybe<Private_Modified_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "private.modified_data". */
export type Private_Modified_Data_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_schema?: InputMaybe<Order_By>;
  item_table?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: private.modified_data */
export type Private_Modified_Data_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Private_Modified_Data_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "private.modified_data" */
export enum Private_Modified_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemSchema = 'item_schema',
  /** column name */
  ItemTable = 'item_table',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "private.modified_data" */
export type Private_Modified_Data_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  item_id?: InputMaybe<Scalars['Int']['input']>;
  item_schema?: InputMaybe<Scalars['String']['input']>;
  item_table?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Private_Modified_Data_Stddev_Fields = {
  item_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Private_Modified_Data_Stddev_Pop_Fields = {
  item_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Private_Modified_Data_Stddev_Samp_Fields = {
  item_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "private_modified_data" */
export type Private_Modified_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Private_Modified_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Private_Modified_Data_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  item_id?: InputMaybe<Scalars['Int']['input']>;
  item_schema?: InputMaybe<Scalars['String']['input']>;
  item_table?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Private_Modified_Data_Sum_Fields = {
  item_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "private.modified_data" */
export enum Private_Modified_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemSchema = 'item_schema',
  /** column name */
  ItemTable = 'item_table',
  /** column name */
  UserId = 'user_id'
}

export type Private_Modified_Data_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Private_Modified_Data_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Private_Modified_Data_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Private_Modified_Data_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Private_Modified_Data_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Private_Modified_Data_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Private_Modified_Data_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Private_Modified_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Private_Modified_Data_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Private_Modified_Data_Var_Pop_Fields = {
  item_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Private_Modified_Data_Var_Samp_Fields = {
  item_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Private_Modified_Data_Variance_Fields = {
  item_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "push_token_type". All fields are combined with logical 'AND'. */
export type Push_Token_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['push_token_type']['input']>;
  _gt?: InputMaybe<Scalars['push_token_type']['input']>;
  _gte?: InputMaybe<Scalars['push_token_type']['input']>;
  _in?: InputMaybe<Array<Scalars['push_token_type']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['push_token_type']['input']>;
  _lte?: InputMaybe<Scalars['push_token_type']['input']>;
  _neq?: InputMaybe<Scalars['push_token_type']['input']>;
  _nin?: InputMaybe<Array<Scalars['push_token_type']['input']>>;
};

export type Query_Root = {
  Author?: Maybe<Author>;
  Campaign?: Maybe<Campaign>;
  SanityFileAsset?: Maybe<SanityFileAsset>;
  SanityImageAsset?: Maybe<SanityImageAsset>;
  Team?: Maybe<Team>;
  Update?: Maybe<Update>;
  /** fetch data from the table: "addresses" */
  addresses: Array<Addresses>;
  /** fetch aggregated fields from the table: "addresses" */
  addresses_aggregate: Addresses_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  addresses_by_pk?: Maybe<Addresses>;
  /** fetch data from the table: "addresses_need_informations" */
  addresses_need_informations: Array<Addresses_Need_Informations>;
  /** fetch aggregated fields from the table: "addresses_need_informations" */
  addresses_need_informations_aggregate: Addresses_Need_Informations_Aggregate;
  /** fetch data from the table: "addresses_organizations" */
  addresses_organizations: Array<Addresses_Organizations>;
  /** fetch aggregated fields from the table: "addresses_organizations" */
  addresses_organizations_aggregate: Addresses_Organizations_Aggregate;
  /** fetch data from the table: "addresses_users" */
  addresses_users: Array<Addresses_Users>;
  /** fetch aggregated fields from the table: "addresses_users" */
  addresses_users_aggregate: Addresses_Users_Aggregate;
  /** fetch data from the table: "aliases" */
  aliases: Array<Aliases>;
  /** fetch aggregated fields from the table: "aliases" */
  aliases_aggregate: Aliases_Aggregate;
  allAuthor: Array<Author>;
  allCampaign: Array<Campaign>;
  allSanityFileAsset: Array<SanityFileAsset>;
  allSanityImageAsset: Array<SanityImageAsset>;
  allTeam: Array<Team>;
  allUpdate: Array<Update>;
  /** An array relationship */
  checkout_intents: Array<Checkout_Intents>;
  /** An aggregate relationship */
  checkout_intents_aggregate: Checkout_Intents_Aggregate;
  /** fetch data from the table: "checkout_intents" using primary key columns */
  checkout_intents_by_pk?: Maybe<Checkout_Intents>;
  current_user?: Maybe<CurrentUserOutput>;
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  /** An array relationship */
  feed_items: Array<Feed_Items>;
  /** An aggregate relationship */
  feed_items_aggregate: Feed_Items_Aggregate;
  /** fetch data from the table: "feed_items" using primary key columns */
  feed_items_by_pk?: Maybe<Feed_Items>;
  /** fetch data from the table: "feed_posts" */
  feed_posts: Array<Feed_Posts>;
  /** fetch aggregated fields from the table: "feed_posts" */
  feed_posts_aggregate: Feed_Posts_Aggregate;
  /** fetch data from the table: "feed_posts" using primary key columns */
  feed_posts_by_pk?: Maybe<Feed_Posts>;
  get_ip_location?: Maybe<Location>;
  /** fetch data from the table: "goals" */
  goals: Array<Goals>;
  /** fetch aggregated fields from the table: "goals" */
  goals_aggregate: Goals_Aggregate;
  /** fetch data from the table: "goals" using primary key columns */
  goals_by_pk?: Maybe<Goals>;
  /** fetch data from the table: "internal_data.organization_goals" */
  internal_data_organization_goals: Array<Internal_Data_Organization_Goals>;
  /** fetch aggregated fields from the table: "internal_data.organization_goals" */
  internal_data_organization_goals_aggregate: Internal_Data_Organization_Goals_Aggregate;
  /** fetch data from the table: "internal_data.organization_goals" using primary key columns */
  internal_data_organization_goals_by_pk?: Maybe<Internal_Data_Organization_Goals>;
  /** fetch data from the table: "list_locations" */
  list_locations: Array<List_Locations>;
  /** fetch aggregated fields from the table: "list_locations" */
  list_locations_aggregate: List_Locations_Aggregate;
  /** An array relationship */
  list_subscriptions: Array<List_Subscriptions>;
  /** An aggregate relationship */
  list_subscriptions_aggregate: List_Subscriptions_Aggregate;
  /** fetch data from the table: "list_subscriptions" using primary key columns */
  list_subscriptions_by_pk?: Maybe<List_Subscriptions>;
  /** An array relationship */
  lists: Array<Lists>;
  /** An aggregate relationship */
  lists_aggregate: Lists_Aggregate;
  /** fetch data from the table: "lists" using primary key columns */
  lists_by_pk?: Maybe<Lists>;
  /** execute function "lists_geo" which returns "lists" */
  lists_geo: Array<Lists>;
  /** execute function "lists_geo" and query aggregates on result of table type "lists" */
  lists_geo_aggregate: Lists_Aggregate;
  /** execute function "me" which returns "users" */
  me?: Maybe<Users>;
  /** execute function "me" and query aggregates on result of table type "users" */
  me_aggregate: Users_Aggregate;
  /** An array relationship */
  need_fulfill_intents: Array<Need_Fulfill_Intents>;
  /** An aggregate relationship */
  need_fulfill_intents_aggregate: Need_Fulfill_Intents_Aggregate;
  /** fetch data from the table: "need_fulfill_intents" using primary key columns */
  need_fulfill_intents_by_pk?: Maybe<Need_Fulfill_Intents>;
  /** An array relationship */
  need_informations: Array<Need_Informations>;
  /** An aggregate relationship */
  need_informations_aggregate: Need_Informations_Aggregate;
  /** fetch data from the table: "need_informations" using primary key columns */
  need_informations_by_pk?: Maybe<Need_Informations>;
  /** fetch data from the table: "need_locations" */
  need_locations: Array<Need_Locations>;
  /** fetch aggregated fields from the table: "need_locations" */
  need_locations_aggregate: Need_Locations_Aggregate;
  /** fetch data from the table: "need_tags" */
  need_tags: Array<Need_Tags>;
  /** fetch aggregated fields from the table: "need_tags" */
  need_tags_aggregate: Need_Tags_Aggregate;
  /** fetch data from the table: "need_tags" using primary key columns */
  need_tags_by_pk?: Maybe<Need_Tags>;
  /** An array relationship */
  needs: Array<Needs>;
  /** An aggregate relationship */
  needs_aggregate: Needs_Aggregate;
  /** fetch data from the table: "needs" using primary key columns */
  needs_by_pk?: Maybe<Needs>;
  /** execute function "needs_geo" which returns "needs" */
  needs_geo: Array<Needs>;
  /** execute function "needs_geo" and query aggregates on result of table type "needs" */
  needs_geo_aggregate: Needs_Aggregate;
  /** execute function "needs_list" which returns "needs" */
  needs_list: Array<Needs>;
  /** execute function "needs_list" and query aggregates on result of table type "needs" */
  needs_list_aggregate: Needs_Aggregate;
  /** execute function "needs_shuffled" which returns "needs" */
  needs_shuffled: Array<Needs>;
  /** execute function "needs_shuffled" and query aggregates on result of table type "needs" */
  needs_shuffled_aggregate: Needs_Aggregate;
  /** fetch data from the table: "organization_locations" */
  organization_locations: Array<Organization_Locations>;
  /** fetch aggregated fields from the table: "organization_locations" */
  organization_locations_aggregate: Organization_Locations_Aggregate;
  /** fetch data from the table: "organization_stats" */
  organization_stats: Array<Organization_Stats>;
  /** fetch aggregated fields from the table: "organization_stats" */
  organization_stats_aggregate: Organization_Stats_Aggregate;
  /** An array relationship */
  organization_subscriptions: Array<Organization_Subscriptions>;
  /** An aggregate relationship */
  organization_subscriptions_aggregate: Organization_Subscriptions_Aggregate;
  /** fetch data from the table: "organization_subscriptions" using primary key columns */
  organization_subscriptions_by_pk?: Maybe<Organization_Subscriptions>;
  /** An array relationship */
  organization_tags: Array<Organization_Tags>;
  /** An aggregate relationship */
  organization_tags_aggregate: Organization_Tags_Aggregate;
  /** fetch data from the table: "organization_tags" using primary key columns */
  organization_tags_by_pk?: Maybe<Organization_Tags>;
  /** An array relationship */
  organizations: Array<Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Organizations_Aggregate;
  /** execute function "organizations_by_alias" which returns "organizations" */
  organizations_by_alias: Array<Organizations>;
  /** execute function "organizations_by_alias" and query aggregates on result of table type "organizations" */
  organizations_by_alias_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** execute function "organizations_geo" which returns "organizations" */
  organizations_geo: Array<Organizations>;
  /** execute function "organizations_geo" and query aggregates on result of table type "organizations" */
  organizations_geo_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "parent_organizations" */
  parent_organizations: Array<Parent_Organizations>;
  /** fetch aggregated fields from the table: "parent_organizations" */
  parent_organizations_aggregate: Parent_Organizations_Aggregate;
  /** fetch data from the table: "parent_organizations" using primary key columns */
  parent_organizations_by_pk?: Maybe<Parent_Organizations>;
  /** fetch data from the table: "posts" */
  posts: Array<Posts>;
  /** fetch aggregated fields from the table: "posts" */
  posts_aggregate: Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>;
  /** fetch data from the table: "private.modified_data" */
  private_modified_data: Array<Private_Modified_Data>;
  /** fetch aggregated fields from the table: "private.modified_data" */
  private_modified_data_aggregate: Private_Modified_Data_Aggregate;
  /** fetch data from the table: "private.modified_data" using primary key columns */
  private_modified_data_by_pk?: Maybe<Private_Modified_Data>;
  /** execute function "search_users" which returns "users" */
  search_users: Array<Users>;
  /** execute function "search_users" and query aggregates on result of table type "users" */
  search_users_aggregate: Users_Aggregate;
  /** fetch data from the table: "sponsors" */
  sponsors: Array<Sponsors>;
  /** fetch aggregated fields from the table: "sponsors" */
  sponsors_aggregate: Sponsors_Aggregate;
  /** fetch data from the table: "sponsors" using primary key columns */
  sponsors_by_pk?: Maybe<Sponsors>;
  /** An array relationship */
  sponsorships: Array<Sponsorships>;
  /** An aggregate relationship */
  sponsorships_aggregate: Sponsorships_Aggregate;
  /** fetch data from the table: "sponsorships" using primary key columns */
  sponsorships_by_pk?: Maybe<Sponsorships>;
  /** fetch data from the table: "stories" */
  stories: Array<Stories>;
  /** fetch aggregated fields from the table: "stories" */
  stories_aggregate: Stories_Aggregate;
  /** fetch data from the table: "stories" using primary key columns */
  stories_by_pk?: Maybe<Stories>;
  /** fetch data from the table: "stripe_charges" */
  stripe_charges: Array<Stripe_Charges>;
  /** fetch aggregated fields from the table: "stripe_charges" */
  stripe_charges_aggregate: Stripe_Charges_Aggregate;
  /** fetch data from the table: "stripe_charges" using primary key columns */
  stripe_charges_by_pk?: Maybe<Stripe_Charges>;
  /** fetch data from the table: "stripe_checkout_sessions" */
  stripe_checkout_sessions: Array<Stripe_Checkout_Sessions>;
  /** fetch aggregated fields from the table: "stripe_checkout_sessions" */
  stripe_checkout_sessions_aggregate: Stripe_Checkout_Sessions_Aggregate;
  /** fetch data from the table: "stripe_checkout_sessions" using primary key columns */
  stripe_checkout_sessions_by_pk?: Maybe<Stripe_Checkout_Sessions>;
  /** fetch data from the table: "stripe_customers" */
  stripe_customers: Array<Stripe_Customers>;
  /** fetch aggregated fields from the table: "stripe_customers" */
  stripe_customers_aggregate: Stripe_Customers_Aggregate;
  /** fetch data from the table: "stripe_customers" using primary key columns */
  stripe_customers_by_pk?: Maybe<Stripe_Customers>;
  /** fetch data from the table: "stripe_invoice_items" */
  stripe_invoice_items: Array<Stripe_Invoice_Items>;
  /** fetch aggregated fields from the table: "stripe_invoice_items" */
  stripe_invoice_items_aggregate: Stripe_Invoice_Items_Aggregate;
  /** fetch data from the table: "stripe_invoice_items" using primary key columns */
  stripe_invoice_items_by_pk?: Maybe<Stripe_Invoice_Items>;
  /** fetch data from the table: "stripe_invoices" */
  stripe_invoices: Array<Stripe_Invoices>;
  /** fetch aggregated fields from the table: "stripe_invoices" */
  stripe_invoices_aggregate: Stripe_Invoices_Aggregate;
  /** fetch data from the table: "stripe_invoices" using primary key columns */
  stripe_invoices_by_pk?: Maybe<Stripe_Invoices>;
  /** fetch data from the table: "stripe_payment_intents" */
  stripe_payment_intents: Array<Stripe_Payment_Intents>;
  /** fetch aggregated fields from the table: "stripe_payment_intents" */
  stripe_payment_intents_aggregate: Stripe_Payment_Intents_Aggregate;
  /** fetch data from the table: "stripe_payment_intents" using primary key columns */
  stripe_payment_intents_by_pk?: Maybe<Stripe_Payment_Intents>;
  /** fetch data from the table: "stripe_payment_links" */
  stripe_payment_links: Array<Stripe_Payment_Links>;
  /** fetch aggregated fields from the table: "stripe_payment_links" */
  stripe_payment_links_aggregate: Stripe_Payment_Links_Aggregate;
  /** fetch data from the table: "stripe_payment_links" using primary key columns */
  stripe_payment_links_by_pk?: Maybe<Stripe_Payment_Links>;
  /** fetch data from the table: "stripe_payment_methods" */
  stripe_payment_methods: Array<Stripe_Payment_Methods>;
  /** fetch aggregated fields from the table: "stripe_payment_methods" */
  stripe_payment_methods_aggregate: Stripe_Payment_Methods_Aggregate;
  /** fetch data from the table: "stripe_payment_methods" using primary key columns */
  stripe_payment_methods_by_pk?: Maybe<Stripe_Payment_Methods>;
  /** fetch data from the table: "stripe_prices" */
  stripe_prices: Array<Stripe_Prices>;
  /** fetch aggregated fields from the table: "stripe_prices" */
  stripe_prices_aggregate: Stripe_Prices_Aggregate;
  /** fetch data from the table: "stripe_prices" using primary key columns */
  stripe_prices_by_pk?: Maybe<Stripe_Prices>;
  /** fetch data from the table: "stripe_products" */
  stripe_products: Array<Stripe_Products>;
  /** fetch aggregated fields from the table: "stripe_products" */
  stripe_products_aggregate: Stripe_Products_Aggregate;
  /** fetch data from the table: "stripe_products" using primary key columns */
  stripe_products_by_pk?: Maybe<Stripe_Products>;
  /** fetch data from the table: "stripe_subscriptions" */
  stripe_subscriptions: Array<Stripe_Subscriptions>;
  /** fetch aggregated fields from the table: "stripe_subscriptions" */
  stripe_subscriptions_aggregate: Stripe_Subscriptions_Aggregate;
  /** fetch data from the table: "stripe_subscriptions" using primary key columns */
  stripe_subscriptions_by_pk?: Maybe<Stripe_Subscriptions>;
  /** An array relationship */
  tax_receipts: Array<Tax_Receipts>;
  /** An aggregate relationship */
  tax_receipts_aggregate: Tax_Receipts_Aggregate;
  /** fetch data from the table: "tax_receipts" using primary key columns */
  tax_receipts_by_pk?: Maybe<Tax_Receipts>;
  /** fetch data from the table: "team_avatars" */
  team_avatars: Array<Team_Avatars>;
  /** fetch aggregated fields from the table: "team_avatars" */
  team_avatars_aggregate: Team_Avatars_Aggregate;
  /** An array relationship */
  team_memberships: Array<Team_Memberships>;
  /** An aggregate relationship */
  team_memberships_aggregate: Team_Memberships_Aggregate;
  /** fetch data from the table: "memberships" using primary key columns */
  team_memberships_by_pk?: Maybe<Team_Memberships>;
  /** An array relationship */
  team_needs: Array<Team_Needs>;
  /** An aggregate relationship */
  team_needs_aggregate: Team_Needs_Aggregate;
  /** fetch data from the table: "team_needs" using primary key columns */
  team_needs_by_pk?: Maybe<Team_Needs>;
  /** fetch data from the table: "team_needs_stats" */
  team_needs_stats: Array<Team_Needs_Stats>;
  /** fetch aggregated fields from the table: "team_needs_stats" */
  team_needs_stats_aggregate: Team_Needs_Stats_Aggregate;
  /** fetch data from the table: "team_stats" */
  team_stats: Array<Team_Stats>;
  /** fetch aggregated fields from the table: "team_stats" */
  team_stats_aggregate: Team_Stats_Aggregate;
  /** fetch data from the table: "team_tags" */
  team_tags: Array<Team_Tags>;
  /** fetch aggregated fields from the table: "team_tags" */
  team_tags_aggregate: Team_Tags_Aggregate;
  /** fetch data from the table: "team_tags" using primary key columns */
  team_tags_by_pk?: Maybe<Team_Tags>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** An array relationship */
  thank_yous: Array<Thank_Yous>;
  /** An aggregate relationship */
  thank_yous_aggregate: Thank_Yous_Aggregate;
  /** fetch data from the table: "thank_yous" using primary key columns */
  thank_yous_by_pk?: Maybe<Thank_Yous>;
  /** fetch data from the table: "user_goals" */
  user_goals: Array<User_Goals>;
  /** fetch aggregated fields from the table: "user_goals" */
  user_goals_aggregate: User_Goals_Aggregate;
  /** fetch data from the table: "user_needs_met_stats" */
  user_needs_met_stats: Array<User_Needs_Met_Stats>;
  /** fetch aggregated fields from the table: "user_needs_met_stats" */
  user_needs_met_stats_aggregate: User_Needs_Met_Stats_Aggregate;
  /** fetch data from the table: "user_stats" */
  user_stats: Array<User_Stats>;
  /** fetch aggregated fields from the table: "user_stats" */
  user_stats_aggregate: User_Stats_Aggregate;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootAuthorArgs = {
  id: Scalars['ID']['input'];
};


export type Query_RootCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type Query_RootSanityFileAssetArgs = {
  id: Scalars['ID']['input'];
};


export type Query_RootSanityImageAssetArgs = {
  id: Scalars['ID']['input'];
};


export type Query_RootTeamArgs = {
  id: Scalars['ID']['input'];
};


export type Query_RootUpdateArgs = {
  id: Scalars['ID']['input'];
};


export type Query_RootAddressesArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Query_RootAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Query_RootAddresses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootAddresses_Need_InformationsArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Need_Informations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Need_Informations_Order_By>>;
  where?: InputMaybe<Addresses_Need_Informations_Bool_Exp>;
};


export type Query_RootAddresses_Need_Informations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Need_Informations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Need_Informations_Order_By>>;
  where?: InputMaybe<Addresses_Need_Informations_Bool_Exp>;
};


export type Query_RootAddresses_OrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Organizations_Order_By>>;
  where?: InputMaybe<Addresses_Organizations_Bool_Exp>;
};


export type Query_RootAddresses_Organizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Organizations_Order_By>>;
  where?: InputMaybe<Addresses_Organizations_Bool_Exp>;
};


export type Query_RootAddresses_UsersArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Users_Order_By>>;
  where?: InputMaybe<Addresses_Users_Bool_Exp>;
};


export type Query_RootAddresses_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Users_Order_By>>;
  where?: InputMaybe<Addresses_Users_Bool_Exp>;
};


export type Query_RootAliasesArgs = {
  distinct_on?: InputMaybe<Array<Aliases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Aliases_Order_By>>;
  where?: InputMaybe<Aliases_Bool_Exp>;
};


export type Query_RootAliases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aliases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Aliases_Order_By>>;
  where?: InputMaybe<Aliases_Bool_Exp>;
};


export type Query_RootAllAuthorArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuthorSorting>>;
  where?: InputMaybe<AuthorFilter>;
};


export type Query_RootAllCampaignArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CampaignSorting>>;
  where?: InputMaybe<CampaignFilter>;
};


export type Query_RootAllSanityFileAssetArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SanityFileAssetSorting>>;
  where?: InputMaybe<SanityFileAssetFilter>;
};


export type Query_RootAllSanityImageAssetArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SanityImageAssetSorting>>;
  where?: InputMaybe<SanityImageAssetFilter>;
};


export type Query_RootAllTeamArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TeamSorting>>;
  where?: InputMaybe<TeamFilter>;
};


export type Query_RootAllUpdateArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UpdateSorting>>;
  where?: InputMaybe<UpdateFilter>;
};


export type Query_RootCheckout_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Checkout_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Checkout_Intents_Order_By>>;
  where?: InputMaybe<Checkout_Intents_Bool_Exp>;
};


export type Query_RootCheckout_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Checkout_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Checkout_Intents_Order_By>>;
  where?: InputMaybe<Checkout_Intents_Bool_Exp>;
};


export type Query_RootCheckout_Intents_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Query_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Query_RootDevices_By_PkArgs = {
  bundle_id: Scalars['String']['input'];
  local_id: Scalars['uuid']['input'];
};


export type Query_RootFeed_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


export type Query_RootFeed_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


export type Query_RootFeed_Items_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootFeed_PostsArgs = {
  distinct_on?: InputMaybe<Array<Feed_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Posts_Order_By>>;
  where?: InputMaybe<Feed_Posts_Bool_Exp>;
};


export type Query_RootFeed_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feed_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Posts_Order_By>>;
  where?: InputMaybe<Feed_Posts_Bool_Exp>;
};


export type Query_RootFeed_Posts_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootGoalsArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


export type Query_RootGoals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


export type Query_RootGoals_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootInternal_Data_Organization_GoalsArgs = {
  distinct_on?: InputMaybe<Array<Internal_Data_Organization_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Data_Organization_Goals_Order_By>>;
  where?: InputMaybe<Internal_Data_Organization_Goals_Bool_Exp>;
};


export type Query_RootInternal_Data_Organization_Goals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Data_Organization_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Data_Organization_Goals_Order_By>>;
  where?: InputMaybe<Internal_Data_Organization_Goals_Bool_Exp>;
};


export type Query_RootInternal_Data_Organization_Goals_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootList_LocationsArgs = {
  distinct_on?: InputMaybe<Array<List_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Locations_Order_By>>;
  where?: InputMaybe<List_Locations_Bool_Exp>;
};


export type Query_RootList_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Locations_Order_By>>;
  where?: InputMaybe<List_Locations_Bool_Exp>;
};


export type Query_RootList_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<List_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Subscriptions_Order_By>>;
  where?: InputMaybe<List_Subscriptions_Bool_Exp>;
};


export type Query_RootList_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Subscriptions_Order_By>>;
  where?: InputMaybe<List_Subscriptions_Bool_Exp>;
};


export type Query_RootList_Subscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootListsArgs = {
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


export type Query_RootLists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


export type Query_RootLists_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootLists_GeoArgs = {
  args: Lists_Geo_Args;
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


export type Query_RootLists_Geo_AggregateArgs = {
  args: Lists_Geo_Args;
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


export type Query_RootMeArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootMe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootNeed_Fulfill_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Need_Fulfill_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Fulfill_Intents_Order_By>>;
  where?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
};


export type Query_RootNeed_Fulfill_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Fulfill_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Fulfill_Intents_Order_By>>;
  where?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
};


export type Query_RootNeed_Fulfill_Intents_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootNeed_InformationsArgs = {
  distinct_on?: InputMaybe<Array<Need_Informations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Informations_Order_By>>;
  where?: InputMaybe<Need_Informations_Bool_Exp>;
};


export type Query_RootNeed_Informations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Informations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Informations_Order_By>>;
  where?: InputMaybe<Need_Informations_Bool_Exp>;
};


export type Query_RootNeed_Informations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootNeed_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Need_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Locations_Order_By>>;
  where?: InputMaybe<Need_Locations_Bool_Exp>;
};


export type Query_RootNeed_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Locations_Order_By>>;
  where?: InputMaybe<Need_Locations_Bool_Exp>;
};


export type Query_RootNeed_TagsArgs = {
  distinct_on?: InputMaybe<Array<Need_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Tags_Order_By>>;
  where?: InputMaybe<Need_Tags_Bool_Exp>;
};


export type Query_RootNeed_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Tags_Order_By>>;
  where?: InputMaybe<Need_Tags_Bool_Exp>;
};


export type Query_RootNeed_Tags_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootNeedsArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Query_RootNeeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Query_RootNeeds_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootNeeds_GeoArgs = {
  args?: InputMaybe<Needs_Geo_Args>;
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Query_RootNeeds_Geo_AggregateArgs = {
  args?: InputMaybe<Needs_Geo_Args>;
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Query_RootNeeds_ListArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Query_RootNeeds_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Query_RootNeeds_ShuffledArgs = {
  args?: InputMaybe<Needs_Shuffled_Args>;
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Query_RootNeeds_Shuffled_AggregateArgs = {
  args?: InputMaybe<Needs_Shuffled_Args>;
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Query_RootOrganization_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};


export type Query_RootOrganization_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};


export type Query_RootOrganization_StatsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Stats_Order_By>>;
  where?: InputMaybe<Organization_Stats_Bool_Exp>;
};


export type Query_RootOrganization_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Stats_Order_By>>;
  where?: InputMaybe<Organization_Stats_Bool_Exp>;
};


export type Query_RootOrganization_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Subscriptions_Order_By>>;
  where?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
};


export type Query_RootOrganization_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Subscriptions_Order_By>>;
  where?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
};


export type Query_RootOrganization_Subscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootOrganization_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tags_Order_By>>;
  where?: InputMaybe<Organization_Tags_Bool_Exp>;
};


export type Query_RootOrganization_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tags_Order_By>>;
  where?: InputMaybe<Organization_Tags_Bool_Exp>;
};


export type Query_RootOrganization_Tags_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_By_AliasArgs = {
  args: Organizations_By_Alias_Args;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_By_Alias_AggregateArgs = {
  args: Organizations_By_Alias_Args;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootOrganizations_GeoArgs = {
  args: Organizations_Geo_Args;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Query_RootOrganizations_Geo_AggregateArgs = {
  args: Organizations_Geo_Args;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Query_RootParent_OrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Parent_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_Organizations_Order_By>>;
  where?: InputMaybe<Parent_Organizations_Bool_Exp>;
};


export type Query_RootParent_Organizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parent_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_Organizations_Order_By>>;
  where?: InputMaybe<Parent_Organizations_Bool_Exp>;
};


export type Query_RootParent_Organizations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Query_RootPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Query_RootPosts_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootPrivate_Modified_DataArgs = {
  distinct_on?: InputMaybe<Array<Private_Modified_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Private_Modified_Data_Order_By>>;
  where?: InputMaybe<Private_Modified_Data_Bool_Exp>;
};


export type Query_RootPrivate_Modified_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Private_Modified_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Private_Modified_Data_Order_By>>;
  where?: InputMaybe<Private_Modified_Data_Bool_Exp>;
};


export type Query_RootPrivate_Modified_Data_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootSearch_UsersArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootSponsorsArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Order_By>>;
  where?: InputMaybe<Sponsors_Bool_Exp>;
};


export type Query_RootSponsors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Order_By>>;
  where?: InputMaybe<Sponsors_Bool_Exp>;
};


export type Query_RootSponsors_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootSponsorshipsArgs = {
  distinct_on?: InputMaybe<Array<Sponsorships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsorships_Order_By>>;
  where?: InputMaybe<Sponsorships_Bool_Exp>;
};


export type Query_RootSponsorships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sponsorships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsorships_Order_By>>;
  where?: InputMaybe<Sponsorships_Bool_Exp>;
};


export type Query_RootSponsorships_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootStoriesArgs = {
  distinct_on?: InputMaybe<Array<Stories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stories_Order_By>>;
  where?: InputMaybe<Stories_Bool_Exp>;
};


export type Query_RootStories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stories_Order_By>>;
  where?: InputMaybe<Stories_Bool_Exp>;
};


export type Query_RootStories_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootStripe_ChargesArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Charges_Order_By>>;
  where?: InputMaybe<Stripe_Charges_Bool_Exp>;
};


export type Query_RootStripe_Charges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Charges_Order_By>>;
  where?: InputMaybe<Stripe_Charges_Bool_Exp>;
};


export type Query_RootStripe_Charges_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootStripe_Checkout_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Checkout_Sessions_Order_By>>;
  where?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


export type Query_RootStripe_Checkout_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Checkout_Sessions_Order_By>>;
  where?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


export type Query_RootStripe_Checkout_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootStripe_CustomersArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Customers_Order_By>>;
  where?: InputMaybe<Stripe_Customers_Bool_Exp>;
};


export type Query_RootStripe_Customers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Customers_Order_By>>;
  where?: InputMaybe<Stripe_Customers_Bool_Exp>;
};


export type Query_RootStripe_Customers_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootStripe_Invoice_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Invoice_Items_Order_By>>;
  where?: InputMaybe<Stripe_Invoice_Items_Bool_Exp>;
};


export type Query_RootStripe_Invoice_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Invoice_Items_Order_By>>;
  where?: InputMaybe<Stripe_Invoice_Items_Bool_Exp>;
};


export type Query_RootStripe_Invoice_Items_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootStripe_InvoicesArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Invoices_Order_By>>;
  where?: InputMaybe<Stripe_Invoices_Bool_Exp>;
};


export type Query_RootStripe_Invoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Invoices_Order_By>>;
  where?: InputMaybe<Stripe_Invoices_Bool_Exp>;
};


export type Query_RootStripe_Invoices_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootStripe_Payment_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Intents_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Intents_Bool_Exp>;
};


export type Query_RootStripe_Payment_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Intents_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Intents_Bool_Exp>;
};


export type Query_RootStripe_Payment_Intents_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootStripe_Payment_LinksArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Links_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Links_Bool_Exp>;
};


export type Query_RootStripe_Payment_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Links_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Links_Bool_Exp>;
};


export type Query_RootStripe_Payment_Links_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootStripe_Payment_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Methods_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Methods_Bool_Exp>;
};


export type Query_RootStripe_Payment_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Methods_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Methods_Bool_Exp>;
};


export type Query_RootStripe_Payment_Methods_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootStripe_PricesArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Prices_Order_By>>;
  where?: InputMaybe<Stripe_Prices_Bool_Exp>;
};


export type Query_RootStripe_Prices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Prices_Order_By>>;
  where?: InputMaybe<Stripe_Prices_Bool_Exp>;
};


export type Query_RootStripe_Prices_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootStripe_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Products_Order_By>>;
  where?: InputMaybe<Stripe_Products_Bool_Exp>;
};


export type Query_RootStripe_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Products_Order_By>>;
  where?: InputMaybe<Stripe_Products_Bool_Exp>;
};


export type Query_RootStripe_Products_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootStripe_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Subscriptions_Order_By>>;
  where?: InputMaybe<Stripe_Subscriptions_Bool_Exp>;
};


export type Query_RootStripe_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Subscriptions_Order_By>>;
  where?: InputMaybe<Stripe_Subscriptions_Bool_Exp>;
};


export type Query_RootStripe_Subscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTax_ReceiptsArgs = {
  distinct_on?: InputMaybe<Array<Tax_Receipts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tax_Receipts_Order_By>>;
  where?: InputMaybe<Tax_Receipts_Bool_Exp>;
};


export type Query_RootTax_Receipts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tax_Receipts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tax_Receipts_Order_By>>;
  where?: InputMaybe<Tax_Receipts_Bool_Exp>;
};


export type Query_RootTax_Receipts_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootTeam_AvatarsArgs = {
  distinct_on?: InputMaybe<Array<Team_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Avatars_Order_By>>;
  where?: InputMaybe<Team_Avatars_Bool_Exp>;
};


export type Query_RootTeam_Avatars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Avatars_Order_By>>;
  where?: InputMaybe<Team_Avatars_Bool_Exp>;
};


export type Query_RootTeam_MembershipsArgs = {
  distinct_on?: InputMaybe<Array<Team_Memberships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Memberships_Order_By>>;
  where?: InputMaybe<Team_Memberships_Bool_Exp>;
};


export type Query_RootTeam_Memberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Memberships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Memberships_Order_By>>;
  where?: InputMaybe<Team_Memberships_Bool_Exp>;
};


export type Query_RootTeam_Memberships_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootTeam_NeedsArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Order_By>>;
  where?: InputMaybe<Team_Needs_Bool_Exp>;
};


export type Query_RootTeam_Needs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Order_By>>;
  where?: InputMaybe<Team_Needs_Bool_Exp>;
};


export type Query_RootTeam_Needs_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootTeam_Needs_StatsArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Stats_Order_By>>;
  where?: InputMaybe<Team_Needs_Stats_Bool_Exp>;
};


export type Query_RootTeam_Needs_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Stats_Order_By>>;
  where?: InputMaybe<Team_Needs_Stats_Bool_Exp>;
};


export type Query_RootTeam_StatsArgs = {
  distinct_on?: InputMaybe<Array<Team_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Stats_Order_By>>;
  where?: InputMaybe<Team_Stats_Bool_Exp>;
};


export type Query_RootTeam_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Stats_Order_By>>;
  where?: InputMaybe<Team_Stats_Bool_Exp>;
};


export type Query_RootTeam_TagsArgs = {
  distinct_on?: InputMaybe<Array<Team_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Tags_Order_By>>;
  where?: InputMaybe<Team_Tags_Bool_Exp>;
};


export type Query_RootTeam_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Tags_Order_By>>;
  where?: InputMaybe<Team_Tags_Bool_Exp>;
};


export type Query_RootTeam_Tags_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootThank_YousArgs = {
  distinct_on?: InputMaybe<Array<Thank_Yous_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Thank_Yous_Order_By>>;
  where?: InputMaybe<Thank_Yous_Bool_Exp>;
};


export type Query_RootThank_Yous_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Thank_Yous_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Thank_Yous_Order_By>>;
  where?: InputMaybe<Thank_Yous_Bool_Exp>;
};


export type Query_RootThank_Yous_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootUser_GoalsArgs = {
  distinct_on?: InputMaybe<Array<User_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Goals_Order_By>>;
  where?: InputMaybe<User_Goals_Bool_Exp>;
};


export type Query_RootUser_Goals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Goals_Order_By>>;
  where?: InputMaybe<User_Goals_Bool_Exp>;
};


export type Query_RootUser_Needs_Met_StatsArgs = {
  distinct_on?: InputMaybe<Array<User_Needs_Met_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Needs_Met_Stats_Order_By>>;
  where?: InputMaybe<User_Needs_Met_Stats_Bool_Exp>;
};


export type Query_RootUser_Needs_Met_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Needs_Met_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Needs_Met_Stats_Order_By>>;
  where?: InputMaybe<User_Needs_Met_Stats_Bool_Exp>;
};


export type Query_RootUser_StatsArgs = {
  distinct_on?: InputMaybe<Array<User_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Stats_Order_By>>;
  where?: InputMaybe<User_Stats_Bool_Exp>;
};


export type Query_RootUser_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Stats_Order_By>>;
  where?: InputMaybe<User_Stats_Bool_Exp>;
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Search_Users_Args = {
  search?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "sponsors" */
export type Sponsors = {
  abbreviated_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  link?: Maybe<Scalars['String']['output']>;
  logo_content_type?: Maybe<Scalars['String']['output']>;
  logo_file_name?: Maybe<Scalars['String']['output']>;
  logo_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "sponsor_logo_path" */
  logo_path?: Maybe<Scalars['String']['output']>;
  logo_updated_at?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  primary: Scalars['Boolean']['output'];
  /** An array relationship */
  sponsorships: Array<Sponsorships>;
  /** An aggregate relationship */
  sponsorships_aggregate: Sponsorships_Aggregate;
};


/** columns and relationships of "sponsors" */
export type SponsorsSponsorshipsArgs = {
  distinct_on?: InputMaybe<Array<Sponsorships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsorships_Order_By>>;
  where?: InputMaybe<Sponsorships_Bool_Exp>;
};


/** columns and relationships of "sponsors" */
export type SponsorsSponsorships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sponsorships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsorships_Order_By>>;
  where?: InputMaybe<Sponsorships_Bool_Exp>;
};

/** aggregated selection of "sponsors" */
export type Sponsors_Aggregate = {
  aggregate?: Maybe<Sponsors_Aggregate_Fields>;
  nodes: Array<Sponsors>;
};

/** aggregate fields of "sponsors" */
export type Sponsors_Aggregate_Fields = {
  avg?: Maybe<Sponsors_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Sponsors_Max_Fields>;
  min?: Maybe<Sponsors_Min_Fields>;
  stddev?: Maybe<Sponsors_Stddev_Fields>;
  stddev_pop?: Maybe<Sponsors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sponsors_Stddev_Samp_Fields>;
  sum?: Maybe<Sponsors_Sum_Fields>;
  var_pop?: Maybe<Sponsors_Var_Pop_Fields>;
  var_samp?: Maybe<Sponsors_Var_Samp_Fields>;
  variance?: Maybe<Sponsors_Variance_Fields>;
};


/** aggregate fields of "sponsors" */
export type Sponsors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sponsors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Sponsors_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  logo_file_size?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sponsors". All fields are combined with a logical 'AND'. */
export type Sponsors_Bool_Exp = {
  _and?: InputMaybe<Array<Sponsors_Bool_Exp>>;
  _not?: InputMaybe<Sponsors_Bool_Exp>;
  _or?: InputMaybe<Array<Sponsors_Bool_Exp>>;
  abbreviated_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  logo_content_type?: InputMaybe<String_Comparison_Exp>;
  logo_file_name?: InputMaybe<String_Comparison_Exp>;
  logo_file_size?: InputMaybe<Int_Comparison_Exp>;
  logo_path?: InputMaybe<String_Comparison_Exp>;
  logo_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  primary?: InputMaybe<Boolean_Comparison_Exp>;
  sponsorships?: InputMaybe<Sponsorships_Bool_Exp>;
  sponsorships_aggregate?: InputMaybe<Sponsorships_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "sponsors" */
export enum Sponsors_Constraint {
  /** unique or primary key constraint on columns "id" */
  SponsorsPkey = 'sponsors_pkey'
}

/** input type for incrementing numeric columns in table "sponsors" */
export type Sponsors_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  logo_file_size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "sponsors" */
export type Sponsors_Insert_Input = {
  abbreviated_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  logo_content_type?: InputMaybe<Scalars['String']['input']>;
  logo_file_name?: InputMaybe<Scalars['String']['input']>;
  logo_file_size?: InputMaybe<Scalars['Int']['input']>;
  logo_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  sponsorships?: InputMaybe<Sponsorships_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Sponsors_Max_Fields = {
  abbreviated_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  logo_content_type?: Maybe<Scalars['String']['output']>;
  logo_file_name?: Maybe<Scalars['String']['output']>;
  logo_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "sponsor_logo_path" */
  logo_path?: Maybe<Scalars['String']['output']>;
  logo_updated_at?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Sponsors_Min_Fields = {
  abbreviated_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  logo_content_type?: Maybe<Scalars['String']['output']>;
  logo_file_name?: Maybe<Scalars['String']['output']>;
  logo_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "sponsor_logo_path" */
  logo_path?: Maybe<Scalars['String']['output']>;
  logo_updated_at?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "sponsors" */
export type Sponsors_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Sponsors>;
};

/** input type for inserting object relation for remote table "sponsors" */
export type Sponsors_Obj_Rel_Insert_Input = {
  data: Sponsors_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sponsors_On_Conflict>;
};

/** on_conflict condition type for table "sponsors" */
export type Sponsors_On_Conflict = {
  constraint: Sponsors_Constraint;
  update_columns?: Array<Sponsors_Update_Column>;
  where?: InputMaybe<Sponsors_Bool_Exp>;
};

/** Ordering options when selecting data from "sponsors". */
export type Sponsors_Order_By = {
  abbreviated_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  logo_content_type?: InputMaybe<Order_By>;
  logo_file_name?: InputMaybe<Order_By>;
  logo_file_size?: InputMaybe<Order_By>;
  logo_path?: InputMaybe<Order_By>;
  logo_updated_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  primary?: InputMaybe<Order_By>;
  sponsorships_aggregate?: InputMaybe<Sponsorships_Aggregate_Order_By>;
};

/** primary key columns input for table: sponsors */
export type Sponsors_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "sponsors" */
export enum Sponsors_Select_Column {
  /** column name */
  AbbreviatedName = 'abbreviated_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  Link = 'link',
  /** column name */
  LogoContentType = 'logo_content_type',
  /** column name */
  LogoFileName = 'logo_file_name',
  /** column name */
  LogoFileSize = 'logo_file_size',
  /** column name */
  LogoUpdatedAt = 'logo_updated_at',
  /** column name */
  Name = 'name',
  /** column name */
  Primary = 'primary'
}

/** input type for updating data in table "sponsors" */
export type Sponsors_Set_Input = {
  abbreviated_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  logo_content_type?: InputMaybe<Scalars['String']['input']>;
  logo_file_name?: InputMaybe<Scalars['String']['input']>;
  logo_file_size?: InputMaybe<Scalars['Int']['input']>;
  logo_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type Sponsors_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  logo_file_size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Sponsors_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  logo_file_size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Sponsors_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  logo_file_size?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "sponsors" */
export type Sponsors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sponsors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sponsors_Stream_Cursor_Value_Input = {
  abbreviated_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  logo_content_type?: InputMaybe<Scalars['String']['input']>;
  logo_file_name?: InputMaybe<Scalars['String']['input']>;
  logo_file_size?: InputMaybe<Scalars['Int']['input']>;
  logo_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Sponsors_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  logo_file_size?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "sponsors" */
export enum Sponsors_Update_Column {
  /** column name */
  AbbreviatedName = 'abbreviated_name',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  LogoContentType = 'logo_content_type',
  /** column name */
  LogoFileName = 'logo_file_name',
  /** column name */
  LogoFileSize = 'logo_file_size',
  /** column name */
  LogoUpdatedAt = 'logo_updated_at',
  /** column name */
  Name = 'name',
  /** column name */
  Primary = 'primary'
}

export type Sponsors_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sponsors_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sponsors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sponsors_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sponsors_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  logo_file_size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Sponsors_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  logo_file_size?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Sponsors_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  logo_file_size?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "sponsorships" */
export type Sponsorships = {
  id: Scalars['Int']['output'];
  /** An object relationship */
  list?: Maybe<Lists>;
  list_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  sponsor?: Maybe<Sponsors>;
  sponsor_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "sponsorships" */
export type Sponsorships_Aggregate = {
  aggregate?: Maybe<Sponsorships_Aggregate_Fields>;
  nodes: Array<Sponsorships>;
};

export type Sponsorships_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sponsorships_Aggregate_Bool_Exp_Count>;
};

export type Sponsorships_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sponsorships_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sponsorships_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sponsorships" */
export type Sponsorships_Aggregate_Fields = {
  avg?: Maybe<Sponsorships_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Sponsorships_Max_Fields>;
  min?: Maybe<Sponsorships_Min_Fields>;
  stddev?: Maybe<Sponsorships_Stddev_Fields>;
  stddev_pop?: Maybe<Sponsorships_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sponsorships_Stddev_Samp_Fields>;
  sum?: Maybe<Sponsorships_Sum_Fields>;
  var_pop?: Maybe<Sponsorships_Var_Pop_Fields>;
  var_samp?: Maybe<Sponsorships_Var_Samp_Fields>;
  variance?: Maybe<Sponsorships_Variance_Fields>;
};


/** aggregate fields of "sponsorships" */
export type Sponsorships_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sponsorships_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "sponsorships" */
export type Sponsorships_Aggregate_Order_By = {
  avg?: InputMaybe<Sponsorships_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sponsorships_Max_Order_By>;
  min?: InputMaybe<Sponsorships_Min_Order_By>;
  stddev?: InputMaybe<Sponsorships_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sponsorships_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sponsorships_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sponsorships_Sum_Order_By>;
  var_pop?: InputMaybe<Sponsorships_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sponsorships_Var_Samp_Order_By>;
  variance?: InputMaybe<Sponsorships_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sponsorships" */
export type Sponsorships_Arr_Rel_Insert_Input = {
  data: Array<Sponsorships_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sponsorships_On_Conflict>;
};

/** aggregate avg on columns */
export type Sponsorships_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  sponsor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "sponsorships" */
export type Sponsorships_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sponsorships". All fields are combined with a logical 'AND'. */
export type Sponsorships_Bool_Exp = {
  _and?: InputMaybe<Array<Sponsorships_Bool_Exp>>;
  _not?: InputMaybe<Sponsorships_Bool_Exp>;
  _or?: InputMaybe<Array<Sponsorships_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  list?: InputMaybe<Lists_Bool_Exp>;
  list_id?: InputMaybe<Int_Comparison_Exp>;
  sponsor?: InputMaybe<Sponsors_Bool_Exp>;
  sponsor_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "sponsorships" */
export enum Sponsorships_Constraint {
  /** unique or primary key constraint on columns "id" */
  SponsorshipsPkey = 'sponsorships_pkey'
}

/** input type for incrementing numeric columns in table "sponsorships" */
export type Sponsorships_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  sponsor_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "sponsorships" */
export type Sponsorships_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  list?: InputMaybe<Lists_Obj_Rel_Insert_Input>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  sponsor?: InputMaybe<Sponsors_Obj_Rel_Insert_Input>;
  sponsor_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Sponsorships_Max_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  sponsor_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "sponsorships" */
export type Sponsorships_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sponsorships_Min_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  sponsor_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "sponsorships" */
export type Sponsorships_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sponsorships" */
export type Sponsorships_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Sponsorships>;
};

/** input type for inserting object relation for remote table "sponsorships" */
export type Sponsorships_Obj_Rel_Insert_Input = {
  data: Sponsorships_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sponsorships_On_Conflict>;
};

/** on_conflict condition type for table "sponsorships" */
export type Sponsorships_On_Conflict = {
  constraint: Sponsorships_Constraint;
  update_columns?: Array<Sponsorships_Update_Column>;
  where?: InputMaybe<Sponsorships_Bool_Exp>;
};

/** Ordering options when selecting data from "sponsorships". */
export type Sponsorships_Order_By = {
  id?: InputMaybe<Order_By>;
  list?: InputMaybe<Lists_Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor?: InputMaybe<Sponsors_Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sponsorships */
export type Sponsorships_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "sponsorships" */
export enum Sponsorships_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ListId = 'list_id',
  /** column name */
  SponsorId = 'sponsor_id'
}

/** input type for updating data in table "sponsorships" */
export type Sponsorships_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  sponsor_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Sponsorships_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  sponsor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "sponsorships" */
export type Sponsorships_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sponsorships_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  sponsor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "sponsorships" */
export type Sponsorships_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sponsorships_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  sponsor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "sponsorships" */
export type Sponsorships_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sponsorships" */
export type Sponsorships_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sponsorships_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sponsorships_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  sponsor_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Sponsorships_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  sponsor_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "sponsorships" */
export type Sponsorships_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

/** update columns of table "sponsorships" */
export enum Sponsorships_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ListId = 'list_id',
  /** column name */
  SponsorId = 'sponsor_id'
}

export type Sponsorships_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sponsorships_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sponsorships_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sponsorships_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sponsorships_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  sponsor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "sponsorships" */
export type Sponsorships_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sponsorships_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  sponsor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "sponsorships" */
export type Sponsorships_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sponsorships_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  sponsor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "sponsorships" */
export type Sponsorships_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float']['input'];
  from: Scalars['geography']['input'];
  use_spheroid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float']['input'];
  from: Scalars['geometry']['input'];
};

/** columns and relationships of "stories" */
export type Stories = {
  body: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  /** An array relationship */
  feed_items: Array<Feed_Items>;
  /** An aggregate relationship */
  feed_items_aggregate: Feed_Items_Aggregate;
  hero_image_content_type?: Maybe<Scalars['String']['output']>;
  hero_image_file_name?: Maybe<Scalars['String']['output']>;
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "story_hero_image_path" */
  hero_image_path?: Maybe<Scalars['String']['output']>;
  hero_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['Int']['output'];
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  published_date?: Maybe<Scalars['timestamp']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamp']['output'];
};


/** columns and relationships of "stories" */
export type StoriesFeed_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


/** columns and relationships of "stories" */
export type StoriesFeed_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};

/** aggregated selection of "stories" */
export type Stories_Aggregate = {
  aggregate?: Maybe<Stories_Aggregate_Fields>;
  nodes: Array<Stories>;
};

/** aggregate fields of "stories" */
export type Stories_Aggregate_Fields = {
  avg?: Maybe<Stories_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stories_Max_Fields>;
  min?: Maybe<Stories_Min_Fields>;
  stddev?: Maybe<Stories_Stddev_Fields>;
  stddev_pop?: Maybe<Stories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stories_Stddev_Samp_Fields>;
  sum?: Maybe<Stories_Sum_Fields>;
  var_pop?: Maybe<Stories_Var_Pop_Fields>;
  var_samp?: Maybe<Stories_Var_Samp_Fields>;
  variance?: Maybe<Stories_Variance_Fields>;
};


/** aggregate fields of "stories" */
export type Stories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Stories_Avg_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stories". All fields are combined with a logical 'AND'. */
export type Stories_Bool_Exp = {
  _and?: InputMaybe<Array<Stories_Bool_Exp>>;
  _not?: InputMaybe<Stories_Bool_Exp>;
  _or?: InputMaybe<Array<Stories_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  feed_items?: InputMaybe<Feed_Items_Bool_Exp>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Bool_Exp>;
  hero_image_content_type?: InputMaybe<String_Comparison_Exp>;
  hero_image_file_name?: InputMaybe<String_Comparison_Exp>;
  hero_image_file_size?: InputMaybe<Int_Comparison_Exp>;
  hero_image_path?: InputMaybe<String_Comparison_Exp>;
  hero_image_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Numeric_Comparison_Exp>;
  published_date?: InputMaybe<Timestamp_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "stories" */
export enum Stories_Constraint {
  /** unique or primary key constraint on columns "id" */
  StoriesPkey = 'stories_pkey'
}

/** input type for incrementing numeric columns in table "stories" */
export type Stories_Inc_Input = {
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "stories" */
export type Stories_Insert_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  feed_items?: InputMaybe<Feed_Items_Arr_Rel_Insert_Input>;
  hero_image_content_type?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_name?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  hero_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_date?: InputMaybe<Scalars['timestamp']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Stories_Max_Fields = {
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  hero_image_content_type?: Maybe<Scalars['String']['output']>;
  hero_image_file_name?: Maybe<Scalars['String']['output']>;
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "story_hero_image_path" */
  hero_image_path?: Maybe<Scalars['String']['output']>;
  hero_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  published_date?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Stories_Min_Fields = {
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  hero_image_content_type?: Maybe<Scalars['String']['output']>;
  hero_image_file_name?: Maybe<Scalars['String']['output']>;
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "story_hero_image_path" */
  hero_image_path?: Maybe<Scalars['String']['output']>;
  hero_image_updated_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  published_date?: Maybe<Scalars['timestamp']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "stories" */
export type Stories_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stories>;
};

/** input type for inserting object relation for remote table "stories" */
export type Stories_Obj_Rel_Insert_Input = {
  data: Stories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stories_On_Conflict>;
};

/** on_conflict condition type for table "stories" */
export type Stories_On_Conflict = {
  constraint: Stories_Constraint;
  update_columns?: Array<Stories_Update_Column>;
  where?: InputMaybe<Stories_Bool_Exp>;
};

/** Ordering options when selecting data from "stories". */
export type Stories_Order_By = {
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Order_By>;
  hero_image_content_type?: InputMaybe<Order_By>;
  hero_image_file_name?: InputMaybe<Order_By>;
  hero_image_file_size?: InputMaybe<Order_By>;
  hero_image_path?: InputMaybe<Order_By>;
  hero_image_updated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  published_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stories */
export type Stories_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "stories" */
export enum Stories_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HeroImageContentType = 'hero_image_content_type',
  /** column name */
  HeroImageFileName = 'hero_image_file_name',
  /** column name */
  HeroImageFileSize = 'hero_image_file_size',
  /** column name */
  HeroImageUpdatedAt = 'hero_image_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "stories" */
export type Stories_Set_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  hero_image_content_type?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_name?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  hero_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  published_date?: InputMaybe<Scalars['timestamp']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Stories_Stddev_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stories_Stddev_Pop_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stories_Stddev_Samp_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stories" */
export type Stories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stories_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  hero_image_content_type?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_name?: InputMaybe<Scalars['String']['input']>;
  hero_image_file_size?: InputMaybe<Scalars['Int']['input']>;
  hero_image_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  published_date?: InputMaybe<Scalars['timestamp']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Stories_Sum_Fields = {
  hero_image_file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "stories" */
export enum Stories_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HeroImageContentType = 'hero_image_content_type',
  /** column name */
  HeroImageFileName = 'hero_image_file_name',
  /** column name */
  HeroImageFileSize = 'hero_image_file_size',
  /** column name */
  HeroImageUpdatedAt = 'hero_image_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Stories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stories_Var_Pop_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stories_Var_Samp_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stories_Variance_Fields = {
  hero_image_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stripe_charges" */
export type Stripe_Charges = {
  amount?: Maybe<Scalars['bigint']['output']>;
  amount_captured?: Maybe<Scalars['bigint']['output']>;
  amount_refunded?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  application_id?: Maybe<Scalars['String']['output']>;
  balance_transaction_id?: Maybe<Scalars['String']['output']>;
  billing_details_address_city?: Maybe<Scalars['String']['output']>;
  billing_details_address_country?: Maybe<Scalars['String']['output']>;
  billing_details_address_line1?: Maybe<Scalars['String']['output']>;
  billing_details_address_line2?: Maybe<Scalars['String']['output']>;
  billing_details_address_postal_code?: Maybe<Scalars['String']['output']>;
  billing_details_address_state?: Maybe<Scalars['String']['output']>;
  billing_details_email?: Maybe<Scalars['String']['output']>;
  billing_details_name?: Maybe<Scalars['String']['output']>;
  billing_details_phone?: Maybe<Scalars['String']['output']>;
  calculated_statement_descriptor?: Maybe<Scalars['String']['output']>;
  captured?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disputed?: Maybe<Scalars['Boolean']['output']>;
  failure_code?: Maybe<Scalars['String']['output']>;
  failure_message?: Maybe<Scalars['String']['output']>;
  fraud_details_stripe_report?: Maybe<Scalars['String']['output']>;
  fraud_details_user_report?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoice_id?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  outcome_network_status?: Maybe<Scalars['String']['output']>;
  outcome_reason?: Maybe<Scalars['String']['output']>;
  outcome_risk_level?: Maybe<Scalars['String']['output']>;
  outcome_risk_score?: Maybe<Scalars['bigint']['output']>;
  outcome_seller_message?: Maybe<Scalars['String']['output']>;
  outcome_type?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Boolean']['output']>;
  payment_intent_id?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_method_details?: Maybe<Scalars['jsonb']['output']>;
  receipt_email?: Maybe<Scalars['String']['output']>;
  receipt_number?: Maybe<Scalars['String']['output']>;
  receipt_url?: Maybe<Scalars['String']['output']>;
  refunded?: Maybe<Scalars['Boolean']['output']>;
  review_id?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_carrier?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  shipping_tracking_number?: Maybe<Scalars['String']['output']>;
  source_transfer_id?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  transfer_group?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "stripe_charges" */
export type Stripe_ChargesMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_charges" */
export type Stripe_ChargesPayment_Method_DetailsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_charges" */
export type Stripe_Charges_Aggregate = {
  aggregate?: Maybe<Stripe_Charges_Aggregate_Fields>;
  nodes: Array<Stripe_Charges>;
};

export type Stripe_Charges_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Stripe_Charges_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Stripe_Charges_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Stripe_Charges_Aggregate_Bool_Exp_Count>;
};

export type Stripe_Charges_Aggregate_Bool_Exp_Bool_And = {
  arguments: Stripe_Charges_Select_Column_Stripe_Charges_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Stripe_Charges_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Stripe_Charges_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Stripe_Charges_Select_Column_Stripe_Charges_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Stripe_Charges_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Stripe_Charges_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Stripe_Charges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Stripe_Charges_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stripe_charges" */
export type Stripe_Charges_Aggregate_Fields = {
  avg?: Maybe<Stripe_Charges_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Charges_Max_Fields>;
  min?: Maybe<Stripe_Charges_Min_Fields>;
  stddev?: Maybe<Stripe_Charges_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Charges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Charges_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Charges_Sum_Fields>;
  var_pop?: Maybe<Stripe_Charges_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Charges_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Charges_Variance_Fields>;
};


/** aggregate fields of "stripe_charges" */
export type Stripe_Charges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Charges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "stripe_charges" */
export type Stripe_Charges_Aggregate_Order_By = {
  avg?: InputMaybe<Stripe_Charges_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stripe_Charges_Max_Order_By>;
  min?: InputMaybe<Stripe_Charges_Min_Order_By>;
  stddev?: InputMaybe<Stripe_Charges_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stripe_Charges_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stripe_Charges_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stripe_Charges_Sum_Order_By>;
  var_pop?: InputMaybe<Stripe_Charges_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stripe_Charges_Var_Samp_Order_By>;
  variance?: InputMaybe<Stripe_Charges_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Charges_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_details?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "stripe_charges" */
export type Stripe_Charges_Arr_Rel_Insert_Input = {
  data: Array<Stripe_Charges_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Stripe_Charges_On_Conflict>;
};

/** aggregate avg on columns */
export type Stripe_Charges_Avg_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_captured?: Maybe<Scalars['Float']['output']>;
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  outcome_risk_score?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "stripe_charges" */
export type Stripe_Charges_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stripe_charges". All fields are combined with a logical 'AND'. */
export type Stripe_Charges_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Charges_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Charges_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Charges_Bool_Exp>>;
  amount?: InputMaybe<Bigint_Comparison_Exp>;
  amount_captured?: InputMaybe<Bigint_Comparison_Exp>;
  amount_refunded?: InputMaybe<Bigint_Comparison_Exp>;
  application_fee_amount?: InputMaybe<Bigint_Comparison_Exp>;
  application_id?: InputMaybe<String_Comparison_Exp>;
  balance_transaction_id?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_city?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_country?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_line1?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_line2?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_state?: InputMaybe<String_Comparison_Exp>;
  billing_details_email?: InputMaybe<String_Comparison_Exp>;
  billing_details_name?: InputMaybe<String_Comparison_Exp>;
  billing_details_phone?: InputMaybe<String_Comparison_Exp>;
  calculated_statement_descriptor?: InputMaybe<String_Comparison_Exp>;
  captured?: InputMaybe<Boolean_Comparison_Exp>;
  created?: InputMaybe<Bigint_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  disputed?: InputMaybe<Boolean_Comparison_Exp>;
  failure_code?: InputMaybe<String_Comparison_Exp>;
  failure_message?: InputMaybe<String_Comparison_Exp>;
  fraud_details_stripe_report?: InputMaybe<String_Comparison_Exp>;
  fraud_details_user_report?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  on_behalf_of_id?: InputMaybe<String_Comparison_Exp>;
  order_id?: InputMaybe<String_Comparison_Exp>;
  outcome_network_status?: InputMaybe<String_Comparison_Exp>;
  outcome_reason?: InputMaybe<String_Comparison_Exp>;
  outcome_risk_level?: InputMaybe<String_Comparison_Exp>;
  outcome_risk_score?: InputMaybe<Bigint_Comparison_Exp>;
  outcome_seller_message?: InputMaybe<String_Comparison_Exp>;
  outcome_type?: InputMaybe<String_Comparison_Exp>;
  paid?: InputMaybe<Boolean_Comparison_Exp>;
  payment_intent_id?: InputMaybe<String_Comparison_Exp>;
  payment_method?: InputMaybe<String_Comparison_Exp>;
  payment_method_details?: InputMaybe<Jsonb_Comparison_Exp>;
  receipt_email?: InputMaybe<String_Comparison_Exp>;
  receipt_number?: InputMaybe<String_Comparison_Exp>;
  receipt_url?: InputMaybe<String_Comparison_Exp>;
  refunded?: InputMaybe<Boolean_Comparison_Exp>;
  review_id?: InputMaybe<String_Comparison_Exp>;
  shipping_address_city?: InputMaybe<String_Comparison_Exp>;
  shipping_address_country?: InputMaybe<String_Comparison_Exp>;
  shipping_address_line1?: InputMaybe<String_Comparison_Exp>;
  shipping_address_line2?: InputMaybe<String_Comparison_Exp>;
  shipping_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  shipping_address_state?: InputMaybe<String_Comparison_Exp>;
  shipping_carrier?: InputMaybe<String_Comparison_Exp>;
  shipping_name?: InputMaybe<String_Comparison_Exp>;
  shipping_phone?: InputMaybe<String_Comparison_Exp>;
  shipping_tracking_number?: InputMaybe<String_Comparison_Exp>;
  source_transfer_id?: InputMaybe<String_Comparison_Exp>;
  statement_descriptor?: InputMaybe<String_Comparison_Exp>;
  statement_descriptor_suffix?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  transfer_dataamount?: InputMaybe<Bigint_Comparison_Exp>;
  transfer_datadestination_id?: InputMaybe<String_Comparison_Exp>;
  transfer_group?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_charges" */
export enum Stripe_Charges_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripeChargesPkey = 'stripe_charges_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Charges_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  payment_method_details?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Charges_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
  payment_method_details?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Charges_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
  payment_method_details?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_charges" */
export type Stripe_Charges_Inc_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  amount_captured?: InputMaybe<Scalars['bigint']['input']>;
  amount_refunded?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  outcome_risk_score?: InputMaybe<Scalars['bigint']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stripe_charges" */
export type Stripe_Charges_Insert_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  amount_captured?: InputMaybe<Scalars['bigint']['input']>;
  amount_refunded?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  application_id?: InputMaybe<Scalars['String']['input']>;
  balance_transaction_id?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_city?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_country?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line1?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line2?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_state?: InputMaybe<Scalars['String']['input']>;
  billing_details_email?: InputMaybe<Scalars['String']['input']>;
  billing_details_name?: InputMaybe<Scalars['String']['input']>;
  billing_details_phone?: InputMaybe<Scalars['String']['input']>;
  calculated_statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  captured?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disputed?: InputMaybe<Scalars['Boolean']['input']>;
  failure_code?: InputMaybe<Scalars['String']['input']>;
  failure_message?: InputMaybe<Scalars['String']['input']>;
  fraud_details_stripe_report?: InputMaybe<Scalars['String']['input']>;
  fraud_details_user_report?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  outcome_network_status?: InputMaybe<Scalars['String']['input']>;
  outcome_reason?: InputMaybe<Scalars['String']['input']>;
  outcome_risk_level?: InputMaybe<Scalars['String']['input']>;
  outcome_risk_score?: InputMaybe<Scalars['bigint']['input']>;
  outcome_seller_message?: InputMaybe<Scalars['String']['input']>;
  outcome_type?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  payment_intent_id?: InputMaybe<Scalars['String']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  payment_method_details?: InputMaybe<Scalars['jsonb']['input']>;
  receipt_email?: InputMaybe<Scalars['String']['input']>;
  receipt_number?: InputMaybe<Scalars['String']['input']>;
  receipt_url?: InputMaybe<Scalars['String']['input']>;
  refunded?: InputMaybe<Scalars['Boolean']['input']>;
  review_id?: InputMaybe<Scalars['String']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_carrier?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  shipping_tracking_number?: InputMaybe<Scalars['String']['input']>;
  source_transfer_id?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor_suffix?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  transfer_group?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Stripe_Charges_Max_Fields = {
  amount?: Maybe<Scalars['bigint']['output']>;
  amount_captured?: Maybe<Scalars['bigint']['output']>;
  amount_refunded?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  application_id?: Maybe<Scalars['String']['output']>;
  balance_transaction_id?: Maybe<Scalars['String']['output']>;
  billing_details_address_city?: Maybe<Scalars['String']['output']>;
  billing_details_address_country?: Maybe<Scalars['String']['output']>;
  billing_details_address_line1?: Maybe<Scalars['String']['output']>;
  billing_details_address_line2?: Maybe<Scalars['String']['output']>;
  billing_details_address_postal_code?: Maybe<Scalars['String']['output']>;
  billing_details_address_state?: Maybe<Scalars['String']['output']>;
  billing_details_email?: Maybe<Scalars['String']['output']>;
  billing_details_name?: Maybe<Scalars['String']['output']>;
  billing_details_phone?: Maybe<Scalars['String']['output']>;
  calculated_statement_descriptor?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  failure_code?: Maybe<Scalars['String']['output']>;
  failure_message?: Maybe<Scalars['String']['output']>;
  fraud_details_stripe_report?: Maybe<Scalars['String']['output']>;
  fraud_details_user_report?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_id?: Maybe<Scalars['String']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  outcome_network_status?: Maybe<Scalars['String']['output']>;
  outcome_reason?: Maybe<Scalars['String']['output']>;
  outcome_risk_level?: Maybe<Scalars['String']['output']>;
  outcome_risk_score?: Maybe<Scalars['bigint']['output']>;
  outcome_seller_message?: Maybe<Scalars['String']['output']>;
  outcome_type?: Maybe<Scalars['String']['output']>;
  payment_intent_id?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  receipt_email?: Maybe<Scalars['String']['output']>;
  receipt_number?: Maybe<Scalars['String']['output']>;
  receipt_url?: Maybe<Scalars['String']['output']>;
  review_id?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_carrier?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  shipping_tracking_number?: Maybe<Scalars['String']['output']>;
  source_transfer_id?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  transfer_group?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "stripe_charges" */
export type Stripe_Charges_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  application_id?: InputMaybe<Order_By>;
  balance_transaction_id?: InputMaybe<Order_By>;
  billing_details_address_city?: InputMaybe<Order_By>;
  billing_details_address_country?: InputMaybe<Order_By>;
  billing_details_address_line1?: InputMaybe<Order_By>;
  billing_details_address_line2?: InputMaybe<Order_By>;
  billing_details_address_postal_code?: InputMaybe<Order_By>;
  billing_details_address_state?: InputMaybe<Order_By>;
  billing_details_email?: InputMaybe<Order_By>;
  billing_details_name?: InputMaybe<Order_By>;
  billing_details_phone?: InputMaybe<Order_By>;
  calculated_statement_descriptor?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  failure_code?: InputMaybe<Order_By>;
  failure_message?: InputMaybe<Order_By>;
  fraud_details_stripe_report?: InputMaybe<Order_By>;
  fraud_details_user_report?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  on_behalf_of_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  outcome_network_status?: InputMaybe<Order_By>;
  outcome_reason?: InputMaybe<Order_By>;
  outcome_risk_level?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  outcome_seller_message?: InputMaybe<Order_By>;
  outcome_type?: InputMaybe<Order_By>;
  payment_intent_id?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  receipt_email?: InputMaybe<Order_By>;
  receipt_number?: InputMaybe<Order_By>;
  receipt_url?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  shipping_address_city?: InputMaybe<Order_By>;
  shipping_address_country?: InputMaybe<Order_By>;
  shipping_address_line1?: InputMaybe<Order_By>;
  shipping_address_line2?: InputMaybe<Order_By>;
  shipping_address_postal_code?: InputMaybe<Order_By>;
  shipping_address_state?: InputMaybe<Order_By>;
  shipping_carrier?: InputMaybe<Order_By>;
  shipping_name?: InputMaybe<Order_By>;
  shipping_phone?: InputMaybe<Order_By>;
  shipping_tracking_number?: InputMaybe<Order_By>;
  source_transfer_id?: InputMaybe<Order_By>;
  statement_descriptor?: InputMaybe<Order_By>;
  statement_descriptor_suffix?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
  transfer_datadestination_id?: InputMaybe<Order_By>;
  transfer_group?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stripe_Charges_Min_Fields = {
  amount?: Maybe<Scalars['bigint']['output']>;
  amount_captured?: Maybe<Scalars['bigint']['output']>;
  amount_refunded?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  application_id?: Maybe<Scalars['String']['output']>;
  balance_transaction_id?: Maybe<Scalars['String']['output']>;
  billing_details_address_city?: Maybe<Scalars['String']['output']>;
  billing_details_address_country?: Maybe<Scalars['String']['output']>;
  billing_details_address_line1?: Maybe<Scalars['String']['output']>;
  billing_details_address_line2?: Maybe<Scalars['String']['output']>;
  billing_details_address_postal_code?: Maybe<Scalars['String']['output']>;
  billing_details_address_state?: Maybe<Scalars['String']['output']>;
  billing_details_email?: Maybe<Scalars['String']['output']>;
  billing_details_name?: Maybe<Scalars['String']['output']>;
  billing_details_phone?: Maybe<Scalars['String']['output']>;
  calculated_statement_descriptor?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  failure_code?: Maybe<Scalars['String']['output']>;
  failure_message?: Maybe<Scalars['String']['output']>;
  fraud_details_stripe_report?: Maybe<Scalars['String']['output']>;
  fraud_details_user_report?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_id?: Maybe<Scalars['String']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  outcome_network_status?: Maybe<Scalars['String']['output']>;
  outcome_reason?: Maybe<Scalars['String']['output']>;
  outcome_risk_level?: Maybe<Scalars['String']['output']>;
  outcome_risk_score?: Maybe<Scalars['bigint']['output']>;
  outcome_seller_message?: Maybe<Scalars['String']['output']>;
  outcome_type?: Maybe<Scalars['String']['output']>;
  payment_intent_id?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  receipt_email?: Maybe<Scalars['String']['output']>;
  receipt_number?: Maybe<Scalars['String']['output']>;
  receipt_url?: Maybe<Scalars['String']['output']>;
  review_id?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_carrier?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  shipping_tracking_number?: Maybe<Scalars['String']['output']>;
  source_transfer_id?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  transfer_group?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "stripe_charges" */
export type Stripe_Charges_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  application_id?: InputMaybe<Order_By>;
  balance_transaction_id?: InputMaybe<Order_By>;
  billing_details_address_city?: InputMaybe<Order_By>;
  billing_details_address_country?: InputMaybe<Order_By>;
  billing_details_address_line1?: InputMaybe<Order_By>;
  billing_details_address_line2?: InputMaybe<Order_By>;
  billing_details_address_postal_code?: InputMaybe<Order_By>;
  billing_details_address_state?: InputMaybe<Order_By>;
  billing_details_email?: InputMaybe<Order_By>;
  billing_details_name?: InputMaybe<Order_By>;
  billing_details_phone?: InputMaybe<Order_By>;
  calculated_statement_descriptor?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  failure_code?: InputMaybe<Order_By>;
  failure_message?: InputMaybe<Order_By>;
  fraud_details_stripe_report?: InputMaybe<Order_By>;
  fraud_details_user_report?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  on_behalf_of_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  outcome_network_status?: InputMaybe<Order_By>;
  outcome_reason?: InputMaybe<Order_By>;
  outcome_risk_level?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  outcome_seller_message?: InputMaybe<Order_By>;
  outcome_type?: InputMaybe<Order_By>;
  payment_intent_id?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  receipt_email?: InputMaybe<Order_By>;
  receipt_number?: InputMaybe<Order_By>;
  receipt_url?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  shipping_address_city?: InputMaybe<Order_By>;
  shipping_address_country?: InputMaybe<Order_By>;
  shipping_address_line1?: InputMaybe<Order_By>;
  shipping_address_line2?: InputMaybe<Order_By>;
  shipping_address_postal_code?: InputMaybe<Order_By>;
  shipping_address_state?: InputMaybe<Order_By>;
  shipping_carrier?: InputMaybe<Order_By>;
  shipping_name?: InputMaybe<Order_By>;
  shipping_phone?: InputMaybe<Order_By>;
  shipping_tracking_number?: InputMaybe<Order_By>;
  source_transfer_id?: InputMaybe<Order_By>;
  statement_descriptor?: InputMaybe<Order_By>;
  statement_descriptor_suffix?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
  transfer_datadestination_id?: InputMaybe<Order_By>;
  transfer_group?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stripe_charges" */
export type Stripe_Charges_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Charges>;
};

/** on_conflict condition type for table "stripe_charges" */
export type Stripe_Charges_On_Conflict = {
  constraint: Stripe_Charges_Constraint;
  update_columns?: Array<Stripe_Charges_Update_Column>;
  where?: InputMaybe<Stripe_Charges_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_charges". */
export type Stripe_Charges_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  application_id?: InputMaybe<Order_By>;
  balance_transaction_id?: InputMaybe<Order_By>;
  billing_details_address_city?: InputMaybe<Order_By>;
  billing_details_address_country?: InputMaybe<Order_By>;
  billing_details_address_line1?: InputMaybe<Order_By>;
  billing_details_address_line2?: InputMaybe<Order_By>;
  billing_details_address_postal_code?: InputMaybe<Order_By>;
  billing_details_address_state?: InputMaybe<Order_By>;
  billing_details_email?: InputMaybe<Order_By>;
  billing_details_name?: InputMaybe<Order_By>;
  billing_details_phone?: InputMaybe<Order_By>;
  calculated_statement_descriptor?: InputMaybe<Order_By>;
  captured?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  disputed?: InputMaybe<Order_By>;
  failure_code?: InputMaybe<Order_By>;
  failure_message?: InputMaybe<Order_By>;
  fraud_details_stripe_report?: InputMaybe<Order_By>;
  fraud_details_user_report?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  on_behalf_of_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  outcome_network_status?: InputMaybe<Order_By>;
  outcome_reason?: InputMaybe<Order_By>;
  outcome_risk_level?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  outcome_seller_message?: InputMaybe<Order_By>;
  outcome_type?: InputMaybe<Order_By>;
  paid?: InputMaybe<Order_By>;
  payment_intent_id?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  payment_method_details?: InputMaybe<Order_By>;
  receipt_email?: InputMaybe<Order_By>;
  receipt_number?: InputMaybe<Order_By>;
  receipt_url?: InputMaybe<Order_By>;
  refunded?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  shipping_address_city?: InputMaybe<Order_By>;
  shipping_address_country?: InputMaybe<Order_By>;
  shipping_address_line1?: InputMaybe<Order_By>;
  shipping_address_line2?: InputMaybe<Order_By>;
  shipping_address_postal_code?: InputMaybe<Order_By>;
  shipping_address_state?: InputMaybe<Order_By>;
  shipping_carrier?: InputMaybe<Order_By>;
  shipping_name?: InputMaybe<Order_By>;
  shipping_phone?: InputMaybe<Order_By>;
  shipping_tracking_number?: InputMaybe<Order_By>;
  source_transfer_id?: InputMaybe<Order_By>;
  statement_descriptor?: InputMaybe<Order_By>;
  statement_descriptor_suffix?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
  transfer_datadestination_id?: InputMaybe<Order_By>;
  transfer_group?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_charges */
export type Stripe_Charges_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Charges_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_details?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_charges" */
export enum Stripe_Charges_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountCaptured = 'amount_captured',
  /** column name */
  AmountRefunded = 'amount_refunded',
  /** column name */
  ApplicationFeeAmount = 'application_fee_amount',
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  BalanceTransactionId = 'balance_transaction_id',
  /** column name */
  BillingDetailsAddressCity = 'billing_details_address_city',
  /** column name */
  BillingDetailsAddressCountry = 'billing_details_address_country',
  /** column name */
  BillingDetailsAddressLine1 = 'billing_details_address_line1',
  /** column name */
  BillingDetailsAddressLine2 = 'billing_details_address_line2',
  /** column name */
  BillingDetailsAddressPostalCode = 'billing_details_address_postal_code',
  /** column name */
  BillingDetailsAddressState = 'billing_details_address_state',
  /** column name */
  BillingDetailsEmail = 'billing_details_email',
  /** column name */
  BillingDetailsName = 'billing_details_name',
  /** column name */
  BillingDetailsPhone = 'billing_details_phone',
  /** column name */
  CalculatedStatementDescriptor = 'calculated_statement_descriptor',
  /** column name */
  Captured = 'captured',
  /** column name */
  Created = 'created',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Description = 'description',
  /** column name */
  Disputed = 'disputed',
  /** column name */
  FailureCode = 'failure_code',
  /** column name */
  FailureMessage = 'failure_message',
  /** column name */
  FraudDetailsStripeReport = 'fraud_details_stripe_report',
  /** column name */
  FraudDetailsUserReport = 'fraud_details_user_report',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OnBehalfOfId = 'on_behalf_of_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OutcomeNetworkStatus = 'outcome_network_status',
  /** column name */
  OutcomeReason = 'outcome_reason',
  /** column name */
  OutcomeRiskLevel = 'outcome_risk_level',
  /** column name */
  OutcomeRiskScore = 'outcome_risk_score',
  /** column name */
  OutcomeSellerMessage = 'outcome_seller_message',
  /** column name */
  OutcomeType = 'outcome_type',
  /** column name */
  Paid = 'paid',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentMethodDetails = 'payment_method_details',
  /** column name */
  ReceiptEmail = 'receipt_email',
  /** column name */
  ReceiptNumber = 'receipt_number',
  /** column name */
  ReceiptUrl = 'receipt_url',
  /** column name */
  Refunded = 'refunded',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  ShippingAddressCity = 'shipping_address_city',
  /** column name */
  ShippingAddressCountry = 'shipping_address_country',
  /** column name */
  ShippingAddressLine1 = 'shipping_address_line1',
  /** column name */
  ShippingAddressLine2 = 'shipping_address_line2',
  /** column name */
  ShippingAddressPostalCode = 'shipping_address_postal_code',
  /** column name */
  ShippingAddressState = 'shipping_address_state',
  /** column name */
  ShippingCarrier = 'shipping_carrier',
  /** column name */
  ShippingName = 'shipping_name',
  /** column name */
  ShippingPhone = 'shipping_phone',
  /** column name */
  ShippingTrackingNumber = 'shipping_tracking_number',
  /** column name */
  SourceTransferId = 'source_transfer_id',
  /** column name */
  StatementDescriptor = 'statement_descriptor',
  /** column name */
  StatementDescriptorSuffix = 'statement_descriptor_suffix',
  /** column name */
  Status = 'status',
  /** column name */
  TransferDataamount = 'transfer_dataamount',
  /** column name */
  TransferDatadestinationId = 'transfer_datadestination_id',
  /** column name */
  TransferGroup = 'transfer_group'
}

/** select "stripe_charges_aggregate_bool_exp_bool_and_arguments_columns" columns of table "stripe_charges" */
export enum Stripe_Charges_Select_Column_Stripe_Charges_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Captured = 'captured',
  /** column name */
  Disputed = 'disputed',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Paid = 'paid',
  /** column name */
  Refunded = 'refunded'
}

/** select "stripe_charges_aggregate_bool_exp_bool_or_arguments_columns" columns of table "stripe_charges" */
export enum Stripe_Charges_Select_Column_Stripe_Charges_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Captured = 'captured',
  /** column name */
  Disputed = 'disputed',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Paid = 'paid',
  /** column name */
  Refunded = 'refunded'
}

/** input type for updating data in table "stripe_charges" */
export type Stripe_Charges_Set_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  amount_captured?: InputMaybe<Scalars['bigint']['input']>;
  amount_refunded?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  application_id?: InputMaybe<Scalars['String']['input']>;
  balance_transaction_id?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_city?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_country?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line1?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line2?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_state?: InputMaybe<Scalars['String']['input']>;
  billing_details_email?: InputMaybe<Scalars['String']['input']>;
  billing_details_name?: InputMaybe<Scalars['String']['input']>;
  billing_details_phone?: InputMaybe<Scalars['String']['input']>;
  calculated_statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  captured?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disputed?: InputMaybe<Scalars['Boolean']['input']>;
  failure_code?: InputMaybe<Scalars['String']['input']>;
  failure_message?: InputMaybe<Scalars['String']['input']>;
  fraud_details_stripe_report?: InputMaybe<Scalars['String']['input']>;
  fraud_details_user_report?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  outcome_network_status?: InputMaybe<Scalars['String']['input']>;
  outcome_reason?: InputMaybe<Scalars['String']['input']>;
  outcome_risk_level?: InputMaybe<Scalars['String']['input']>;
  outcome_risk_score?: InputMaybe<Scalars['bigint']['input']>;
  outcome_seller_message?: InputMaybe<Scalars['String']['input']>;
  outcome_type?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  payment_intent_id?: InputMaybe<Scalars['String']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  payment_method_details?: InputMaybe<Scalars['jsonb']['input']>;
  receipt_email?: InputMaybe<Scalars['String']['input']>;
  receipt_number?: InputMaybe<Scalars['String']['input']>;
  receipt_url?: InputMaybe<Scalars['String']['input']>;
  refunded?: InputMaybe<Scalars['Boolean']['input']>;
  review_id?: InputMaybe<Scalars['String']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_carrier?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  shipping_tracking_number?: InputMaybe<Scalars['String']['input']>;
  source_transfer_id?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor_suffix?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  transfer_group?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Charges_Stddev_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_captured?: Maybe<Scalars['Float']['output']>;
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  outcome_risk_score?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "stripe_charges" */
export type Stripe_Charges_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Charges_Stddev_Pop_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_captured?: Maybe<Scalars['Float']['output']>;
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  outcome_risk_score?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "stripe_charges" */
export type Stripe_Charges_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Charges_Stddev_Samp_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_captured?: Maybe<Scalars['Float']['output']>;
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  outcome_risk_score?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "stripe_charges" */
export type Stripe_Charges_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "stripe_charges" */
export type Stripe_Charges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Charges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Charges_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  amount_captured?: InputMaybe<Scalars['bigint']['input']>;
  amount_refunded?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  application_id?: InputMaybe<Scalars['String']['input']>;
  balance_transaction_id?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_city?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_country?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line1?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line2?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_state?: InputMaybe<Scalars['String']['input']>;
  billing_details_email?: InputMaybe<Scalars['String']['input']>;
  billing_details_name?: InputMaybe<Scalars['String']['input']>;
  billing_details_phone?: InputMaybe<Scalars['String']['input']>;
  calculated_statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  captured?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disputed?: InputMaybe<Scalars['Boolean']['input']>;
  failure_code?: InputMaybe<Scalars['String']['input']>;
  failure_message?: InputMaybe<Scalars['String']['input']>;
  fraud_details_stripe_report?: InputMaybe<Scalars['String']['input']>;
  fraud_details_user_report?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  order_id?: InputMaybe<Scalars['String']['input']>;
  outcome_network_status?: InputMaybe<Scalars['String']['input']>;
  outcome_reason?: InputMaybe<Scalars['String']['input']>;
  outcome_risk_level?: InputMaybe<Scalars['String']['input']>;
  outcome_risk_score?: InputMaybe<Scalars['bigint']['input']>;
  outcome_seller_message?: InputMaybe<Scalars['String']['input']>;
  outcome_type?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  payment_intent_id?: InputMaybe<Scalars['String']['input']>;
  payment_method?: InputMaybe<Scalars['String']['input']>;
  payment_method_details?: InputMaybe<Scalars['jsonb']['input']>;
  receipt_email?: InputMaybe<Scalars['String']['input']>;
  receipt_number?: InputMaybe<Scalars['String']['input']>;
  receipt_url?: InputMaybe<Scalars['String']['input']>;
  refunded?: InputMaybe<Scalars['Boolean']['input']>;
  review_id?: InputMaybe<Scalars['String']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_carrier?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  shipping_tracking_number?: InputMaybe<Scalars['String']['input']>;
  source_transfer_id?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor_suffix?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  transfer_group?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Charges_Sum_Fields = {
  amount?: Maybe<Scalars['bigint']['output']>;
  amount_captured?: Maybe<Scalars['bigint']['output']>;
  amount_refunded?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  outcome_risk_score?: Maybe<Scalars['bigint']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "stripe_charges" */
export type Stripe_Charges_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
};

/** update columns of table "stripe_charges" */
export enum Stripe_Charges_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountCaptured = 'amount_captured',
  /** column name */
  AmountRefunded = 'amount_refunded',
  /** column name */
  ApplicationFeeAmount = 'application_fee_amount',
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  BalanceTransactionId = 'balance_transaction_id',
  /** column name */
  BillingDetailsAddressCity = 'billing_details_address_city',
  /** column name */
  BillingDetailsAddressCountry = 'billing_details_address_country',
  /** column name */
  BillingDetailsAddressLine1 = 'billing_details_address_line1',
  /** column name */
  BillingDetailsAddressLine2 = 'billing_details_address_line2',
  /** column name */
  BillingDetailsAddressPostalCode = 'billing_details_address_postal_code',
  /** column name */
  BillingDetailsAddressState = 'billing_details_address_state',
  /** column name */
  BillingDetailsEmail = 'billing_details_email',
  /** column name */
  BillingDetailsName = 'billing_details_name',
  /** column name */
  BillingDetailsPhone = 'billing_details_phone',
  /** column name */
  CalculatedStatementDescriptor = 'calculated_statement_descriptor',
  /** column name */
  Captured = 'captured',
  /** column name */
  Created = 'created',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Description = 'description',
  /** column name */
  Disputed = 'disputed',
  /** column name */
  FailureCode = 'failure_code',
  /** column name */
  FailureMessage = 'failure_message',
  /** column name */
  FraudDetailsStripeReport = 'fraud_details_stripe_report',
  /** column name */
  FraudDetailsUserReport = 'fraud_details_user_report',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OnBehalfOfId = 'on_behalf_of_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OutcomeNetworkStatus = 'outcome_network_status',
  /** column name */
  OutcomeReason = 'outcome_reason',
  /** column name */
  OutcomeRiskLevel = 'outcome_risk_level',
  /** column name */
  OutcomeRiskScore = 'outcome_risk_score',
  /** column name */
  OutcomeSellerMessage = 'outcome_seller_message',
  /** column name */
  OutcomeType = 'outcome_type',
  /** column name */
  Paid = 'paid',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentMethodDetails = 'payment_method_details',
  /** column name */
  ReceiptEmail = 'receipt_email',
  /** column name */
  ReceiptNumber = 'receipt_number',
  /** column name */
  ReceiptUrl = 'receipt_url',
  /** column name */
  Refunded = 'refunded',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  ShippingAddressCity = 'shipping_address_city',
  /** column name */
  ShippingAddressCountry = 'shipping_address_country',
  /** column name */
  ShippingAddressLine1 = 'shipping_address_line1',
  /** column name */
  ShippingAddressLine2 = 'shipping_address_line2',
  /** column name */
  ShippingAddressPostalCode = 'shipping_address_postal_code',
  /** column name */
  ShippingAddressState = 'shipping_address_state',
  /** column name */
  ShippingCarrier = 'shipping_carrier',
  /** column name */
  ShippingName = 'shipping_name',
  /** column name */
  ShippingPhone = 'shipping_phone',
  /** column name */
  ShippingTrackingNumber = 'shipping_tracking_number',
  /** column name */
  SourceTransferId = 'source_transfer_id',
  /** column name */
  StatementDescriptor = 'statement_descriptor',
  /** column name */
  StatementDescriptorSuffix = 'statement_descriptor_suffix',
  /** column name */
  Status = 'status',
  /** column name */
  TransferDataamount = 'transfer_dataamount',
  /** column name */
  TransferDatadestinationId = 'transfer_datadestination_id',
  /** column name */
  TransferGroup = 'transfer_group'
}

export type Stripe_Charges_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Charges_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Charges_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Charges_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Charges_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Charges_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Charges_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Charges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Charges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Charges_Var_Pop_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_captured?: Maybe<Scalars['Float']['output']>;
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  outcome_risk_score?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "stripe_charges" */
export type Stripe_Charges_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stripe_Charges_Var_Samp_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_captured?: Maybe<Scalars['Float']['output']>;
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  outcome_risk_score?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "stripe_charges" */
export type Stripe_Charges_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stripe_Charges_Variance_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_captured?: Maybe<Scalars['Float']['output']>;
  amount_refunded?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  outcome_risk_score?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "stripe_charges" */
export type Stripe_Charges_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_captured?: InputMaybe<Order_By>;
  amount_refunded?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  outcome_risk_score?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
};

/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions = {
  after_expiration?: Maybe<Scalars['jsonb']['output']>;
  allow_promotion_codes?: Maybe<Scalars['Boolean']['output']>;
  amount_subtotal?: Maybe<Scalars['bigint']['output']>;
  amount_total?: Maybe<Scalars['bigint']['output']>;
  automatic_tax_enabled?: Maybe<Scalars['Boolean']['output']>;
  automatic_tax_status?: Maybe<Scalars['String']['output']>;
  billing_address_collection?: Maybe<Scalars['String']['output']>;
  cancel_url?: Maybe<Scalars['String']['output']>;
  client_reference_id?: Maybe<Scalars['String']['output']>;
  consent?: Maybe<Scalars['jsonb']['output']>;
  consent_collection?: Maybe<Scalars['jsonb']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  customer?: Maybe<Stripe_Customers>;
  customer_creation?: Maybe<Scalars['String']['output']>;
  customer_details?: Maybe<Scalars['jsonb']['output']>;
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['String']['output'];
  livemode?: Maybe<Scalars['Boolean']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  mode?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  payment_intent?: Maybe<Stripe_Payment_Intents>;
  payment_intent_id?: Maybe<Scalars['String']['output']>;
  payment_link_id?: Maybe<Scalars['String']['output']>;
  payment_method_options?: Maybe<Scalars['jsonb']['output']>;
  payment_method_types?: Maybe<Scalars['jsonb']['output']>;
  payment_status?: Maybe<Scalars['String']['output']>;
  phone_number_collection_enabled?: Maybe<Scalars['Boolean']['output']>;
  recovered_from?: Maybe<Scalars['String']['output']>;
  setup_intent_id?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_collection?: Maybe<Scalars['jsonb']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_carrier?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_options?: Maybe<Scalars['jsonb']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  shipping_rate_id?: Maybe<Scalars['String']['output']>;
  shipping_tracking_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  submit_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  subscription?: Maybe<Stripe_Subscriptions>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  success_url?: Maybe<Scalars['String']['output']>;
  total_details?: Maybe<Scalars['jsonb']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Users>;
};


/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_SessionsAfter_ExpirationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_SessionsConsentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_SessionsConsent_CollectionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_SessionsCustomer_DetailsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_SessionsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_SessionsPayment_Method_OptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_SessionsPayment_Method_TypesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_SessionsShipping_Address_CollectionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_SessionsShipping_OptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_checkout_sessions" */
export type Stripe_Checkout_SessionsTotal_DetailsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Aggregate = {
  aggregate?: Maybe<Stripe_Checkout_Sessions_Aggregate_Fields>;
  nodes: Array<Stripe_Checkout_Sessions>;
};

export type Stripe_Checkout_Sessions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Stripe_Checkout_Sessions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Stripe_Checkout_Sessions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Stripe_Checkout_Sessions_Aggregate_Bool_Exp_Count>;
};

export type Stripe_Checkout_Sessions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Stripe_Checkout_Sessions_Select_Column_Stripe_Checkout_Sessions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Stripe_Checkout_Sessions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Stripe_Checkout_Sessions_Select_Column_Stripe_Checkout_Sessions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Stripe_Checkout_Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Aggregate_Fields = {
  avg?: Maybe<Stripe_Checkout_Sessions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Checkout_Sessions_Max_Fields>;
  min?: Maybe<Stripe_Checkout_Sessions_Min_Fields>;
  stddev?: Maybe<Stripe_Checkout_Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Checkout_Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Checkout_Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Checkout_Sessions_Sum_Fields>;
  var_pop?: Maybe<Stripe_Checkout_Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Checkout_Sessions_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Checkout_Sessions_Variance_Fields>;
};


/** aggregate fields of "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Aggregate_Order_By = {
  avg?: InputMaybe<Stripe_Checkout_Sessions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stripe_Checkout_Sessions_Max_Order_By>;
  min?: InputMaybe<Stripe_Checkout_Sessions_Min_Order_By>;
  stddev?: InputMaybe<Stripe_Checkout_Sessions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stripe_Checkout_Sessions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stripe_Checkout_Sessions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stripe_Checkout_Sessions_Sum_Order_By>;
  var_pop?: InputMaybe<Stripe_Checkout_Sessions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stripe_Checkout_Sessions_Var_Samp_Order_By>;
  variance?: InputMaybe<Stripe_Checkout_Sessions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Checkout_Sessions_Append_Input = {
  after_expiration?: InputMaybe<Scalars['jsonb']['input']>;
  consent?: InputMaybe<Scalars['jsonb']['input']>;
  consent_collection?: InputMaybe<Scalars['jsonb']['input']>;
  customer_details?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_options?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_options?: InputMaybe<Scalars['jsonb']['input']>;
  total_details?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Stripe_Checkout_Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Stripe_Checkout_Sessions_On_Conflict>;
};

/** aggregate avg on columns */
export type Stripe_Checkout_Sessions_Avg_Fields = {
  amount_subtotal?: Maybe<Scalars['Float']['output']>;
  amount_total?: Maybe<Scalars['Float']['output']>;
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Avg_Order_By = {
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stripe_checkout_sessions". All fields are combined with a logical 'AND'. */
export type Stripe_Checkout_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Checkout_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Checkout_Sessions_Bool_Exp>>;
  after_expiration?: InputMaybe<Jsonb_Comparison_Exp>;
  allow_promotion_codes?: InputMaybe<Boolean_Comparison_Exp>;
  amount_subtotal?: InputMaybe<Bigint_Comparison_Exp>;
  amount_total?: InputMaybe<Bigint_Comparison_Exp>;
  automatic_tax_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  automatic_tax_status?: InputMaybe<String_Comparison_Exp>;
  billing_address_collection?: InputMaybe<String_Comparison_Exp>;
  cancel_url?: InputMaybe<String_Comparison_Exp>;
  client_reference_id?: InputMaybe<String_Comparison_Exp>;
  consent?: InputMaybe<Jsonb_Comparison_Exp>;
  consent_collection?: InputMaybe<Jsonb_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  customer?: InputMaybe<Stripe_Customers_Bool_Exp>;
  customer_creation?: InputMaybe<String_Comparison_Exp>;
  customer_details?: InputMaybe<Jsonb_Comparison_Exp>;
  customer_email?: InputMaybe<String_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  expires_at?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  mode?: InputMaybe<String_Comparison_Exp>;
  payment_intent?: InputMaybe<Stripe_Payment_Intents_Bool_Exp>;
  payment_intent_id?: InputMaybe<String_Comparison_Exp>;
  payment_link_id?: InputMaybe<String_Comparison_Exp>;
  payment_method_options?: InputMaybe<Jsonb_Comparison_Exp>;
  payment_method_types?: InputMaybe<Jsonb_Comparison_Exp>;
  payment_status?: InputMaybe<String_Comparison_Exp>;
  phone_number_collection_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  recovered_from?: InputMaybe<String_Comparison_Exp>;
  setup_intent_id?: InputMaybe<String_Comparison_Exp>;
  shipping_address_city?: InputMaybe<String_Comparison_Exp>;
  shipping_address_collection?: InputMaybe<Jsonb_Comparison_Exp>;
  shipping_address_country?: InputMaybe<String_Comparison_Exp>;
  shipping_address_line1?: InputMaybe<String_Comparison_Exp>;
  shipping_address_line2?: InputMaybe<String_Comparison_Exp>;
  shipping_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  shipping_address_state?: InputMaybe<String_Comparison_Exp>;
  shipping_carrier?: InputMaybe<String_Comparison_Exp>;
  shipping_name?: InputMaybe<String_Comparison_Exp>;
  shipping_options?: InputMaybe<Jsonb_Comparison_Exp>;
  shipping_phone?: InputMaybe<String_Comparison_Exp>;
  shipping_rate_id?: InputMaybe<String_Comparison_Exp>;
  shipping_tracking_number?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  submit_type?: InputMaybe<String_Comparison_Exp>;
  subscription?: InputMaybe<Stripe_Subscriptions_Bool_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  success_url?: InputMaybe<String_Comparison_Exp>;
  total_details?: InputMaybe<Jsonb_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_checkout_sessions" */
export enum Stripe_Checkout_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripeCheckoutSessionsPkey = 'stripe_checkout_sessions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Checkout_Sessions_Delete_At_Path_Input = {
  after_expiration?: InputMaybe<Array<Scalars['String']['input']>>;
  consent?: InputMaybe<Array<Scalars['String']['input']>>;
  consent_collection?: InputMaybe<Array<Scalars['String']['input']>>;
  customer_details?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  payment_method_options?: InputMaybe<Array<Scalars['String']['input']>>;
  payment_method_types?: InputMaybe<Array<Scalars['String']['input']>>;
  shipping_address_collection?: InputMaybe<Array<Scalars['String']['input']>>;
  shipping_options?: InputMaybe<Array<Scalars['String']['input']>>;
  total_details?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Checkout_Sessions_Delete_Elem_Input = {
  after_expiration?: InputMaybe<Scalars['Int']['input']>;
  consent?: InputMaybe<Scalars['Int']['input']>;
  consent_collection?: InputMaybe<Scalars['Int']['input']>;
  customer_details?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  payment_method_options?: InputMaybe<Scalars['Int']['input']>;
  payment_method_types?: InputMaybe<Scalars['Int']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['Int']['input']>;
  shipping_options?: InputMaybe<Scalars['Int']['input']>;
  total_details?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Checkout_Sessions_Delete_Key_Input = {
  after_expiration?: InputMaybe<Scalars['String']['input']>;
  consent?: InputMaybe<Scalars['String']['input']>;
  consent_collection?: InputMaybe<Scalars['String']['input']>;
  customer_details?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  payment_method_options?: InputMaybe<Scalars['String']['input']>;
  payment_method_types?: InputMaybe<Scalars['String']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['String']['input']>;
  shipping_options?: InputMaybe<Scalars['String']['input']>;
  total_details?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Inc_Input = {
  amount_subtotal?: InputMaybe<Scalars['bigint']['input']>;
  amount_total?: InputMaybe<Scalars['bigint']['input']>;
  expires_at?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Insert_Input = {
  after_expiration?: InputMaybe<Scalars['jsonb']['input']>;
  allow_promotion_codes?: InputMaybe<Scalars['Boolean']['input']>;
  amount_subtotal?: InputMaybe<Scalars['bigint']['input']>;
  amount_total?: InputMaybe<Scalars['bigint']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_tax_status?: InputMaybe<Scalars['String']['input']>;
  billing_address_collection?: InputMaybe<Scalars['String']['input']>;
  cancel_url?: InputMaybe<Scalars['String']['input']>;
  client_reference_id?: InputMaybe<Scalars['String']['input']>;
  consent?: InputMaybe<Scalars['jsonb']['input']>;
  consent_collection?: InputMaybe<Scalars['jsonb']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Stripe_Customers_Obj_Rel_Insert_Input>;
  customer_creation?: InputMaybe<Scalars['String']['input']>;
  customer_details?: InputMaybe<Scalars['jsonb']['input']>;
  customer_email?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  payment_intent?: InputMaybe<Stripe_Payment_Intents_Obj_Rel_Insert_Input>;
  payment_intent_id?: InputMaybe<Scalars['String']['input']>;
  payment_link_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_options?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  payment_status?: InputMaybe<Scalars['String']['input']>;
  phone_number_collection_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  recovered_from?: InputMaybe<Scalars['String']['input']>;
  setup_intent_id?: InputMaybe<Scalars['String']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_carrier?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_options?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  shipping_rate_id?: InputMaybe<Scalars['String']['input']>;
  shipping_tracking_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  submit_type?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<Stripe_Subscriptions_Obj_Rel_Insert_Input>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  success_url?: InputMaybe<Scalars['String']['input']>;
  total_details?: InputMaybe<Scalars['jsonb']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Stripe_Checkout_Sessions_Max_Fields = {
  amount_subtotal?: Maybe<Scalars['bigint']['output']>;
  amount_total?: Maybe<Scalars['bigint']['output']>;
  automatic_tax_status?: Maybe<Scalars['String']['output']>;
  billing_address_collection?: Maybe<Scalars['String']['output']>;
  cancel_url?: Maybe<Scalars['String']['output']>;
  client_reference_id?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_creation?: Maybe<Scalars['String']['output']>;
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  mode?: Maybe<Scalars['String']['output']>;
  payment_intent_id?: Maybe<Scalars['String']['output']>;
  payment_link_id?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['String']['output']>;
  recovered_from?: Maybe<Scalars['String']['output']>;
  setup_intent_id?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_carrier?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  shipping_rate_id?: Maybe<Scalars['String']['output']>;
  shipping_tracking_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  submit_type?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  success_url?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Max_Order_By = {
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  automatic_tax_status?: InputMaybe<Order_By>;
  billing_address_collection?: InputMaybe<Order_By>;
  cancel_url?: InputMaybe<Order_By>;
  client_reference_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer_creation?: InputMaybe<Order_By>;
  customer_email?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  mode?: InputMaybe<Order_By>;
  payment_intent_id?: InputMaybe<Order_By>;
  payment_link_id?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  recovered_from?: InputMaybe<Order_By>;
  setup_intent_id?: InputMaybe<Order_By>;
  shipping_address_city?: InputMaybe<Order_By>;
  shipping_address_country?: InputMaybe<Order_By>;
  shipping_address_line1?: InputMaybe<Order_By>;
  shipping_address_line2?: InputMaybe<Order_By>;
  shipping_address_postal_code?: InputMaybe<Order_By>;
  shipping_address_state?: InputMaybe<Order_By>;
  shipping_carrier?: InputMaybe<Order_By>;
  shipping_name?: InputMaybe<Order_By>;
  shipping_phone?: InputMaybe<Order_By>;
  shipping_rate_id?: InputMaybe<Order_By>;
  shipping_tracking_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  submit_type?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  success_url?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stripe_Checkout_Sessions_Min_Fields = {
  amount_subtotal?: Maybe<Scalars['bigint']['output']>;
  amount_total?: Maybe<Scalars['bigint']['output']>;
  automatic_tax_status?: Maybe<Scalars['String']['output']>;
  billing_address_collection?: Maybe<Scalars['String']['output']>;
  cancel_url?: Maybe<Scalars['String']['output']>;
  client_reference_id?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_creation?: Maybe<Scalars['String']['output']>;
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  expires_at?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  mode?: Maybe<Scalars['String']['output']>;
  payment_intent_id?: Maybe<Scalars['String']['output']>;
  payment_link_id?: Maybe<Scalars['String']['output']>;
  payment_status?: Maybe<Scalars['String']['output']>;
  recovered_from?: Maybe<Scalars['String']['output']>;
  setup_intent_id?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_carrier?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  shipping_rate_id?: Maybe<Scalars['String']['output']>;
  shipping_tracking_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  submit_type?: Maybe<Scalars['String']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  success_url?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Min_Order_By = {
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  automatic_tax_status?: InputMaybe<Order_By>;
  billing_address_collection?: InputMaybe<Order_By>;
  cancel_url?: InputMaybe<Order_By>;
  client_reference_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer_creation?: InputMaybe<Order_By>;
  customer_email?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  mode?: InputMaybe<Order_By>;
  payment_intent_id?: InputMaybe<Order_By>;
  payment_link_id?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  recovered_from?: InputMaybe<Order_By>;
  setup_intent_id?: InputMaybe<Order_By>;
  shipping_address_city?: InputMaybe<Order_By>;
  shipping_address_country?: InputMaybe<Order_By>;
  shipping_address_line1?: InputMaybe<Order_By>;
  shipping_address_line2?: InputMaybe<Order_By>;
  shipping_address_postal_code?: InputMaybe<Order_By>;
  shipping_address_state?: InputMaybe<Order_By>;
  shipping_carrier?: InputMaybe<Order_By>;
  shipping_name?: InputMaybe<Order_By>;
  shipping_phone?: InputMaybe<Order_By>;
  shipping_rate_id?: InputMaybe<Order_By>;
  shipping_tracking_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  submit_type?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  success_url?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Checkout_Sessions>;
};

/** on_conflict condition type for table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_On_Conflict = {
  constraint: Stripe_Checkout_Sessions_Constraint;
  update_columns?: Array<Stripe_Checkout_Sessions_Update_Column>;
  where?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_checkout_sessions". */
export type Stripe_Checkout_Sessions_Order_By = {
  after_expiration?: InputMaybe<Order_By>;
  allow_promotion_codes?: InputMaybe<Order_By>;
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  automatic_tax_enabled?: InputMaybe<Order_By>;
  automatic_tax_status?: InputMaybe<Order_By>;
  billing_address_collection?: InputMaybe<Order_By>;
  cancel_url?: InputMaybe<Order_By>;
  client_reference_id?: InputMaybe<Order_By>;
  consent?: InputMaybe<Order_By>;
  consent_collection?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer?: InputMaybe<Stripe_Customers_Order_By>;
  customer_creation?: InputMaybe<Order_By>;
  customer_details?: InputMaybe<Order_By>;
  customer_email?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  mode?: InputMaybe<Order_By>;
  payment_intent?: InputMaybe<Stripe_Payment_Intents_Order_By>;
  payment_intent_id?: InputMaybe<Order_By>;
  payment_link_id?: InputMaybe<Order_By>;
  payment_method_options?: InputMaybe<Order_By>;
  payment_method_types?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  phone_number_collection_enabled?: InputMaybe<Order_By>;
  recovered_from?: InputMaybe<Order_By>;
  setup_intent_id?: InputMaybe<Order_By>;
  shipping_address_city?: InputMaybe<Order_By>;
  shipping_address_collection?: InputMaybe<Order_By>;
  shipping_address_country?: InputMaybe<Order_By>;
  shipping_address_line1?: InputMaybe<Order_By>;
  shipping_address_line2?: InputMaybe<Order_By>;
  shipping_address_postal_code?: InputMaybe<Order_By>;
  shipping_address_state?: InputMaybe<Order_By>;
  shipping_carrier?: InputMaybe<Order_By>;
  shipping_name?: InputMaybe<Order_By>;
  shipping_options?: InputMaybe<Order_By>;
  shipping_phone?: InputMaybe<Order_By>;
  shipping_rate_id?: InputMaybe<Order_By>;
  shipping_tracking_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  submit_type?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Stripe_Subscriptions_Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  success_url?: InputMaybe<Order_By>;
  total_details?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_checkout_sessions */
export type Stripe_Checkout_Sessions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Checkout_Sessions_Prepend_Input = {
  after_expiration?: InputMaybe<Scalars['jsonb']['input']>;
  consent?: InputMaybe<Scalars['jsonb']['input']>;
  consent_collection?: InputMaybe<Scalars['jsonb']['input']>;
  customer_details?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_options?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_options?: InputMaybe<Scalars['jsonb']['input']>;
  total_details?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_checkout_sessions" */
export enum Stripe_Checkout_Sessions_Select_Column {
  /** column name */
  AfterExpiration = 'after_expiration',
  /** column name */
  AllowPromotionCodes = 'allow_promotion_codes',
  /** column name */
  AmountSubtotal = 'amount_subtotal',
  /** column name */
  AmountTotal = 'amount_total',
  /** column name */
  AutomaticTaxEnabled = 'automatic_tax_enabled',
  /** column name */
  AutomaticTaxStatus = 'automatic_tax_status',
  /** column name */
  BillingAddressCollection = 'billing_address_collection',
  /** column name */
  CancelUrl = 'cancel_url',
  /** column name */
  ClientReferenceId = 'client_reference_id',
  /** column name */
  Consent = 'consent',
  /** column name */
  ConsentCollection = 'consent_collection',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerCreation = 'customer_creation',
  /** column name */
  CustomerDetails = 'customer_details',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Mode = 'mode',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  PaymentLinkId = 'payment_link_id',
  /** column name */
  PaymentMethodOptions = 'payment_method_options',
  /** column name */
  PaymentMethodTypes = 'payment_method_types',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  PhoneNumberCollectionEnabled = 'phone_number_collection_enabled',
  /** column name */
  RecoveredFrom = 'recovered_from',
  /** column name */
  SetupIntentId = 'setup_intent_id',
  /** column name */
  ShippingAddressCity = 'shipping_address_city',
  /** column name */
  ShippingAddressCollection = 'shipping_address_collection',
  /** column name */
  ShippingAddressCountry = 'shipping_address_country',
  /** column name */
  ShippingAddressLine1 = 'shipping_address_line1',
  /** column name */
  ShippingAddressLine2 = 'shipping_address_line2',
  /** column name */
  ShippingAddressPostalCode = 'shipping_address_postal_code',
  /** column name */
  ShippingAddressState = 'shipping_address_state',
  /** column name */
  ShippingCarrier = 'shipping_carrier',
  /** column name */
  ShippingName = 'shipping_name',
  /** column name */
  ShippingOptions = 'shipping_options',
  /** column name */
  ShippingPhone = 'shipping_phone',
  /** column name */
  ShippingRateId = 'shipping_rate_id',
  /** column name */
  ShippingTrackingNumber = 'shipping_tracking_number',
  /** column name */
  Status = 'status',
  /** column name */
  SubmitType = 'submit_type',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  SuccessUrl = 'success_url',
  /** column name */
  TotalDetails = 'total_details',
  /** column name */
  Url = 'url'
}

/** select "stripe_checkout_sessions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "stripe_checkout_sessions" */
export enum Stripe_Checkout_Sessions_Select_Column_Stripe_Checkout_Sessions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowPromotionCodes = 'allow_promotion_codes',
  /** column name */
  AutomaticTaxEnabled = 'automatic_tax_enabled',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  PhoneNumberCollectionEnabled = 'phone_number_collection_enabled'
}

/** select "stripe_checkout_sessions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "stripe_checkout_sessions" */
export enum Stripe_Checkout_Sessions_Select_Column_Stripe_Checkout_Sessions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowPromotionCodes = 'allow_promotion_codes',
  /** column name */
  AutomaticTaxEnabled = 'automatic_tax_enabled',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  PhoneNumberCollectionEnabled = 'phone_number_collection_enabled'
}

/** input type for updating data in table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Set_Input = {
  after_expiration?: InputMaybe<Scalars['jsonb']['input']>;
  allow_promotion_codes?: InputMaybe<Scalars['Boolean']['input']>;
  amount_subtotal?: InputMaybe<Scalars['bigint']['input']>;
  amount_total?: InputMaybe<Scalars['bigint']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_tax_status?: InputMaybe<Scalars['String']['input']>;
  billing_address_collection?: InputMaybe<Scalars['String']['input']>;
  cancel_url?: InputMaybe<Scalars['String']['input']>;
  client_reference_id?: InputMaybe<Scalars['String']['input']>;
  consent?: InputMaybe<Scalars['jsonb']['input']>;
  consent_collection?: InputMaybe<Scalars['jsonb']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer_creation?: InputMaybe<Scalars['String']['input']>;
  customer_details?: InputMaybe<Scalars['jsonb']['input']>;
  customer_email?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  payment_intent_id?: InputMaybe<Scalars['String']['input']>;
  payment_link_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_options?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  payment_status?: InputMaybe<Scalars['String']['input']>;
  phone_number_collection_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  recovered_from?: InputMaybe<Scalars['String']['input']>;
  setup_intent_id?: InputMaybe<Scalars['String']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_carrier?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_options?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  shipping_rate_id?: InputMaybe<Scalars['String']['input']>;
  shipping_tracking_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  submit_type?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  success_url?: InputMaybe<Scalars['String']['input']>;
  total_details?: InputMaybe<Scalars['jsonb']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Checkout_Sessions_Stddev_Fields = {
  amount_subtotal?: Maybe<Scalars['Float']['output']>;
  amount_total?: Maybe<Scalars['Float']['output']>;
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Stddev_Order_By = {
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Checkout_Sessions_Stddev_Pop_Fields = {
  amount_subtotal?: Maybe<Scalars['Float']['output']>;
  amount_total?: Maybe<Scalars['Float']['output']>;
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Stddev_Pop_Order_By = {
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Checkout_Sessions_Stddev_Samp_Fields = {
  amount_subtotal?: Maybe<Scalars['Float']['output']>;
  amount_total?: Maybe<Scalars['Float']['output']>;
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Stddev_Samp_Order_By = {
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Checkout_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Checkout_Sessions_Stream_Cursor_Value_Input = {
  after_expiration?: InputMaybe<Scalars['jsonb']['input']>;
  allow_promotion_codes?: InputMaybe<Scalars['Boolean']['input']>;
  amount_subtotal?: InputMaybe<Scalars['bigint']['input']>;
  amount_total?: InputMaybe<Scalars['bigint']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_tax_status?: InputMaybe<Scalars['String']['input']>;
  billing_address_collection?: InputMaybe<Scalars['String']['input']>;
  cancel_url?: InputMaybe<Scalars['String']['input']>;
  client_reference_id?: InputMaybe<Scalars['String']['input']>;
  consent?: InputMaybe<Scalars['jsonb']['input']>;
  consent_collection?: InputMaybe<Scalars['jsonb']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer_creation?: InputMaybe<Scalars['String']['input']>;
  customer_details?: InputMaybe<Scalars['jsonb']['input']>;
  customer_email?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  expires_at?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  payment_intent_id?: InputMaybe<Scalars['String']['input']>;
  payment_link_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_options?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  payment_status?: InputMaybe<Scalars['String']['input']>;
  phone_number_collection_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  recovered_from?: InputMaybe<Scalars['String']['input']>;
  setup_intent_id?: InputMaybe<Scalars['String']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_carrier?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_options?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  shipping_rate_id?: InputMaybe<Scalars['String']['input']>;
  shipping_tracking_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  submit_type?: InputMaybe<Scalars['String']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  success_url?: InputMaybe<Scalars['String']['input']>;
  total_details?: InputMaybe<Scalars['jsonb']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Checkout_Sessions_Sum_Fields = {
  amount_subtotal?: Maybe<Scalars['bigint']['output']>;
  amount_total?: Maybe<Scalars['bigint']['output']>;
  expires_at?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Sum_Order_By = {
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
};

/** update columns of table "stripe_checkout_sessions" */
export enum Stripe_Checkout_Sessions_Update_Column {
  /** column name */
  AfterExpiration = 'after_expiration',
  /** column name */
  AllowPromotionCodes = 'allow_promotion_codes',
  /** column name */
  AmountSubtotal = 'amount_subtotal',
  /** column name */
  AmountTotal = 'amount_total',
  /** column name */
  AutomaticTaxEnabled = 'automatic_tax_enabled',
  /** column name */
  AutomaticTaxStatus = 'automatic_tax_status',
  /** column name */
  BillingAddressCollection = 'billing_address_collection',
  /** column name */
  CancelUrl = 'cancel_url',
  /** column name */
  ClientReferenceId = 'client_reference_id',
  /** column name */
  Consent = 'consent',
  /** column name */
  ConsentCollection = 'consent_collection',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerCreation = 'customer_creation',
  /** column name */
  CustomerDetails = 'customer_details',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Mode = 'mode',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  PaymentLinkId = 'payment_link_id',
  /** column name */
  PaymentMethodOptions = 'payment_method_options',
  /** column name */
  PaymentMethodTypes = 'payment_method_types',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  PhoneNumberCollectionEnabled = 'phone_number_collection_enabled',
  /** column name */
  RecoveredFrom = 'recovered_from',
  /** column name */
  SetupIntentId = 'setup_intent_id',
  /** column name */
  ShippingAddressCity = 'shipping_address_city',
  /** column name */
  ShippingAddressCollection = 'shipping_address_collection',
  /** column name */
  ShippingAddressCountry = 'shipping_address_country',
  /** column name */
  ShippingAddressLine1 = 'shipping_address_line1',
  /** column name */
  ShippingAddressLine2 = 'shipping_address_line2',
  /** column name */
  ShippingAddressPostalCode = 'shipping_address_postal_code',
  /** column name */
  ShippingAddressState = 'shipping_address_state',
  /** column name */
  ShippingCarrier = 'shipping_carrier',
  /** column name */
  ShippingName = 'shipping_name',
  /** column name */
  ShippingOptions = 'shipping_options',
  /** column name */
  ShippingPhone = 'shipping_phone',
  /** column name */
  ShippingRateId = 'shipping_rate_id',
  /** column name */
  ShippingTrackingNumber = 'shipping_tracking_number',
  /** column name */
  Status = 'status',
  /** column name */
  SubmitType = 'submit_type',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  SuccessUrl = 'success_url',
  /** column name */
  TotalDetails = 'total_details',
  /** column name */
  Url = 'url'
}

export type Stripe_Checkout_Sessions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Checkout_Sessions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Checkout_Sessions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Checkout_Sessions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Checkout_Sessions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Checkout_Sessions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Checkout_Sessions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Checkout_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Checkout_Sessions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Checkout_Sessions_Var_Pop_Fields = {
  amount_subtotal?: Maybe<Scalars['Float']['output']>;
  amount_total?: Maybe<Scalars['Float']['output']>;
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Var_Pop_Order_By = {
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stripe_Checkout_Sessions_Var_Samp_Fields = {
  amount_subtotal?: Maybe<Scalars['Float']['output']>;
  amount_total?: Maybe<Scalars['Float']['output']>;
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Var_Samp_Order_By = {
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stripe_Checkout_Sessions_Variance_Fields = {
  amount_subtotal?: Maybe<Scalars['Float']['output']>;
  amount_total?: Maybe<Scalars['Float']['output']>;
  expires_at?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "stripe_checkout_sessions" */
export type Stripe_Checkout_Sessions_Variance_Order_By = {
  amount_subtotal?: InputMaybe<Order_By>;
  amount_total?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "stripe_customers" */
export type Stripe_Customers = {
  address_city?: Maybe<Scalars['String']['output']>;
  address_country?: Maybe<Scalars['String']['output']>;
  address_line1?: Maybe<Scalars['String']['output']>;
  address_line2?: Maybe<Scalars['String']['output']>;
  address_postal_code?: Maybe<Scalars['String']['output']>;
  address_state?: Maybe<Scalars['String']['output']>;
  balance?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  charges: Array<Stripe_Charges>;
  /** An aggregate relationship */
  charges_aggregate: Stripe_Charges_Aggregate;
  /** An array relationship */
  checkout_sessions: Array<Stripe_Checkout_Sessions>;
  /** An aggregate relationship */
  checkout_sessions_aggregate: Stripe_Checkout_Sessions_Aggregate;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  default_source_id?: Maybe<Scalars['String']['output']>;
  delinquent?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoice_prefix?: Maybe<Scalars['String']['output']>;
  invoice_settings_custom_fields?: Maybe<Scalars['jsonb']['output']>;
  invoice_settings_default_payment_method_id?: Maybe<Scalars['String']['output']>;
  invoice_settings_footer?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  next_invoice_sequence?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  preferred_locales?: Maybe<Scalars['jsonb']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  tax_exempt?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "stripe_customers" */
export type Stripe_CustomersChargesArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Charges_Order_By>>;
  where?: InputMaybe<Stripe_Charges_Bool_Exp>;
};


/** columns and relationships of "stripe_customers" */
export type Stripe_CustomersCharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Charges_Order_By>>;
  where?: InputMaybe<Stripe_Charges_Bool_Exp>;
};


/** columns and relationships of "stripe_customers" */
export type Stripe_CustomersCheckout_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Checkout_Sessions_Order_By>>;
  where?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


/** columns and relationships of "stripe_customers" */
export type Stripe_CustomersCheckout_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Checkout_Sessions_Order_By>>;
  where?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


/** columns and relationships of "stripe_customers" */
export type Stripe_CustomersInvoice_Settings_Custom_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_customers" */
export type Stripe_CustomersMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_customers" */
export type Stripe_CustomersPreferred_LocalesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_customers" */
export type Stripe_Customers_Aggregate = {
  aggregate?: Maybe<Stripe_Customers_Aggregate_Fields>;
  nodes: Array<Stripe_Customers>;
};

/** aggregate fields of "stripe_customers" */
export type Stripe_Customers_Aggregate_Fields = {
  avg?: Maybe<Stripe_Customers_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Customers_Max_Fields>;
  min?: Maybe<Stripe_Customers_Min_Fields>;
  stddev?: Maybe<Stripe_Customers_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Customers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Customers_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Customers_Sum_Fields>;
  var_pop?: Maybe<Stripe_Customers_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Customers_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Customers_Variance_Fields>;
};


/** aggregate fields of "stripe_customers" */
export type Stripe_Customers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Customers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Customers_Append_Input = {
  invoice_settings_custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  preferred_locales?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Stripe_Customers_Avg_Fields = {
  balance?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  next_invoice_sequence?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stripe_customers". All fields are combined with a logical 'AND'. */
export type Stripe_Customers_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Customers_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Customers_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Customers_Bool_Exp>>;
  address_city?: InputMaybe<String_Comparison_Exp>;
  address_country?: InputMaybe<String_Comparison_Exp>;
  address_line1?: InputMaybe<String_Comparison_Exp>;
  address_line2?: InputMaybe<String_Comparison_Exp>;
  address_postal_code?: InputMaybe<String_Comparison_Exp>;
  address_state?: InputMaybe<String_Comparison_Exp>;
  balance?: InputMaybe<Bigint_Comparison_Exp>;
  charges?: InputMaybe<Stripe_Charges_Bool_Exp>;
  charges_aggregate?: InputMaybe<Stripe_Charges_Aggregate_Bool_Exp>;
  checkout_sessions?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
  checkout_sessions_aggregate?: InputMaybe<Stripe_Checkout_Sessions_Aggregate_Bool_Exp>;
  created?: InputMaybe<Bigint_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  default_source_id?: InputMaybe<String_Comparison_Exp>;
  delinquent?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discount_id?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  invoice_prefix?: InputMaybe<String_Comparison_Exp>;
  invoice_settings_custom_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  invoice_settings_default_payment_method_id?: InputMaybe<String_Comparison_Exp>;
  invoice_settings_footer?: InputMaybe<String_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  next_invoice_sequence?: InputMaybe<Bigint_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  preferred_locales?: InputMaybe<Jsonb_Comparison_Exp>;
  shipping_address_city?: InputMaybe<String_Comparison_Exp>;
  shipping_address_country?: InputMaybe<String_Comparison_Exp>;
  shipping_address_line1?: InputMaybe<String_Comparison_Exp>;
  shipping_address_line2?: InputMaybe<String_Comparison_Exp>;
  shipping_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  shipping_address_state?: InputMaybe<String_Comparison_Exp>;
  shipping_name?: InputMaybe<String_Comparison_Exp>;
  shipping_phone?: InputMaybe<String_Comparison_Exp>;
  tax_exempt?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_customers" */
export enum Stripe_Customers_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripeCustomersPkey = 'stripe_customers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Customers_Delete_At_Path_Input = {
  invoice_settings_custom_fields?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  preferred_locales?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Customers_Delete_Elem_Input = {
  invoice_settings_custom_fields?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  preferred_locales?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Customers_Delete_Key_Input = {
  invoice_settings_custom_fields?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  preferred_locales?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_customers" */
export type Stripe_Customers_Inc_Input = {
  balance?: InputMaybe<Scalars['bigint']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  next_invoice_sequence?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stripe_customers" */
export type Stripe_Customers_Insert_Input = {
  address_city?: InputMaybe<Scalars['String']['input']>;
  address_country?: InputMaybe<Scalars['String']['input']>;
  address_line1?: InputMaybe<Scalars['String']['input']>;
  address_line2?: InputMaybe<Scalars['String']['input']>;
  address_postal_code?: InputMaybe<Scalars['String']['input']>;
  address_state?: InputMaybe<Scalars['String']['input']>;
  balance?: InputMaybe<Scalars['bigint']['input']>;
  charges?: InputMaybe<Stripe_Charges_Arr_Rel_Insert_Input>;
  checkout_sessions?: InputMaybe<Stripe_Checkout_Sessions_Arr_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  default_source_id?: InputMaybe<Scalars['String']['input']>;
  delinquent?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_prefix?: InputMaybe<Scalars['String']['input']>;
  invoice_settings_custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  invoice_settings_default_payment_method_id?: InputMaybe<Scalars['String']['input']>;
  invoice_settings_footer?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  next_invoice_sequence?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferred_locales?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  tax_exempt?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Stripe_Customers_Max_Fields = {
  address_city?: Maybe<Scalars['String']['output']>;
  address_country?: Maybe<Scalars['String']['output']>;
  address_line1?: Maybe<Scalars['String']['output']>;
  address_line2?: Maybe<Scalars['String']['output']>;
  address_postal_code?: Maybe<Scalars['String']['output']>;
  address_state?: Maybe<Scalars['String']['output']>;
  balance?: Maybe<Scalars['bigint']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  default_source_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_prefix?: Maybe<Scalars['String']['output']>;
  invoice_settings_default_payment_method_id?: Maybe<Scalars['String']['output']>;
  invoice_settings_footer?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  next_invoice_sequence?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  tax_exempt?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Stripe_Customers_Min_Fields = {
  address_city?: Maybe<Scalars['String']['output']>;
  address_country?: Maybe<Scalars['String']['output']>;
  address_line1?: Maybe<Scalars['String']['output']>;
  address_line2?: Maybe<Scalars['String']['output']>;
  address_postal_code?: Maybe<Scalars['String']['output']>;
  address_state?: Maybe<Scalars['String']['output']>;
  balance?: Maybe<Scalars['bigint']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  default_source_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_prefix?: Maybe<Scalars['String']['output']>;
  invoice_settings_default_payment_method_id?: Maybe<Scalars['String']['output']>;
  invoice_settings_footer?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  next_invoice_sequence?: Maybe<Scalars['bigint']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  tax_exempt?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "stripe_customers" */
export type Stripe_Customers_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Customers>;
};

/** input type for inserting object relation for remote table "stripe_customers" */
export type Stripe_Customers_Obj_Rel_Insert_Input = {
  data: Stripe_Customers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stripe_Customers_On_Conflict>;
};

/** on_conflict condition type for table "stripe_customers" */
export type Stripe_Customers_On_Conflict = {
  constraint: Stripe_Customers_Constraint;
  update_columns?: Array<Stripe_Customers_Update_Column>;
  where?: InputMaybe<Stripe_Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_customers". */
export type Stripe_Customers_Order_By = {
  address_city?: InputMaybe<Order_By>;
  address_country?: InputMaybe<Order_By>;
  address_line1?: InputMaybe<Order_By>;
  address_line2?: InputMaybe<Order_By>;
  address_postal_code?: InputMaybe<Order_By>;
  address_state?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
  charges_aggregate?: InputMaybe<Stripe_Charges_Aggregate_Order_By>;
  checkout_sessions_aggregate?: InputMaybe<Stripe_Checkout_Sessions_Aggregate_Order_By>;
  created?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  default_source_id?: InputMaybe<Order_By>;
  delinquent?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discount_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_prefix?: InputMaybe<Order_By>;
  invoice_settings_custom_fields?: InputMaybe<Order_By>;
  invoice_settings_default_payment_method_id?: InputMaybe<Order_By>;
  invoice_settings_footer?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  next_invoice_sequence?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  preferred_locales?: InputMaybe<Order_By>;
  shipping_address_city?: InputMaybe<Order_By>;
  shipping_address_country?: InputMaybe<Order_By>;
  shipping_address_line1?: InputMaybe<Order_By>;
  shipping_address_line2?: InputMaybe<Order_By>;
  shipping_address_postal_code?: InputMaybe<Order_By>;
  shipping_address_state?: InputMaybe<Order_By>;
  shipping_name?: InputMaybe<Order_By>;
  shipping_phone?: InputMaybe<Order_By>;
  tax_exempt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_customers */
export type Stripe_Customers_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Customers_Prepend_Input = {
  invoice_settings_custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  preferred_locales?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_customers" */
export enum Stripe_Customers_Select_Column {
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressCountry = 'address_country',
  /** column name */
  AddressLine1 = 'address_line1',
  /** column name */
  AddressLine2 = 'address_line2',
  /** column name */
  AddressPostalCode = 'address_postal_code',
  /** column name */
  AddressState = 'address_state',
  /** column name */
  Balance = 'balance',
  /** column name */
  Created = 'created',
  /** column name */
  Currency = 'currency',
  /** column name */
  DefaultSourceId = 'default_source_id',
  /** column name */
  Delinquent = 'delinquent',
  /** column name */
  Description = 'description',
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvoicePrefix = 'invoice_prefix',
  /** column name */
  InvoiceSettingsCustomFields = 'invoice_settings_custom_fields',
  /** column name */
  InvoiceSettingsDefaultPaymentMethodId = 'invoice_settings_default_payment_method_id',
  /** column name */
  InvoiceSettingsFooter = 'invoice_settings_footer',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NextInvoiceSequence = 'next_invoice_sequence',
  /** column name */
  Phone = 'phone',
  /** column name */
  PreferredLocales = 'preferred_locales',
  /** column name */
  ShippingAddressCity = 'shipping_address_city',
  /** column name */
  ShippingAddressCountry = 'shipping_address_country',
  /** column name */
  ShippingAddressLine1 = 'shipping_address_line1',
  /** column name */
  ShippingAddressLine2 = 'shipping_address_line2',
  /** column name */
  ShippingAddressPostalCode = 'shipping_address_postal_code',
  /** column name */
  ShippingAddressState = 'shipping_address_state',
  /** column name */
  ShippingName = 'shipping_name',
  /** column name */
  ShippingPhone = 'shipping_phone',
  /** column name */
  TaxExempt = 'tax_exempt'
}

/** input type for updating data in table "stripe_customers" */
export type Stripe_Customers_Set_Input = {
  address_city?: InputMaybe<Scalars['String']['input']>;
  address_country?: InputMaybe<Scalars['String']['input']>;
  address_line1?: InputMaybe<Scalars['String']['input']>;
  address_line2?: InputMaybe<Scalars['String']['input']>;
  address_postal_code?: InputMaybe<Scalars['String']['input']>;
  address_state?: InputMaybe<Scalars['String']['input']>;
  balance?: InputMaybe<Scalars['bigint']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  default_source_id?: InputMaybe<Scalars['String']['input']>;
  delinquent?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_prefix?: InputMaybe<Scalars['String']['input']>;
  invoice_settings_custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  invoice_settings_default_payment_method_id?: InputMaybe<Scalars['String']['input']>;
  invoice_settings_footer?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  next_invoice_sequence?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferred_locales?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  tax_exempt?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Customers_Stddev_Fields = {
  balance?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  next_invoice_sequence?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Customers_Stddev_Pop_Fields = {
  balance?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  next_invoice_sequence?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Customers_Stddev_Samp_Fields = {
  balance?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  next_invoice_sequence?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stripe_customers" */
export type Stripe_Customers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Customers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Customers_Stream_Cursor_Value_Input = {
  address_city?: InputMaybe<Scalars['String']['input']>;
  address_country?: InputMaybe<Scalars['String']['input']>;
  address_line1?: InputMaybe<Scalars['String']['input']>;
  address_line2?: InputMaybe<Scalars['String']['input']>;
  address_postal_code?: InputMaybe<Scalars['String']['input']>;
  address_state?: InputMaybe<Scalars['String']['input']>;
  balance?: InputMaybe<Scalars['bigint']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  default_source_id?: InputMaybe<Scalars['String']['input']>;
  delinquent?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_prefix?: InputMaybe<Scalars['String']['input']>;
  invoice_settings_custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  invoice_settings_default_payment_method_id?: InputMaybe<Scalars['String']['input']>;
  invoice_settings_footer?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  next_invoice_sequence?: InputMaybe<Scalars['bigint']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferred_locales?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  tax_exempt?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Customers_Sum_Fields = {
  balance?: Maybe<Scalars['bigint']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  next_invoice_sequence?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stripe_customers" */
export enum Stripe_Customers_Update_Column {
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressCountry = 'address_country',
  /** column name */
  AddressLine1 = 'address_line1',
  /** column name */
  AddressLine2 = 'address_line2',
  /** column name */
  AddressPostalCode = 'address_postal_code',
  /** column name */
  AddressState = 'address_state',
  /** column name */
  Balance = 'balance',
  /** column name */
  Created = 'created',
  /** column name */
  Currency = 'currency',
  /** column name */
  DefaultSourceId = 'default_source_id',
  /** column name */
  Delinquent = 'delinquent',
  /** column name */
  Description = 'description',
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvoicePrefix = 'invoice_prefix',
  /** column name */
  InvoiceSettingsCustomFields = 'invoice_settings_custom_fields',
  /** column name */
  InvoiceSettingsDefaultPaymentMethodId = 'invoice_settings_default_payment_method_id',
  /** column name */
  InvoiceSettingsFooter = 'invoice_settings_footer',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NextInvoiceSequence = 'next_invoice_sequence',
  /** column name */
  Phone = 'phone',
  /** column name */
  PreferredLocales = 'preferred_locales',
  /** column name */
  ShippingAddressCity = 'shipping_address_city',
  /** column name */
  ShippingAddressCountry = 'shipping_address_country',
  /** column name */
  ShippingAddressLine1 = 'shipping_address_line1',
  /** column name */
  ShippingAddressLine2 = 'shipping_address_line2',
  /** column name */
  ShippingAddressPostalCode = 'shipping_address_postal_code',
  /** column name */
  ShippingAddressState = 'shipping_address_state',
  /** column name */
  ShippingName = 'shipping_name',
  /** column name */
  ShippingPhone = 'shipping_phone',
  /** column name */
  TaxExempt = 'tax_exempt'
}

export type Stripe_Customers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Customers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Customers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Customers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Customers_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Customers_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Customers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Customers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Customers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Customers_Var_Pop_Fields = {
  balance?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  next_invoice_sequence?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stripe_Customers_Var_Samp_Fields = {
  balance?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  next_invoice_sequence?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stripe_Customers_Variance_Fields = {
  balance?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  next_invoice_sequence?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stripe_invoice_items" */
export type Stripe_Invoice_Items = {
  amount?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['bigint']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountable?: Maybe<Scalars['Boolean']['output']>;
  discounts?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['String']['output'];
  invoice_id?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  period_end?: Maybe<Scalars['bigint']['output']>;
  period_start?: Maybe<Scalars['bigint']['output']>;
  price_id?: Maybe<Scalars['String']['output']>;
  proration?: Maybe<Scalars['Boolean']['output']>;
  quantity?: Maybe<Scalars['bigint']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  tax_rates?: Maybe<Scalars['jsonb']['output']>;
  unit_amount?: Maybe<Scalars['bigint']['output']>;
  unit_amount_decimal?: Maybe<Scalars['numeric']['output']>;
};


/** columns and relationships of "stripe_invoice_items" */
export type Stripe_Invoice_ItemsDiscountsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoice_items" */
export type Stripe_Invoice_ItemsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoice_items" */
export type Stripe_Invoice_ItemsTax_RatesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_invoice_items" */
export type Stripe_Invoice_Items_Aggregate = {
  aggregate?: Maybe<Stripe_Invoice_Items_Aggregate_Fields>;
  nodes: Array<Stripe_Invoice_Items>;
};

/** aggregate fields of "stripe_invoice_items" */
export type Stripe_Invoice_Items_Aggregate_Fields = {
  avg?: Maybe<Stripe_Invoice_Items_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Invoice_Items_Max_Fields>;
  min?: Maybe<Stripe_Invoice_Items_Min_Fields>;
  stddev?: Maybe<Stripe_Invoice_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Invoice_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Invoice_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Invoice_Items_Sum_Fields>;
  var_pop?: Maybe<Stripe_Invoice_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Invoice_Items_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Invoice_Items_Variance_Fields>;
};


/** aggregate fields of "stripe_invoice_items" */
export type Stripe_Invoice_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Invoice_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Invoice_Items_Append_Input = {
  discounts?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Stripe_Invoice_Items_Avg_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stripe_invoice_items". All fields are combined with a logical 'AND'. */
export type Stripe_Invoice_Items_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Invoice_Items_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Invoice_Items_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Invoice_Items_Bool_Exp>>;
  amount?: InputMaybe<Bigint_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discountable?: InputMaybe<Boolean_Comparison_Exp>;
  discounts?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  period_end?: InputMaybe<Bigint_Comparison_Exp>;
  period_start?: InputMaybe<Bigint_Comparison_Exp>;
  price_id?: InputMaybe<String_Comparison_Exp>;
  proration?: InputMaybe<Boolean_Comparison_Exp>;
  quantity?: InputMaybe<Bigint_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  tax_rates?: InputMaybe<Jsonb_Comparison_Exp>;
  unit_amount?: InputMaybe<Bigint_Comparison_Exp>;
  unit_amount_decimal?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_invoice_items" */
export enum Stripe_Invoice_Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripeInvoiceItemsPkey = 'stripe_invoice_items_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Invoice_Items_Delete_At_Path_Input = {
  discounts?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  tax_rates?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Invoice_Items_Delete_Elem_Input = {
  discounts?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  tax_rates?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Invoice_Items_Delete_Key_Input = {
  discounts?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  tax_rates?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_invoice_items" */
export type Stripe_Invoice_Items_Inc_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  date?: InputMaybe<Scalars['bigint']['input']>;
  period_end?: InputMaybe<Scalars['bigint']['input']>;
  period_start?: InputMaybe<Scalars['bigint']['input']>;
  quantity?: InputMaybe<Scalars['bigint']['input']>;
  unit_amount?: InputMaybe<Scalars['bigint']['input']>;
  unit_amount_decimal?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "stripe_invoice_items" */
export type Stripe_Invoice_Items_Insert_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['bigint']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountable?: InputMaybe<Scalars['Boolean']['input']>;
  discounts?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  period_end?: InputMaybe<Scalars['bigint']['input']>;
  period_start?: InputMaybe<Scalars['bigint']['input']>;
  price_id?: InputMaybe<Scalars['String']['input']>;
  proration?: InputMaybe<Scalars['Boolean']['input']>;
  quantity?: InputMaybe<Scalars['bigint']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  unit_amount?: InputMaybe<Scalars['bigint']['input']>;
  unit_amount_decimal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Stripe_Invoice_Items_Max_Fields = {
  amount?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['bigint']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_id?: Maybe<Scalars['String']['output']>;
  period_end?: Maybe<Scalars['bigint']['output']>;
  period_start?: Maybe<Scalars['bigint']['output']>;
  price_id?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['bigint']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  unit_amount?: Maybe<Scalars['bigint']['output']>;
  unit_amount_decimal?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Stripe_Invoice_Items_Min_Fields = {
  amount?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['bigint']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_id?: Maybe<Scalars['String']['output']>;
  period_end?: Maybe<Scalars['bigint']['output']>;
  period_start?: Maybe<Scalars['bigint']['output']>;
  price_id?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['bigint']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  unit_amount?: Maybe<Scalars['bigint']['output']>;
  unit_amount_decimal?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "stripe_invoice_items" */
export type Stripe_Invoice_Items_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Invoice_Items>;
};

/** on_conflict condition type for table "stripe_invoice_items" */
export type Stripe_Invoice_Items_On_Conflict = {
  constraint: Stripe_Invoice_Items_Constraint;
  update_columns?: Array<Stripe_Invoice_Items_Update_Column>;
  where?: InputMaybe<Stripe_Invoice_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_invoice_items". */
export type Stripe_Invoice_Items_Order_By = {
  amount?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discountable?: InputMaybe<Order_By>;
  discounts?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  period_end?: InputMaybe<Order_By>;
  period_start?: InputMaybe<Order_By>;
  price_id?: InputMaybe<Order_By>;
  proration?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  tax_rates?: InputMaybe<Order_By>;
  unit_amount?: InputMaybe<Order_By>;
  unit_amount_decimal?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_invoice_items */
export type Stripe_Invoice_Items_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Invoice_Items_Prepend_Input = {
  discounts?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_invoice_items" */
export enum Stripe_Invoice_Items_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Discountable = 'discountable',
  /** column name */
  Discounts = 'discounts',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PeriodEnd = 'period_end',
  /** column name */
  PeriodStart = 'period_start',
  /** column name */
  PriceId = 'price_id',
  /** column name */
  Proration = 'proration',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TaxRates = 'tax_rates',
  /** column name */
  UnitAmount = 'unit_amount',
  /** column name */
  UnitAmountDecimal = 'unit_amount_decimal'
}

/** input type for updating data in table "stripe_invoice_items" */
export type Stripe_Invoice_Items_Set_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['bigint']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountable?: InputMaybe<Scalars['Boolean']['input']>;
  discounts?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  period_end?: InputMaybe<Scalars['bigint']['input']>;
  period_start?: InputMaybe<Scalars['bigint']['input']>;
  price_id?: InputMaybe<Scalars['String']['input']>;
  proration?: InputMaybe<Scalars['Boolean']['input']>;
  quantity?: InputMaybe<Scalars['bigint']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  unit_amount?: InputMaybe<Scalars['bigint']['input']>;
  unit_amount_decimal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Invoice_Items_Stddev_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Invoice_Items_Stddev_Pop_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Invoice_Items_Stddev_Samp_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stripe_invoice_items" */
export type Stripe_Invoice_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Invoice_Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Invoice_Items_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['bigint']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountable?: InputMaybe<Scalars['Boolean']['input']>;
  discounts?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  period_end?: InputMaybe<Scalars['bigint']['input']>;
  period_start?: InputMaybe<Scalars['bigint']['input']>;
  price_id?: InputMaybe<Scalars['String']['input']>;
  proration?: InputMaybe<Scalars['Boolean']['input']>;
  quantity?: InputMaybe<Scalars['bigint']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  unit_amount?: InputMaybe<Scalars['bigint']['input']>;
  unit_amount_decimal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Invoice_Items_Sum_Fields = {
  amount?: Maybe<Scalars['bigint']['output']>;
  date?: Maybe<Scalars['bigint']['output']>;
  period_end?: Maybe<Scalars['bigint']['output']>;
  period_start?: Maybe<Scalars['bigint']['output']>;
  quantity?: Maybe<Scalars['bigint']['output']>;
  unit_amount?: Maybe<Scalars['bigint']['output']>;
  unit_amount_decimal?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "stripe_invoice_items" */
export enum Stripe_Invoice_Items_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Discountable = 'discountable',
  /** column name */
  Discounts = 'discounts',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PeriodEnd = 'period_end',
  /** column name */
  PeriodStart = 'period_start',
  /** column name */
  PriceId = 'price_id',
  /** column name */
  Proration = 'proration',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TaxRates = 'tax_rates',
  /** column name */
  UnitAmount = 'unit_amount',
  /** column name */
  UnitAmountDecimal = 'unit_amount_decimal'
}

export type Stripe_Invoice_Items_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Invoice_Items_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Invoice_Items_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Invoice_Items_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Invoice_Items_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Invoice_Items_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Invoice_Items_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Invoice_Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Invoice_Items_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Invoice_Items_Var_Pop_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stripe_Invoice_Items_Var_Samp_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stripe_Invoice_Items_Variance_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stripe_invoices" */
export type Stripe_Invoices = {
  account_country?: Maybe<Scalars['String']['output']>;
  account_name?: Maybe<Scalars['String']['output']>;
  account_tax_ids?: Maybe<Scalars['jsonb']['output']>;
  amount_due?: Maybe<Scalars['bigint']['output']>;
  amount_paid?: Maybe<Scalars['bigint']['output']>;
  amount_remaining?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  attempt_count?: Maybe<Scalars['bigint']['output']>;
  attempted?: Maybe<Scalars['Boolean']['output']>;
  auto_advance?: Maybe<Scalars['Boolean']['output']>;
  automatic_tax_enabled?: Maybe<Scalars['Boolean']['output']>;
  automatic_tax_status?: Maybe<Scalars['String']['output']>;
  billing_reason?: Maybe<Scalars['String']['output']>;
  charge_id?: Maybe<Scalars['String']['output']>;
  collection_method?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  custom_fields?: Maybe<Scalars['jsonb']['output']>;
  customer_address_city?: Maybe<Scalars['String']['output']>;
  customer_address_country?: Maybe<Scalars['String']['output']>;
  customer_address_line1?: Maybe<Scalars['String']['output']>;
  customer_address_line2?: Maybe<Scalars['String']['output']>;
  customer_address_postal_code?: Maybe<Scalars['String']['output']>;
  customer_address_state?: Maybe<Scalars['String']['output']>;
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  customer_phone?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_city?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_country?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_line1?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_line2?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_state?: Maybe<Scalars['String']['output']>;
  customer_shipping_name?: Maybe<Scalars['String']['output']>;
  customer_shipping_phone?: Maybe<Scalars['String']['output']>;
  customer_tax_exempt?: Maybe<Scalars['String']['output']>;
  customer_tax_ids?: Maybe<Scalars['jsonb']['output']>;
  default_payment_method_id?: Maybe<Scalars['String']['output']>;
  default_source_id?: Maybe<Scalars['String']['output']>;
  default_tax_rates?: Maybe<Scalars['jsonb']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['jsonb']['output']>;
  discounts?: Maybe<Scalars['jsonb']['output']>;
  due_date?: Maybe<Scalars['bigint']['output']>;
  ending_balance?: Maybe<Scalars['bigint']['output']>;
  footer?: Maybe<Scalars['String']['output']>;
  hosted_invoice_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoice_pdf?: Maybe<Scalars['String']['output']>;
  last_finalization_error?: Maybe<Scalars['jsonb']['output']>;
  lines?: Maybe<Scalars['jsonb']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_payment_attempt?: Maybe<Scalars['bigint']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Boolean']['output']>;
  paid_out_of_band?: Maybe<Scalars['Boolean']['output']>;
  payment_intent_id?: Maybe<Scalars['String']['output']>;
  payment_settings?: Maybe<Scalars['jsonb']['output']>;
  period_end?: Maybe<Scalars['bigint']['output']>;
  period_start?: Maybe<Scalars['bigint']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['bigint']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['bigint']['output']>;
  quote_id?: Maybe<Scalars['String']['output']>;
  receipt_number?: Maybe<Scalars['String']['output']>;
  starting_balance?: Maybe<Scalars['bigint']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['bigint']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['bigint']['output']>;
  tax?: Maybe<Scalars['bigint']['output']>;
  total?: Maybe<Scalars['bigint']['output']>;
  total_discount_amounts?: Maybe<Scalars['jsonb']['output']>;
  total_tax_amounts?: Maybe<Scalars['jsonb']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesAccount_Tax_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesCustom_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesCustomer_Tax_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesDefault_Tax_RatesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesDiscountArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesDiscountsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesLast_Finalization_ErrorArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesLinesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesPayment_SettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesTotal_Discount_AmountsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_invoices" */
export type Stripe_InvoicesTotal_Tax_AmountsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_invoices" */
export type Stripe_Invoices_Aggregate = {
  aggregate?: Maybe<Stripe_Invoices_Aggregate_Fields>;
  nodes: Array<Stripe_Invoices>;
};

/** aggregate fields of "stripe_invoices" */
export type Stripe_Invoices_Aggregate_Fields = {
  avg?: Maybe<Stripe_Invoices_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Invoices_Max_Fields>;
  min?: Maybe<Stripe_Invoices_Min_Fields>;
  stddev?: Maybe<Stripe_Invoices_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Invoices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Invoices_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Invoices_Sum_Fields>;
  var_pop?: Maybe<Stripe_Invoices_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Invoices_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Invoices_Variance_Fields>;
};


/** aggregate fields of "stripe_invoices" */
export type Stripe_Invoices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Invoices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Invoices_Append_Input = {
  account_tax_ids?: InputMaybe<Scalars['jsonb']['input']>;
  custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  customer_tax_ids?: InputMaybe<Scalars['jsonb']['input']>;
  default_tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  discount?: InputMaybe<Scalars['jsonb']['input']>;
  discounts?: InputMaybe<Scalars['jsonb']['input']>;
  last_finalization_error?: InputMaybe<Scalars['jsonb']['input']>;
  lines?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  payment_settings?: InputMaybe<Scalars['jsonb']['input']>;
  total_discount_amounts?: InputMaybe<Scalars['jsonb']['input']>;
  total_tax_amounts?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Stripe_Invoices_Avg_Fields = {
  amount_due?: Maybe<Scalars['Float']['output']>;
  amount_paid?: Maybe<Scalars['Float']['output']>;
  amount_remaining?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  attempt_count?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  due_date?: Maybe<Scalars['Float']['output']>;
  ending_balance?: Maybe<Scalars['Float']['output']>;
  next_payment_attempt?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  starting_balance?: Maybe<Scalars['Float']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['Float']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stripe_invoices". All fields are combined with a logical 'AND'. */
export type Stripe_Invoices_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Invoices_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Invoices_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Invoices_Bool_Exp>>;
  account_country?: InputMaybe<String_Comparison_Exp>;
  account_name?: InputMaybe<String_Comparison_Exp>;
  account_tax_ids?: InputMaybe<Jsonb_Comparison_Exp>;
  amount_due?: InputMaybe<Bigint_Comparison_Exp>;
  amount_paid?: InputMaybe<Bigint_Comparison_Exp>;
  amount_remaining?: InputMaybe<Bigint_Comparison_Exp>;
  application_fee_amount?: InputMaybe<Bigint_Comparison_Exp>;
  attempt_count?: InputMaybe<Bigint_Comparison_Exp>;
  attempted?: InputMaybe<Boolean_Comparison_Exp>;
  auto_advance?: InputMaybe<Boolean_Comparison_Exp>;
  automatic_tax_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  automatic_tax_status?: InputMaybe<String_Comparison_Exp>;
  billing_reason?: InputMaybe<String_Comparison_Exp>;
  charge_id?: InputMaybe<String_Comparison_Exp>;
  collection_method?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Bigint_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  custom_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  customer_address_city?: InputMaybe<String_Comparison_Exp>;
  customer_address_country?: InputMaybe<String_Comparison_Exp>;
  customer_address_line1?: InputMaybe<String_Comparison_Exp>;
  customer_address_line2?: InputMaybe<String_Comparison_Exp>;
  customer_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  customer_address_state?: InputMaybe<String_Comparison_Exp>;
  customer_email?: InputMaybe<String_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  customer_name?: InputMaybe<String_Comparison_Exp>;
  customer_phone?: InputMaybe<String_Comparison_Exp>;
  customer_shipping_address_city?: InputMaybe<String_Comparison_Exp>;
  customer_shipping_address_country?: InputMaybe<String_Comparison_Exp>;
  customer_shipping_address_line1?: InputMaybe<String_Comparison_Exp>;
  customer_shipping_address_line2?: InputMaybe<String_Comparison_Exp>;
  customer_shipping_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  customer_shipping_address_state?: InputMaybe<String_Comparison_Exp>;
  customer_shipping_name?: InputMaybe<String_Comparison_Exp>;
  customer_shipping_phone?: InputMaybe<String_Comparison_Exp>;
  customer_tax_exempt?: InputMaybe<String_Comparison_Exp>;
  customer_tax_ids?: InputMaybe<Jsonb_Comparison_Exp>;
  default_payment_method_id?: InputMaybe<String_Comparison_Exp>;
  default_source_id?: InputMaybe<String_Comparison_Exp>;
  default_tax_rates?: InputMaybe<Jsonb_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  discount?: InputMaybe<Jsonb_Comparison_Exp>;
  discounts?: InputMaybe<Jsonb_Comparison_Exp>;
  due_date?: InputMaybe<Bigint_Comparison_Exp>;
  ending_balance?: InputMaybe<Bigint_Comparison_Exp>;
  footer?: InputMaybe<String_Comparison_Exp>;
  hosted_invoice_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  invoice_pdf?: InputMaybe<String_Comparison_Exp>;
  last_finalization_error?: InputMaybe<Jsonb_Comparison_Exp>;
  lines?: InputMaybe<Jsonb_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_payment_attempt?: InputMaybe<Bigint_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  on_behalf_of_id?: InputMaybe<String_Comparison_Exp>;
  paid?: InputMaybe<Boolean_Comparison_Exp>;
  paid_out_of_band?: InputMaybe<Boolean_Comparison_Exp>;
  payment_intent_id?: InputMaybe<String_Comparison_Exp>;
  payment_settings?: InputMaybe<Jsonb_Comparison_Exp>;
  period_end?: InputMaybe<Bigint_Comparison_Exp>;
  period_start?: InputMaybe<Bigint_Comparison_Exp>;
  post_payment_credit_notes_amount?: InputMaybe<Bigint_Comparison_Exp>;
  pre_payment_credit_notes_amount?: InputMaybe<Bigint_Comparison_Exp>;
  quote_id?: InputMaybe<String_Comparison_Exp>;
  receipt_number?: InputMaybe<String_Comparison_Exp>;
  starting_balance?: InputMaybe<Bigint_Comparison_Exp>;
  statement_descriptor?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  status_transitions_finalized_at?: InputMaybe<Bigint_Comparison_Exp>;
  status_transitions_marked_uncollectible_at?: InputMaybe<Bigint_Comparison_Exp>;
  status_transitions_paid_at?: InputMaybe<Bigint_Comparison_Exp>;
  status_transitions_voided_at?: InputMaybe<Bigint_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  subtotal?: InputMaybe<Bigint_Comparison_Exp>;
  tax?: InputMaybe<Bigint_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
  total_discount_amounts?: InputMaybe<Jsonb_Comparison_Exp>;
  total_tax_amounts?: InputMaybe<Jsonb_Comparison_Exp>;
  transfer_dataamount?: InputMaybe<Bigint_Comparison_Exp>;
  transfer_datadestination_id?: InputMaybe<String_Comparison_Exp>;
  webhooks_delivered_at?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_invoices" */
export enum Stripe_Invoices_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripeInvoicesPkey = 'stripe_invoices_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Invoices_Delete_At_Path_Input = {
  account_tax_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  custom_fields?: InputMaybe<Array<Scalars['String']['input']>>;
  customer_tax_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  default_tax_rates?: InputMaybe<Array<Scalars['String']['input']>>;
  discount?: InputMaybe<Array<Scalars['String']['input']>>;
  discounts?: InputMaybe<Array<Scalars['String']['input']>>;
  last_finalization_error?: InputMaybe<Array<Scalars['String']['input']>>;
  lines?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  payment_settings?: InputMaybe<Array<Scalars['String']['input']>>;
  total_discount_amounts?: InputMaybe<Array<Scalars['String']['input']>>;
  total_tax_amounts?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Invoices_Delete_Elem_Input = {
  account_tax_ids?: InputMaybe<Scalars['Int']['input']>;
  custom_fields?: InputMaybe<Scalars['Int']['input']>;
  customer_tax_ids?: InputMaybe<Scalars['Int']['input']>;
  default_tax_rates?: InputMaybe<Scalars['Int']['input']>;
  discount?: InputMaybe<Scalars['Int']['input']>;
  discounts?: InputMaybe<Scalars['Int']['input']>;
  last_finalization_error?: InputMaybe<Scalars['Int']['input']>;
  lines?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  payment_settings?: InputMaybe<Scalars['Int']['input']>;
  total_discount_amounts?: InputMaybe<Scalars['Int']['input']>;
  total_tax_amounts?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Invoices_Delete_Key_Input = {
  account_tax_ids?: InputMaybe<Scalars['String']['input']>;
  custom_fields?: InputMaybe<Scalars['String']['input']>;
  customer_tax_ids?: InputMaybe<Scalars['String']['input']>;
  default_tax_rates?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['String']['input']>;
  discounts?: InputMaybe<Scalars['String']['input']>;
  last_finalization_error?: InputMaybe<Scalars['String']['input']>;
  lines?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<Scalars['String']['input']>;
  total_discount_amounts?: InputMaybe<Scalars['String']['input']>;
  total_tax_amounts?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_invoices" */
export type Stripe_Invoices_Inc_Input = {
  amount_due?: InputMaybe<Scalars['bigint']['input']>;
  amount_paid?: InputMaybe<Scalars['bigint']['input']>;
  amount_remaining?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  attempt_count?: InputMaybe<Scalars['bigint']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  due_date?: InputMaybe<Scalars['bigint']['input']>;
  ending_balance?: InputMaybe<Scalars['bigint']['input']>;
  next_payment_attempt?: InputMaybe<Scalars['bigint']['input']>;
  period_end?: InputMaybe<Scalars['bigint']['input']>;
  period_start?: InputMaybe<Scalars['bigint']['input']>;
  post_payment_credit_notes_amount?: InputMaybe<Scalars['bigint']['input']>;
  pre_payment_credit_notes_amount?: InputMaybe<Scalars['bigint']['input']>;
  starting_balance?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_finalized_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_marked_uncollectible_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_paid_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_voided_at?: InputMaybe<Scalars['bigint']['input']>;
  subtotal?: InputMaybe<Scalars['bigint']['input']>;
  tax?: InputMaybe<Scalars['bigint']['input']>;
  total?: InputMaybe<Scalars['bigint']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  webhooks_delivered_at?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stripe_invoices" */
export type Stripe_Invoices_Insert_Input = {
  account_country?: InputMaybe<Scalars['String']['input']>;
  account_name?: InputMaybe<Scalars['String']['input']>;
  account_tax_ids?: InputMaybe<Scalars['jsonb']['input']>;
  amount_due?: InputMaybe<Scalars['bigint']['input']>;
  amount_paid?: InputMaybe<Scalars['bigint']['input']>;
  amount_remaining?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  attempt_count?: InputMaybe<Scalars['bigint']['input']>;
  attempted?: InputMaybe<Scalars['Boolean']['input']>;
  auto_advance?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_tax_status?: InputMaybe<Scalars['String']['input']>;
  billing_reason?: InputMaybe<Scalars['String']['input']>;
  charge_id?: InputMaybe<Scalars['String']['input']>;
  collection_method?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  customer_address_city?: InputMaybe<Scalars['String']['input']>;
  customer_address_country?: InputMaybe<Scalars['String']['input']>;
  customer_address_line1?: InputMaybe<Scalars['String']['input']>;
  customer_address_line2?: InputMaybe<Scalars['String']['input']>;
  customer_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  customer_address_state?: InputMaybe<Scalars['String']['input']>;
  customer_email?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  customer_phone?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_name?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_phone?: InputMaybe<Scalars['String']['input']>;
  customer_tax_exempt?: InputMaybe<Scalars['String']['input']>;
  customer_tax_ids?: InputMaybe<Scalars['jsonb']['input']>;
  default_payment_method_id?: InputMaybe<Scalars['String']['input']>;
  default_source_id?: InputMaybe<Scalars['String']['input']>;
  default_tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['jsonb']['input']>;
  discounts?: InputMaybe<Scalars['jsonb']['input']>;
  due_date?: InputMaybe<Scalars['bigint']['input']>;
  ending_balance?: InputMaybe<Scalars['bigint']['input']>;
  footer?: InputMaybe<Scalars['String']['input']>;
  hosted_invoice_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_pdf?: InputMaybe<Scalars['String']['input']>;
  last_finalization_error?: InputMaybe<Scalars['jsonb']['input']>;
  lines?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_payment_attempt?: InputMaybe<Scalars['bigint']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  paid_out_of_band?: InputMaybe<Scalars['Boolean']['input']>;
  payment_intent_id?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<Scalars['jsonb']['input']>;
  period_end?: InputMaybe<Scalars['bigint']['input']>;
  period_start?: InputMaybe<Scalars['bigint']['input']>;
  post_payment_credit_notes_amount?: InputMaybe<Scalars['bigint']['input']>;
  pre_payment_credit_notes_amount?: InputMaybe<Scalars['bigint']['input']>;
  quote_id?: InputMaybe<Scalars['String']['input']>;
  receipt_number?: InputMaybe<Scalars['String']['input']>;
  starting_balance?: InputMaybe<Scalars['bigint']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_transitions_finalized_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_marked_uncollectible_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_paid_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_voided_at?: InputMaybe<Scalars['bigint']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  subtotal?: InputMaybe<Scalars['bigint']['input']>;
  tax?: InputMaybe<Scalars['bigint']['input']>;
  total?: InputMaybe<Scalars['bigint']['input']>;
  total_discount_amounts?: InputMaybe<Scalars['jsonb']['input']>;
  total_tax_amounts?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  webhooks_delivered_at?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Stripe_Invoices_Max_Fields = {
  account_country?: Maybe<Scalars['String']['output']>;
  account_name?: Maybe<Scalars['String']['output']>;
  amount_due?: Maybe<Scalars['bigint']['output']>;
  amount_paid?: Maybe<Scalars['bigint']['output']>;
  amount_remaining?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  attempt_count?: Maybe<Scalars['bigint']['output']>;
  automatic_tax_status?: Maybe<Scalars['String']['output']>;
  billing_reason?: Maybe<Scalars['String']['output']>;
  charge_id?: Maybe<Scalars['String']['output']>;
  collection_method?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_address_city?: Maybe<Scalars['String']['output']>;
  customer_address_country?: Maybe<Scalars['String']['output']>;
  customer_address_line1?: Maybe<Scalars['String']['output']>;
  customer_address_line2?: Maybe<Scalars['String']['output']>;
  customer_address_postal_code?: Maybe<Scalars['String']['output']>;
  customer_address_state?: Maybe<Scalars['String']['output']>;
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  customer_phone?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_city?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_country?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_line1?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_line2?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_state?: Maybe<Scalars['String']['output']>;
  customer_shipping_name?: Maybe<Scalars['String']['output']>;
  customer_shipping_phone?: Maybe<Scalars['String']['output']>;
  customer_tax_exempt?: Maybe<Scalars['String']['output']>;
  default_payment_method_id?: Maybe<Scalars['String']['output']>;
  default_source_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['bigint']['output']>;
  ending_balance?: Maybe<Scalars['bigint']['output']>;
  footer?: Maybe<Scalars['String']['output']>;
  hosted_invoice_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_pdf?: Maybe<Scalars['String']['output']>;
  next_payment_attempt?: Maybe<Scalars['bigint']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  payment_intent_id?: Maybe<Scalars['String']['output']>;
  period_end?: Maybe<Scalars['bigint']['output']>;
  period_start?: Maybe<Scalars['bigint']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['bigint']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['bigint']['output']>;
  quote_id?: Maybe<Scalars['String']['output']>;
  receipt_number?: Maybe<Scalars['String']['output']>;
  starting_balance?: Maybe<Scalars['bigint']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['bigint']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['bigint']['output']>;
  tax?: Maybe<Scalars['bigint']['output']>;
  total?: Maybe<Scalars['bigint']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Stripe_Invoices_Min_Fields = {
  account_country?: Maybe<Scalars['String']['output']>;
  account_name?: Maybe<Scalars['String']['output']>;
  amount_due?: Maybe<Scalars['bigint']['output']>;
  amount_paid?: Maybe<Scalars['bigint']['output']>;
  amount_remaining?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  attempt_count?: Maybe<Scalars['bigint']['output']>;
  automatic_tax_status?: Maybe<Scalars['String']['output']>;
  billing_reason?: Maybe<Scalars['String']['output']>;
  charge_id?: Maybe<Scalars['String']['output']>;
  collection_method?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_address_city?: Maybe<Scalars['String']['output']>;
  customer_address_country?: Maybe<Scalars['String']['output']>;
  customer_address_line1?: Maybe<Scalars['String']['output']>;
  customer_address_line2?: Maybe<Scalars['String']['output']>;
  customer_address_postal_code?: Maybe<Scalars['String']['output']>;
  customer_address_state?: Maybe<Scalars['String']['output']>;
  customer_email?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  customer_phone?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_city?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_country?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_line1?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_line2?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  customer_shipping_address_state?: Maybe<Scalars['String']['output']>;
  customer_shipping_name?: Maybe<Scalars['String']['output']>;
  customer_shipping_phone?: Maybe<Scalars['String']['output']>;
  customer_tax_exempt?: Maybe<Scalars['String']['output']>;
  default_payment_method_id?: Maybe<Scalars['String']['output']>;
  default_source_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['bigint']['output']>;
  ending_balance?: Maybe<Scalars['bigint']['output']>;
  footer?: Maybe<Scalars['String']['output']>;
  hosted_invoice_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_pdf?: Maybe<Scalars['String']['output']>;
  next_payment_attempt?: Maybe<Scalars['bigint']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  payment_intent_id?: Maybe<Scalars['String']['output']>;
  period_end?: Maybe<Scalars['bigint']['output']>;
  period_start?: Maybe<Scalars['bigint']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['bigint']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['bigint']['output']>;
  quote_id?: Maybe<Scalars['String']['output']>;
  receipt_number?: Maybe<Scalars['String']['output']>;
  starting_balance?: Maybe<Scalars['bigint']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['bigint']['output']>;
  subscription_id?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['bigint']['output']>;
  tax?: Maybe<Scalars['bigint']['output']>;
  total?: Maybe<Scalars['bigint']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "stripe_invoices" */
export type Stripe_Invoices_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Invoices>;
};

/** on_conflict condition type for table "stripe_invoices" */
export type Stripe_Invoices_On_Conflict = {
  constraint: Stripe_Invoices_Constraint;
  update_columns?: Array<Stripe_Invoices_Update_Column>;
  where?: InputMaybe<Stripe_Invoices_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_invoices". */
export type Stripe_Invoices_Order_By = {
  account_country?: InputMaybe<Order_By>;
  account_name?: InputMaybe<Order_By>;
  account_tax_ids?: InputMaybe<Order_By>;
  amount_due?: InputMaybe<Order_By>;
  amount_paid?: InputMaybe<Order_By>;
  amount_remaining?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  attempt_count?: InputMaybe<Order_By>;
  attempted?: InputMaybe<Order_By>;
  auto_advance?: InputMaybe<Order_By>;
  automatic_tax_enabled?: InputMaybe<Order_By>;
  automatic_tax_status?: InputMaybe<Order_By>;
  billing_reason?: InputMaybe<Order_By>;
  charge_id?: InputMaybe<Order_By>;
  collection_method?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  custom_fields?: InputMaybe<Order_By>;
  customer_address_city?: InputMaybe<Order_By>;
  customer_address_country?: InputMaybe<Order_By>;
  customer_address_line1?: InputMaybe<Order_By>;
  customer_address_line2?: InputMaybe<Order_By>;
  customer_address_postal_code?: InputMaybe<Order_By>;
  customer_address_state?: InputMaybe<Order_By>;
  customer_email?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  customer_phone?: InputMaybe<Order_By>;
  customer_shipping_address_city?: InputMaybe<Order_By>;
  customer_shipping_address_country?: InputMaybe<Order_By>;
  customer_shipping_address_line1?: InputMaybe<Order_By>;
  customer_shipping_address_line2?: InputMaybe<Order_By>;
  customer_shipping_address_postal_code?: InputMaybe<Order_By>;
  customer_shipping_address_state?: InputMaybe<Order_By>;
  customer_shipping_name?: InputMaybe<Order_By>;
  customer_shipping_phone?: InputMaybe<Order_By>;
  customer_tax_exempt?: InputMaybe<Order_By>;
  customer_tax_ids?: InputMaybe<Order_By>;
  default_payment_method_id?: InputMaybe<Order_By>;
  default_source_id?: InputMaybe<Order_By>;
  default_tax_rates?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  discounts?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  ending_balance?: InputMaybe<Order_By>;
  footer?: InputMaybe<Order_By>;
  hosted_invoice_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_pdf?: InputMaybe<Order_By>;
  last_finalization_error?: InputMaybe<Order_By>;
  lines?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_payment_attempt?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  on_behalf_of_id?: InputMaybe<Order_By>;
  paid?: InputMaybe<Order_By>;
  paid_out_of_band?: InputMaybe<Order_By>;
  payment_intent_id?: InputMaybe<Order_By>;
  payment_settings?: InputMaybe<Order_By>;
  period_end?: InputMaybe<Order_By>;
  period_start?: InputMaybe<Order_By>;
  post_payment_credit_notes_amount?: InputMaybe<Order_By>;
  pre_payment_credit_notes_amount?: InputMaybe<Order_By>;
  quote_id?: InputMaybe<Order_By>;
  receipt_number?: InputMaybe<Order_By>;
  starting_balance?: InputMaybe<Order_By>;
  statement_descriptor?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_transitions_finalized_at?: InputMaybe<Order_By>;
  status_transitions_marked_uncollectible_at?: InputMaybe<Order_By>;
  status_transitions_paid_at?: InputMaybe<Order_By>;
  status_transitions_voided_at?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  subtotal?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  total_discount_amounts?: InputMaybe<Order_By>;
  total_tax_amounts?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
  transfer_datadestination_id?: InputMaybe<Order_By>;
  webhooks_delivered_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_invoices */
export type Stripe_Invoices_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Invoices_Prepend_Input = {
  account_tax_ids?: InputMaybe<Scalars['jsonb']['input']>;
  custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  customer_tax_ids?: InputMaybe<Scalars['jsonb']['input']>;
  default_tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  discount?: InputMaybe<Scalars['jsonb']['input']>;
  discounts?: InputMaybe<Scalars['jsonb']['input']>;
  last_finalization_error?: InputMaybe<Scalars['jsonb']['input']>;
  lines?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  payment_settings?: InputMaybe<Scalars['jsonb']['input']>;
  total_discount_amounts?: InputMaybe<Scalars['jsonb']['input']>;
  total_tax_amounts?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_invoices" */
export enum Stripe_Invoices_Select_Column {
  /** column name */
  AccountCountry = 'account_country',
  /** column name */
  AccountName = 'account_name',
  /** column name */
  AccountTaxIds = 'account_tax_ids',
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  AmountPaid = 'amount_paid',
  /** column name */
  AmountRemaining = 'amount_remaining',
  /** column name */
  ApplicationFeeAmount = 'application_fee_amount',
  /** column name */
  AttemptCount = 'attempt_count',
  /** column name */
  Attempted = 'attempted',
  /** column name */
  AutoAdvance = 'auto_advance',
  /** column name */
  AutomaticTaxEnabled = 'automatic_tax_enabled',
  /** column name */
  AutomaticTaxStatus = 'automatic_tax_status',
  /** column name */
  BillingReason = 'billing_reason',
  /** column name */
  ChargeId = 'charge_id',
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  Created = 'created',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomFields = 'custom_fields',
  /** column name */
  CustomerAddressCity = 'customer_address_city',
  /** column name */
  CustomerAddressCountry = 'customer_address_country',
  /** column name */
  CustomerAddressLine1 = 'customer_address_line1',
  /** column name */
  CustomerAddressLine2 = 'customer_address_line2',
  /** column name */
  CustomerAddressPostalCode = 'customer_address_postal_code',
  /** column name */
  CustomerAddressState = 'customer_address_state',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  CustomerPhone = 'customer_phone',
  /** column name */
  CustomerShippingAddressCity = 'customer_shipping_address_city',
  /** column name */
  CustomerShippingAddressCountry = 'customer_shipping_address_country',
  /** column name */
  CustomerShippingAddressLine1 = 'customer_shipping_address_line1',
  /** column name */
  CustomerShippingAddressLine2 = 'customer_shipping_address_line2',
  /** column name */
  CustomerShippingAddressPostalCode = 'customer_shipping_address_postal_code',
  /** column name */
  CustomerShippingAddressState = 'customer_shipping_address_state',
  /** column name */
  CustomerShippingName = 'customer_shipping_name',
  /** column name */
  CustomerShippingPhone = 'customer_shipping_phone',
  /** column name */
  CustomerTaxExempt = 'customer_tax_exempt',
  /** column name */
  CustomerTaxIds = 'customer_tax_ids',
  /** column name */
  DefaultPaymentMethodId = 'default_payment_method_id',
  /** column name */
  DefaultSourceId = 'default_source_id',
  /** column name */
  DefaultTaxRates = 'default_tax_rates',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  Discounts = 'discounts',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  EndingBalance = 'ending_balance',
  /** column name */
  Footer = 'footer',
  /** column name */
  HostedInvoiceUrl = 'hosted_invoice_url',
  /** column name */
  Id = 'id',
  /** column name */
  InvoicePdf = 'invoice_pdf',
  /** column name */
  LastFinalizationError = 'last_finalization_error',
  /** column name */
  Lines = 'lines',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextPaymentAttempt = 'next_payment_attempt',
  /** column name */
  Number = 'number',
  /** column name */
  OnBehalfOfId = 'on_behalf_of_id',
  /** column name */
  Paid = 'paid',
  /** column name */
  PaidOutOfBand = 'paid_out_of_band',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  PaymentSettings = 'payment_settings',
  /** column name */
  PeriodEnd = 'period_end',
  /** column name */
  PeriodStart = 'period_start',
  /** column name */
  PostPaymentCreditNotesAmount = 'post_payment_credit_notes_amount',
  /** column name */
  PrePaymentCreditNotesAmount = 'pre_payment_credit_notes_amount',
  /** column name */
  QuoteId = 'quote_id',
  /** column name */
  ReceiptNumber = 'receipt_number',
  /** column name */
  StartingBalance = 'starting_balance',
  /** column name */
  StatementDescriptor = 'statement_descriptor',
  /** column name */
  Status = 'status',
  /** column name */
  StatusTransitionsFinalizedAt = 'status_transitions_finalized_at',
  /** column name */
  StatusTransitionsMarkedUncollectibleAt = 'status_transitions_marked_uncollectible_at',
  /** column name */
  StatusTransitionsPaidAt = 'status_transitions_paid_at',
  /** column name */
  StatusTransitionsVoidedAt = 'status_transitions_voided_at',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  Subtotal = 'subtotal',
  /** column name */
  Tax = 'tax',
  /** column name */
  Total = 'total',
  /** column name */
  TotalDiscountAmounts = 'total_discount_amounts',
  /** column name */
  TotalTaxAmounts = 'total_tax_amounts',
  /** column name */
  TransferDataamount = 'transfer_dataamount',
  /** column name */
  TransferDatadestinationId = 'transfer_datadestination_id',
  /** column name */
  WebhooksDeliveredAt = 'webhooks_delivered_at'
}

/** input type for updating data in table "stripe_invoices" */
export type Stripe_Invoices_Set_Input = {
  account_country?: InputMaybe<Scalars['String']['input']>;
  account_name?: InputMaybe<Scalars['String']['input']>;
  account_tax_ids?: InputMaybe<Scalars['jsonb']['input']>;
  amount_due?: InputMaybe<Scalars['bigint']['input']>;
  amount_paid?: InputMaybe<Scalars['bigint']['input']>;
  amount_remaining?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  attempt_count?: InputMaybe<Scalars['bigint']['input']>;
  attempted?: InputMaybe<Scalars['Boolean']['input']>;
  auto_advance?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_tax_status?: InputMaybe<Scalars['String']['input']>;
  billing_reason?: InputMaybe<Scalars['String']['input']>;
  charge_id?: InputMaybe<Scalars['String']['input']>;
  collection_method?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  customer_address_city?: InputMaybe<Scalars['String']['input']>;
  customer_address_country?: InputMaybe<Scalars['String']['input']>;
  customer_address_line1?: InputMaybe<Scalars['String']['input']>;
  customer_address_line2?: InputMaybe<Scalars['String']['input']>;
  customer_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  customer_address_state?: InputMaybe<Scalars['String']['input']>;
  customer_email?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  customer_phone?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_name?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_phone?: InputMaybe<Scalars['String']['input']>;
  customer_tax_exempt?: InputMaybe<Scalars['String']['input']>;
  customer_tax_ids?: InputMaybe<Scalars['jsonb']['input']>;
  default_payment_method_id?: InputMaybe<Scalars['String']['input']>;
  default_source_id?: InputMaybe<Scalars['String']['input']>;
  default_tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['jsonb']['input']>;
  discounts?: InputMaybe<Scalars['jsonb']['input']>;
  due_date?: InputMaybe<Scalars['bigint']['input']>;
  ending_balance?: InputMaybe<Scalars['bigint']['input']>;
  footer?: InputMaybe<Scalars['String']['input']>;
  hosted_invoice_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_pdf?: InputMaybe<Scalars['String']['input']>;
  last_finalization_error?: InputMaybe<Scalars['jsonb']['input']>;
  lines?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_payment_attempt?: InputMaybe<Scalars['bigint']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  paid_out_of_band?: InputMaybe<Scalars['Boolean']['input']>;
  payment_intent_id?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<Scalars['jsonb']['input']>;
  period_end?: InputMaybe<Scalars['bigint']['input']>;
  period_start?: InputMaybe<Scalars['bigint']['input']>;
  post_payment_credit_notes_amount?: InputMaybe<Scalars['bigint']['input']>;
  pre_payment_credit_notes_amount?: InputMaybe<Scalars['bigint']['input']>;
  quote_id?: InputMaybe<Scalars['String']['input']>;
  receipt_number?: InputMaybe<Scalars['String']['input']>;
  starting_balance?: InputMaybe<Scalars['bigint']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_transitions_finalized_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_marked_uncollectible_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_paid_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_voided_at?: InputMaybe<Scalars['bigint']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  subtotal?: InputMaybe<Scalars['bigint']['input']>;
  tax?: InputMaybe<Scalars['bigint']['input']>;
  total?: InputMaybe<Scalars['bigint']['input']>;
  total_discount_amounts?: InputMaybe<Scalars['jsonb']['input']>;
  total_tax_amounts?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  webhooks_delivered_at?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Invoices_Stddev_Fields = {
  amount_due?: Maybe<Scalars['Float']['output']>;
  amount_paid?: Maybe<Scalars['Float']['output']>;
  amount_remaining?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  attempt_count?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  due_date?: Maybe<Scalars['Float']['output']>;
  ending_balance?: Maybe<Scalars['Float']['output']>;
  next_payment_attempt?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  starting_balance?: Maybe<Scalars['Float']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['Float']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Invoices_Stddev_Pop_Fields = {
  amount_due?: Maybe<Scalars['Float']['output']>;
  amount_paid?: Maybe<Scalars['Float']['output']>;
  amount_remaining?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  attempt_count?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  due_date?: Maybe<Scalars['Float']['output']>;
  ending_balance?: Maybe<Scalars['Float']['output']>;
  next_payment_attempt?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  starting_balance?: Maybe<Scalars['Float']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['Float']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Invoices_Stddev_Samp_Fields = {
  amount_due?: Maybe<Scalars['Float']['output']>;
  amount_paid?: Maybe<Scalars['Float']['output']>;
  amount_remaining?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  attempt_count?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  due_date?: Maybe<Scalars['Float']['output']>;
  ending_balance?: Maybe<Scalars['Float']['output']>;
  next_payment_attempt?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  starting_balance?: Maybe<Scalars['Float']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['Float']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stripe_invoices" */
export type Stripe_Invoices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Invoices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Invoices_Stream_Cursor_Value_Input = {
  account_country?: InputMaybe<Scalars['String']['input']>;
  account_name?: InputMaybe<Scalars['String']['input']>;
  account_tax_ids?: InputMaybe<Scalars['jsonb']['input']>;
  amount_due?: InputMaybe<Scalars['bigint']['input']>;
  amount_paid?: InputMaybe<Scalars['bigint']['input']>;
  amount_remaining?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  attempt_count?: InputMaybe<Scalars['bigint']['input']>;
  attempted?: InputMaybe<Scalars['Boolean']['input']>;
  auto_advance?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  automatic_tax_status?: InputMaybe<Scalars['String']['input']>;
  billing_reason?: InputMaybe<Scalars['String']['input']>;
  charge_id?: InputMaybe<Scalars['String']['input']>;
  collection_method?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  custom_fields?: InputMaybe<Scalars['jsonb']['input']>;
  customer_address_city?: InputMaybe<Scalars['String']['input']>;
  customer_address_country?: InputMaybe<Scalars['String']['input']>;
  customer_address_line1?: InputMaybe<Scalars['String']['input']>;
  customer_address_line2?: InputMaybe<Scalars['String']['input']>;
  customer_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  customer_address_state?: InputMaybe<Scalars['String']['input']>;
  customer_email?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  customer_phone?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_name?: InputMaybe<Scalars['String']['input']>;
  customer_shipping_phone?: InputMaybe<Scalars['String']['input']>;
  customer_tax_exempt?: InputMaybe<Scalars['String']['input']>;
  customer_tax_ids?: InputMaybe<Scalars['jsonb']['input']>;
  default_payment_method_id?: InputMaybe<Scalars['String']['input']>;
  default_source_id?: InputMaybe<Scalars['String']['input']>;
  default_tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['jsonb']['input']>;
  discounts?: InputMaybe<Scalars['jsonb']['input']>;
  due_date?: InputMaybe<Scalars['bigint']['input']>;
  ending_balance?: InputMaybe<Scalars['bigint']['input']>;
  footer?: InputMaybe<Scalars['String']['input']>;
  hosted_invoice_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_pdf?: InputMaybe<Scalars['String']['input']>;
  last_finalization_error?: InputMaybe<Scalars['jsonb']['input']>;
  lines?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_payment_attempt?: InputMaybe<Scalars['bigint']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  paid_out_of_band?: InputMaybe<Scalars['Boolean']['input']>;
  payment_intent_id?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<Scalars['jsonb']['input']>;
  period_end?: InputMaybe<Scalars['bigint']['input']>;
  period_start?: InputMaybe<Scalars['bigint']['input']>;
  post_payment_credit_notes_amount?: InputMaybe<Scalars['bigint']['input']>;
  pre_payment_credit_notes_amount?: InputMaybe<Scalars['bigint']['input']>;
  quote_id?: InputMaybe<Scalars['String']['input']>;
  receipt_number?: InputMaybe<Scalars['String']['input']>;
  starting_balance?: InputMaybe<Scalars['bigint']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_transitions_finalized_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_marked_uncollectible_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_paid_at?: InputMaybe<Scalars['bigint']['input']>;
  status_transitions_voided_at?: InputMaybe<Scalars['bigint']['input']>;
  subscription_id?: InputMaybe<Scalars['String']['input']>;
  subtotal?: InputMaybe<Scalars['bigint']['input']>;
  tax?: InputMaybe<Scalars['bigint']['input']>;
  total?: InputMaybe<Scalars['bigint']['input']>;
  total_discount_amounts?: InputMaybe<Scalars['jsonb']['input']>;
  total_tax_amounts?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  webhooks_delivered_at?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Invoices_Sum_Fields = {
  amount_due?: Maybe<Scalars['bigint']['output']>;
  amount_paid?: Maybe<Scalars['bigint']['output']>;
  amount_remaining?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  attempt_count?: Maybe<Scalars['bigint']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  due_date?: Maybe<Scalars['bigint']['output']>;
  ending_balance?: Maybe<Scalars['bigint']['output']>;
  next_payment_attempt?: Maybe<Scalars['bigint']['output']>;
  period_end?: Maybe<Scalars['bigint']['output']>;
  period_start?: Maybe<Scalars['bigint']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['bigint']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['bigint']['output']>;
  starting_balance?: Maybe<Scalars['bigint']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['bigint']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['bigint']['output']>;
  subtotal?: Maybe<Scalars['bigint']['output']>;
  tax?: Maybe<Scalars['bigint']['output']>;
  total?: Maybe<Scalars['bigint']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stripe_invoices" */
export enum Stripe_Invoices_Update_Column {
  /** column name */
  AccountCountry = 'account_country',
  /** column name */
  AccountName = 'account_name',
  /** column name */
  AccountTaxIds = 'account_tax_ids',
  /** column name */
  AmountDue = 'amount_due',
  /** column name */
  AmountPaid = 'amount_paid',
  /** column name */
  AmountRemaining = 'amount_remaining',
  /** column name */
  ApplicationFeeAmount = 'application_fee_amount',
  /** column name */
  AttemptCount = 'attempt_count',
  /** column name */
  Attempted = 'attempted',
  /** column name */
  AutoAdvance = 'auto_advance',
  /** column name */
  AutomaticTaxEnabled = 'automatic_tax_enabled',
  /** column name */
  AutomaticTaxStatus = 'automatic_tax_status',
  /** column name */
  BillingReason = 'billing_reason',
  /** column name */
  ChargeId = 'charge_id',
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  Created = 'created',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomFields = 'custom_fields',
  /** column name */
  CustomerAddressCity = 'customer_address_city',
  /** column name */
  CustomerAddressCountry = 'customer_address_country',
  /** column name */
  CustomerAddressLine1 = 'customer_address_line1',
  /** column name */
  CustomerAddressLine2 = 'customer_address_line2',
  /** column name */
  CustomerAddressPostalCode = 'customer_address_postal_code',
  /** column name */
  CustomerAddressState = 'customer_address_state',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  CustomerPhone = 'customer_phone',
  /** column name */
  CustomerShippingAddressCity = 'customer_shipping_address_city',
  /** column name */
  CustomerShippingAddressCountry = 'customer_shipping_address_country',
  /** column name */
  CustomerShippingAddressLine1 = 'customer_shipping_address_line1',
  /** column name */
  CustomerShippingAddressLine2 = 'customer_shipping_address_line2',
  /** column name */
  CustomerShippingAddressPostalCode = 'customer_shipping_address_postal_code',
  /** column name */
  CustomerShippingAddressState = 'customer_shipping_address_state',
  /** column name */
  CustomerShippingName = 'customer_shipping_name',
  /** column name */
  CustomerShippingPhone = 'customer_shipping_phone',
  /** column name */
  CustomerTaxExempt = 'customer_tax_exempt',
  /** column name */
  CustomerTaxIds = 'customer_tax_ids',
  /** column name */
  DefaultPaymentMethodId = 'default_payment_method_id',
  /** column name */
  DefaultSourceId = 'default_source_id',
  /** column name */
  DefaultTaxRates = 'default_tax_rates',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  Discounts = 'discounts',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  EndingBalance = 'ending_balance',
  /** column name */
  Footer = 'footer',
  /** column name */
  HostedInvoiceUrl = 'hosted_invoice_url',
  /** column name */
  Id = 'id',
  /** column name */
  InvoicePdf = 'invoice_pdf',
  /** column name */
  LastFinalizationError = 'last_finalization_error',
  /** column name */
  Lines = 'lines',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextPaymentAttempt = 'next_payment_attempt',
  /** column name */
  Number = 'number',
  /** column name */
  OnBehalfOfId = 'on_behalf_of_id',
  /** column name */
  Paid = 'paid',
  /** column name */
  PaidOutOfBand = 'paid_out_of_band',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  PaymentSettings = 'payment_settings',
  /** column name */
  PeriodEnd = 'period_end',
  /** column name */
  PeriodStart = 'period_start',
  /** column name */
  PostPaymentCreditNotesAmount = 'post_payment_credit_notes_amount',
  /** column name */
  PrePaymentCreditNotesAmount = 'pre_payment_credit_notes_amount',
  /** column name */
  QuoteId = 'quote_id',
  /** column name */
  ReceiptNumber = 'receipt_number',
  /** column name */
  StartingBalance = 'starting_balance',
  /** column name */
  StatementDescriptor = 'statement_descriptor',
  /** column name */
  Status = 'status',
  /** column name */
  StatusTransitionsFinalizedAt = 'status_transitions_finalized_at',
  /** column name */
  StatusTransitionsMarkedUncollectibleAt = 'status_transitions_marked_uncollectible_at',
  /** column name */
  StatusTransitionsPaidAt = 'status_transitions_paid_at',
  /** column name */
  StatusTransitionsVoidedAt = 'status_transitions_voided_at',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  Subtotal = 'subtotal',
  /** column name */
  Tax = 'tax',
  /** column name */
  Total = 'total',
  /** column name */
  TotalDiscountAmounts = 'total_discount_amounts',
  /** column name */
  TotalTaxAmounts = 'total_tax_amounts',
  /** column name */
  TransferDataamount = 'transfer_dataamount',
  /** column name */
  TransferDatadestinationId = 'transfer_datadestination_id',
  /** column name */
  WebhooksDeliveredAt = 'webhooks_delivered_at'
}

export type Stripe_Invoices_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Invoices_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Invoices_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Invoices_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Invoices_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Invoices_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Invoices_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Invoices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Invoices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Invoices_Var_Pop_Fields = {
  amount_due?: Maybe<Scalars['Float']['output']>;
  amount_paid?: Maybe<Scalars['Float']['output']>;
  amount_remaining?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  attempt_count?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  due_date?: Maybe<Scalars['Float']['output']>;
  ending_balance?: Maybe<Scalars['Float']['output']>;
  next_payment_attempt?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  starting_balance?: Maybe<Scalars['Float']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['Float']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stripe_Invoices_Var_Samp_Fields = {
  amount_due?: Maybe<Scalars['Float']['output']>;
  amount_paid?: Maybe<Scalars['Float']['output']>;
  amount_remaining?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  attempt_count?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  due_date?: Maybe<Scalars['Float']['output']>;
  ending_balance?: Maybe<Scalars['Float']['output']>;
  next_payment_attempt?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  starting_balance?: Maybe<Scalars['Float']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['Float']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stripe_Invoices_Variance_Fields = {
  amount_due?: Maybe<Scalars['Float']['output']>;
  amount_paid?: Maybe<Scalars['Float']['output']>;
  amount_remaining?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  attempt_count?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  due_date?: Maybe<Scalars['Float']['output']>;
  ending_balance?: Maybe<Scalars['Float']['output']>;
  next_payment_attempt?: Maybe<Scalars['Float']['output']>;
  period_end?: Maybe<Scalars['Float']['output']>;
  period_start?: Maybe<Scalars['Float']['output']>;
  post_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  pre_payment_credit_notes_amount?: Maybe<Scalars['Float']['output']>;
  starting_balance?: Maybe<Scalars['Float']['output']>;
  status_transitions_finalized_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_marked_uncollectible_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_paid_at?: Maybe<Scalars['Float']['output']>;
  status_transitions_voided_at?: Maybe<Scalars['Float']['output']>;
  subtotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
  webhooks_delivered_at?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stripe_payment_intents" */
export type Stripe_Payment_Intents = {
  amount?: Maybe<Scalars['bigint']['output']>;
  amount_capturable?: Maybe<Scalars['bigint']['output']>;
  amount_received?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  application_id?: Maybe<Scalars['String']['output']>;
  automatic_payment_methods_enabled?: Maybe<Scalars['Boolean']['output']>;
  canceled_at?: Maybe<Scalars['bigint']['output']>;
  cancellation_reason?: Maybe<Scalars['String']['output']>;
  capture_method?: Maybe<Scalars['String']['output']>;
  charges_data?: Maybe<Scalars['jsonb']['output']>;
  confirmation_method?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  customer?: Maybe<Stripe_Customers>;
  customer_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoice_id?: Maybe<Scalars['String']['output']>;
  last_payment_error?: Maybe<Scalars['jsonb']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_action?: Maybe<Scalars['jsonb']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  payment_method_id?: Maybe<Scalars['String']['output']>;
  payment_method_options?: Maybe<Scalars['jsonb']['output']>;
  payment_method_types?: Maybe<Scalars['jsonb']['output']>;
  processing?: Maybe<Scalars['jsonb']['output']>;
  receipt_email?: Maybe<Scalars['String']['output']>;
  review_id?: Maybe<Scalars['String']['output']>;
  setup_future_usage?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_email?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  shipping_tracking_number?: Maybe<Scalars['String']['output']>;
  source_id?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  transfer_group?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "stripe_payment_intents" */
export type Stripe_Payment_IntentsCharges_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_intents" */
export type Stripe_Payment_IntentsLast_Payment_ErrorArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_intents" */
export type Stripe_Payment_IntentsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_intents" */
export type Stripe_Payment_IntentsNext_ActionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_intents" */
export type Stripe_Payment_IntentsPayment_Method_OptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_intents" */
export type Stripe_Payment_IntentsPayment_Method_TypesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_intents" */
export type Stripe_Payment_IntentsProcessingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_payment_intents" */
export type Stripe_Payment_Intents_Aggregate = {
  aggregate?: Maybe<Stripe_Payment_Intents_Aggregate_Fields>;
  nodes: Array<Stripe_Payment_Intents>;
};

/** aggregate fields of "stripe_payment_intents" */
export type Stripe_Payment_Intents_Aggregate_Fields = {
  avg?: Maybe<Stripe_Payment_Intents_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Payment_Intents_Max_Fields>;
  min?: Maybe<Stripe_Payment_Intents_Min_Fields>;
  stddev?: Maybe<Stripe_Payment_Intents_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Payment_Intents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Payment_Intents_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Payment_Intents_Sum_Fields>;
  var_pop?: Maybe<Stripe_Payment_Intents_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Payment_Intents_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Payment_Intents_Variance_Fields>;
};


/** aggregate fields of "stripe_payment_intents" */
export type Stripe_Payment_Intents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Payment_Intents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Payment_Intents_Append_Input = {
  charges_data?: InputMaybe<Scalars['jsonb']['input']>;
  last_payment_error?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_action?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_options?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  processing?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Stripe_Payment_Intents_Avg_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_capturable?: Maybe<Scalars['Float']['output']>;
  amount_received?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stripe_payment_intents". All fields are combined with a logical 'AND'. */
export type Stripe_Payment_Intents_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Payment_Intents_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Payment_Intents_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Payment_Intents_Bool_Exp>>;
  amount?: InputMaybe<Bigint_Comparison_Exp>;
  amount_capturable?: InputMaybe<Bigint_Comparison_Exp>;
  amount_received?: InputMaybe<Bigint_Comparison_Exp>;
  application_fee_amount?: InputMaybe<Bigint_Comparison_Exp>;
  application_id?: InputMaybe<String_Comparison_Exp>;
  automatic_payment_methods_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  canceled_at?: InputMaybe<Bigint_Comparison_Exp>;
  cancellation_reason?: InputMaybe<String_Comparison_Exp>;
  capture_method?: InputMaybe<String_Comparison_Exp>;
  charges_data?: InputMaybe<Jsonb_Comparison_Exp>;
  confirmation_method?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Bigint_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  customer?: InputMaybe<Stripe_Customers_Bool_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  last_payment_error?: InputMaybe<Jsonb_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_action?: InputMaybe<Jsonb_Comparison_Exp>;
  on_behalf_of_id?: InputMaybe<String_Comparison_Exp>;
  payment_method_id?: InputMaybe<String_Comparison_Exp>;
  payment_method_options?: InputMaybe<Jsonb_Comparison_Exp>;
  payment_method_types?: InputMaybe<Jsonb_Comparison_Exp>;
  processing?: InputMaybe<Jsonb_Comparison_Exp>;
  receipt_email?: InputMaybe<String_Comparison_Exp>;
  review_id?: InputMaybe<String_Comparison_Exp>;
  setup_future_usage?: InputMaybe<String_Comparison_Exp>;
  shipping_address_city?: InputMaybe<String_Comparison_Exp>;
  shipping_address_country?: InputMaybe<String_Comparison_Exp>;
  shipping_address_line1?: InputMaybe<String_Comparison_Exp>;
  shipping_address_line2?: InputMaybe<String_Comparison_Exp>;
  shipping_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  shipping_address_state?: InputMaybe<String_Comparison_Exp>;
  shipping_email?: InputMaybe<String_Comparison_Exp>;
  shipping_name?: InputMaybe<String_Comparison_Exp>;
  shipping_phone?: InputMaybe<String_Comparison_Exp>;
  shipping_tracking_number?: InputMaybe<String_Comparison_Exp>;
  source_id?: InputMaybe<String_Comparison_Exp>;
  statement_descriptor?: InputMaybe<String_Comparison_Exp>;
  statement_descriptor_suffix?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  transfer_dataamount?: InputMaybe<Bigint_Comparison_Exp>;
  transfer_datadestination_id?: InputMaybe<String_Comparison_Exp>;
  transfer_group?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_payment_intents" */
export enum Stripe_Payment_Intents_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripePaymentIntentsPkey = 'stripe_payment_intents_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Payment_Intents_Delete_At_Path_Input = {
  charges_data?: InputMaybe<Array<Scalars['String']['input']>>;
  last_payment_error?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  next_action?: InputMaybe<Array<Scalars['String']['input']>>;
  payment_method_options?: InputMaybe<Array<Scalars['String']['input']>>;
  payment_method_types?: InputMaybe<Array<Scalars['String']['input']>>;
  processing?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Payment_Intents_Delete_Elem_Input = {
  charges_data?: InputMaybe<Scalars['Int']['input']>;
  last_payment_error?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  next_action?: InputMaybe<Scalars['Int']['input']>;
  payment_method_options?: InputMaybe<Scalars['Int']['input']>;
  payment_method_types?: InputMaybe<Scalars['Int']['input']>;
  processing?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Payment_Intents_Delete_Key_Input = {
  charges_data?: InputMaybe<Scalars['String']['input']>;
  last_payment_error?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  next_action?: InputMaybe<Scalars['String']['input']>;
  payment_method_options?: InputMaybe<Scalars['String']['input']>;
  payment_method_types?: InputMaybe<Scalars['String']['input']>;
  processing?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_payment_intents" */
export type Stripe_Payment_Intents_Inc_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  amount_capturable?: InputMaybe<Scalars['bigint']['input']>;
  amount_received?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  canceled_at?: InputMaybe<Scalars['bigint']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stripe_payment_intents" */
export type Stripe_Payment_Intents_Insert_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  amount_capturable?: InputMaybe<Scalars['bigint']['input']>;
  amount_received?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  application_id?: InputMaybe<Scalars['String']['input']>;
  automatic_payment_methods_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  canceled_at?: InputMaybe<Scalars['bigint']['input']>;
  cancellation_reason?: InputMaybe<Scalars['String']['input']>;
  capture_method?: InputMaybe<Scalars['String']['input']>;
  charges_data?: InputMaybe<Scalars['jsonb']['input']>;
  confirmation_method?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Stripe_Customers_Obj_Rel_Insert_Input>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  last_payment_error?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_action?: InputMaybe<Scalars['jsonb']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_options?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  processing?: InputMaybe<Scalars['jsonb']['input']>;
  receipt_email?: InputMaybe<Scalars['String']['input']>;
  review_id?: InputMaybe<Scalars['String']['input']>;
  setup_future_usage?: InputMaybe<Scalars['String']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_email?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  shipping_tracking_number?: InputMaybe<Scalars['String']['input']>;
  source_id?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor_suffix?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  transfer_group?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Stripe_Payment_Intents_Max_Fields = {
  amount?: Maybe<Scalars['bigint']['output']>;
  amount_capturable?: Maybe<Scalars['bigint']['output']>;
  amount_received?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  application_id?: Maybe<Scalars['String']['output']>;
  canceled_at?: Maybe<Scalars['bigint']['output']>;
  cancellation_reason?: Maybe<Scalars['String']['output']>;
  capture_method?: Maybe<Scalars['String']['output']>;
  confirmation_method?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_id?: Maybe<Scalars['String']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  payment_method_id?: Maybe<Scalars['String']['output']>;
  receipt_email?: Maybe<Scalars['String']['output']>;
  review_id?: Maybe<Scalars['String']['output']>;
  setup_future_usage?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_email?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  shipping_tracking_number?: Maybe<Scalars['String']['output']>;
  source_id?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  transfer_group?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Stripe_Payment_Intents_Min_Fields = {
  amount?: Maybe<Scalars['bigint']['output']>;
  amount_capturable?: Maybe<Scalars['bigint']['output']>;
  amount_received?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  application_id?: Maybe<Scalars['String']['output']>;
  canceled_at?: Maybe<Scalars['bigint']['output']>;
  cancellation_reason?: Maybe<Scalars['String']['output']>;
  capture_method?: Maybe<Scalars['String']['output']>;
  confirmation_method?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invoice_id?: Maybe<Scalars['String']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  payment_method_id?: Maybe<Scalars['String']['output']>;
  receipt_email?: Maybe<Scalars['String']['output']>;
  review_id?: Maybe<Scalars['String']['output']>;
  setup_future_usage?: Maybe<Scalars['String']['output']>;
  shipping_address_city?: Maybe<Scalars['String']['output']>;
  shipping_address_country?: Maybe<Scalars['String']['output']>;
  shipping_address_line1?: Maybe<Scalars['String']['output']>;
  shipping_address_line2?: Maybe<Scalars['String']['output']>;
  shipping_address_postal_code?: Maybe<Scalars['String']['output']>;
  shipping_address_state?: Maybe<Scalars['String']['output']>;
  shipping_email?: Maybe<Scalars['String']['output']>;
  shipping_name?: Maybe<Scalars['String']['output']>;
  shipping_phone?: Maybe<Scalars['String']['output']>;
  shipping_tracking_number?: Maybe<Scalars['String']['output']>;
  source_id?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  transfer_group?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "stripe_payment_intents" */
export type Stripe_Payment_Intents_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Payment_Intents>;
};

/** input type for inserting object relation for remote table "stripe_payment_intents" */
export type Stripe_Payment_Intents_Obj_Rel_Insert_Input = {
  data: Stripe_Payment_Intents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stripe_Payment_Intents_On_Conflict>;
};

/** on_conflict condition type for table "stripe_payment_intents" */
export type Stripe_Payment_Intents_On_Conflict = {
  constraint: Stripe_Payment_Intents_Constraint;
  update_columns?: Array<Stripe_Payment_Intents_Update_Column>;
  where?: InputMaybe<Stripe_Payment_Intents_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_payment_intents". */
export type Stripe_Payment_Intents_Order_By = {
  amount?: InputMaybe<Order_By>;
  amount_capturable?: InputMaybe<Order_By>;
  amount_received?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  application_id?: InputMaybe<Order_By>;
  automatic_payment_methods_enabled?: InputMaybe<Order_By>;
  canceled_at?: InputMaybe<Order_By>;
  cancellation_reason?: InputMaybe<Order_By>;
  capture_method?: InputMaybe<Order_By>;
  charges_data?: InputMaybe<Order_By>;
  confirmation_method?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  customer?: InputMaybe<Stripe_Customers_Order_By>;
  customer_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  last_payment_error?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_action?: InputMaybe<Order_By>;
  on_behalf_of_id?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  payment_method_options?: InputMaybe<Order_By>;
  payment_method_types?: InputMaybe<Order_By>;
  processing?: InputMaybe<Order_By>;
  receipt_email?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
  setup_future_usage?: InputMaybe<Order_By>;
  shipping_address_city?: InputMaybe<Order_By>;
  shipping_address_country?: InputMaybe<Order_By>;
  shipping_address_line1?: InputMaybe<Order_By>;
  shipping_address_line2?: InputMaybe<Order_By>;
  shipping_address_postal_code?: InputMaybe<Order_By>;
  shipping_address_state?: InputMaybe<Order_By>;
  shipping_email?: InputMaybe<Order_By>;
  shipping_name?: InputMaybe<Order_By>;
  shipping_phone?: InputMaybe<Order_By>;
  shipping_tracking_number?: InputMaybe<Order_By>;
  source_id?: InputMaybe<Order_By>;
  statement_descriptor?: InputMaybe<Order_By>;
  statement_descriptor_suffix?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
  transfer_datadestination_id?: InputMaybe<Order_By>;
  transfer_group?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_payment_intents */
export type Stripe_Payment_Intents_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Payment_Intents_Prepend_Input = {
  charges_data?: InputMaybe<Scalars['jsonb']['input']>;
  last_payment_error?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_action?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_options?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  processing?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_payment_intents" */
export enum Stripe_Payment_Intents_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountCapturable = 'amount_capturable',
  /** column name */
  AmountReceived = 'amount_received',
  /** column name */
  ApplicationFeeAmount = 'application_fee_amount',
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  AutomaticPaymentMethodsEnabled = 'automatic_payment_methods_enabled',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CancellationReason = 'cancellation_reason',
  /** column name */
  CaptureMethod = 'capture_method',
  /** column name */
  ChargesData = 'charges_data',
  /** column name */
  ConfirmationMethod = 'confirmation_method',
  /** column name */
  Created = 'created',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  LastPaymentError = 'last_payment_error',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextAction = 'next_action',
  /** column name */
  OnBehalfOfId = 'on_behalf_of_id',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  PaymentMethodOptions = 'payment_method_options',
  /** column name */
  PaymentMethodTypes = 'payment_method_types',
  /** column name */
  Processing = 'processing',
  /** column name */
  ReceiptEmail = 'receipt_email',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  SetupFutureUsage = 'setup_future_usage',
  /** column name */
  ShippingAddressCity = 'shipping_address_city',
  /** column name */
  ShippingAddressCountry = 'shipping_address_country',
  /** column name */
  ShippingAddressLine1 = 'shipping_address_line1',
  /** column name */
  ShippingAddressLine2 = 'shipping_address_line2',
  /** column name */
  ShippingAddressPostalCode = 'shipping_address_postal_code',
  /** column name */
  ShippingAddressState = 'shipping_address_state',
  /** column name */
  ShippingEmail = 'shipping_email',
  /** column name */
  ShippingName = 'shipping_name',
  /** column name */
  ShippingPhone = 'shipping_phone',
  /** column name */
  ShippingTrackingNumber = 'shipping_tracking_number',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  StatementDescriptor = 'statement_descriptor',
  /** column name */
  StatementDescriptorSuffix = 'statement_descriptor_suffix',
  /** column name */
  Status = 'status',
  /** column name */
  TransferDataamount = 'transfer_dataamount',
  /** column name */
  TransferDatadestinationId = 'transfer_datadestination_id',
  /** column name */
  TransferGroup = 'transfer_group'
}

/** input type for updating data in table "stripe_payment_intents" */
export type Stripe_Payment_Intents_Set_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  amount_capturable?: InputMaybe<Scalars['bigint']['input']>;
  amount_received?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  application_id?: InputMaybe<Scalars['String']['input']>;
  automatic_payment_methods_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  canceled_at?: InputMaybe<Scalars['bigint']['input']>;
  cancellation_reason?: InputMaybe<Scalars['String']['input']>;
  capture_method?: InputMaybe<Scalars['String']['input']>;
  charges_data?: InputMaybe<Scalars['jsonb']['input']>;
  confirmation_method?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  last_payment_error?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_action?: InputMaybe<Scalars['jsonb']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_options?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  processing?: InputMaybe<Scalars['jsonb']['input']>;
  receipt_email?: InputMaybe<Scalars['String']['input']>;
  review_id?: InputMaybe<Scalars['String']['input']>;
  setup_future_usage?: InputMaybe<Scalars['String']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_email?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  shipping_tracking_number?: InputMaybe<Scalars['String']['input']>;
  source_id?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor_suffix?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  transfer_group?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Payment_Intents_Stddev_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_capturable?: Maybe<Scalars['Float']['output']>;
  amount_received?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Payment_Intents_Stddev_Pop_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_capturable?: Maybe<Scalars['Float']['output']>;
  amount_received?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Payment_Intents_Stddev_Samp_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_capturable?: Maybe<Scalars['Float']['output']>;
  amount_received?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stripe_payment_intents" */
export type Stripe_Payment_Intents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Payment_Intents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Payment_Intents_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['bigint']['input']>;
  amount_capturable?: InputMaybe<Scalars['bigint']['input']>;
  amount_received?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  application_id?: InputMaybe<Scalars['String']['input']>;
  automatic_payment_methods_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  canceled_at?: InputMaybe<Scalars['bigint']['input']>;
  cancellation_reason?: InputMaybe<Scalars['String']['input']>;
  capture_method?: InputMaybe<Scalars['String']['input']>;
  charges_data?: InputMaybe<Scalars['jsonb']['input']>;
  confirmation_method?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoice_id?: InputMaybe<Scalars['String']['input']>;
  last_payment_error?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_action?: InputMaybe<Scalars['jsonb']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_options?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  processing?: InputMaybe<Scalars['jsonb']['input']>;
  receipt_email?: InputMaybe<Scalars['String']['input']>;
  review_id?: InputMaybe<Scalars['String']['input']>;
  setup_future_usage?: InputMaybe<Scalars['String']['input']>;
  shipping_address_city?: InputMaybe<Scalars['String']['input']>;
  shipping_address_country?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line1?: InputMaybe<Scalars['String']['input']>;
  shipping_address_line2?: InputMaybe<Scalars['String']['input']>;
  shipping_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  shipping_address_state?: InputMaybe<Scalars['String']['input']>;
  shipping_email?: InputMaybe<Scalars['String']['input']>;
  shipping_name?: InputMaybe<Scalars['String']['input']>;
  shipping_phone?: InputMaybe<Scalars['String']['input']>;
  shipping_tracking_number?: InputMaybe<Scalars['String']['input']>;
  source_id?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  statement_descriptor_suffix?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  transfer_group?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Payment_Intents_Sum_Fields = {
  amount?: Maybe<Scalars['bigint']['output']>;
  amount_capturable?: Maybe<Scalars['bigint']['output']>;
  amount_received?: Maybe<Scalars['bigint']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  canceled_at?: Maybe<Scalars['bigint']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stripe_payment_intents" */
export enum Stripe_Payment_Intents_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountCapturable = 'amount_capturable',
  /** column name */
  AmountReceived = 'amount_received',
  /** column name */
  ApplicationFeeAmount = 'application_fee_amount',
  /** column name */
  ApplicationId = 'application_id',
  /** column name */
  AutomaticPaymentMethodsEnabled = 'automatic_payment_methods_enabled',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CancellationReason = 'cancellation_reason',
  /** column name */
  CaptureMethod = 'capture_method',
  /** column name */
  ChargesData = 'charges_data',
  /** column name */
  ConfirmationMethod = 'confirmation_method',
  /** column name */
  Created = 'created',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  LastPaymentError = 'last_payment_error',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextAction = 'next_action',
  /** column name */
  OnBehalfOfId = 'on_behalf_of_id',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  PaymentMethodOptions = 'payment_method_options',
  /** column name */
  PaymentMethodTypes = 'payment_method_types',
  /** column name */
  Processing = 'processing',
  /** column name */
  ReceiptEmail = 'receipt_email',
  /** column name */
  ReviewId = 'review_id',
  /** column name */
  SetupFutureUsage = 'setup_future_usage',
  /** column name */
  ShippingAddressCity = 'shipping_address_city',
  /** column name */
  ShippingAddressCountry = 'shipping_address_country',
  /** column name */
  ShippingAddressLine1 = 'shipping_address_line1',
  /** column name */
  ShippingAddressLine2 = 'shipping_address_line2',
  /** column name */
  ShippingAddressPostalCode = 'shipping_address_postal_code',
  /** column name */
  ShippingAddressState = 'shipping_address_state',
  /** column name */
  ShippingEmail = 'shipping_email',
  /** column name */
  ShippingName = 'shipping_name',
  /** column name */
  ShippingPhone = 'shipping_phone',
  /** column name */
  ShippingTrackingNumber = 'shipping_tracking_number',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  StatementDescriptor = 'statement_descriptor',
  /** column name */
  StatementDescriptorSuffix = 'statement_descriptor_suffix',
  /** column name */
  Status = 'status',
  /** column name */
  TransferDataamount = 'transfer_dataamount',
  /** column name */
  TransferDatadestinationId = 'transfer_datadestination_id',
  /** column name */
  TransferGroup = 'transfer_group'
}

export type Stripe_Payment_Intents_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Payment_Intents_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Payment_Intents_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Payment_Intents_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Payment_Intents_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Payment_Intents_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Payment_Intents_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Payment_Intents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Payment_Intents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Payment_Intents_Var_Pop_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_capturable?: Maybe<Scalars['Float']['output']>;
  amount_received?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stripe_Payment_Intents_Var_Samp_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_capturable?: Maybe<Scalars['Float']['output']>;
  amount_received?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stripe_Payment_Intents_Variance_Fields = {
  amount?: Maybe<Scalars['Float']['output']>;
  amount_capturable?: Maybe<Scalars['Float']['output']>;
  amount_received?: Maybe<Scalars['Float']['output']>;
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stripe_payment_links" */
export type Stripe_Payment_Links = {
  active?: Maybe<Scalars['Boolean']['output']>;
  after_completion?: Maybe<Scalars['jsonb']['output']>;
  allow_promotion_codes?: Maybe<Scalars['Boolean']['output']>;
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  application_fee_percent?: Maybe<Scalars['numeric']['output']>;
  automatic_tax_enabled?: Maybe<Scalars['Boolean']['output']>;
  billing_address_collection?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  payment_method_types?: Maybe<Scalars['jsonb']['output']>;
  phone_number_collection_enabled?: Maybe<Scalars['Boolean']['output']>;
  shipping_address_collection?: Maybe<Scalars['jsonb']['output']>;
  subscription_data?: Maybe<Scalars['jsonb']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "stripe_payment_links" */
export type Stripe_Payment_LinksAfter_CompletionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_links" */
export type Stripe_Payment_LinksMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_links" */
export type Stripe_Payment_LinksPayment_Method_TypesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_links" */
export type Stripe_Payment_LinksShipping_Address_CollectionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_links" */
export type Stripe_Payment_LinksSubscription_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_payment_links" */
export type Stripe_Payment_Links_Aggregate = {
  aggregate?: Maybe<Stripe_Payment_Links_Aggregate_Fields>;
  nodes: Array<Stripe_Payment_Links>;
};

/** aggregate fields of "stripe_payment_links" */
export type Stripe_Payment_Links_Aggregate_Fields = {
  avg?: Maybe<Stripe_Payment_Links_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Payment_Links_Max_Fields>;
  min?: Maybe<Stripe_Payment_Links_Min_Fields>;
  stddev?: Maybe<Stripe_Payment_Links_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Payment_Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Payment_Links_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Payment_Links_Sum_Fields>;
  var_pop?: Maybe<Stripe_Payment_Links_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Payment_Links_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Payment_Links_Variance_Fields>;
};


/** aggregate fields of "stripe_payment_links" */
export type Stripe_Payment_Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Payment_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Payment_Links_Append_Input = {
  after_completion?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['jsonb']['input']>;
  subscription_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Stripe_Payment_Links_Avg_Fields = {
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stripe_payment_links". All fields are combined with a logical 'AND'. */
export type Stripe_Payment_Links_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Payment_Links_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Payment_Links_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Payment_Links_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  after_completion?: InputMaybe<Jsonb_Comparison_Exp>;
  allow_promotion_codes?: InputMaybe<Boolean_Comparison_Exp>;
  application_fee_amount?: InputMaybe<Bigint_Comparison_Exp>;
  application_fee_percent?: InputMaybe<Numeric_Comparison_Exp>;
  automatic_tax_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  billing_address_collection?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  on_behalf_of_id?: InputMaybe<String_Comparison_Exp>;
  payment_method_types?: InputMaybe<Jsonb_Comparison_Exp>;
  phone_number_collection_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  shipping_address_collection?: InputMaybe<Jsonb_Comparison_Exp>;
  subscription_data?: InputMaybe<Jsonb_Comparison_Exp>;
  transfer_dataamount?: InputMaybe<Bigint_Comparison_Exp>;
  transfer_datadestination_id?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_payment_links" */
export enum Stripe_Payment_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripePaymentLinksPkey = 'stripe_payment_links_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Payment_Links_Delete_At_Path_Input = {
  after_completion?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  payment_method_types?: InputMaybe<Array<Scalars['String']['input']>>;
  shipping_address_collection?: InputMaybe<Array<Scalars['String']['input']>>;
  subscription_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Payment_Links_Delete_Elem_Input = {
  after_completion?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  payment_method_types?: InputMaybe<Scalars['Int']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['Int']['input']>;
  subscription_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Payment_Links_Delete_Key_Input = {
  after_completion?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  payment_method_types?: InputMaybe<Scalars['String']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['String']['input']>;
  subscription_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_payment_links" */
export type Stripe_Payment_Links_Inc_Input = {
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_percent?: InputMaybe<Scalars['numeric']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stripe_payment_links" */
export type Stripe_Payment_Links_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after_completion?: InputMaybe<Scalars['jsonb']['input']>;
  allow_promotion_codes?: InputMaybe<Scalars['Boolean']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_percent?: InputMaybe<Scalars['numeric']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  billing_address_collection?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  phone_number_collection_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['jsonb']['input']>;
  subscription_data?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Stripe_Payment_Links_Max_Fields = {
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  application_fee_percent?: Maybe<Scalars['numeric']['output']>;
  billing_address_collection?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Stripe_Payment_Links_Min_Fields = {
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  application_fee_percent?: Maybe<Scalars['numeric']['output']>;
  billing_address_collection?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  on_behalf_of_id?: Maybe<Scalars['String']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "stripe_payment_links" */
export type Stripe_Payment_Links_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Payment_Links>;
};

/** on_conflict condition type for table "stripe_payment_links" */
export type Stripe_Payment_Links_On_Conflict = {
  constraint: Stripe_Payment_Links_Constraint;
  update_columns?: Array<Stripe_Payment_Links_Update_Column>;
  where?: InputMaybe<Stripe_Payment_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_payment_links". */
export type Stripe_Payment_Links_Order_By = {
  active?: InputMaybe<Order_By>;
  after_completion?: InputMaybe<Order_By>;
  allow_promotion_codes?: InputMaybe<Order_By>;
  application_fee_amount?: InputMaybe<Order_By>;
  application_fee_percent?: InputMaybe<Order_By>;
  automatic_tax_enabled?: InputMaybe<Order_By>;
  billing_address_collection?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  on_behalf_of_id?: InputMaybe<Order_By>;
  payment_method_types?: InputMaybe<Order_By>;
  phone_number_collection_enabled?: InputMaybe<Order_By>;
  shipping_address_collection?: InputMaybe<Order_By>;
  subscription_data?: InputMaybe<Order_By>;
  transfer_dataamount?: InputMaybe<Order_By>;
  transfer_datadestination_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_payment_links */
export type Stripe_Payment_Links_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Payment_Links_Prepend_Input = {
  after_completion?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['jsonb']['input']>;
  subscription_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_payment_links" */
export enum Stripe_Payment_Links_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AfterCompletion = 'after_completion',
  /** column name */
  AllowPromotionCodes = 'allow_promotion_codes',
  /** column name */
  ApplicationFeeAmount = 'application_fee_amount',
  /** column name */
  ApplicationFeePercent = 'application_fee_percent',
  /** column name */
  AutomaticTaxEnabled = 'automatic_tax_enabled',
  /** column name */
  BillingAddressCollection = 'billing_address_collection',
  /** column name */
  Id = 'id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OnBehalfOfId = 'on_behalf_of_id',
  /** column name */
  PaymentMethodTypes = 'payment_method_types',
  /** column name */
  PhoneNumberCollectionEnabled = 'phone_number_collection_enabled',
  /** column name */
  ShippingAddressCollection = 'shipping_address_collection',
  /** column name */
  SubscriptionData = 'subscription_data',
  /** column name */
  TransferDataamount = 'transfer_dataamount',
  /** column name */
  TransferDatadestinationId = 'transfer_datadestination_id',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "stripe_payment_links" */
export type Stripe_Payment_Links_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after_completion?: InputMaybe<Scalars['jsonb']['input']>;
  allow_promotion_codes?: InputMaybe<Scalars['Boolean']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_percent?: InputMaybe<Scalars['numeric']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  billing_address_collection?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  phone_number_collection_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['jsonb']['input']>;
  subscription_data?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Payment_Links_Stddev_Fields = {
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Payment_Links_Stddev_Pop_Fields = {
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Payment_Links_Stddev_Samp_Fields = {
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stripe_payment_links" */
export type Stripe_Payment_Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Payment_Links_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Payment_Links_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after_completion?: InputMaybe<Scalars['jsonb']['input']>;
  allow_promotion_codes?: InputMaybe<Scalars['Boolean']['input']>;
  application_fee_amount?: InputMaybe<Scalars['bigint']['input']>;
  application_fee_percent?: InputMaybe<Scalars['numeric']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  billing_address_collection?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  on_behalf_of_id?: InputMaybe<Scalars['String']['input']>;
  payment_method_types?: InputMaybe<Scalars['jsonb']['input']>;
  phone_number_collection_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  shipping_address_collection?: InputMaybe<Scalars['jsonb']['input']>;
  subscription_data?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_dataamount?: InputMaybe<Scalars['bigint']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Payment_Links_Sum_Fields = {
  application_fee_amount?: Maybe<Scalars['bigint']['output']>;
  application_fee_percent?: Maybe<Scalars['numeric']['output']>;
  transfer_dataamount?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stripe_payment_links" */
export enum Stripe_Payment_Links_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AfterCompletion = 'after_completion',
  /** column name */
  AllowPromotionCodes = 'allow_promotion_codes',
  /** column name */
  ApplicationFeeAmount = 'application_fee_amount',
  /** column name */
  ApplicationFeePercent = 'application_fee_percent',
  /** column name */
  AutomaticTaxEnabled = 'automatic_tax_enabled',
  /** column name */
  BillingAddressCollection = 'billing_address_collection',
  /** column name */
  Id = 'id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OnBehalfOfId = 'on_behalf_of_id',
  /** column name */
  PaymentMethodTypes = 'payment_method_types',
  /** column name */
  PhoneNumberCollectionEnabled = 'phone_number_collection_enabled',
  /** column name */
  ShippingAddressCollection = 'shipping_address_collection',
  /** column name */
  SubscriptionData = 'subscription_data',
  /** column name */
  TransferDataamount = 'transfer_dataamount',
  /** column name */
  TransferDatadestinationId = 'transfer_datadestination_id',
  /** column name */
  Url = 'url'
}

export type Stripe_Payment_Links_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Payment_Links_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Payment_Links_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Payment_Links_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Payment_Links_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Payment_Links_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Payment_Links_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Payment_Links_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Payment_Links_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Payment_Links_Var_Pop_Fields = {
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stripe_Payment_Links_Var_Samp_Fields = {
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stripe_Payment_Links_Variance_Fields = {
  application_fee_amount?: Maybe<Scalars['Float']['output']>;
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_Methods = {
  acss_debit?: Maybe<Scalars['jsonb']['output']>;
  afterpay_clearpay?: Maybe<Scalars['jsonb']['output']>;
  alipay?: Maybe<Scalars['jsonb']['output']>;
  aubecs_debit?: Maybe<Scalars['jsonb']['output']>;
  bacs_debit?: Maybe<Scalars['jsonb']['output']>;
  bancontact?: Maybe<Scalars['jsonb']['output']>;
  billing_details_address_city?: Maybe<Scalars['String']['output']>;
  billing_details_address_country?: Maybe<Scalars['String']['output']>;
  billing_details_address_line1?: Maybe<Scalars['String']['output']>;
  billing_details_address_line2?: Maybe<Scalars['String']['output']>;
  billing_details_address_postal_code?: Maybe<Scalars['String']['output']>;
  billing_details_address_state?: Maybe<Scalars['String']['output']>;
  billing_details_email?: Maybe<Scalars['String']['output']>;
  billing_details_name?: Maybe<Scalars['String']['output']>;
  billing_details_phone?: Maybe<Scalars['String']['output']>;
  boleto?: Maybe<Scalars['jsonb']['output']>;
  card?: Maybe<Scalars['jsonb']['output']>;
  card_present?: Maybe<Scalars['jsonb']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  eps?: Maybe<Scalars['jsonb']['output']>;
  fpx?: Maybe<Scalars['jsonb']['output']>;
  giropay?: Maybe<Scalars['jsonb']['output']>;
  grabpay?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['String']['output'];
  ideal?: Maybe<Scalars['jsonb']['output']>;
  interac_present?: Maybe<Scalars['jsonb']['output']>;
  klarna?: Maybe<Scalars['jsonb']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  oxxo?: Maybe<Scalars['jsonb']['output']>;
  p24?: Maybe<Scalars['jsonb']['output']>;
  sepa_debit?: Maybe<Scalars['jsonb']['output']>;
  sofort?: Maybe<Scalars['jsonb']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  us_bank_account?: Maybe<Scalars['jsonb']['output']>;
  wechat_pay?: Maybe<Scalars['jsonb']['output']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsAcss_DebitArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsAfterpay_ClearpayArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsAlipayArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsAubecs_DebitArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsBacs_DebitArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsBancontactArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsBoletoArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsCardArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsCard_PresentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsEpsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsFpxArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsGiropayArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsGrabpayArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsIdealArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsInterac_PresentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsKlarnaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsOxxoArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsP24Args = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsSepa_DebitArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsSofortArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsUs_Bank_AccountArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_payment_methods" */
export type Stripe_Payment_MethodsWechat_PayArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_payment_methods" */
export type Stripe_Payment_Methods_Aggregate = {
  aggregate?: Maybe<Stripe_Payment_Methods_Aggregate_Fields>;
  nodes: Array<Stripe_Payment_Methods>;
};

/** aggregate fields of "stripe_payment_methods" */
export type Stripe_Payment_Methods_Aggregate_Fields = {
  avg?: Maybe<Stripe_Payment_Methods_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Payment_Methods_Max_Fields>;
  min?: Maybe<Stripe_Payment_Methods_Min_Fields>;
  stddev?: Maybe<Stripe_Payment_Methods_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Payment_Methods_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Payment_Methods_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Payment_Methods_Sum_Fields>;
  var_pop?: Maybe<Stripe_Payment_Methods_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Payment_Methods_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Payment_Methods_Variance_Fields>;
};


/** aggregate fields of "stripe_payment_methods" */
export type Stripe_Payment_Methods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Payment_Methods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Payment_Methods_Append_Input = {
  acss_debit?: InputMaybe<Scalars['jsonb']['input']>;
  afterpay_clearpay?: InputMaybe<Scalars['jsonb']['input']>;
  alipay?: InputMaybe<Scalars['jsonb']['input']>;
  aubecs_debit?: InputMaybe<Scalars['jsonb']['input']>;
  bacs_debit?: InputMaybe<Scalars['jsonb']['input']>;
  bancontact?: InputMaybe<Scalars['jsonb']['input']>;
  boleto?: InputMaybe<Scalars['jsonb']['input']>;
  card?: InputMaybe<Scalars['jsonb']['input']>;
  card_present?: InputMaybe<Scalars['jsonb']['input']>;
  eps?: InputMaybe<Scalars['jsonb']['input']>;
  fpx?: InputMaybe<Scalars['jsonb']['input']>;
  giropay?: InputMaybe<Scalars['jsonb']['input']>;
  grabpay?: InputMaybe<Scalars['jsonb']['input']>;
  ideal?: InputMaybe<Scalars['jsonb']['input']>;
  interac_present?: InputMaybe<Scalars['jsonb']['input']>;
  klarna?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  oxxo?: InputMaybe<Scalars['jsonb']['input']>;
  p24?: InputMaybe<Scalars['jsonb']['input']>;
  sepa_debit?: InputMaybe<Scalars['jsonb']['input']>;
  sofort?: InputMaybe<Scalars['jsonb']['input']>;
  us_bank_account?: InputMaybe<Scalars['jsonb']['input']>;
  wechat_pay?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Stripe_Payment_Methods_Avg_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stripe_payment_methods". All fields are combined with a logical 'AND'. */
export type Stripe_Payment_Methods_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Payment_Methods_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Payment_Methods_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Payment_Methods_Bool_Exp>>;
  acss_debit?: InputMaybe<Jsonb_Comparison_Exp>;
  afterpay_clearpay?: InputMaybe<Jsonb_Comparison_Exp>;
  alipay?: InputMaybe<Jsonb_Comparison_Exp>;
  aubecs_debit?: InputMaybe<Jsonb_Comparison_Exp>;
  bacs_debit?: InputMaybe<Jsonb_Comparison_Exp>;
  bancontact?: InputMaybe<Jsonb_Comparison_Exp>;
  billing_details_address_city?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_country?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_line1?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_line2?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_postal_code?: InputMaybe<String_Comparison_Exp>;
  billing_details_address_state?: InputMaybe<String_Comparison_Exp>;
  billing_details_email?: InputMaybe<String_Comparison_Exp>;
  billing_details_name?: InputMaybe<String_Comparison_Exp>;
  billing_details_phone?: InputMaybe<String_Comparison_Exp>;
  boleto?: InputMaybe<Jsonb_Comparison_Exp>;
  card?: InputMaybe<Jsonb_Comparison_Exp>;
  card_present?: InputMaybe<Jsonb_Comparison_Exp>;
  created?: InputMaybe<Bigint_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  eps?: InputMaybe<Jsonb_Comparison_Exp>;
  fpx?: InputMaybe<Jsonb_Comparison_Exp>;
  giropay?: InputMaybe<Jsonb_Comparison_Exp>;
  grabpay?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  ideal?: InputMaybe<Jsonb_Comparison_Exp>;
  interac_present?: InputMaybe<Jsonb_Comparison_Exp>;
  klarna?: InputMaybe<Jsonb_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  oxxo?: InputMaybe<Jsonb_Comparison_Exp>;
  p24?: InputMaybe<Jsonb_Comparison_Exp>;
  sepa_debit?: InputMaybe<Jsonb_Comparison_Exp>;
  sofort?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  us_bank_account?: InputMaybe<Jsonb_Comparison_Exp>;
  wechat_pay?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_payment_methods" */
export enum Stripe_Payment_Methods_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripePaymentMethodsPkey = 'stripe_payment_methods_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Payment_Methods_Delete_At_Path_Input = {
  acss_debit?: InputMaybe<Array<Scalars['String']['input']>>;
  afterpay_clearpay?: InputMaybe<Array<Scalars['String']['input']>>;
  alipay?: InputMaybe<Array<Scalars['String']['input']>>;
  aubecs_debit?: InputMaybe<Array<Scalars['String']['input']>>;
  bacs_debit?: InputMaybe<Array<Scalars['String']['input']>>;
  bancontact?: InputMaybe<Array<Scalars['String']['input']>>;
  boleto?: InputMaybe<Array<Scalars['String']['input']>>;
  card?: InputMaybe<Array<Scalars['String']['input']>>;
  card_present?: InputMaybe<Array<Scalars['String']['input']>>;
  eps?: InputMaybe<Array<Scalars['String']['input']>>;
  fpx?: InputMaybe<Array<Scalars['String']['input']>>;
  giropay?: InputMaybe<Array<Scalars['String']['input']>>;
  grabpay?: InputMaybe<Array<Scalars['String']['input']>>;
  ideal?: InputMaybe<Array<Scalars['String']['input']>>;
  interac_present?: InputMaybe<Array<Scalars['String']['input']>>;
  klarna?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  oxxo?: InputMaybe<Array<Scalars['String']['input']>>;
  p24?: InputMaybe<Array<Scalars['String']['input']>>;
  sepa_debit?: InputMaybe<Array<Scalars['String']['input']>>;
  sofort?: InputMaybe<Array<Scalars['String']['input']>>;
  us_bank_account?: InputMaybe<Array<Scalars['String']['input']>>;
  wechat_pay?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Payment_Methods_Delete_Elem_Input = {
  acss_debit?: InputMaybe<Scalars['Int']['input']>;
  afterpay_clearpay?: InputMaybe<Scalars['Int']['input']>;
  alipay?: InputMaybe<Scalars['Int']['input']>;
  aubecs_debit?: InputMaybe<Scalars['Int']['input']>;
  bacs_debit?: InputMaybe<Scalars['Int']['input']>;
  bancontact?: InputMaybe<Scalars['Int']['input']>;
  boleto?: InputMaybe<Scalars['Int']['input']>;
  card?: InputMaybe<Scalars['Int']['input']>;
  card_present?: InputMaybe<Scalars['Int']['input']>;
  eps?: InputMaybe<Scalars['Int']['input']>;
  fpx?: InputMaybe<Scalars['Int']['input']>;
  giropay?: InputMaybe<Scalars['Int']['input']>;
  grabpay?: InputMaybe<Scalars['Int']['input']>;
  ideal?: InputMaybe<Scalars['Int']['input']>;
  interac_present?: InputMaybe<Scalars['Int']['input']>;
  klarna?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  oxxo?: InputMaybe<Scalars['Int']['input']>;
  p24?: InputMaybe<Scalars['Int']['input']>;
  sepa_debit?: InputMaybe<Scalars['Int']['input']>;
  sofort?: InputMaybe<Scalars['Int']['input']>;
  us_bank_account?: InputMaybe<Scalars['Int']['input']>;
  wechat_pay?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Payment_Methods_Delete_Key_Input = {
  acss_debit?: InputMaybe<Scalars['String']['input']>;
  afterpay_clearpay?: InputMaybe<Scalars['String']['input']>;
  alipay?: InputMaybe<Scalars['String']['input']>;
  aubecs_debit?: InputMaybe<Scalars['String']['input']>;
  bacs_debit?: InputMaybe<Scalars['String']['input']>;
  bancontact?: InputMaybe<Scalars['String']['input']>;
  boleto?: InputMaybe<Scalars['String']['input']>;
  card?: InputMaybe<Scalars['String']['input']>;
  card_present?: InputMaybe<Scalars['String']['input']>;
  eps?: InputMaybe<Scalars['String']['input']>;
  fpx?: InputMaybe<Scalars['String']['input']>;
  giropay?: InputMaybe<Scalars['String']['input']>;
  grabpay?: InputMaybe<Scalars['String']['input']>;
  ideal?: InputMaybe<Scalars['String']['input']>;
  interac_present?: InputMaybe<Scalars['String']['input']>;
  klarna?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  oxxo?: InputMaybe<Scalars['String']['input']>;
  p24?: InputMaybe<Scalars['String']['input']>;
  sepa_debit?: InputMaybe<Scalars['String']['input']>;
  sofort?: InputMaybe<Scalars['String']['input']>;
  us_bank_account?: InputMaybe<Scalars['String']['input']>;
  wechat_pay?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_payment_methods" */
export type Stripe_Payment_Methods_Inc_Input = {
  created?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stripe_payment_methods" */
export type Stripe_Payment_Methods_Insert_Input = {
  acss_debit?: InputMaybe<Scalars['jsonb']['input']>;
  afterpay_clearpay?: InputMaybe<Scalars['jsonb']['input']>;
  alipay?: InputMaybe<Scalars['jsonb']['input']>;
  aubecs_debit?: InputMaybe<Scalars['jsonb']['input']>;
  bacs_debit?: InputMaybe<Scalars['jsonb']['input']>;
  bancontact?: InputMaybe<Scalars['jsonb']['input']>;
  billing_details_address_city?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_country?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line1?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line2?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_state?: InputMaybe<Scalars['String']['input']>;
  billing_details_email?: InputMaybe<Scalars['String']['input']>;
  billing_details_name?: InputMaybe<Scalars['String']['input']>;
  billing_details_phone?: InputMaybe<Scalars['String']['input']>;
  boleto?: InputMaybe<Scalars['jsonb']['input']>;
  card?: InputMaybe<Scalars['jsonb']['input']>;
  card_present?: InputMaybe<Scalars['jsonb']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  eps?: InputMaybe<Scalars['jsonb']['input']>;
  fpx?: InputMaybe<Scalars['jsonb']['input']>;
  giropay?: InputMaybe<Scalars['jsonb']['input']>;
  grabpay?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ideal?: InputMaybe<Scalars['jsonb']['input']>;
  interac_present?: InputMaybe<Scalars['jsonb']['input']>;
  klarna?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  oxxo?: InputMaybe<Scalars['jsonb']['input']>;
  p24?: InputMaybe<Scalars['jsonb']['input']>;
  sepa_debit?: InputMaybe<Scalars['jsonb']['input']>;
  sofort?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  us_bank_account?: InputMaybe<Scalars['jsonb']['input']>;
  wechat_pay?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Stripe_Payment_Methods_Max_Fields = {
  billing_details_address_city?: Maybe<Scalars['String']['output']>;
  billing_details_address_country?: Maybe<Scalars['String']['output']>;
  billing_details_address_line1?: Maybe<Scalars['String']['output']>;
  billing_details_address_line2?: Maybe<Scalars['String']['output']>;
  billing_details_address_postal_code?: Maybe<Scalars['String']['output']>;
  billing_details_address_state?: Maybe<Scalars['String']['output']>;
  billing_details_email?: Maybe<Scalars['String']['output']>;
  billing_details_name?: Maybe<Scalars['String']['output']>;
  billing_details_phone?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Stripe_Payment_Methods_Min_Fields = {
  billing_details_address_city?: Maybe<Scalars['String']['output']>;
  billing_details_address_country?: Maybe<Scalars['String']['output']>;
  billing_details_address_line1?: Maybe<Scalars['String']['output']>;
  billing_details_address_line2?: Maybe<Scalars['String']['output']>;
  billing_details_address_postal_code?: Maybe<Scalars['String']['output']>;
  billing_details_address_state?: Maybe<Scalars['String']['output']>;
  billing_details_email?: Maybe<Scalars['String']['output']>;
  billing_details_name?: Maybe<Scalars['String']['output']>;
  billing_details_phone?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "stripe_payment_methods" */
export type Stripe_Payment_Methods_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Payment_Methods>;
};

/** on_conflict condition type for table "stripe_payment_methods" */
export type Stripe_Payment_Methods_On_Conflict = {
  constraint: Stripe_Payment_Methods_Constraint;
  update_columns?: Array<Stripe_Payment_Methods_Update_Column>;
  where?: InputMaybe<Stripe_Payment_Methods_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_payment_methods". */
export type Stripe_Payment_Methods_Order_By = {
  acss_debit?: InputMaybe<Order_By>;
  afterpay_clearpay?: InputMaybe<Order_By>;
  alipay?: InputMaybe<Order_By>;
  aubecs_debit?: InputMaybe<Order_By>;
  bacs_debit?: InputMaybe<Order_By>;
  bancontact?: InputMaybe<Order_By>;
  billing_details_address_city?: InputMaybe<Order_By>;
  billing_details_address_country?: InputMaybe<Order_By>;
  billing_details_address_line1?: InputMaybe<Order_By>;
  billing_details_address_line2?: InputMaybe<Order_By>;
  billing_details_address_postal_code?: InputMaybe<Order_By>;
  billing_details_address_state?: InputMaybe<Order_By>;
  billing_details_email?: InputMaybe<Order_By>;
  billing_details_name?: InputMaybe<Order_By>;
  billing_details_phone?: InputMaybe<Order_By>;
  boleto?: InputMaybe<Order_By>;
  card?: InputMaybe<Order_By>;
  card_present?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  eps?: InputMaybe<Order_By>;
  fpx?: InputMaybe<Order_By>;
  giropay?: InputMaybe<Order_By>;
  grabpay?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ideal?: InputMaybe<Order_By>;
  interac_present?: InputMaybe<Order_By>;
  klarna?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  oxxo?: InputMaybe<Order_By>;
  p24?: InputMaybe<Order_By>;
  sepa_debit?: InputMaybe<Order_By>;
  sofort?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  us_bank_account?: InputMaybe<Order_By>;
  wechat_pay?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_payment_methods */
export type Stripe_Payment_Methods_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Payment_Methods_Prepend_Input = {
  acss_debit?: InputMaybe<Scalars['jsonb']['input']>;
  afterpay_clearpay?: InputMaybe<Scalars['jsonb']['input']>;
  alipay?: InputMaybe<Scalars['jsonb']['input']>;
  aubecs_debit?: InputMaybe<Scalars['jsonb']['input']>;
  bacs_debit?: InputMaybe<Scalars['jsonb']['input']>;
  bancontact?: InputMaybe<Scalars['jsonb']['input']>;
  boleto?: InputMaybe<Scalars['jsonb']['input']>;
  card?: InputMaybe<Scalars['jsonb']['input']>;
  card_present?: InputMaybe<Scalars['jsonb']['input']>;
  eps?: InputMaybe<Scalars['jsonb']['input']>;
  fpx?: InputMaybe<Scalars['jsonb']['input']>;
  giropay?: InputMaybe<Scalars['jsonb']['input']>;
  grabpay?: InputMaybe<Scalars['jsonb']['input']>;
  ideal?: InputMaybe<Scalars['jsonb']['input']>;
  interac_present?: InputMaybe<Scalars['jsonb']['input']>;
  klarna?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  oxxo?: InputMaybe<Scalars['jsonb']['input']>;
  p24?: InputMaybe<Scalars['jsonb']['input']>;
  sepa_debit?: InputMaybe<Scalars['jsonb']['input']>;
  sofort?: InputMaybe<Scalars['jsonb']['input']>;
  us_bank_account?: InputMaybe<Scalars['jsonb']['input']>;
  wechat_pay?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_payment_methods" */
export enum Stripe_Payment_Methods_Select_Column {
  /** column name */
  AcssDebit = 'acss_debit',
  /** column name */
  AfterpayClearpay = 'afterpay_clearpay',
  /** column name */
  Alipay = 'alipay',
  /** column name */
  AubecsDebit = 'aubecs_debit',
  /** column name */
  BacsDebit = 'bacs_debit',
  /** column name */
  Bancontact = 'bancontact',
  /** column name */
  BillingDetailsAddressCity = 'billing_details_address_city',
  /** column name */
  BillingDetailsAddressCountry = 'billing_details_address_country',
  /** column name */
  BillingDetailsAddressLine1 = 'billing_details_address_line1',
  /** column name */
  BillingDetailsAddressLine2 = 'billing_details_address_line2',
  /** column name */
  BillingDetailsAddressPostalCode = 'billing_details_address_postal_code',
  /** column name */
  BillingDetailsAddressState = 'billing_details_address_state',
  /** column name */
  BillingDetailsEmail = 'billing_details_email',
  /** column name */
  BillingDetailsName = 'billing_details_name',
  /** column name */
  BillingDetailsPhone = 'billing_details_phone',
  /** column name */
  Boleto = 'boleto',
  /** column name */
  Card = 'card',
  /** column name */
  CardPresent = 'card_present',
  /** column name */
  Created = 'created',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Eps = 'eps',
  /** column name */
  Fpx = 'fpx',
  /** column name */
  Giropay = 'giropay',
  /** column name */
  Grabpay = 'grabpay',
  /** column name */
  Id = 'id',
  /** column name */
  Ideal = 'ideal',
  /** column name */
  InteracPresent = 'interac_present',
  /** column name */
  Klarna = 'klarna',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Oxxo = 'oxxo',
  /** column name */
  P24 = 'p24',
  /** column name */
  SepaDebit = 'sepa_debit',
  /** column name */
  Sofort = 'sofort',
  /** column name */
  Type = 'type',
  /** column name */
  UsBankAccount = 'us_bank_account',
  /** column name */
  WechatPay = 'wechat_pay'
}

/** input type for updating data in table "stripe_payment_methods" */
export type Stripe_Payment_Methods_Set_Input = {
  acss_debit?: InputMaybe<Scalars['jsonb']['input']>;
  afterpay_clearpay?: InputMaybe<Scalars['jsonb']['input']>;
  alipay?: InputMaybe<Scalars['jsonb']['input']>;
  aubecs_debit?: InputMaybe<Scalars['jsonb']['input']>;
  bacs_debit?: InputMaybe<Scalars['jsonb']['input']>;
  bancontact?: InputMaybe<Scalars['jsonb']['input']>;
  billing_details_address_city?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_country?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line1?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line2?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_state?: InputMaybe<Scalars['String']['input']>;
  billing_details_email?: InputMaybe<Scalars['String']['input']>;
  billing_details_name?: InputMaybe<Scalars['String']['input']>;
  billing_details_phone?: InputMaybe<Scalars['String']['input']>;
  boleto?: InputMaybe<Scalars['jsonb']['input']>;
  card?: InputMaybe<Scalars['jsonb']['input']>;
  card_present?: InputMaybe<Scalars['jsonb']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  eps?: InputMaybe<Scalars['jsonb']['input']>;
  fpx?: InputMaybe<Scalars['jsonb']['input']>;
  giropay?: InputMaybe<Scalars['jsonb']['input']>;
  grabpay?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ideal?: InputMaybe<Scalars['jsonb']['input']>;
  interac_present?: InputMaybe<Scalars['jsonb']['input']>;
  klarna?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  oxxo?: InputMaybe<Scalars['jsonb']['input']>;
  p24?: InputMaybe<Scalars['jsonb']['input']>;
  sepa_debit?: InputMaybe<Scalars['jsonb']['input']>;
  sofort?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  us_bank_account?: InputMaybe<Scalars['jsonb']['input']>;
  wechat_pay?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Payment_Methods_Stddev_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Payment_Methods_Stddev_Pop_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Payment_Methods_Stddev_Samp_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stripe_payment_methods" */
export type Stripe_Payment_Methods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Payment_Methods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Payment_Methods_Stream_Cursor_Value_Input = {
  acss_debit?: InputMaybe<Scalars['jsonb']['input']>;
  afterpay_clearpay?: InputMaybe<Scalars['jsonb']['input']>;
  alipay?: InputMaybe<Scalars['jsonb']['input']>;
  aubecs_debit?: InputMaybe<Scalars['jsonb']['input']>;
  bacs_debit?: InputMaybe<Scalars['jsonb']['input']>;
  bancontact?: InputMaybe<Scalars['jsonb']['input']>;
  billing_details_address_city?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_country?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line1?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_line2?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_postal_code?: InputMaybe<Scalars['String']['input']>;
  billing_details_address_state?: InputMaybe<Scalars['String']['input']>;
  billing_details_email?: InputMaybe<Scalars['String']['input']>;
  billing_details_name?: InputMaybe<Scalars['String']['input']>;
  billing_details_phone?: InputMaybe<Scalars['String']['input']>;
  boleto?: InputMaybe<Scalars['jsonb']['input']>;
  card?: InputMaybe<Scalars['jsonb']['input']>;
  card_present?: InputMaybe<Scalars['jsonb']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  eps?: InputMaybe<Scalars['jsonb']['input']>;
  fpx?: InputMaybe<Scalars['jsonb']['input']>;
  giropay?: InputMaybe<Scalars['jsonb']['input']>;
  grabpay?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ideal?: InputMaybe<Scalars['jsonb']['input']>;
  interac_present?: InputMaybe<Scalars['jsonb']['input']>;
  klarna?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  oxxo?: InputMaybe<Scalars['jsonb']['input']>;
  p24?: InputMaybe<Scalars['jsonb']['input']>;
  sepa_debit?: InputMaybe<Scalars['jsonb']['input']>;
  sofort?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  us_bank_account?: InputMaybe<Scalars['jsonb']['input']>;
  wechat_pay?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Payment_Methods_Sum_Fields = {
  created?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stripe_payment_methods" */
export enum Stripe_Payment_Methods_Update_Column {
  /** column name */
  AcssDebit = 'acss_debit',
  /** column name */
  AfterpayClearpay = 'afterpay_clearpay',
  /** column name */
  Alipay = 'alipay',
  /** column name */
  AubecsDebit = 'aubecs_debit',
  /** column name */
  BacsDebit = 'bacs_debit',
  /** column name */
  Bancontact = 'bancontact',
  /** column name */
  BillingDetailsAddressCity = 'billing_details_address_city',
  /** column name */
  BillingDetailsAddressCountry = 'billing_details_address_country',
  /** column name */
  BillingDetailsAddressLine1 = 'billing_details_address_line1',
  /** column name */
  BillingDetailsAddressLine2 = 'billing_details_address_line2',
  /** column name */
  BillingDetailsAddressPostalCode = 'billing_details_address_postal_code',
  /** column name */
  BillingDetailsAddressState = 'billing_details_address_state',
  /** column name */
  BillingDetailsEmail = 'billing_details_email',
  /** column name */
  BillingDetailsName = 'billing_details_name',
  /** column name */
  BillingDetailsPhone = 'billing_details_phone',
  /** column name */
  Boleto = 'boleto',
  /** column name */
  Card = 'card',
  /** column name */
  CardPresent = 'card_present',
  /** column name */
  Created = 'created',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Eps = 'eps',
  /** column name */
  Fpx = 'fpx',
  /** column name */
  Giropay = 'giropay',
  /** column name */
  Grabpay = 'grabpay',
  /** column name */
  Id = 'id',
  /** column name */
  Ideal = 'ideal',
  /** column name */
  InteracPresent = 'interac_present',
  /** column name */
  Klarna = 'klarna',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Oxxo = 'oxxo',
  /** column name */
  P24 = 'p24',
  /** column name */
  SepaDebit = 'sepa_debit',
  /** column name */
  Sofort = 'sofort',
  /** column name */
  Type = 'type',
  /** column name */
  UsBankAccount = 'us_bank_account',
  /** column name */
  WechatPay = 'wechat_pay'
}

export type Stripe_Payment_Methods_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Payment_Methods_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Payment_Methods_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Payment_Methods_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Payment_Methods_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Payment_Methods_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Payment_Methods_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Payment_Methods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Payment_Methods_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Payment_Methods_Var_Pop_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stripe_Payment_Methods_Var_Samp_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stripe_Payment_Methods_Variance_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stripe_prices" */
export type Stripe_Prices = {
  active?: Maybe<Scalars['Boolean']['output']>;
  billing_scheme?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  livemode?: Maybe<Scalars['Boolean']['output']>;
  lookup_key?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  product?: Maybe<Stripe_Products>;
  product_id?: Maybe<Scalars['String']['output']>;
  recurring_aggregate_usage?: Maybe<Scalars['String']['output']>;
  recurring_interval?: Maybe<Scalars['String']['output']>;
  recurring_interval_count?: Maybe<Scalars['bigint']['output']>;
  recurring_usage_type?: Maybe<Scalars['String']['output']>;
  tax_behavior?: Maybe<Scalars['String']['output']>;
  tiers_mode?: Maybe<Scalars['String']['output']>;
  transform_quantity?: Maybe<Scalars['jsonb']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit_amount?: Maybe<Scalars['bigint']['output']>;
  unit_amount_decimal?: Maybe<Scalars['numeric']['output']>;
};


/** columns and relationships of "stripe_prices" */
export type Stripe_PricesMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_prices" */
export type Stripe_PricesTransform_QuantityArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_prices" */
export type Stripe_Prices_Aggregate = {
  aggregate?: Maybe<Stripe_Prices_Aggregate_Fields>;
  nodes: Array<Stripe_Prices>;
};

/** aggregate fields of "stripe_prices" */
export type Stripe_Prices_Aggregate_Fields = {
  avg?: Maybe<Stripe_Prices_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Prices_Max_Fields>;
  min?: Maybe<Stripe_Prices_Min_Fields>;
  stddev?: Maybe<Stripe_Prices_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Prices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Prices_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Prices_Sum_Fields>;
  var_pop?: Maybe<Stripe_Prices_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Prices_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Prices_Variance_Fields>;
};


/** aggregate fields of "stripe_prices" */
export type Stripe_Prices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Prices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Prices_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  transform_quantity?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Stripe_Prices_Avg_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  recurring_interval_count?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stripe_prices". All fields are combined with a logical 'AND'. */
export type Stripe_Prices_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Prices_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Prices_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Prices_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  billing_scheme?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Bigint_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  lookup_key?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  nickname?: InputMaybe<String_Comparison_Exp>;
  product?: InputMaybe<Stripe_Products_Bool_Exp>;
  product_id?: InputMaybe<String_Comparison_Exp>;
  recurring_aggregate_usage?: InputMaybe<String_Comparison_Exp>;
  recurring_interval?: InputMaybe<String_Comparison_Exp>;
  recurring_interval_count?: InputMaybe<Bigint_Comparison_Exp>;
  recurring_usage_type?: InputMaybe<String_Comparison_Exp>;
  tax_behavior?: InputMaybe<String_Comparison_Exp>;
  tiers_mode?: InputMaybe<String_Comparison_Exp>;
  transform_quantity?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  unit_amount?: InputMaybe<Bigint_Comparison_Exp>;
  unit_amount_decimal?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_prices" */
export enum Stripe_Prices_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripePricesPkey = 'stripe_prices_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Prices_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  transform_quantity?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Prices_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
  transform_quantity?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Prices_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
  transform_quantity?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_prices" */
export type Stripe_Prices_Inc_Input = {
  created?: InputMaybe<Scalars['bigint']['input']>;
  recurring_interval_count?: InputMaybe<Scalars['bigint']['input']>;
  unit_amount?: InputMaybe<Scalars['bigint']['input']>;
  unit_amount_decimal?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "stripe_prices" */
export type Stripe_Prices_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billing_scheme?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  lookup_key?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Stripe_Products_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  recurring_aggregate_usage?: InputMaybe<Scalars['String']['input']>;
  recurring_interval?: InputMaybe<Scalars['String']['input']>;
  recurring_interval_count?: InputMaybe<Scalars['bigint']['input']>;
  recurring_usage_type?: InputMaybe<Scalars['String']['input']>;
  tax_behavior?: InputMaybe<Scalars['String']['input']>;
  tiers_mode?: InputMaybe<Scalars['String']['input']>;
  transform_quantity?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unit_amount?: InputMaybe<Scalars['bigint']['input']>;
  unit_amount_decimal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Stripe_Prices_Max_Fields = {
  billing_scheme?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lookup_key?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  recurring_aggregate_usage?: Maybe<Scalars['String']['output']>;
  recurring_interval?: Maybe<Scalars['String']['output']>;
  recurring_interval_count?: Maybe<Scalars['bigint']['output']>;
  recurring_usage_type?: Maybe<Scalars['String']['output']>;
  tax_behavior?: Maybe<Scalars['String']['output']>;
  tiers_mode?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit_amount?: Maybe<Scalars['bigint']['output']>;
  unit_amount_decimal?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type Stripe_Prices_Min_Fields = {
  billing_scheme?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lookup_key?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  product_id?: Maybe<Scalars['String']['output']>;
  recurring_aggregate_usage?: Maybe<Scalars['String']['output']>;
  recurring_interval?: Maybe<Scalars['String']['output']>;
  recurring_interval_count?: Maybe<Scalars['bigint']['output']>;
  recurring_usage_type?: Maybe<Scalars['String']['output']>;
  tax_behavior?: Maybe<Scalars['String']['output']>;
  tiers_mode?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit_amount?: Maybe<Scalars['bigint']['output']>;
  unit_amount_decimal?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "stripe_prices" */
export type Stripe_Prices_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Prices>;
};

/** on_conflict condition type for table "stripe_prices" */
export type Stripe_Prices_On_Conflict = {
  constraint: Stripe_Prices_Constraint;
  update_columns?: Array<Stripe_Prices_Update_Column>;
  where?: InputMaybe<Stripe_Prices_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_prices". */
export type Stripe_Prices_Order_By = {
  active?: InputMaybe<Order_By>;
  billing_scheme?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  lookup_key?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  product?: InputMaybe<Stripe_Products_Order_By>;
  product_id?: InputMaybe<Order_By>;
  recurring_aggregate_usage?: InputMaybe<Order_By>;
  recurring_interval?: InputMaybe<Order_By>;
  recurring_interval_count?: InputMaybe<Order_By>;
  recurring_usage_type?: InputMaybe<Order_By>;
  tax_behavior?: InputMaybe<Order_By>;
  tiers_mode?: InputMaybe<Order_By>;
  transform_quantity?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  unit_amount?: InputMaybe<Order_By>;
  unit_amount_decimal?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_prices */
export type Stripe_Prices_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Prices_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  transform_quantity?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_prices" */
export enum Stripe_Prices_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  BillingScheme = 'billing_scheme',
  /** column name */
  Created = 'created',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  LookupKey = 'lookup_key',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  RecurringAggregateUsage = 'recurring_aggregate_usage',
  /** column name */
  RecurringInterval = 'recurring_interval',
  /** column name */
  RecurringIntervalCount = 'recurring_interval_count',
  /** column name */
  RecurringUsageType = 'recurring_usage_type',
  /** column name */
  TaxBehavior = 'tax_behavior',
  /** column name */
  TiersMode = 'tiers_mode',
  /** column name */
  TransformQuantity = 'transform_quantity',
  /** column name */
  Type = 'type',
  /** column name */
  UnitAmount = 'unit_amount',
  /** column name */
  UnitAmountDecimal = 'unit_amount_decimal'
}

/** input type for updating data in table "stripe_prices" */
export type Stripe_Prices_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billing_scheme?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  lookup_key?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  recurring_aggregate_usage?: InputMaybe<Scalars['String']['input']>;
  recurring_interval?: InputMaybe<Scalars['String']['input']>;
  recurring_interval_count?: InputMaybe<Scalars['bigint']['input']>;
  recurring_usage_type?: InputMaybe<Scalars['String']['input']>;
  tax_behavior?: InputMaybe<Scalars['String']['input']>;
  tiers_mode?: InputMaybe<Scalars['String']['input']>;
  transform_quantity?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unit_amount?: InputMaybe<Scalars['bigint']['input']>;
  unit_amount_decimal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Prices_Stddev_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  recurring_interval_count?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Prices_Stddev_Pop_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  recurring_interval_count?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Prices_Stddev_Samp_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  recurring_interval_count?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stripe_prices" */
export type Stripe_Prices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Prices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Prices_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billing_scheme?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  lookup_key?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  product_id?: InputMaybe<Scalars['String']['input']>;
  recurring_aggregate_usage?: InputMaybe<Scalars['String']['input']>;
  recurring_interval?: InputMaybe<Scalars['String']['input']>;
  recurring_interval_count?: InputMaybe<Scalars['bigint']['input']>;
  recurring_usage_type?: InputMaybe<Scalars['String']['input']>;
  tax_behavior?: InputMaybe<Scalars['String']['input']>;
  tiers_mode?: InputMaybe<Scalars['String']['input']>;
  transform_quantity?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unit_amount?: InputMaybe<Scalars['bigint']['input']>;
  unit_amount_decimal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Prices_Sum_Fields = {
  created?: Maybe<Scalars['bigint']['output']>;
  recurring_interval_count?: Maybe<Scalars['bigint']['output']>;
  unit_amount?: Maybe<Scalars['bigint']['output']>;
  unit_amount_decimal?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "stripe_prices" */
export enum Stripe_Prices_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  BillingScheme = 'billing_scheme',
  /** column name */
  Created = 'created',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  LookupKey = 'lookup_key',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  RecurringAggregateUsage = 'recurring_aggregate_usage',
  /** column name */
  RecurringInterval = 'recurring_interval',
  /** column name */
  RecurringIntervalCount = 'recurring_interval_count',
  /** column name */
  RecurringUsageType = 'recurring_usage_type',
  /** column name */
  TaxBehavior = 'tax_behavior',
  /** column name */
  TiersMode = 'tiers_mode',
  /** column name */
  TransformQuantity = 'transform_quantity',
  /** column name */
  Type = 'type',
  /** column name */
  UnitAmount = 'unit_amount',
  /** column name */
  UnitAmountDecimal = 'unit_amount_decimal'
}

export type Stripe_Prices_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Prices_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Prices_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Prices_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Prices_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Prices_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Prices_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Prices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Prices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Prices_Var_Pop_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  recurring_interval_count?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stripe_Prices_Var_Samp_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  recurring_interval_count?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stripe_Prices_Variance_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  recurring_interval_count?: Maybe<Scalars['Float']['output']>;
  unit_amount?: Maybe<Scalars['Float']['output']>;
  unit_amount_decimal?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stripe_products" */
export type Stripe_Products = {
  active?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  images?: Maybe<Scalars['jsonb']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  package_dimensions?: Maybe<Scalars['jsonb']['output']>;
  shippable?: Maybe<Scalars['Boolean']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  tax_code_id?: Maybe<Scalars['String']['output']>;
  unit_label?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['bigint']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "stripe_products" */
export type Stripe_ProductsImagesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_products" */
export type Stripe_ProductsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_products" */
export type Stripe_ProductsPackage_DimensionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_products" */
export type Stripe_Products_Aggregate = {
  aggregate?: Maybe<Stripe_Products_Aggregate_Fields>;
  nodes: Array<Stripe_Products>;
};

/** aggregate fields of "stripe_products" */
export type Stripe_Products_Aggregate_Fields = {
  avg?: Maybe<Stripe_Products_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Products_Max_Fields>;
  min?: Maybe<Stripe_Products_Min_Fields>;
  stddev?: Maybe<Stripe_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Products_Sum_Fields>;
  var_pop?: Maybe<Stripe_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Products_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Products_Variance_Fields>;
};


/** aggregate fields of "stripe_products" */
export type Stripe_Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Products_Append_Input = {
  images?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  package_dimensions?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Stripe_Products_Avg_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stripe_products". All fields are combined with a logical 'AND'. */
export type Stripe_Products_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Products_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Products_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created?: InputMaybe<Bigint_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  images?: InputMaybe<Jsonb_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  package_dimensions?: InputMaybe<Jsonb_Comparison_Exp>;
  shippable?: InputMaybe<Boolean_Comparison_Exp>;
  statement_descriptor?: InputMaybe<String_Comparison_Exp>;
  tax_code_id?: InputMaybe<String_Comparison_Exp>;
  unit_label?: InputMaybe<String_Comparison_Exp>;
  updated?: InputMaybe<Bigint_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_products" */
export enum Stripe_Products_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripeProductsPkey = 'stripe_products_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Products_Delete_At_Path_Input = {
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  package_dimensions?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Products_Delete_Elem_Input = {
  images?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  package_dimensions?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Products_Delete_Key_Input = {
  images?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  package_dimensions?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_products" */
export type Stripe_Products_Inc_Input = {
  created?: InputMaybe<Scalars['bigint']['input']>;
  updated?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stripe_products" */
export type Stripe_Products_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  package_dimensions?: InputMaybe<Scalars['jsonb']['input']>;
  shippable?: InputMaybe<Scalars['Boolean']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  tax_code_id?: InputMaybe<Scalars['String']['input']>;
  unit_label?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['bigint']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Stripe_Products_Max_Fields = {
  created?: Maybe<Scalars['bigint']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  tax_code_id?: Maybe<Scalars['String']['output']>;
  unit_label?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['bigint']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Stripe_Products_Min_Fields = {
  created?: Maybe<Scalars['bigint']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  tax_code_id?: Maybe<Scalars['String']['output']>;
  unit_label?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['bigint']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "stripe_products" */
export type Stripe_Products_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Products>;
};

/** input type for inserting object relation for remote table "stripe_products" */
export type Stripe_Products_Obj_Rel_Insert_Input = {
  data: Stripe_Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stripe_Products_On_Conflict>;
};

/** on_conflict condition type for table "stripe_products" */
export type Stripe_Products_On_Conflict = {
  constraint: Stripe_Products_Constraint;
  update_columns?: Array<Stripe_Products_Update_Column>;
  where?: InputMaybe<Stripe_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_products". */
export type Stripe_Products_Order_By = {
  active?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  images?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  package_dimensions?: InputMaybe<Order_By>;
  shippable?: InputMaybe<Order_By>;
  statement_descriptor?: InputMaybe<Order_By>;
  tax_code_id?: InputMaybe<Order_By>;
  unit_label?: InputMaybe<Order_By>;
  updated?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_products */
export type Stripe_Products_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Products_Prepend_Input = {
  images?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  package_dimensions?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_products" */
export enum Stripe_Products_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Created = 'created',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  PackageDimensions = 'package_dimensions',
  /** column name */
  Shippable = 'shippable',
  /** column name */
  StatementDescriptor = 'statement_descriptor',
  /** column name */
  TaxCodeId = 'tax_code_id',
  /** column name */
  UnitLabel = 'unit_label',
  /** column name */
  Updated = 'updated',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "stripe_products" */
export type Stripe_Products_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  package_dimensions?: InputMaybe<Scalars['jsonb']['input']>;
  shippable?: InputMaybe<Scalars['Boolean']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  tax_code_id?: InputMaybe<Scalars['String']['input']>;
  unit_label?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['bigint']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Products_Stddev_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Products_Stddev_Pop_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Products_Stddev_Samp_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stripe_products" */
export type Stripe_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Products_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Scalars['jsonb']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  package_dimensions?: InputMaybe<Scalars['jsonb']['input']>;
  shippable?: InputMaybe<Scalars['Boolean']['input']>;
  statement_descriptor?: InputMaybe<Scalars['String']['input']>;
  tax_code_id?: InputMaybe<Scalars['String']['input']>;
  unit_label?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['bigint']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Products_Sum_Fields = {
  created?: Maybe<Scalars['bigint']['output']>;
  updated?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stripe_products" */
export enum Stripe_Products_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Created = 'created',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Images = 'images',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  PackageDimensions = 'package_dimensions',
  /** column name */
  Shippable = 'shippable',
  /** column name */
  StatementDescriptor = 'statement_descriptor',
  /** column name */
  TaxCodeId = 'tax_code_id',
  /** column name */
  UnitLabel = 'unit_label',
  /** column name */
  Updated = 'updated',
  /** column name */
  Url = 'url'
}

export type Stripe_Products_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Products_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Products_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Products_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Products_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Products_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Products_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Products_Var_Pop_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stripe_Products_Var_Samp_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stripe_Products_Variance_Fields = {
  created?: Maybe<Scalars['Float']['output']>;
  updated?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stripe_subscriptions" */
export type Stripe_Subscriptions = {
  application_fee_percent?: Maybe<Scalars['numeric']['output']>;
  automatic_tax_enabled?: Maybe<Scalars['Boolean']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['bigint']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['bigint']['output']>;
  billing_thresholds_reset_billing_cycle_anchor?: Maybe<Scalars['Boolean']['output']>;
  cancel_at?: Maybe<Scalars['bigint']['output']>;
  cancel_at_period_end?: Maybe<Scalars['Boolean']['output']>;
  canceled_at?: Maybe<Scalars['bigint']['output']>;
  collection_method?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  current_period_end?: Maybe<Scalars['bigint']['output']>;
  current_period_start?: Maybe<Scalars['bigint']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  days_until_due?: Maybe<Scalars['bigint']['output']>;
  default_payment_method_id?: Maybe<Scalars['String']['output']>;
  default_source_id?: Maybe<Scalars['String']['output']>;
  default_tax_rates?: Maybe<Scalars['jsonb']['output']>;
  discount_id?: Maybe<Scalars['String']['output']>;
  ended_at?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['String']['output'];
  items?: Maybe<Scalars['jsonb']['output']>;
  latest_invoice_id?: Maybe<Scalars['String']['output']>;
  livemode?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['bigint']['output']>;
  pause_collection_behavior?: Maybe<Scalars['String']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['bigint']['output']>;
  payment_settings?: Maybe<Scalars['jsonb']['output']>;
  pending_invoice_item_interval_interval?: Maybe<Scalars['String']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['bigint']['output']>;
  pending_setup_intent_id?: Maybe<Scalars['String']['output']>;
  pending_update?: Maybe<Scalars['jsonb']['output']>;
  schedule_id?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['numeric']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  trial_end?: Maybe<Scalars['bigint']['output']>;
  trial_start?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "stripe_subscriptions" */
export type Stripe_SubscriptionsDefault_Tax_RatesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_subscriptions" */
export type Stripe_SubscriptionsItemsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_subscriptions" */
export type Stripe_SubscriptionsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_subscriptions" */
export type Stripe_SubscriptionsPayment_SettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stripe_subscriptions" */
export type Stripe_SubscriptionsPending_UpdateArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stripe_subscriptions" */
export type Stripe_Subscriptions_Aggregate = {
  aggregate?: Maybe<Stripe_Subscriptions_Aggregate_Fields>;
  nodes: Array<Stripe_Subscriptions>;
};

/** aggregate fields of "stripe_subscriptions" */
export type Stripe_Subscriptions_Aggregate_Fields = {
  avg?: Maybe<Stripe_Subscriptions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stripe_Subscriptions_Max_Fields>;
  min?: Maybe<Stripe_Subscriptions_Min_Fields>;
  stddev?: Maybe<Stripe_Subscriptions_Stddev_Fields>;
  stddev_pop?: Maybe<Stripe_Subscriptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stripe_Subscriptions_Stddev_Samp_Fields>;
  sum?: Maybe<Stripe_Subscriptions_Sum_Fields>;
  var_pop?: Maybe<Stripe_Subscriptions_Var_Pop_Fields>;
  var_samp?: Maybe<Stripe_Subscriptions_Var_Samp_Fields>;
  variance?: Maybe<Stripe_Subscriptions_Variance_Fields>;
};


/** aggregate fields of "stripe_subscriptions" */
export type Stripe_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Subscriptions_Append_Input = {
  default_tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  items?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  payment_settings?: InputMaybe<Scalars['jsonb']['input']>;
  pending_update?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Stripe_Subscriptions_Avg_Fields = {
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['Float']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['Float']['output']>;
  cancel_at?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  current_period_end?: Maybe<Scalars['Float']['output']>;
  current_period_start?: Maybe<Scalars['Float']['output']>;
  days_until_due?: Maybe<Scalars['Float']['output']>;
  ended_at?: Maybe<Scalars['Float']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['Float']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['Float']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['Float']['output']>;
  start_date?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['Float']['output']>;
  trial_end?: Maybe<Scalars['Float']['output']>;
  trial_start?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stripe_subscriptions". All fields are combined with a logical 'AND'. */
export type Stripe_Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Subscriptions_Bool_Exp>>;
  application_fee_percent?: InputMaybe<Numeric_Comparison_Exp>;
  automatic_tax_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  billing_cycle_anchor?: InputMaybe<Bigint_Comparison_Exp>;
  billing_thresholds_amount_gte?: InputMaybe<Bigint_Comparison_Exp>;
  billing_thresholds_reset_billing_cycle_anchor?: InputMaybe<Boolean_Comparison_Exp>;
  cancel_at?: InputMaybe<Bigint_Comparison_Exp>;
  cancel_at_period_end?: InputMaybe<Boolean_Comparison_Exp>;
  canceled_at?: InputMaybe<Bigint_Comparison_Exp>;
  collection_method?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Bigint_Comparison_Exp>;
  current_period_end?: InputMaybe<Bigint_Comparison_Exp>;
  current_period_start?: InputMaybe<Bigint_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  days_until_due?: InputMaybe<Bigint_Comparison_Exp>;
  default_payment_method_id?: InputMaybe<String_Comparison_Exp>;
  default_source_id?: InputMaybe<String_Comparison_Exp>;
  default_tax_rates?: InputMaybe<Jsonb_Comparison_Exp>;
  discount_id?: InputMaybe<String_Comparison_Exp>;
  ended_at?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  items?: InputMaybe<Jsonb_Comparison_Exp>;
  latest_invoice_id?: InputMaybe<String_Comparison_Exp>;
  livemode?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_pending_invoice_item_invoice?: InputMaybe<Bigint_Comparison_Exp>;
  pause_collection_behavior?: InputMaybe<String_Comparison_Exp>;
  pause_collection_resumes_at?: InputMaybe<Bigint_Comparison_Exp>;
  payment_settings?: InputMaybe<Jsonb_Comparison_Exp>;
  pending_invoice_item_interval_interval?: InputMaybe<String_Comparison_Exp>;
  pending_invoice_item_interval_interval_count?: InputMaybe<Bigint_Comparison_Exp>;
  pending_setup_intent_id?: InputMaybe<String_Comparison_Exp>;
  pending_update?: InputMaybe<Jsonb_Comparison_Exp>;
  schedule_id?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  transfer_dataamount_percent?: InputMaybe<Numeric_Comparison_Exp>;
  transfer_datadestination_id?: InputMaybe<String_Comparison_Exp>;
  trial_end?: InputMaybe<Bigint_Comparison_Exp>;
  trial_start?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_subscriptions" */
export enum Stripe_Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripeSubscriptionsPkey = 'stripe_subscriptions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Subscriptions_Delete_At_Path_Input = {
  default_tax_rates?: InputMaybe<Array<Scalars['String']['input']>>;
  items?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  payment_settings?: InputMaybe<Array<Scalars['String']['input']>>;
  pending_update?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Subscriptions_Delete_Elem_Input = {
  default_tax_rates?: InputMaybe<Scalars['Int']['input']>;
  items?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
  payment_settings?: InputMaybe<Scalars['Int']['input']>;
  pending_update?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Subscriptions_Delete_Key_Input = {
  default_tax_rates?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  payment_settings?: InputMaybe<Scalars['String']['input']>;
  pending_update?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stripe_subscriptions" */
export type Stripe_Subscriptions_Inc_Input = {
  application_fee_percent?: InputMaybe<Scalars['numeric']['input']>;
  billing_cycle_anchor?: InputMaybe<Scalars['bigint']['input']>;
  billing_thresholds_amount_gte?: InputMaybe<Scalars['bigint']['input']>;
  cancel_at?: InputMaybe<Scalars['bigint']['input']>;
  canceled_at?: InputMaybe<Scalars['bigint']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  current_period_end?: InputMaybe<Scalars['bigint']['input']>;
  current_period_start?: InputMaybe<Scalars['bigint']['input']>;
  days_until_due?: InputMaybe<Scalars['bigint']['input']>;
  ended_at?: InputMaybe<Scalars['bigint']['input']>;
  next_pending_invoice_item_invoice?: InputMaybe<Scalars['bigint']['input']>;
  pause_collection_resumes_at?: InputMaybe<Scalars['bigint']['input']>;
  pending_invoice_item_interval_interval_count?: InputMaybe<Scalars['bigint']['input']>;
  start_date?: InputMaybe<Scalars['bigint']['input']>;
  transfer_dataamount_percent?: InputMaybe<Scalars['numeric']['input']>;
  trial_end?: InputMaybe<Scalars['bigint']['input']>;
  trial_start?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stripe_subscriptions" */
export type Stripe_Subscriptions_Insert_Input = {
  application_fee_percent?: InputMaybe<Scalars['numeric']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  billing_cycle_anchor?: InputMaybe<Scalars['bigint']['input']>;
  billing_thresholds_amount_gte?: InputMaybe<Scalars['bigint']['input']>;
  billing_thresholds_reset_billing_cycle_anchor?: InputMaybe<Scalars['Boolean']['input']>;
  cancel_at?: InputMaybe<Scalars['bigint']['input']>;
  cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>;
  canceled_at?: InputMaybe<Scalars['bigint']['input']>;
  collection_method?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  current_period_end?: InputMaybe<Scalars['bigint']['input']>;
  current_period_start?: InputMaybe<Scalars['bigint']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  days_until_due?: InputMaybe<Scalars['bigint']['input']>;
  default_payment_method_id?: InputMaybe<Scalars['String']['input']>;
  default_source_id?: InputMaybe<Scalars['String']['input']>;
  default_tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  discount_id?: InputMaybe<Scalars['String']['input']>;
  ended_at?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Scalars['jsonb']['input']>;
  latest_invoice_id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_pending_invoice_item_invoice?: InputMaybe<Scalars['bigint']['input']>;
  pause_collection_behavior?: InputMaybe<Scalars['String']['input']>;
  pause_collection_resumes_at?: InputMaybe<Scalars['bigint']['input']>;
  payment_settings?: InputMaybe<Scalars['jsonb']['input']>;
  pending_invoice_item_interval_interval?: InputMaybe<Scalars['String']['input']>;
  pending_invoice_item_interval_interval_count?: InputMaybe<Scalars['bigint']['input']>;
  pending_setup_intent_id?: InputMaybe<Scalars['String']['input']>;
  pending_update?: InputMaybe<Scalars['jsonb']['input']>;
  schedule_id?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transfer_dataamount_percent?: InputMaybe<Scalars['numeric']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  trial_end?: InputMaybe<Scalars['bigint']['input']>;
  trial_start?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Stripe_Subscriptions_Max_Fields = {
  application_fee_percent?: Maybe<Scalars['numeric']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['bigint']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['bigint']['output']>;
  cancel_at?: Maybe<Scalars['bigint']['output']>;
  canceled_at?: Maybe<Scalars['bigint']['output']>;
  collection_method?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  current_period_end?: Maybe<Scalars['bigint']['output']>;
  current_period_start?: Maybe<Scalars['bigint']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  days_until_due?: Maybe<Scalars['bigint']['output']>;
  default_payment_method_id?: Maybe<Scalars['String']['output']>;
  default_source_id?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['String']['output']>;
  ended_at?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latest_invoice_id?: Maybe<Scalars['String']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['bigint']['output']>;
  pause_collection_behavior?: Maybe<Scalars['String']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['bigint']['output']>;
  pending_invoice_item_interval_interval?: Maybe<Scalars['String']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['bigint']['output']>;
  pending_setup_intent_id?: Maybe<Scalars['String']['output']>;
  schedule_id?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['numeric']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  trial_end?: Maybe<Scalars['bigint']['output']>;
  trial_start?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Stripe_Subscriptions_Min_Fields = {
  application_fee_percent?: Maybe<Scalars['numeric']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['bigint']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['bigint']['output']>;
  cancel_at?: Maybe<Scalars['bigint']['output']>;
  canceled_at?: Maybe<Scalars['bigint']['output']>;
  collection_method?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  current_period_end?: Maybe<Scalars['bigint']['output']>;
  current_period_start?: Maybe<Scalars['bigint']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  days_until_due?: Maybe<Scalars['bigint']['output']>;
  default_payment_method_id?: Maybe<Scalars['String']['output']>;
  default_source_id?: Maybe<Scalars['String']['output']>;
  discount_id?: Maybe<Scalars['String']['output']>;
  ended_at?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latest_invoice_id?: Maybe<Scalars['String']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['bigint']['output']>;
  pause_collection_behavior?: Maybe<Scalars['String']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['bigint']['output']>;
  pending_invoice_item_interval_interval?: Maybe<Scalars['String']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['bigint']['output']>;
  pending_setup_intent_id?: Maybe<Scalars['String']['output']>;
  schedule_id?: Maybe<Scalars['String']['output']>;
  start_date?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['numeric']['output']>;
  transfer_datadestination_id?: Maybe<Scalars['String']['output']>;
  trial_end?: Maybe<Scalars['bigint']['output']>;
  trial_start?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "stripe_subscriptions" */
export type Stripe_Subscriptions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Subscriptions>;
};

/** input type for inserting object relation for remote table "stripe_subscriptions" */
export type Stripe_Subscriptions_Obj_Rel_Insert_Input = {
  data: Stripe_Subscriptions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Stripe_Subscriptions_On_Conflict>;
};

/** on_conflict condition type for table "stripe_subscriptions" */
export type Stripe_Subscriptions_On_Conflict = {
  constraint: Stripe_Subscriptions_Constraint;
  update_columns?: Array<Stripe_Subscriptions_Update_Column>;
  where?: InputMaybe<Stripe_Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_subscriptions". */
export type Stripe_Subscriptions_Order_By = {
  application_fee_percent?: InputMaybe<Order_By>;
  automatic_tax_enabled?: InputMaybe<Order_By>;
  billing_cycle_anchor?: InputMaybe<Order_By>;
  billing_thresholds_amount_gte?: InputMaybe<Order_By>;
  billing_thresholds_reset_billing_cycle_anchor?: InputMaybe<Order_By>;
  cancel_at?: InputMaybe<Order_By>;
  cancel_at_period_end?: InputMaybe<Order_By>;
  canceled_at?: InputMaybe<Order_By>;
  collection_method?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  current_period_end?: InputMaybe<Order_By>;
  current_period_start?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  days_until_due?: InputMaybe<Order_By>;
  default_payment_method_id?: InputMaybe<Order_By>;
  default_source_id?: InputMaybe<Order_By>;
  default_tax_rates?: InputMaybe<Order_By>;
  discount_id?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  items?: InputMaybe<Order_By>;
  latest_invoice_id?: InputMaybe<Order_By>;
  livemode?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_pending_invoice_item_invoice?: InputMaybe<Order_By>;
  pause_collection_behavior?: InputMaybe<Order_By>;
  pause_collection_resumes_at?: InputMaybe<Order_By>;
  payment_settings?: InputMaybe<Order_By>;
  pending_invoice_item_interval_interval?: InputMaybe<Order_By>;
  pending_invoice_item_interval_interval_count?: InputMaybe<Order_By>;
  pending_setup_intent_id?: InputMaybe<Order_By>;
  pending_update?: InputMaybe<Order_By>;
  schedule_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfer_dataamount_percent?: InputMaybe<Order_By>;
  transfer_datadestination_id?: InputMaybe<Order_By>;
  trial_end?: InputMaybe<Order_By>;
  trial_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_subscriptions */
export type Stripe_Subscriptions_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Subscriptions_Prepend_Input = {
  default_tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  items?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  payment_settings?: InputMaybe<Scalars['jsonb']['input']>;
  pending_update?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stripe_subscriptions" */
export enum Stripe_Subscriptions_Select_Column {
  /** column name */
  ApplicationFeePercent = 'application_fee_percent',
  /** column name */
  AutomaticTaxEnabled = 'automatic_tax_enabled',
  /** column name */
  BillingCycleAnchor = 'billing_cycle_anchor',
  /** column name */
  BillingThresholdsAmountGte = 'billing_thresholds_amount_gte',
  /** column name */
  BillingThresholdsResetBillingCycleAnchor = 'billing_thresholds_reset_billing_cycle_anchor',
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CancelAtPeriodEnd = 'cancel_at_period_end',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  Created = 'created',
  /** column name */
  CurrentPeriodEnd = 'current_period_end',
  /** column name */
  CurrentPeriodStart = 'current_period_start',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DaysUntilDue = 'days_until_due',
  /** column name */
  DefaultPaymentMethodId = 'default_payment_method_id',
  /** column name */
  DefaultSourceId = 'default_source_id',
  /** column name */
  DefaultTaxRates = 'default_tax_rates',
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  Items = 'items',
  /** column name */
  LatestInvoiceId = 'latest_invoice_id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextPendingInvoiceItemInvoice = 'next_pending_invoice_item_invoice',
  /** column name */
  PauseCollectionBehavior = 'pause_collection_behavior',
  /** column name */
  PauseCollectionResumesAt = 'pause_collection_resumes_at',
  /** column name */
  PaymentSettings = 'payment_settings',
  /** column name */
  PendingInvoiceItemIntervalInterval = 'pending_invoice_item_interval_interval',
  /** column name */
  PendingInvoiceItemIntervalIntervalCount = 'pending_invoice_item_interval_interval_count',
  /** column name */
  PendingSetupIntentId = 'pending_setup_intent_id',
  /** column name */
  PendingUpdate = 'pending_update',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  TransferDataamountPercent = 'transfer_dataamount_percent',
  /** column name */
  TransferDatadestinationId = 'transfer_datadestination_id',
  /** column name */
  TrialEnd = 'trial_end',
  /** column name */
  TrialStart = 'trial_start'
}

/** input type for updating data in table "stripe_subscriptions" */
export type Stripe_Subscriptions_Set_Input = {
  application_fee_percent?: InputMaybe<Scalars['numeric']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  billing_cycle_anchor?: InputMaybe<Scalars['bigint']['input']>;
  billing_thresholds_amount_gte?: InputMaybe<Scalars['bigint']['input']>;
  billing_thresholds_reset_billing_cycle_anchor?: InputMaybe<Scalars['Boolean']['input']>;
  cancel_at?: InputMaybe<Scalars['bigint']['input']>;
  cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>;
  canceled_at?: InputMaybe<Scalars['bigint']['input']>;
  collection_method?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  current_period_end?: InputMaybe<Scalars['bigint']['input']>;
  current_period_start?: InputMaybe<Scalars['bigint']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  days_until_due?: InputMaybe<Scalars['bigint']['input']>;
  default_payment_method_id?: InputMaybe<Scalars['String']['input']>;
  default_source_id?: InputMaybe<Scalars['String']['input']>;
  default_tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  discount_id?: InputMaybe<Scalars['String']['input']>;
  ended_at?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Scalars['jsonb']['input']>;
  latest_invoice_id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_pending_invoice_item_invoice?: InputMaybe<Scalars['bigint']['input']>;
  pause_collection_behavior?: InputMaybe<Scalars['String']['input']>;
  pause_collection_resumes_at?: InputMaybe<Scalars['bigint']['input']>;
  payment_settings?: InputMaybe<Scalars['jsonb']['input']>;
  pending_invoice_item_interval_interval?: InputMaybe<Scalars['String']['input']>;
  pending_invoice_item_interval_interval_count?: InputMaybe<Scalars['bigint']['input']>;
  pending_setup_intent_id?: InputMaybe<Scalars['String']['input']>;
  pending_update?: InputMaybe<Scalars['jsonb']['input']>;
  schedule_id?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transfer_dataamount_percent?: InputMaybe<Scalars['numeric']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  trial_end?: InputMaybe<Scalars['bigint']['input']>;
  trial_start?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Stripe_Subscriptions_Stddev_Fields = {
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['Float']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['Float']['output']>;
  cancel_at?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  current_period_end?: Maybe<Scalars['Float']['output']>;
  current_period_start?: Maybe<Scalars['Float']['output']>;
  days_until_due?: Maybe<Scalars['Float']['output']>;
  ended_at?: Maybe<Scalars['Float']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['Float']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['Float']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['Float']['output']>;
  start_date?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['Float']['output']>;
  trial_end?: Maybe<Scalars['Float']['output']>;
  trial_start?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stripe_Subscriptions_Stddev_Pop_Fields = {
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['Float']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['Float']['output']>;
  cancel_at?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  current_period_end?: Maybe<Scalars['Float']['output']>;
  current_period_start?: Maybe<Scalars['Float']['output']>;
  days_until_due?: Maybe<Scalars['Float']['output']>;
  ended_at?: Maybe<Scalars['Float']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['Float']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['Float']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['Float']['output']>;
  start_date?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['Float']['output']>;
  trial_end?: Maybe<Scalars['Float']['output']>;
  trial_start?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stripe_Subscriptions_Stddev_Samp_Fields = {
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['Float']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['Float']['output']>;
  cancel_at?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  current_period_end?: Maybe<Scalars['Float']['output']>;
  current_period_start?: Maybe<Scalars['Float']['output']>;
  days_until_due?: Maybe<Scalars['Float']['output']>;
  ended_at?: Maybe<Scalars['Float']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['Float']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['Float']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['Float']['output']>;
  start_date?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['Float']['output']>;
  trial_end?: Maybe<Scalars['Float']['output']>;
  trial_start?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stripe_subscriptions" */
export type Stripe_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Subscriptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Subscriptions_Stream_Cursor_Value_Input = {
  application_fee_percent?: InputMaybe<Scalars['numeric']['input']>;
  automatic_tax_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  billing_cycle_anchor?: InputMaybe<Scalars['bigint']['input']>;
  billing_thresholds_amount_gte?: InputMaybe<Scalars['bigint']['input']>;
  billing_thresholds_reset_billing_cycle_anchor?: InputMaybe<Scalars['Boolean']['input']>;
  cancel_at?: InputMaybe<Scalars['bigint']['input']>;
  cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>;
  canceled_at?: InputMaybe<Scalars['bigint']['input']>;
  collection_method?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['bigint']['input']>;
  current_period_end?: InputMaybe<Scalars['bigint']['input']>;
  current_period_start?: InputMaybe<Scalars['bigint']['input']>;
  customer_id?: InputMaybe<Scalars['String']['input']>;
  days_until_due?: InputMaybe<Scalars['bigint']['input']>;
  default_payment_method_id?: InputMaybe<Scalars['String']['input']>;
  default_source_id?: InputMaybe<Scalars['String']['input']>;
  default_tax_rates?: InputMaybe<Scalars['jsonb']['input']>;
  discount_id?: InputMaybe<Scalars['String']['input']>;
  ended_at?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Scalars['jsonb']['input']>;
  latest_invoice_id?: InputMaybe<Scalars['String']['input']>;
  livemode?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_pending_invoice_item_invoice?: InputMaybe<Scalars['bigint']['input']>;
  pause_collection_behavior?: InputMaybe<Scalars['String']['input']>;
  pause_collection_resumes_at?: InputMaybe<Scalars['bigint']['input']>;
  payment_settings?: InputMaybe<Scalars['jsonb']['input']>;
  pending_invoice_item_interval_interval?: InputMaybe<Scalars['String']['input']>;
  pending_invoice_item_interval_interval_count?: InputMaybe<Scalars['bigint']['input']>;
  pending_setup_intent_id?: InputMaybe<Scalars['String']['input']>;
  pending_update?: InputMaybe<Scalars['jsonb']['input']>;
  schedule_id?: InputMaybe<Scalars['String']['input']>;
  start_date?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transfer_dataamount_percent?: InputMaybe<Scalars['numeric']['input']>;
  transfer_datadestination_id?: InputMaybe<Scalars['String']['input']>;
  trial_end?: InputMaybe<Scalars['bigint']['input']>;
  trial_start?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Stripe_Subscriptions_Sum_Fields = {
  application_fee_percent?: Maybe<Scalars['numeric']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['bigint']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['bigint']['output']>;
  cancel_at?: Maybe<Scalars['bigint']['output']>;
  canceled_at?: Maybe<Scalars['bigint']['output']>;
  created?: Maybe<Scalars['bigint']['output']>;
  current_period_end?: Maybe<Scalars['bigint']['output']>;
  current_period_start?: Maybe<Scalars['bigint']['output']>;
  days_until_due?: Maybe<Scalars['bigint']['output']>;
  ended_at?: Maybe<Scalars['bigint']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['bigint']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['bigint']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['bigint']['output']>;
  start_date?: Maybe<Scalars['bigint']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['numeric']['output']>;
  trial_end?: Maybe<Scalars['bigint']['output']>;
  trial_start?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stripe_subscriptions" */
export enum Stripe_Subscriptions_Update_Column {
  /** column name */
  ApplicationFeePercent = 'application_fee_percent',
  /** column name */
  AutomaticTaxEnabled = 'automatic_tax_enabled',
  /** column name */
  BillingCycleAnchor = 'billing_cycle_anchor',
  /** column name */
  BillingThresholdsAmountGte = 'billing_thresholds_amount_gte',
  /** column name */
  BillingThresholdsResetBillingCycleAnchor = 'billing_thresholds_reset_billing_cycle_anchor',
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CancelAtPeriodEnd = 'cancel_at_period_end',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  Created = 'created',
  /** column name */
  CurrentPeriodEnd = 'current_period_end',
  /** column name */
  CurrentPeriodStart = 'current_period_start',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DaysUntilDue = 'days_until_due',
  /** column name */
  DefaultPaymentMethodId = 'default_payment_method_id',
  /** column name */
  DefaultSourceId = 'default_source_id',
  /** column name */
  DefaultTaxRates = 'default_tax_rates',
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  Items = 'items',
  /** column name */
  LatestInvoiceId = 'latest_invoice_id',
  /** column name */
  Livemode = 'livemode',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextPendingInvoiceItemInvoice = 'next_pending_invoice_item_invoice',
  /** column name */
  PauseCollectionBehavior = 'pause_collection_behavior',
  /** column name */
  PauseCollectionResumesAt = 'pause_collection_resumes_at',
  /** column name */
  PaymentSettings = 'payment_settings',
  /** column name */
  PendingInvoiceItemIntervalInterval = 'pending_invoice_item_interval_interval',
  /** column name */
  PendingInvoiceItemIntervalIntervalCount = 'pending_invoice_item_interval_interval_count',
  /** column name */
  PendingSetupIntentId = 'pending_setup_intent_id',
  /** column name */
  PendingUpdate = 'pending_update',
  /** column name */
  ScheduleId = 'schedule_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  TransferDataamountPercent = 'transfer_dataamount_percent',
  /** column name */
  TransferDatadestinationId = 'transfer_datadestination_id',
  /** column name */
  TrialEnd = 'trial_end',
  /** column name */
  TrialStart = 'trial_start'
}

export type Stripe_Subscriptions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Subscriptions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Subscriptions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Subscriptions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Subscriptions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stripe_Subscriptions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Subscriptions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Subscriptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stripe_Subscriptions_Var_Pop_Fields = {
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['Float']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['Float']['output']>;
  cancel_at?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  current_period_end?: Maybe<Scalars['Float']['output']>;
  current_period_start?: Maybe<Scalars['Float']['output']>;
  days_until_due?: Maybe<Scalars['Float']['output']>;
  ended_at?: Maybe<Scalars['Float']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['Float']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['Float']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['Float']['output']>;
  start_date?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['Float']['output']>;
  trial_end?: Maybe<Scalars['Float']['output']>;
  trial_start?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stripe_Subscriptions_Var_Samp_Fields = {
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['Float']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['Float']['output']>;
  cancel_at?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  current_period_end?: Maybe<Scalars['Float']['output']>;
  current_period_start?: Maybe<Scalars['Float']['output']>;
  days_until_due?: Maybe<Scalars['Float']['output']>;
  ended_at?: Maybe<Scalars['Float']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['Float']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['Float']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['Float']['output']>;
  start_date?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['Float']['output']>;
  trial_end?: Maybe<Scalars['Float']['output']>;
  trial_start?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stripe_Subscriptions_Variance_Fields = {
  application_fee_percent?: Maybe<Scalars['Float']['output']>;
  billing_cycle_anchor?: Maybe<Scalars['Float']['output']>;
  billing_thresholds_amount_gte?: Maybe<Scalars['Float']['output']>;
  cancel_at?: Maybe<Scalars['Float']['output']>;
  canceled_at?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Float']['output']>;
  current_period_end?: Maybe<Scalars['Float']['output']>;
  current_period_start?: Maybe<Scalars['Float']['output']>;
  days_until_due?: Maybe<Scalars['Float']['output']>;
  ended_at?: Maybe<Scalars['Float']['output']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['Float']['output']>;
  pause_collection_resumes_at?: Maybe<Scalars['Float']['output']>;
  pending_invoice_item_interval_interval_count?: Maybe<Scalars['Float']['output']>;
  start_date?: Maybe<Scalars['Float']['output']>;
  transfer_dataamount_percent?: Maybe<Scalars['Float']['output']>;
  trial_end?: Maybe<Scalars['Float']['output']>;
  trial_start?: Maybe<Scalars['Float']['output']>;
};

export type Subscription_Root = {
  /** fetch data from the table: "addresses" */
  addresses: Array<Addresses>;
  /** fetch aggregated fields from the table: "addresses" */
  addresses_aggregate: Addresses_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  addresses_by_pk?: Maybe<Addresses>;
  /** fetch data from the table: "addresses_need_informations" */
  addresses_need_informations: Array<Addresses_Need_Informations>;
  /** fetch aggregated fields from the table: "addresses_need_informations" */
  addresses_need_informations_aggregate: Addresses_Need_Informations_Aggregate;
  /** fetch data from the table in a streaming manner: "addresses_need_informations" */
  addresses_need_informations_stream: Array<Addresses_Need_Informations>;
  /** fetch data from the table: "addresses_organizations" */
  addresses_organizations: Array<Addresses_Organizations>;
  /** fetch aggregated fields from the table: "addresses_organizations" */
  addresses_organizations_aggregate: Addresses_Organizations_Aggregate;
  /** fetch data from the table in a streaming manner: "addresses_organizations" */
  addresses_organizations_stream: Array<Addresses_Organizations>;
  /** fetch data from the table in a streaming manner: "addresses" */
  addresses_stream: Array<Addresses>;
  /** fetch data from the table: "addresses_users" */
  addresses_users: Array<Addresses_Users>;
  /** fetch aggregated fields from the table: "addresses_users" */
  addresses_users_aggregate: Addresses_Users_Aggregate;
  /** fetch data from the table in a streaming manner: "addresses_users" */
  addresses_users_stream: Array<Addresses_Users>;
  /** fetch data from the table: "aliases" */
  aliases: Array<Aliases>;
  /** fetch aggregated fields from the table: "aliases" */
  aliases_aggregate: Aliases_Aggregate;
  /** fetch data from the table in a streaming manner: "aliases" */
  aliases_stream: Array<Aliases>;
  /** An array relationship */
  checkout_intents: Array<Checkout_Intents>;
  /** An aggregate relationship */
  checkout_intents_aggregate: Checkout_Intents_Aggregate;
  /** fetch data from the table: "checkout_intents" using primary key columns */
  checkout_intents_by_pk?: Maybe<Checkout_Intents>;
  /** fetch data from the table in a streaming manner: "checkout_intents" */
  checkout_intents_stream: Array<Checkout_Intents>;
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  /** fetch data from the table in a streaming manner: "devices" */
  devices_stream: Array<Devices>;
  /** An array relationship */
  feed_items: Array<Feed_Items>;
  /** An aggregate relationship */
  feed_items_aggregate: Feed_Items_Aggregate;
  /** fetch data from the table: "feed_items" using primary key columns */
  feed_items_by_pk?: Maybe<Feed_Items>;
  /** fetch data from the table in a streaming manner: "feed_items" */
  feed_items_stream: Array<Feed_Items>;
  /** fetch data from the table: "feed_posts" */
  feed_posts: Array<Feed_Posts>;
  /** fetch aggregated fields from the table: "feed_posts" */
  feed_posts_aggregate: Feed_Posts_Aggregate;
  /** fetch data from the table: "feed_posts" using primary key columns */
  feed_posts_by_pk?: Maybe<Feed_Posts>;
  /** fetch data from the table in a streaming manner: "feed_posts" */
  feed_posts_stream: Array<Feed_Posts>;
  /** fetch data from the table: "goals" */
  goals: Array<Goals>;
  /** fetch aggregated fields from the table: "goals" */
  goals_aggregate: Goals_Aggregate;
  /** fetch data from the table: "goals" using primary key columns */
  goals_by_pk?: Maybe<Goals>;
  /** fetch data from the table in a streaming manner: "goals" */
  goals_stream: Array<Goals>;
  /** fetch data from the table: "internal_data.organization_goals" */
  internal_data_organization_goals: Array<Internal_Data_Organization_Goals>;
  /** fetch aggregated fields from the table: "internal_data.organization_goals" */
  internal_data_organization_goals_aggregate: Internal_Data_Organization_Goals_Aggregate;
  /** fetch data from the table: "internal_data.organization_goals" using primary key columns */
  internal_data_organization_goals_by_pk?: Maybe<Internal_Data_Organization_Goals>;
  /** fetch data from the table in a streaming manner: "internal_data.organization_goals" */
  internal_data_organization_goals_stream: Array<Internal_Data_Organization_Goals>;
  /** fetch data from the table: "list_locations" */
  list_locations: Array<List_Locations>;
  /** fetch aggregated fields from the table: "list_locations" */
  list_locations_aggregate: List_Locations_Aggregate;
  /** fetch data from the table in a streaming manner: "list_locations" */
  list_locations_stream: Array<List_Locations>;
  /** An array relationship */
  list_subscriptions: Array<List_Subscriptions>;
  /** An aggregate relationship */
  list_subscriptions_aggregate: List_Subscriptions_Aggregate;
  /** fetch data from the table: "list_subscriptions" using primary key columns */
  list_subscriptions_by_pk?: Maybe<List_Subscriptions>;
  /** fetch data from the table in a streaming manner: "list_subscriptions" */
  list_subscriptions_stream: Array<List_Subscriptions>;
  /** An array relationship */
  lists: Array<Lists>;
  /** An aggregate relationship */
  lists_aggregate: Lists_Aggregate;
  /** fetch data from the table: "lists" using primary key columns */
  lists_by_pk?: Maybe<Lists>;
  /** execute function "lists_geo" which returns "lists" */
  lists_geo: Array<Lists>;
  /** execute function "lists_geo" and query aggregates on result of table type "lists" */
  lists_geo_aggregate: Lists_Aggregate;
  /** fetch data from the table in a streaming manner: "lists" */
  lists_stream: Array<Lists>;
  /** execute function "me" which returns "users" */
  me?: Maybe<Users>;
  /** execute function "me" and query aggregates on result of table type "users" */
  me_aggregate: Users_Aggregate;
  /** An array relationship */
  need_fulfill_intents: Array<Need_Fulfill_Intents>;
  /** An aggregate relationship */
  need_fulfill_intents_aggregate: Need_Fulfill_Intents_Aggregate;
  /** fetch data from the table: "need_fulfill_intents" using primary key columns */
  need_fulfill_intents_by_pk?: Maybe<Need_Fulfill_Intents>;
  /** fetch data from the table in a streaming manner: "need_fulfill_intents" */
  need_fulfill_intents_stream: Array<Need_Fulfill_Intents>;
  /** An array relationship */
  need_informations: Array<Need_Informations>;
  /** An aggregate relationship */
  need_informations_aggregate: Need_Informations_Aggregate;
  /** fetch data from the table: "need_informations" using primary key columns */
  need_informations_by_pk?: Maybe<Need_Informations>;
  /** fetch data from the table in a streaming manner: "need_informations" */
  need_informations_stream: Array<Need_Informations>;
  /** fetch data from the table: "need_locations" */
  need_locations: Array<Need_Locations>;
  /** fetch aggregated fields from the table: "need_locations" */
  need_locations_aggregate: Need_Locations_Aggregate;
  /** fetch data from the table in a streaming manner: "need_locations" */
  need_locations_stream: Array<Need_Locations>;
  /** fetch data from the table: "need_tags" */
  need_tags: Array<Need_Tags>;
  /** fetch aggregated fields from the table: "need_tags" */
  need_tags_aggregate: Need_Tags_Aggregate;
  /** fetch data from the table: "need_tags" using primary key columns */
  need_tags_by_pk?: Maybe<Need_Tags>;
  /** fetch data from the table in a streaming manner: "need_tags" */
  need_tags_stream: Array<Need_Tags>;
  /** An array relationship */
  needs: Array<Needs>;
  /** An aggregate relationship */
  needs_aggregate: Needs_Aggregate;
  /** fetch data from the table: "needs" using primary key columns */
  needs_by_pk?: Maybe<Needs>;
  /** execute function "needs_geo" which returns "needs" */
  needs_geo: Array<Needs>;
  /** execute function "needs_geo" and query aggregates on result of table type "needs" */
  needs_geo_aggregate: Needs_Aggregate;
  /** execute function "needs_list" which returns "needs" */
  needs_list: Array<Needs>;
  /** execute function "needs_list" and query aggregates on result of table type "needs" */
  needs_list_aggregate: Needs_Aggregate;
  /** execute function "needs_shuffled" which returns "needs" */
  needs_shuffled: Array<Needs>;
  /** execute function "needs_shuffled" and query aggregates on result of table type "needs" */
  needs_shuffled_aggregate: Needs_Aggregate;
  /** fetch data from the table in a streaming manner: "needs" */
  needs_stream: Array<Needs>;
  /** fetch data from the table: "organization_locations" */
  organization_locations: Array<Organization_Locations>;
  /** fetch aggregated fields from the table: "organization_locations" */
  organization_locations_aggregate: Organization_Locations_Aggregate;
  /** fetch data from the table in a streaming manner: "organization_locations" */
  organization_locations_stream: Array<Organization_Locations>;
  /** fetch data from the table: "organization_stats" */
  organization_stats: Array<Organization_Stats>;
  /** fetch aggregated fields from the table: "organization_stats" */
  organization_stats_aggregate: Organization_Stats_Aggregate;
  /** fetch data from the table in a streaming manner: "organization_stats" */
  organization_stats_stream: Array<Organization_Stats>;
  /** An array relationship */
  organization_subscriptions: Array<Organization_Subscriptions>;
  /** An aggregate relationship */
  organization_subscriptions_aggregate: Organization_Subscriptions_Aggregate;
  /** fetch data from the table: "organization_subscriptions" using primary key columns */
  organization_subscriptions_by_pk?: Maybe<Organization_Subscriptions>;
  /** fetch data from the table in a streaming manner: "organization_subscriptions" */
  organization_subscriptions_stream: Array<Organization_Subscriptions>;
  /** An array relationship */
  organization_tags: Array<Organization_Tags>;
  /** An aggregate relationship */
  organization_tags_aggregate: Organization_Tags_Aggregate;
  /** fetch data from the table: "organization_tags" using primary key columns */
  organization_tags_by_pk?: Maybe<Organization_Tags>;
  /** fetch data from the table in a streaming manner: "organization_tags" */
  organization_tags_stream: Array<Organization_Tags>;
  /** An array relationship */
  organizations: Array<Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Organizations_Aggregate;
  /** execute function "organizations_by_alias" which returns "organizations" */
  organizations_by_alias: Array<Organizations>;
  /** execute function "organizations_by_alias" and query aggregates on result of table type "organizations" */
  organizations_by_alias_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** execute function "organizations_geo" which returns "organizations" */
  organizations_geo: Array<Organizations>;
  /** execute function "organizations_geo" and query aggregates on result of table type "organizations" */
  organizations_geo_aggregate: Organizations_Aggregate;
  /** fetch data from the table in a streaming manner: "organizations" */
  organizations_stream: Array<Organizations>;
  /** fetch data from the table: "parent_organizations" */
  parent_organizations: Array<Parent_Organizations>;
  /** fetch aggregated fields from the table: "parent_organizations" */
  parent_organizations_aggregate: Parent_Organizations_Aggregate;
  /** fetch data from the table: "parent_organizations" using primary key columns */
  parent_organizations_by_pk?: Maybe<Parent_Organizations>;
  /** fetch data from the table in a streaming manner: "parent_organizations" */
  parent_organizations_stream: Array<Parent_Organizations>;
  /** fetch data from the table: "posts" */
  posts: Array<Posts>;
  /** fetch aggregated fields from the table: "posts" */
  posts_aggregate: Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>;
  /** fetch data from the table in a streaming manner: "posts" */
  posts_stream: Array<Posts>;
  /** fetch data from the table: "private.modified_data" */
  private_modified_data: Array<Private_Modified_Data>;
  /** fetch aggregated fields from the table: "private.modified_data" */
  private_modified_data_aggregate: Private_Modified_Data_Aggregate;
  /** fetch data from the table: "private.modified_data" using primary key columns */
  private_modified_data_by_pk?: Maybe<Private_Modified_Data>;
  /** fetch data from the table in a streaming manner: "private.modified_data" */
  private_modified_data_stream: Array<Private_Modified_Data>;
  /** execute function "search_users" which returns "users" */
  search_users: Array<Users>;
  /** execute function "search_users" and query aggregates on result of table type "users" */
  search_users_aggregate: Users_Aggregate;
  /** fetch data from the table: "sponsors" */
  sponsors: Array<Sponsors>;
  /** fetch aggregated fields from the table: "sponsors" */
  sponsors_aggregate: Sponsors_Aggregate;
  /** fetch data from the table: "sponsors" using primary key columns */
  sponsors_by_pk?: Maybe<Sponsors>;
  /** fetch data from the table in a streaming manner: "sponsors" */
  sponsors_stream: Array<Sponsors>;
  /** An array relationship */
  sponsorships: Array<Sponsorships>;
  /** An aggregate relationship */
  sponsorships_aggregate: Sponsorships_Aggregate;
  /** fetch data from the table: "sponsorships" using primary key columns */
  sponsorships_by_pk?: Maybe<Sponsorships>;
  /** fetch data from the table in a streaming manner: "sponsorships" */
  sponsorships_stream: Array<Sponsorships>;
  /** fetch data from the table: "stories" */
  stories: Array<Stories>;
  /** fetch aggregated fields from the table: "stories" */
  stories_aggregate: Stories_Aggregate;
  /** fetch data from the table: "stories" using primary key columns */
  stories_by_pk?: Maybe<Stories>;
  /** fetch data from the table in a streaming manner: "stories" */
  stories_stream: Array<Stories>;
  /** fetch data from the table: "stripe_charges" */
  stripe_charges: Array<Stripe_Charges>;
  /** fetch aggregated fields from the table: "stripe_charges" */
  stripe_charges_aggregate: Stripe_Charges_Aggregate;
  /** fetch data from the table: "stripe_charges" using primary key columns */
  stripe_charges_by_pk?: Maybe<Stripe_Charges>;
  /** fetch data from the table in a streaming manner: "stripe_charges" */
  stripe_charges_stream: Array<Stripe_Charges>;
  /** fetch data from the table: "stripe_checkout_sessions" */
  stripe_checkout_sessions: Array<Stripe_Checkout_Sessions>;
  /** fetch aggregated fields from the table: "stripe_checkout_sessions" */
  stripe_checkout_sessions_aggregate: Stripe_Checkout_Sessions_Aggregate;
  /** fetch data from the table: "stripe_checkout_sessions" using primary key columns */
  stripe_checkout_sessions_by_pk?: Maybe<Stripe_Checkout_Sessions>;
  /** fetch data from the table in a streaming manner: "stripe_checkout_sessions" */
  stripe_checkout_sessions_stream: Array<Stripe_Checkout_Sessions>;
  /** fetch data from the table: "stripe_customers" */
  stripe_customers: Array<Stripe_Customers>;
  /** fetch aggregated fields from the table: "stripe_customers" */
  stripe_customers_aggregate: Stripe_Customers_Aggregate;
  /** fetch data from the table: "stripe_customers" using primary key columns */
  stripe_customers_by_pk?: Maybe<Stripe_Customers>;
  /** fetch data from the table in a streaming manner: "stripe_customers" */
  stripe_customers_stream: Array<Stripe_Customers>;
  /** fetch data from the table: "stripe_invoice_items" */
  stripe_invoice_items: Array<Stripe_Invoice_Items>;
  /** fetch aggregated fields from the table: "stripe_invoice_items" */
  stripe_invoice_items_aggregate: Stripe_Invoice_Items_Aggregate;
  /** fetch data from the table: "stripe_invoice_items" using primary key columns */
  stripe_invoice_items_by_pk?: Maybe<Stripe_Invoice_Items>;
  /** fetch data from the table in a streaming manner: "stripe_invoice_items" */
  stripe_invoice_items_stream: Array<Stripe_Invoice_Items>;
  /** fetch data from the table: "stripe_invoices" */
  stripe_invoices: Array<Stripe_Invoices>;
  /** fetch aggregated fields from the table: "stripe_invoices" */
  stripe_invoices_aggregate: Stripe_Invoices_Aggregate;
  /** fetch data from the table: "stripe_invoices" using primary key columns */
  stripe_invoices_by_pk?: Maybe<Stripe_Invoices>;
  /** fetch data from the table in a streaming manner: "stripe_invoices" */
  stripe_invoices_stream: Array<Stripe_Invoices>;
  /** fetch data from the table: "stripe_payment_intents" */
  stripe_payment_intents: Array<Stripe_Payment_Intents>;
  /** fetch aggregated fields from the table: "stripe_payment_intents" */
  stripe_payment_intents_aggregate: Stripe_Payment_Intents_Aggregate;
  /** fetch data from the table: "stripe_payment_intents" using primary key columns */
  stripe_payment_intents_by_pk?: Maybe<Stripe_Payment_Intents>;
  /** fetch data from the table in a streaming manner: "stripe_payment_intents" */
  stripe_payment_intents_stream: Array<Stripe_Payment_Intents>;
  /** fetch data from the table: "stripe_payment_links" */
  stripe_payment_links: Array<Stripe_Payment_Links>;
  /** fetch aggregated fields from the table: "stripe_payment_links" */
  stripe_payment_links_aggregate: Stripe_Payment_Links_Aggregate;
  /** fetch data from the table: "stripe_payment_links" using primary key columns */
  stripe_payment_links_by_pk?: Maybe<Stripe_Payment_Links>;
  /** fetch data from the table in a streaming manner: "stripe_payment_links" */
  stripe_payment_links_stream: Array<Stripe_Payment_Links>;
  /** fetch data from the table: "stripe_payment_methods" */
  stripe_payment_methods: Array<Stripe_Payment_Methods>;
  /** fetch aggregated fields from the table: "stripe_payment_methods" */
  stripe_payment_methods_aggregate: Stripe_Payment_Methods_Aggregate;
  /** fetch data from the table: "stripe_payment_methods" using primary key columns */
  stripe_payment_methods_by_pk?: Maybe<Stripe_Payment_Methods>;
  /** fetch data from the table in a streaming manner: "stripe_payment_methods" */
  stripe_payment_methods_stream: Array<Stripe_Payment_Methods>;
  /** fetch data from the table: "stripe_prices" */
  stripe_prices: Array<Stripe_Prices>;
  /** fetch aggregated fields from the table: "stripe_prices" */
  stripe_prices_aggregate: Stripe_Prices_Aggregate;
  /** fetch data from the table: "stripe_prices" using primary key columns */
  stripe_prices_by_pk?: Maybe<Stripe_Prices>;
  /** fetch data from the table in a streaming manner: "stripe_prices" */
  stripe_prices_stream: Array<Stripe_Prices>;
  /** fetch data from the table: "stripe_products" */
  stripe_products: Array<Stripe_Products>;
  /** fetch aggregated fields from the table: "stripe_products" */
  stripe_products_aggregate: Stripe_Products_Aggregate;
  /** fetch data from the table: "stripe_products" using primary key columns */
  stripe_products_by_pk?: Maybe<Stripe_Products>;
  /** fetch data from the table in a streaming manner: "stripe_products" */
  stripe_products_stream: Array<Stripe_Products>;
  /** fetch data from the table: "stripe_subscriptions" */
  stripe_subscriptions: Array<Stripe_Subscriptions>;
  /** fetch aggregated fields from the table: "stripe_subscriptions" */
  stripe_subscriptions_aggregate: Stripe_Subscriptions_Aggregate;
  /** fetch data from the table: "stripe_subscriptions" using primary key columns */
  stripe_subscriptions_by_pk?: Maybe<Stripe_Subscriptions>;
  /** fetch data from the table in a streaming manner: "stripe_subscriptions" */
  stripe_subscriptions_stream: Array<Stripe_Subscriptions>;
  /** An array relationship */
  tax_receipts: Array<Tax_Receipts>;
  /** An aggregate relationship */
  tax_receipts_aggregate: Tax_Receipts_Aggregate;
  /** fetch data from the table: "tax_receipts" using primary key columns */
  tax_receipts_by_pk?: Maybe<Tax_Receipts>;
  /** fetch data from the table in a streaming manner: "tax_receipts" */
  tax_receipts_stream: Array<Tax_Receipts>;
  /** fetch data from the table: "team_avatars" */
  team_avatars: Array<Team_Avatars>;
  /** fetch aggregated fields from the table: "team_avatars" */
  team_avatars_aggregate: Team_Avatars_Aggregate;
  /** fetch data from the table in a streaming manner: "team_avatars" */
  team_avatars_stream: Array<Team_Avatars>;
  /** An array relationship */
  team_memberships: Array<Team_Memberships>;
  /** An aggregate relationship */
  team_memberships_aggregate: Team_Memberships_Aggregate;
  /** fetch data from the table: "memberships" using primary key columns */
  team_memberships_by_pk?: Maybe<Team_Memberships>;
  /** fetch data from the table in a streaming manner: "memberships" */
  team_memberships_stream: Array<Team_Memberships>;
  /** An array relationship */
  team_needs: Array<Team_Needs>;
  /** An aggregate relationship */
  team_needs_aggregate: Team_Needs_Aggregate;
  /** fetch data from the table: "team_needs" using primary key columns */
  team_needs_by_pk?: Maybe<Team_Needs>;
  /** fetch data from the table: "team_needs_stats" */
  team_needs_stats: Array<Team_Needs_Stats>;
  /** fetch aggregated fields from the table: "team_needs_stats" */
  team_needs_stats_aggregate: Team_Needs_Stats_Aggregate;
  /** fetch data from the table in a streaming manner: "team_needs_stats" */
  team_needs_stats_stream: Array<Team_Needs_Stats>;
  /** fetch data from the table in a streaming manner: "team_needs" */
  team_needs_stream: Array<Team_Needs>;
  /** fetch data from the table: "team_stats" */
  team_stats: Array<Team_Stats>;
  /** fetch aggregated fields from the table: "team_stats" */
  team_stats_aggregate: Team_Stats_Aggregate;
  /** fetch data from the table in a streaming manner: "team_stats" */
  team_stats_stream: Array<Team_Stats>;
  /** fetch data from the table: "team_tags" */
  team_tags: Array<Team_Tags>;
  /** fetch aggregated fields from the table: "team_tags" */
  team_tags_aggregate: Team_Tags_Aggregate;
  /** fetch data from the table: "team_tags" using primary key columns */
  team_tags_by_pk?: Maybe<Team_Tags>;
  /** fetch data from the table in a streaming manner: "team_tags" */
  team_tags_stream: Array<Team_Tags>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table in a streaming manner: "teams" */
  teams_stream: Array<Teams>;
  /** An array relationship */
  thank_yous: Array<Thank_Yous>;
  /** An aggregate relationship */
  thank_yous_aggregate: Thank_Yous_Aggregate;
  /** fetch data from the table: "thank_yous" using primary key columns */
  thank_yous_by_pk?: Maybe<Thank_Yous>;
  /** fetch data from the table in a streaming manner: "thank_yous" */
  thank_yous_stream: Array<Thank_Yous>;
  /** fetch data from the table: "user_goals" */
  user_goals: Array<User_Goals>;
  /** fetch aggregated fields from the table: "user_goals" */
  user_goals_aggregate: User_Goals_Aggregate;
  /** fetch data from the table in a streaming manner: "user_goals" */
  user_goals_stream: Array<User_Goals>;
  /** fetch data from the table: "user_needs_met_stats" */
  user_needs_met_stats: Array<User_Needs_Met_Stats>;
  /** fetch aggregated fields from the table: "user_needs_met_stats" */
  user_needs_met_stats_aggregate: User_Needs_Met_Stats_Aggregate;
  /** fetch data from the table in a streaming manner: "user_needs_met_stats" */
  user_needs_met_stats_stream: Array<User_Needs_Met_Stats>;
  /** fetch data from the table: "user_stats" */
  user_stats: Array<User_Stats>;
  /** fetch aggregated fields from the table: "user_stats" */
  user_stats_aggregate: User_Stats_Aggregate;
  /** fetch data from the table in a streaming manner: "user_stats" */
  user_stats_stream: Array<User_Stats>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};


export type Subscription_RootAddressesArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootAddresses_Need_InformationsArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Need_Informations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Need_Informations_Order_By>>;
  where?: InputMaybe<Addresses_Need_Informations_Bool_Exp>;
};


export type Subscription_RootAddresses_Need_Informations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Need_Informations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Need_Informations_Order_By>>;
  where?: InputMaybe<Addresses_Need_Informations_Bool_Exp>;
};


export type Subscription_RootAddresses_Need_Informations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Addresses_Need_Informations_Stream_Cursor_Input>>;
  where?: InputMaybe<Addresses_Need_Informations_Bool_Exp>;
};


export type Subscription_RootAddresses_OrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Organizations_Order_By>>;
  where?: InputMaybe<Addresses_Organizations_Bool_Exp>;
};


export type Subscription_RootAddresses_Organizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Organizations_Order_By>>;
  where?: InputMaybe<Addresses_Organizations_Bool_Exp>;
};


export type Subscription_RootAddresses_Organizations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Addresses_Organizations_Stream_Cursor_Input>>;
  where?: InputMaybe<Addresses_Organizations_Bool_Exp>;
};


export type Subscription_RootAddresses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_UsersArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Users_Order_By>>;
  where?: InputMaybe<Addresses_Users_Bool_Exp>;
};


export type Subscription_RootAddresses_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Users_Order_By>>;
  where?: InputMaybe<Addresses_Users_Bool_Exp>;
};


export type Subscription_RootAddresses_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Addresses_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Addresses_Users_Bool_Exp>;
};


export type Subscription_RootAliasesArgs = {
  distinct_on?: InputMaybe<Array<Aliases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Aliases_Order_By>>;
  where?: InputMaybe<Aliases_Bool_Exp>;
};


export type Subscription_RootAliases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Aliases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Aliases_Order_By>>;
  where?: InputMaybe<Aliases_Bool_Exp>;
};


export type Subscription_RootAliases_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Aliases_Stream_Cursor_Input>>;
  where?: InputMaybe<Aliases_Bool_Exp>;
};


export type Subscription_RootCheckout_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Checkout_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Checkout_Intents_Order_By>>;
  where?: InputMaybe<Checkout_Intents_Bool_Exp>;
};


export type Subscription_RootCheckout_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Checkout_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Checkout_Intents_Order_By>>;
  where?: InputMaybe<Checkout_Intents_Bool_Exp>;
};


export type Subscription_RootCheckout_Intents_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootCheckout_Intents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Checkout_Intents_Stream_Cursor_Input>>;
  where?: InputMaybe<Checkout_Intents_Bool_Exp>;
};


export type Subscription_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootDevices_By_PkArgs = {
  bundle_id: Scalars['String']['input'];
  local_id: Scalars['uuid']['input'];
};


export type Subscription_RootDevices_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Devices_Stream_Cursor_Input>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootFeed_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


export type Subscription_RootFeed_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


export type Subscription_RootFeed_Items_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootFeed_Items_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Feed_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


export type Subscription_RootFeed_PostsArgs = {
  distinct_on?: InputMaybe<Array<Feed_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Posts_Order_By>>;
  where?: InputMaybe<Feed_Posts_Bool_Exp>;
};


export type Subscription_RootFeed_Posts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feed_Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Posts_Order_By>>;
  where?: InputMaybe<Feed_Posts_Bool_Exp>;
};


export type Subscription_RootFeed_Posts_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootFeed_Posts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Feed_Posts_Stream_Cursor_Input>>;
  where?: InputMaybe<Feed_Posts_Bool_Exp>;
};


export type Subscription_RootGoalsArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


export type Subscription_RootGoals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Goals_Order_By>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


export type Subscription_RootGoals_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootGoals_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Goals_Stream_Cursor_Input>>;
  where?: InputMaybe<Goals_Bool_Exp>;
};


export type Subscription_RootInternal_Data_Organization_GoalsArgs = {
  distinct_on?: InputMaybe<Array<Internal_Data_Organization_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Data_Organization_Goals_Order_By>>;
  where?: InputMaybe<Internal_Data_Organization_Goals_Bool_Exp>;
};


export type Subscription_RootInternal_Data_Organization_Goals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Internal_Data_Organization_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Internal_Data_Organization_Goals_Order_By>>;
  where?: InputMaybe<Internal_Data_Organization_Goals_Bool_Exp>;
};


export type Subscription_RootInternal_Data_Organization_Goals_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootInternal_Data_Organization_Goals_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Internal_Data_Organization_Goals_Stream_Cursor_Input>>;
  where?: InputMaybe<Internal_Data_Organization_Goals_Bool_Exp>;
};


export type Subscription_RootList_LocationsArgs = {
  distinct_on?: InputMaybe<Array<List_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Locations_Order_By>>;
  where?: InputMaybe<List_Locations_Bool_Exp>;
};


export type Subscription_RootList_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Locations_Order_By>>;
  where?: InputMaybe<List_Locations_Bool_Exp>;
};


export type Subscription_RootList_Locations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<List_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<List_Locations_Bool_Exp>;
};


export type Subscription_RootList_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<List_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Subscriptions_Order_By>>;
  where?: InputMaybe<List_Subscriptions_Bool_Exp>;
};


export type Subscription_RootList_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Subscriptions_Order_By>>;
  where?: InputMaybe<List_Subscriptions_Bool_Exp>;
};


export type Subscription_RootList_Subscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootList_Subscriptions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<List_Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<List_Subscriptions_Bool_Exp>;
};


export type Subscription_RootListsArgs = {
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


export type Subscription_RootLists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


export type Subscription_RootLists_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootLists_GeoArgs = {
  args: Lists_Geo_Args;
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


export type Subscription_RootLists_Geo_AggregateArgs = {
  args: Lists_Geo_Args;
  distinct_on?: InputMaybe<Array<Lists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lists_Order_By>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


export type Subscription_RootLists_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lists_Stream_Cursor_Input>>;
  where?: InputMaybe<Lists_Bool_Exp>;
};


export type Subscription_RootMeArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootMe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootNeed_Fulfill_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Need_Fulfill_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Fulfill_Intents_Order_By>>;
  where?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
};


export type Subscription_RootNeed_Fulfill_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Fulfill_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Fulfill_Intents_Order_By>>;
  where?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
};


export type Subscription_RootNeed_Fulfill_Intents_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootNeed_Fulfill_Intents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Need_Fulfill_Intents_Stream_Cursor_Input>>;
  where?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
};


export type Subscription_RootNeed_InformationsArgs = {
  distinct_on?: InputMaybe<Array<Need_Informations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Informations_Order_By>>;
  where?: InputMaybe<Need_Informations_Bool_Exp>;
};


export type Subscription_RootNeed_Informations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Informations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Informations_Order_By>>;
  where?: InputMaybe<Need_Informations_Bool_Exp>;
};


export type Subscription_RootNeed_Informations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootNeed_Informations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Need_Informations_Stream_Cursor_Input>>;
  where?: InputMaybe<Need_Informations_Bool_Exp>;
};


export type Subscription_RootNeed_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Need_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Locations_Order_By>>;
  where?: InputMaybe<Need_Locations_Bool_Exp>;
};


export type Subscription_RootNeed_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Locations_Order_By>>;
  where?: InputMaybe<Need_Locations_Bool_Exp>;
};


export type Subscription_RootNeed_Locations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Need_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Need_Locations_Bool_Exp>;
};


export type Subscription_RootNeed_TagsArgs = {
  distinct_on?: InputMaybe<Array<Need_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Tags_Order_By>>;
  where?: InputMaybe<Need_Tags_Bool_Exp>;
};


export type Subscription_RootNeed_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Tags_Order_By>>;
  where?: InputMaybe<Need_Tags_Bool_Exp>;
};


export type Subscription_RootNeed_Tags_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootNeed_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Need_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Need_Tags_Bool_Exp>;
};


export type Subscription_RootNeedsArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Subscription_RootNeeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Subscription_RootNeeds_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootNeeds_GeoArgs = {
  args?: InputMaybe<Needs_Geo_Args>;
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Subscription_RootNeeds_Geo_AggregateArgs = {
  args?: InputMaybe<Needs_Geo_Args>;
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Subscription_RootNeeds_ListArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Subscription_RootNeeds_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Subscription_RootNeeds_ShuffledArgs = {
  args?: InputMaybe<Needs_Shuffled_Args>;
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Subscription_RootNeeds_Shuffled_AggregateArgs = {
  args?: InputMaybe<Needs_Shuffled_Args>;
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Subscription_RootNeeds_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Needs_Stream_Cursor_Input>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


export type Subscription_RootOrganization_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};


export type Subscription_RootOrganization_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Locations_Order_By>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};


export type Subscription_RootOrganization_Locations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Locations_Bool_Exp>;
};


export type Subscription_RootOrganization_StatsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Stats_Order_By>>;
  where?: InputMaybe<Organization_Stats_Bool_Exp>;
};


export type Subscription_RootOrganization_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Stats_Order_By>>;
  where?: InputMaybe<Organization_Stats_Bool_Exp>;
};


export type Subscription_RootOrganization_Stats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Stats_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Stats_Bool_Exp>;
};


export type Subscription_RootOrganization_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Subscriptions_Order_By>>;
  where?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
};


export type Subscription_RootOrganization_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Subscriptions_Order_By>>;
  where?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
};


export type Subscription_RootOrganization_Subscriptions_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootOrganization_Subscriptions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
};


export type Subscription_RootOrganization_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tags_Order_By>>;
  where?: InputMaybe<Organization_Tags_Bool_Exp>;
};


export type Subscription_RootOrganization_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tags_Order_By>>;
  where?: InputMaybe<Organization_Tags_Bool_Exp>;
};


export type Subscription_RootOrganization_Tags_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootOrganization_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Tags_Bool_Exp>;
};


export type Subscription_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_By_AliasArgs = {
  args: Organizations_By_Alias_Args;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_By_Alias_AggregateArgs = {
  args: Organizations_By_Alias_Args;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootOrganizations_GeoArgs = {
  args: Organizations_Geo_Args;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_Geo_AggregateArgs = {
  args: Organizations_Geo_Args;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootOrganizations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organizations_Stream_Cursor_Input>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootParent_OrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Parent_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_Organizations_Order_By>>;
  where?: InputMaybe<Parent_Organizations_Bool_Exp>;
};


export type Subscription_RootParent_Organizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parent_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Parent_Organizations_Order_By>>;
  where?: InputMaybe<Parent_Organizations_Bool_Exp>;
};


export type Subscription_RootParent_Organizations_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootParent_Organizations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Parent_Organizations_Stream_Cursor_Input>>;
  where?: InputMaybe<Parent_Organizations_Bool_Exp>;
};


export type Subscription_RootPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootPosts_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootPosts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Posts_Stream_Cursor_Input>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootPrivate_Modified_DataArgs = {
  distinct_on?: InputMaybe<Array<Private_Modified_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Private_Modified_Data_Order_By>>;
  where?: InputMaybe<Private_Modified_Data_Bool_Exp>;
};


export type Subscription_RootPrivate_Modified_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Private_Modified_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Private_Modified_Data_Order_By>>;
  where?: InputMaybe<Private_Modified_Data_Bool_Exp>;
};


export type Subscription_RootPrivate_Modified_Data_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootPrivate_Modified_Data_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Private_Modified_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Private_Modified_Data_Bool_Exp>;
};


export type Subscription_RootSearch_UsersArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootSponsorsArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Order_By>>;
  where?: InputMaybe<Sponsors_Bool_Exp>;
};


export type Subscription_RootSponsors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Order_By>>;
  where?: InputMaybe<Sponsors_Bool_Exp>;
};


export type Subscription_RootSponsors_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootSponsors_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sponsors_Stream_Cursor_Input>>;
  where?: InputMaybe<Sponsors_Bool_Exp>;
};


export type Subscription_RootSponsorshipsArgs = {
  distinct_on?: InputMaybe<Array<Sponsorships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsorships_Order_By>>;
  where?: InputMaybe<Sponsorships_Bool_Exp>;
};


export type Subscription_RootSponsorships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sponsorships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsorships_Order_By>>;
  where?: InputMaybe<Sponsorships_Bool_Exp>;
};


export type Subscription_RootSponsorships_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootSponsorships_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sponsorships_Stream_Cursor_Input>>;
  where?: InputMaybe<Sponsorships_Bool_Exp>;
};


export type Subscription_RootStoriesArgs = {
  distinct_on?: InputMaybe<Array<Stories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stories_Order_By>>;
  where?: InputMaybe<Stories_Bool_Exp>;
};


export type Subscription_RootStories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stories_Order_By>>;
  where?: InputMaybe<Stories_Bool_Exp>;
};


export type Subscription_RootStories_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootStories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stories_Stream_Cursor_Input>>;
  where?: InputMaybe<Stories_Bool_Exp>;
};


export type Subscription_RootStripe_ChargesArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Charges_Order_By>>;
  where?: InputMaybe<Stripe_Charges_Bool_Exp>;
};


export type Subscription_RootStripe_Charges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Charges_Order_By>>;
  where?: InputMaybe<Stripe_Charges_Bool_Exp>;
};


export type Subscription_RootStripe_Charges_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Charges_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Charges_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Charges_Bool_Exp>;
};


export type Subscription_RootStripe_Checkout_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Checkout_Sessions_Order_By>>;
  where?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


export type Subscription_RootStripe_Checkout_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Checkout_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Checkout_Sessions_Order_By>>;
  where?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


export type Subscription_RootStripe_Checkout_Sessions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Checkout_Sessions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Checkout_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Checkout_Sessions_Bool_Exp>;
};


export type Subscription_RootStripe_CustomersArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Customers_Order_By>>;
  where?: InputMaybe<Stripe_Customers_Bool_Exp>;
};


export type Subscription_RootStripe_Customers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Customers_Order_By>>;
  where?: InputMaybe<Stripe_Customers_Bool_Exp>;
};


export type Subscription_RootStripe_Customers_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Customers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Customers_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Customers_Bool_Exp>;
};


export type Subscription_RootStripe_Invoice_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Invoice_Items_Order_By>>;
  where?: InputMaybe<Stripe_Invoice_Items_Bool_Exp>;
};


export type Subscription_RootStripe_Invoice_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Invoice_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Invoice_Items_Order_By>>;
  where?: InputMaybe<Stripe_Invoice_Items_Bool_Exp>;
};


export type Subscription_RootStripe_Invoice_Items_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Invoice_Items_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Invoice_Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Invoice_Items_Bool_Exp>;
};


export type Subscription_RootStripe_InvoicesArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Invoices_Order_By>>;
  where?: InputMaybe<Stripe_Invoices_Bool_Exp>;
};


export type Subscription_RootStripe_Invoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Invoices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Invoices_Order_By>>;
  where?: InputMaybe<Stripe_Invoices_Bool_Exp>;
};


export type Subscription_RootStripe_Invoices_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Invoices_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Invoices_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Invoices_Bool_Exp>;
};


export type Subscription_RootStripe_Payment_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Intents_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Intents_Bool_Exp>;
};


export type Subscription_RootStripe_Payment_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Intents_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Intents_Bool_Exp>;
};


export type Subscription_RootStripe_Payment_Intents_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Payment_Intents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Payment_Intents_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Payment_Intents_Bool_Exp>;
};


export type Subscription_RootStripe_Payment_LinksArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Links_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Links_Bool_Exp>;
};


export type Subscription_RootStripe_Payment_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Links_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Links_Bool_Exp>;
};


export type Subscription_RootStripe_Payment_Links_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Payment_Links_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Payment_Links_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Payment_Links_Bool_Exp>;
};


export type Subscription_RootStripe_Payment_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Methods_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Methods_Bool_Exp>;
};


export type Subscription_RootStripe_Payment_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Payment_Methods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Payment_Methods_Order_By>>;
  where?: InputMaybe<Stripe_Payment_Methods_Bool_Exp>;
};


export type Subscription_RootStripe_Payment_Methods_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Payment_Methods_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Payment_Methods_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Payment_Methods_Bool_Exp>;
};


export type Subscription_RootStripe_PricesArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Prices_Order_By>>;
  where?: InputMaybe<Stripe_Prices_Bool_Exp>;
};


export type Subscription_RootStripe_Prices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Prices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Prices_Order_By>>;
  where?: InputMaybe<Stripe_Prices_Bool_Exp>;
};


export type Subscription_RootStripe_Prices_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Prices_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Prices_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Prices_Bool_Exp>;
};


export type Subscription_RootStripe_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Products_Order_By>>;
  where?: InputMaybe<Stripe_Products_Bool_Exp>;
};


export type Subscription_RootStripe_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Products_Order_By>>;
  where?: InputMaybe<Stripe_Products_Bool_Exp>;
};


export type Subscription_RootStripe_Products_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Products_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Products_Bool_Exp>;
};


export type Subscription_RootStripe_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Subscriptions_Order_By>>;
  where?: InputMaybe<Stripe_Subscriptions_Bool_Exp>;
};


export type Subscription_RootStripe_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stripe_Subscriptions_Order_By>>;
  where?: InputMaybe<Stripe_Subscriptions_Bool_Exp>;
};


export type Subscription_RootStripe_Subscriptions_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootStripe_Subscriptions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stripe_Subscriptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Subscriptions_Bool_Exp>;
};


export type Subscription_RootTax_ReceiptsArgs = {
  distinct_on?: InputMaybe<Array<Tax_Receipts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tax_Receipts_Order_By>>;
  where?: InputMaybe<Tax_Receipts_Bool_Exp>;
};


export type Subscription_RootTax_Receipts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tax_Receipts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tax_Receipts_Order_By>>;
  where?: InputMaybe<Tax_Receipts_Bool_Exp>;
};


export type Subscription_RootTax_Receipts_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTax_Receipts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tax_Receipts_Stream_Cursor_Input>>;
  where?: InputMaybe<Tax_Receipts_Bool_Exp>;
};


export type Subscription_RootTeam_AvatarsArgs = {
  distinct_on?: InputMaybe<Array<Team_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Avatars_Order_By>>;
  where?: InputMaybe<Team_Avatars_Bool_Exp>;
};


export type Subscription_RootTeam_Avatars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Avatars_Order_By>>;
  where?: InputMaybe<Team_Avatars_Bool_Exp>;
};


export type Subscription_RootTeam_Avatars_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Team_Avatars_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Avatars_Bool_Exp>;
};


export type Subscription_RootTeam_MembershipsArgs = {
  distinct_on?: InputMaybe<Array<Team_Memberships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Memberships_Order_By>>;
  where?: InputMaybe<Team_Memberships_Bool_Exp>;
};


export type Subscription_RootTeam_Memberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Memberships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Memberships_Order_By>>;
  where?: InputMaybe<Team_Memberships_Bool_Exp>;
};


export type Subscription_RootTeam_Memberships_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTeam_Memberships_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Team_Memberships_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Memberships_Bool_Exp>;
};


export type Subscription_RootTeam_NeedsArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Order_By>>;
  where?: InputMaybe<Team_Needs_Bool_Exp>;
};


export type Subscription_RootTeam_Needs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Order_By>>;
  where?: InputMaybe<Team_Needs_Bool_Exp>;
};


export type Subscription_RootTeam_Needs_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTeam_Needs_StatsArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Stats_Order_By>>;
  where?: InputMaybe<Team_Needs_Stats_Bool_Exp>;
};


export type Subscription_RootTeam_Needs_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Stats_Order_By>>;
  where?: InputMaybe<Team_Needs_Stats_Bool_Exp>;
};


export type Subscription_RootTeam_Needs_Stats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Team_Needs_Stats_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Needs_Stats_Bool_Exp>;
};


export type Subscription_RootTeam_Needs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Team_Needs_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Needs_Bool_Exp>;
};


export type Subscription_RootTeam_StatsArgs = {
  distinct_on?: InputMaybe<Array<Team_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Stats_Order_By>>;
  where?: InputMaybe<Team_Stats_Bool_Exp>;
};


export type Subscription_RootTeam_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Stats_Order_By>>;
  where?: InputMaybe<Team_Stats_Bool_Exp>;
};


export type Subscription_RootTeam_Stats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Team_Stats_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Stats_Bool_Exp>;
};


export type Subscription_RootTeam_TagsArgs = {
  distinct_on?: InputMaybe<Array<Team_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Tags_Order_By>>;
  where?: InputMaybe<Team_Tags_Bool_Exp>;
};


export type Subscription_RootTeam_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Tags_Order_By>>;
  where?: InputMaybe<Team_Tags_Bool_Exp>;
};


export type Subscription_RootTeam_Tags_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTeam_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Team_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Tags_Bool_Exp>;
};


export type Subscription_RootTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootTeams_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootThank_YousArgs = {
  distinct_on?: InputMaybe<Array<Thank_Yous_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Thank_Yous_Order_By>>;
  where?: InputMaybe<Thank_Yous_Bool_Exp>;
};


export type Subscription_RootThank_Yous_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Thank_Yous_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Thank_Yous_Order_By>>;
  where?: InputMaybe<Thank_Yous_Bool_Exp>;
};


export type Subscription_RootThank_Yous_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootThank_Yous_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Thank_Yous_Stream_Cursor_Input>>;
  where?: InputMaybe<Thank_Yous_Bool_Exp>;
};


export type Subscription_RootUser_GoalsArgs = {
  distinct_on?: InputMaybe<Array<User_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Goals_Order_By>>;
  where?: InputMaybe<User_Goals_Bool_Exp>;
};


export type Subscription_RootUser_Goals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Goals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Goals_Order_By>>;
  where?: InputMaybe<User_Goals_Bool_Exp>;
};


export type Subscription_RootUser_Goals_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Goals_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Goals_Bool_Exp>;
};


export type Subscription_RootUser_Needs_Met_StatsArgs = {
  distinct_on?: InputMaybe<Array<User_Needs_Met_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Needs_Met_Stats_Order_By>>;
  where?: InputMaybe<User_Needs_Met_Stats_Bool_Exp>;
};


export type Subscription_RootUser_Needs_Met_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Needs_Met_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Needs_Met_Stats_Order_By>>;
  where?: InputMaybe<User_Needs_Met_Stats_Bool_Exp>;
};


export type Subscription_RootUser_Needs_Met_Stats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Needs_Met_Stats_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Needs_Met_Stats_Bool_Exp>;
};


export type Subscription_RootUser_StatsArgs = {
  distinct_on?: InputMaybe<Array<User_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Stats_Order_By>>;
  where?: InputMaybe<User_Stats_Bool_Exp>;
};


export type Subscription_RootUser_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Stats_Order_By>>;
  where?: InputMaybe<User_Stats_Bool_Exp>;
};


export type Subscription_RootUser_Stats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Stats_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Stats_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** @deprecated */
export type Tax_Receipts = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  need_data: Scalars['jsonb']['output'];
  tax_year: Scalars['String']['output'];
  total: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['Int']['output']>;
};


/** @deprecated */
export type Tax_ReceiptsNeed_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tax_receipts" */
export type Tax_Receipts_Aggregate = {
  aggregate?: Maybe<Tax_Receipts_Aggregate_Fields>;
  nodes: Array<Tax_Receipts>;
};

export type Tax_Receipts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tax_Receipts_Aggregate_Bool_Exp_Count>;
};

export type Tax_Receipts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tax_Receipts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tax_Receipts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tax_receipts" */
export type Tax_Receipts_Aggregate_Fields = {
  avg?: Maybe<Tax_Receipts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Tax_Receipts_Max_Fields>;
  min?: Maybe<Tax_Receipts_Min_Fields>;
  stddev?: Maybe<Tax_Receipts_Stddev_Fields>;
  stddev_pop?: Maybe<Tax_Receipts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tax_Receipts_Stddev_Samp_Fields>;
  sum?: Maybe<Tax_Receipts_Sum_Fields>;
  var_pop?: Maybe<Tax_Receipts_Var_Pop_Fields>;
  var_samp?: Maybe<Tax_Receipts_Var_Samp_Fields>;
  variance?: Maybe<Tax_Receipts_Variance_Fields>;
};


/** aggregate fields of "tax_receipts" */
export type Tax_Receipts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tax_Receipts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tax_receipts" */
export type Tax_Receipts_Aggregate_Order_By = {
  avg?: InputMaybe<Tax_Receipts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tax_Receipts_Max_Order_By>;
  min?: InputMaybe<Tax_Receipts_Min_Order_By>;
  stddev?: InputMaybe<Tax_Receipts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tax_Receipts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tax_Receipts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tax_Receipts_Sum_Order_By>;
  var_pop?: InputMaybe<Tax_Receipts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tax_Receipts_Var_Samp_Order_By>;
  variance?: InputMaybe<Tax_Receipts_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tax_Receipts_Append_Input = {
  need_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tax_receipts" */
export type Tax_Receipts_Arr_Rel_Insert_Input = {
  data: Array<Tax_Receipts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tax_Receipts_On_Conflict>;
};

/** aggregate avg on columns */
export type Tax_Receipts_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "tax_receipts" */
export type Tax_Receipts_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tax_receipts". All fields are combined with a logical 'AND'. */
export type Tax_Receipts_Bool_Exp = {
  _and?: InputMaybe<Array<Tax_Receipts_Bool_Exp>>;
  _not?: InputMaybe<Tax_Receipts_Bool_Exp>;
  _or?: InputMaybe<Array<Tax_Receipts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  need_data?: InputMaybe<Jsonb_Comparison_Exp>;
  tax_year?: InputMaybe<String_Comparison_Exp>;
  total?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "tax_receipts" */
export enum Tax_Receipts_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaxReceiptsPkey = 'tax_receipts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tax_Receipts_Delete_At_Path_Input = {
  need_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tax_Receipts_Delete_Elem_Input = {
  need_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tax_Receipts_Delete_Key_Input = {
  need_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "tax_receipts" */
export type Tax_Receipts_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tax_receipts" */
export type Tax_Receipts_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need_data?: InputMaybe<Scalars['jsonb']['input']>;
  tax_year?: InputMaybe<Scalars['String']['input']>;
  total?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Tax_Receipts_Max_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  tax_year?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "tax_receipts" */
export type Tax_Receipts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  tax_year?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tax_Receipts_Min_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  tax_year?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "tax_receipts" */
export type Tax_Receipts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  tax_year?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tax_receipts" */
export type Tax_Receipts_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tax_Receipts>;
};

/** on_conflict condition type for table "tax_receipts" */
export type Tax_Receipts_On_Conflict = {
  constraint: Tax_Receipts_Constraint;
  update_columns?: Array<Tax_Receipts_Update_Column>;
  where?: InputMaybe<Tax_Receipts_Bool_Exp>;
};

/** Ordering options when selecting data from "tax_receipts". */
export type Tax_Receipts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_data?: InputMaybe<Order_By>;
  tax_year?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tax_receipts */
export type Tax_Receipts_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tax_Receipts_Prepend_Input = {
  need_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tax_receipts" */
export enum Tax_Receipts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  NeedData = 'need_data',
  /** column name */
  TaxYear = 'tax_year',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "tax_receipts" */
export type Tax_Receipts_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need_data?: InputMaybe<Scalars['jsonb']['input']>;
  tax_year?: InputMaybe<Scalars['String']['input']>;
  total?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Tax_Receipts_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "tax_receipts" */
export type Tax_Receipts_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tax_Receipts_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "tax_receipts" */
export type Tax_Receipts_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tax_Receipts_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "tax_receipts" */
export type Tax_Receipts_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tax_receipts" */
export type Tax_Receipts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tax_Receipts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tax_Receipts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  need_data?: InputMaybe<Scalars['jsonb']['input']>;
  tax_year?: InputMaybe<Scalars['String']['input']>;
  total?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Tax_Receipts_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "tax_receipts" */
export type Tax_Receipts_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "tax_receipts" */
export enum Tax_Receipts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NeedData = 'need_data',
  /** column name */
  TaxYear = 'tax_year',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Tax_Receipts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tax_Receipts_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tax_Receipts_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tax_Receipts_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tax_Receipts_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tax_Receipts_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tax_Receipts_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tax_Receipts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tax_Receipts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tax_Receipts_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "tax_receipts" */
export type Tax_Receipts_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tax_Receipts_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "tax_receipts" */
export type Tax_Receipts_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tax_Receipts_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "tax_receipts" */
export type Tax_Receipts_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "team_avatars" */
export type Team_Avatars = {
  membership_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  user_avatar_path?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "team_avatars" */
export type Team_Avatars_Aggregate = {
  aggregate?: Maybe<Team_Avatars_Aggregate_Fields>;
  nodes: Array<Team_Avatars>;
};

export type Team_Avatars_Aggregate_Bool_Exp = {
  count?: InputMaybe<Team_Avatars_Aggregate_Bool_Exp_Count>;
};

export type Team_Avatars_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Team_Avatars_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Team_Avatars_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "team_avatars" */
export type Team_Avatars_Aggregate_Fields = {
  avg?: Maybe<Team_Avatars_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Team_Avatars_Max_Fields>;
  min?: Maybe<Team_Avatars_Min_Fields>;
  stddev?: Maybe<Team_Avatars_Stddev_Fields>;
  stddev_pop?: Maybe<Team_Avatars_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Team_Avatars_Stddev_Samp_Fields>;
  sum?: Maybe<Team_Avatars_Sum_Fields>;
  var_pop?: Maybe<Team_Avatars_Var_Pop_Fields>;
  var_samp?: Maybe<Team_Avatars_Var_Samp_Fields>;
  variance?: Maybe<Team_Avatars_Variance_Fields>;
};


/** aggregate fields of "team_avatars" */
export type Team_Avatars_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Avatars_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "team_avatars" */
export type Team_Avatars_Aggregate_Order_By = {
  avg?: InputMaybe<Team_Avatars_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Team_Avatars_Max_Order_By>;
  min?: InputMaybe<Team_Avatars_Min_Order_By>;
  stddev?: InputMaybe<Team_Avatars_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Team_Avatars_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Team_Avatars_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Team_Avatars_Sum_Order_By>;
  var_pop?: InputMaybe<Team_Avatars_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Team_Avatars_Var_Samp_Order_By>;
  variance?: InputMaybe<Team_Avatars_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "team_avatars" */
export type Team_Avatars_Arr_Rel_Insert_Input = {
  data: Array<Team_Avatars_Insert_Input>;
};

/** aggregate avg on columns */
export type Team_Avatars_Avg_Fields = {
  membership_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "team_avatars" */
export type Team_Avatars_Avg_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "team_avatars". All fields are combined with a logical 'AND'. */
export type Team_Avatars_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Avatars_Bool_Exp>>;
  _not?: InputMaybe<Team_Avatars_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Avatars_Bool_Exp>>;
  membership_id?: InputMaybe<Int_Comparison_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  user_avatar_path?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "team_avatars" */
export type Team_Avatars_Insert_Input = {
  membership_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  user_avatar_path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Team_Avatars_Max_Fields = {
  membership_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  user_avatar_path?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "team_avatars" */
export type Team_Avatars_Max_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_avatar_path?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Avatars_Min_Fields = {
  membership_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  user_avatar_path?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "team_avatars" */
export type Team_Avatars_Min_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_avatar_path?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "team_avatars". */
export type Team_Avatars_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_avatar_path?: InputMaybe<Order_By>;
};

/** select columns of table "team_avatars" */
export enum Team_Avatars_Select_Column {
  /** column name */
  MembershipId = 'membership_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserAvatarPath = 'user_avatar_path'
}

/** aggregate stddev on columns */
export type Team_Avatars_Stddev_Fields = {
  membership_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "team_avatars" */
export type Team_Avatars_Stddev_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Team_Avatars_Stddev_Pop_Fields = {
  membership_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "team_avatars" */
export type Team_Avatars_Stddev_Pop_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Team_Avatars_Stddev_Samp_Fields = {
  membership_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "team_avatars" */
export type Team_Avatars_Stddev_Samp_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "team_avatars" */
export type Team_Avatars_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Avatars_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Avatars_Stream_Cursor_Value_Input = {
  membership_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  user_avatar_path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Team_Avatars_Sum_Fields = {
  membership_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "team_avatars" */
export type Team_Avatars_Sum_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Team_Avatars_Var_Pop_Fields = {
  membership_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "team_avatars" */
export type Team_Avatars_Var_Pop_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Team_Avatars_Var_Samp_Fields = {
  membership_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "team_avatars" */
export type Team_Avatars_Var_Samp_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Team_Avatars_Variance_Fields = {
  membership_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "team_avatars" */
export type Team_Avatars_Variance_Order_By = {
  membership_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "memberships" */
export type Team_Memberships = {
  created_at: Scalars['timestamp']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  team?: Maybe<Teams>;
  team_id: Scalars['Int']['output'];
  updated_at: Scalars['timestamp']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "memberships" */
export type Team_Memberships_Aggregate = {
  aggregate?: Maybe<Team_Memberships_Aggregate_Fields>;
  nodes: Array<Team_Memberships>;
};

export type Team_Memberships_Aggregate_Bool_Exp = {
  count?: InputMaybe<Team_Memberships_Aggregate_Bool_Exp_Count>;
};

export type Team_Memberships_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Team_Memberships_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Team_Memberships_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "memberships" */
export type Team_Memberships_Aggregate_Fields = {
  avg?: Maybe<Team_Memberships_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Team_Memberships_Max_Fields>;
  min?: Maybe<Team_Memberships_Min_Fields>;
  stddev?: Maybe<Team_Memberships_Stddev_Fields>;
  stddev_pop?: Maybe<Team_Memberships_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Team_Memberships_Stddev_Samp_Fields>;
  sum?: Maybe<Team_Memberships_Sum_Fields>;
  var_pop?: Maybe<Team_Memberships_Var_Pop_Fields>;
  var_samp?: Maybe<Team_Memberships_Var_Samp_Fields>;
  variance?: Maybe<Team_Memberships_Variance_Fields>;
};


/** aggregate fields of "memberships" */
export type Team_Memberships_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Memberships_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "memberships" */
export type Team_Memberships_Aggregate_Order_By = {
  avg?: InputMaybe<Team_Memberships_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Team_Memberships_Max_Order_By>;
  min?: InputMaybe<Team_Memberships_Min_Order_By>;
  stddev?: InputMaybe<Team_Memberships_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Team_Memberships_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Team_Memberships_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Team_Memberships_Sum_Order_By>;
  var_pop?: InputMaybe<Team_Memberships_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Team_Memberships_Var_Samp_Order_By>;
  variance?: InputMaybe<Team_Memberships_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "memberships" */
export type Team_Memberships_Arr_Rel_Insert_Input = {
  data: Array<Team_Memberships_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Team_Memberships_On_Conflict>;
};

/** aggregate avg on columns */
export type Team_Memberships_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "memberships" */
export type Team_Memberships_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "memberships". All fields are combined with a logical 'AND'. */
export type Team_Memberships_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Memberships_Bool_Exp>>;
  _not?: InputMaybe<Team_Memberships_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Memberships_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "memberships" */
export enum Team_Memberships_Constraint {
  /** unique or primary key constraint on columns "user_id", "team_id" */
  IndexMembershipsOnUserIdAndTeamId = 'index_memberships_on_user_id_and_team_id',
  /** unique or primary key constraint on columns "id" */
  MembershipsPkey = 'memberships_pkey'
}

/** input type for incrementing numeric columns in table "memberships" */
export type Team_Memberships_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "memberships" */
export type Team_Memberships_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Team_Memberships_Max_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "memberships" */
export type Team_Memberships_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Memberships_Min_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "memberships" */
export type Team_Memberships_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "memberships" */
export type Team_Memberships_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_Memberships>;
};

/** on_conflict condition type for table "memberships" */
export type Team_Memberships_On_Conflict = {
  constraint: Team_Memberships_Constraint;
  update_columns?: Array<Team_Memberships_Update_Column>;
  where?: InputMaybe<Team_Memberships_Bool_Exp>;
};

/** Ordering options when selecting data from "memberships". */
export type Team_Memberships_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: memberships */
export type Team_Memberships_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "memberships" */
export enum Team_Memberships_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "memberships" */
export type Team_Memberships_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Team_Memberships_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "memberships" */
export type Team_Memberships_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Team_Memberships_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "memberships" */
export type Team_Memberships_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Team_Memberships_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "memberships" */
export type Team_Memberships_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "team_memberships" */
export type Team_Memberships_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Memberships_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Memberships_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Team_Memberships_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "memberships" */
export type Team_Memberships_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "memberships" */
export enum Team_Memberships_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Team_Memberships_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Team_Memberships_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_Memberships_Set_Input>;
  /** filter the rows which have to be updated */
  where: Team_Memberships_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Team_Memberships_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "memberships" */
export type Team_Memberships_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Team_Memberships_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "memberships" */
export type Team_Memberships_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Team_Memberships_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "memberships" */
export type Team_Memberships_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "team_needs" */
export type Team_Needs = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['Int']['output'];
  need_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  needs?: Maybe<Needs>;
  /** An object relationship */
  team?: Maybe<Teams>;
  team_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "team_needs" */
export type Team_Needs_Aggregate = {
  aggregate?: Maybe<Team_Needs_Aggregate_Fields>;
  nodes: Array<Team_Needs>;
};

export type Team_Needs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Team_Needs_Aggregate_Bool_Exp_Count>;
};

export type Team_Needs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Team_Needs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Team_Needs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "team_needs" */
export type Team_Needs_Aggregate_Fields = {
  avg?: Maybe<Team_Needs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Team_Needs_Max_Fields>;
  min?: Maybe<Team_Needs_Min_Fields>;
  stddev?: Maybe<Team_Needs_Stddev_Fields>;
  stddev_pop?: Maybe<Team_Needs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Team_Needs_Stddev_Samp_Fields>;
  sum?: Maybe<Team_Needs_Sum_Fields>;
  var_pop?: Maybe<Team_Needs_Var_Pop_Fields>;
  var_samp?: Maybe<Team_Needs_Var_Samp_Fields>;
  variance?: Maybe<Team_Needs_Variance_Fields>;
};


/** aggregate fields of "team_needs" */
export type Team_Needs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Needs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "team_needs" */
export type Team_Needs_Aggregate_Order_By = {
  avg?: InputMaybe<Team_Needs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Team_Needs_Max_Order_By>;
  min?: InputMaybe<Team_Needs_Min_Order_By>;
  stddev?: InputMaybe<Team_Needs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Team_Needs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Team_Needs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Team_Needs_Sum_Order_By>;
  var_pop?: InputMaybe<Team_Needs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Team_Needs_Var_Samp_Order_By>;
  variance?: InputMaybe<Team_Needs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "team_needs" */
export type Team_Needs_Arr_Rel_Insert_Input = {
  data: Array<Team_Needs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Team_Needs_On_Conflict>;
};

/** aggregate avg on columns */
export type Team_Needs_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "team_needs" */
export type Team_Needs_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "team_needs". All fields are combined with a logical 'AND'. */
export type Team_Needs_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Needs_Bool_Exp>>;
  _not?: InputMaybe<Team_Needs_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Needs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  need_id?: InputMaybe<Int_Comparison_Exp>;
  needs?: InputMaybe<Needs_Bool_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_needs" */
export enum Team_Needs_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamNeedsPkey = 'team_needs_pkey'
}

/** input type for incrementing numeric columns in table "team_needs" */
export type Team_Needs_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "team_needs" */
export type Team_Needs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  needs?: InputMaybe<Needs_Obj_Rel_Insert_Input>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Team_Needs_Max_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "team_needs" */
export type Team_Needs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Needs_Min_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "team_needs" */
export type Team_Needs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "team_needs" */
export type Team_Needs_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_Needs>;
};

/** on_conflict condition type for table "team_needs" */
export type Team_Needs_On_Conflict = {
  constraint: Team_Needs_Constraint;
  update_columns?: Array<Team_Needs_Update_Column>;
  where?: InputMaybe<Team_Needs_Bool_Exp>;
};

/** Ordering options when selecting data from "team_needs". */
export type Team_Needs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  needs?: InputMaybe<Needs_Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_needs */
export type Team_Needs_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "team_needs" */
export enum Team_Needs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "team_needs" */
export type Team_Needs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** columns and relationships of "team_needs_stats" */
export type Team_Needs_Stats = {
  count?: Maybe<Scalars['bigint']['output']>;
  sum?: Maybe<Scalars['numeric']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "team_needs_stats" */
export type Team_Needs_Stats_Aggregate = {
  aggregate?: Maybe<Team_Needs_Stats_Aggregate_Fields>;
  nodes: Array<Team_Needs_Stats>;
};

/** aggregate fields of "team_needs_stats" */
export type Team_Needs_Stats_Aggregate_Fields = {
  avg?: Maybe<Team_Needs_Stats_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Team_Needs_Stats_Max_Fields>;
  min?: Maybe<Team_Needs_Stats_Min_Fields>;
  stddev?: Maybe<Team_Needs_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<Team_Needs_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Team_Needs_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<Team_Needs_Stats_Sum_Fields>;
  var_pop?: Maybe<Team_Needs_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<Team_Needs_Stats_Var_Samp_Fields>;
  variance?: Maybe<Team_Needs_Stats_Variance_Fields>;
};


/** aggregate fields of "team_needs_stats" */
export type Team_Needs_Stats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Needs_Stats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Team_Needs_Stats_Avg_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  sum?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "team_needs_stats". All fields are combined with a logical 'AND'. */
export type Team_Needs_Stats_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Needs_Stats_Bool_Exp>>;
  _not?: InputMaybe<Team_Needs_Stats_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Needs_Stats_Bool_Exp>>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  sum?: InputMaybe<Numeric_Comparison_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "team_needs_stats" */
export type Team_Needs_Stats_Insert_Input = {
  count?: InputMaybe<Scalars['bigint']['input']>;
  sum?: InputMaybe<Scalars['numeric']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Team_Needs_Stats_Max_Fields = {
  count?: Maybe<Scalars['bigint']['output']>;
  sum?: Maybe<Scalars['numeric']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Team_Needs_Stats_Min_Fields = {
  count?: Maybe<Scalars['bigint']['output']>;
  sum?: Maybe<Scalars['numeric']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "team_needs_stats" */
export type Team_Needs_Stats_Obj_Rel_Insert_Input = {
  data: Team_Needs_Stats_Insert_Input;
};

/** Ordering options when selecting data from "team_needs_stats". */
export type Team_Needs_Stats_Order_By = {
  count?: InputMaybe<Order_By>;
  sum?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** select columns of table "team_needs_stats" */
export enum Team_Needs_Stats_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Sum = 'sum',
  /** column name */
  TeamId = 'team_id'
}

/** aggregate stddev on columns */
export type Team_Needs_Stats_Stddev_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  sum?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Team_Needs_Stats_Stddev_Pop_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  sum?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Team_Needs_Stats_Stddev_Samp_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  sum?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "team_needs_stats" */
export type Team_Needs_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Needs_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Needs_Stats_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['bigint']['input']>;
  sum?: InputMaybe<Scalars['numeric']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Team_Needs_Stats_Sum_Fields = {
  count?: Maybe<Scalars['bigint']['output']>;
  sum?: Maybe<Scalars['numeric']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Team_Needs_Stats_Var_Pop_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  sum?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Team_Needs_Stats_Var_Samp_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  sum?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Team_Needs_Stats_Variance_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  sum?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev on columns */
export type Team_Needs_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "team_needs" */
export type Team_Needs_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Team_Needs_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "team_needs" */
export type Team_Needs_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Team_Needs_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "team_needs" */
export type Team_Needs_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "team_needs" */
export type Team_Needs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Needs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Needs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Team_Needs_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "team_needs" */
export type Team_Needs_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** update columns of table "team_needs" */
export enum Team_Needs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Team_Needs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Team_Needs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_Needs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Team_Needs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Team_Needs_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "team_needs" */
export type Team_Needs_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Team_Needs_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "team_needs" */
export type Team_Needs_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Team_Needs_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "team_needs" */
export type Team_Needs_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "team_stats" */
export type Team_Stats = {
  members_count?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "team_stats" */
export type Team_Stats_Aggregate = {
  aggregate?: Maybe<Team_Stats_Aggregate_Fields>;
  nodes: Array<Team_Stats>;
};

/** aggregate fields of "team_stats" */
export type Team_Stats_Aggregate_Fields = {
  avg?: Maybe<Team_Stats_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Team_Stats_Max_Fields>;
  min?: Maybe<Team_Stats_Min_Fields>;
  stddev?: Maybe<Team_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<Team_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Team_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<Team_Stats_Sum_Fields>;
  var_pop?: Maybe<Team_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<Team_Stats_Var_Samp_Fields>;
  variance?: Maybe<Team_Stats_Variance_Fields>;
};


/** aggregate fields of "team_stats" */
export type Team_Stats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Stats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Team_Stats_Avg_Fields = {
  members_count?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "team_stats". All fields are combined with a logical 'AND'. */
export type Team_Stats_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Stats_Bool_Exp>>;
  _not?: InputMaybe<Team_Stats_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Stats_Bool_Exp>>;
  members_count?: InputMaybe<Int_Comparison_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "team_stats" */
export type Team_Stats_Insert_Input = {
  members_count?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Team_Stats_Max_Fields = {
  members_count?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Team_Stats_Min_Fields = {
  members_count?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "team_stats" */
export type Team_Stats_Obj_Rel_Insert_Input = {
  data: Team_Stats_Insert_Input;
};

/** Ordering options when selecting data from "team_stats". */
export type Team_Stats_Order_By = {
  members_count?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** select columns of table "team_stats" */
export enum Team_Stats_Select_Column {
  /** column name */
  MembersCount = 'members_count',
  /** column name */
  TeamId = 'team_id'
}

/** aggregate stddev on columns */
export type Team_Stats_Stddev_Fields = {
  members_count?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Team_Stats_Stddev_Pop_Fields = {
  members_count?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Team_Stats_Stddev_Samp_Fields = {
  members_count?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "team_stats" */
export type Team_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Stats_Stream_Cursor_Value_Input = {
  members_count?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Team_Stats_Sum_Fields = {
  members_count?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Team_Stats_Var_Pop_Fields = {
  members_count?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Team_Stats_Var_Samp_Fields = {
  members_count?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Team_Stats_Variance_Fields = {
  members_count?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "team_tags" */
export type Team_Tags = {
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['Int']['output'];
};

/** aggregated selection of "team_tags" */
export type Team_Tags_Aggregate = {
  aggregate?: Maybe<Team_Tags_Aggregate_Fields>;
  nodes: Array<Team_Tags>;
};

/** aggregate fields of "team_tags" */
export type Team_Tags_Aggregate_Fields = {
  avg?: Maybe<Team_Tags_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Team_Tags_Max_Fields>;
  min?: Maybe<Team_Tags_Min_Fields>;
  stddev?: Maybe<Team_Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Team_Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Team_Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Team_Tags_Sum_Fields>;
  var_pop?: Maybe<Team_Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Team_Tags_Var_Samp_Fields>;
  variance?: Maybe<Team_Tags_Variance_Fields>;
};


/** aggregate fields of "team_tags" */
export type Team_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Team_Tags_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "team_tags". All fields are combined with a logical 'AND'. */
export type Team_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Tags_Bool_Exp>>;
  _not?: InputMaybe<Team_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Tags_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_tags" */
export enum Team_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamTagsPkey = 'team_tags_pkey'
}

/** input type for incrementing numeric columns in table "team_tags" */
export type Team_Tags_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "team_tags" */
export type Team_Tags_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Team_Tags_Max_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Team_Tags_Min_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "team_tags" */
export type Team_Tags_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_Tags>;
};

/** on_conflict condition type for table "team_tags" */
export type Team_Tags_On_Conflict = {
  constraint: Team_Tags_Constraint;
  update_columns?: Array<Team_Tags_Update_Column>;
  where?: InputMaybe<Team_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "team_tags". */
export type Team_Tags_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_tags */
export type Team_Tags_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "team_tags" */
export enum Team_Tags_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  Tag = 'tag',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "team_tags" */
export type Team_Tags_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Team_Tags_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Team_Tags_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Team_Tags_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "team_tags" */
export type Team_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Tags_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Team_Tags_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "team_tags" */
export enum Team_Tags_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Tag = 'tag',
  /** column name */
  TeamId = 'team_id'
}

export type Team_Tags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Team_Tags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Team_Tags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Team_Tags_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Team_Tags_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Team_Tags_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "teams" */
export type Teams = {
  alias: Scalars['String']['output'];
  /** An array relationship */
  avatars: Array<Team_Avatars>;
  /** An aggregate relationship */
  avatars_aggregate: Team_Avatars_Aggregate;
  campaigns: Array<Campaign>;
  data: Scalars['jsonb']['output'];
  id: Scalars['Int']['output'];
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  is_corporate: Scalars['Boolean']['output'];
  /** A computed field, executes function "user_is_team_member" */
  is_member?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  list?: Maybe<Lists>;
  list_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  memberships: Array<Team_Memberships>;
  /** An aggregate relationship */
  memberships_aggregate: Team_Memberships_Aggregate;
  name: Scalars['String']['output'];
  /** An array relationship */
  needs_met: Array<Team_Needs>;
  /** An aggregate relationship */
  needs_met_aggregate: Team_Needs_Aggregate;
  /** An object relationship */
  needs_stats?: Maybe<Team_Needs_Stats>;
  private?: Maybe<Scalars['Boolean']['output']>;
  profile_picture_content_type?: Maybe<Scalars['String']['output']>;
  profile_picture_file_name?: Maybe<Scalars['String']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "team_profile_picture_path" */
  profile_picture_path?: Maybe<Scalars['String']['output']>;
  profile_picture_updated_at?: Maybe<Scalars['timestamp']['output']>;
  remote_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  stats?: Maybe<Team_Stats>;
  subheading?: Maybe<Scalars['String']['output']>;
  team_codes?: Maybe<Scalars['String']['output']>;
  updates: Array<Update>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias: Scalars['String']['output'];
};


/** columns and relationships of "teams" */
export type TeamsAvatarsArgs = {
  distinct_on?: InputMaybe<Array<Team_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Avatars_Order_By>>;
  where?: InputMaybe<Team_Avatars_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsAvatars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Avatars_Order_By>>;
  where?: InputMaybe<Team_Avatars_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsCampaignsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CampaignSorting>>;
  where?: InputMaybe<CampaignFilter_Remote_Rel_Teamscampaigns>;
};


/** columns and relationships of "teams" */
export type TeamsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "teams" */
export type TeamsMembershipsArgs = {
  distinct_on?: InputMaybe<Array<Team_Memberships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Memberships_Order_By>>;
  where?: InputMaybe<Team_Memberships_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Memberships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Memberships_Order_By>>;
  where?: InputMaybe<Team_Memberships_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsNeeds_MetArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Order_By>>;
  where?: InputMaybe<Team_Needs_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsNeeds_Met_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Needs_Order_By>>;
  where?: InputMaybe<Team_Needs_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsUpdatesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<UpdateSorting>>;
  where?: InputMaybe<UpdateFilter_Remote_Rel_Teamsupdates>;
};

/** aggregated selection of "teams" */
export type Teams_Aggregate = {
  aggregate?: Maybe<Teams_Aggregate_Fields>;
  nodes: Array<Teams>;
};

export type Teams_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Teams_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Teams_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Teams_Aggregate_Bool_Exp_Count>;
};

export type Teams_Aggregate_Bool_Exp_Bool_And = {
  arguments: Teams_Select_Column_Teams_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Teams_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Teams_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Teams_Select_Column_Teams_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Teams_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Teams_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Teams_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "teams" */
export type Teams_Aggregate_Fields = {
  avg?: Maybe<Teams_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Teams_Max_Fields>;
  min?: Maybe<Teams_Min_Fields>;
  stddev?: Maybe<Teams_Stddev_Fields>;
  stddev_pop?: Maybe<Teams_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teams_Stddev_Samp_Fields>;
  sum?: Maybe<Teams_Sum_Fields>;
  var_pop?: Maybe<Teams_Var_Pop_Fields>;
  var_samp?: Maybe<Teams_Var_Samp_Fields>;
  variance?: Maybe<Teams_Variance_Fields>;
};


/** aggregate fields of "teams" */
export type Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "teams" */
export type Teams_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Max_Order_By>;
  min?: InputMaybe<Teams_Min_Order_By>;
  stddev?: InputMaybe<Teams_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Teams_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "teams" */
export type Teams_Arr_Rel_Insert_Input = {
  data: Array<Teams_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};

/** aggregate avg on columns */
export type Teams_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "teams" */
export type Teams_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams". All fields are combined with a logical 'AND'. */
export type Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Bool_Exp>>;
  _not?: InputMaybe<Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Bool_Exp>>;
  alias?: InputMaybe<String_Comparison_Exp>;
  avatars?: InputMaybe<Team_Avatars_Bool_Exp>;
  avatars_aggregate?: InputMaybe<Team_Avatars_Aggregate_Bool_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Numeric_Comparison_Exp>;
  is_corporate?: InputMaybe<Boolean_Comparison_Exp>;
  is_member?: InputMaybe<Boolean_Comparison_Exp>;
  list?: InputMaybe<Lists_Bool_Exp>;
  list_id?: InputMaybe<Int_Comparison_Exp>;
  memberships?: InputMaybe<Team_Memberships_Bool_Exp>;
  memberships_aggregate?: InputMaybe<Team_Memberships_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  needs_met?: InputMaybe<Team_Needs_Bool_Exp>;
  needs_met_aggregate?: InputMaybe<Team_Needs_Aggregate_Bool_Exp>;
  needs_stats?: InputMaybe<Team_Needs_Stats_Bool_Exp>;
  private?: InputMaybe<Boolean_Comparison_Exp>;
  profile_picture_content_type?: InputMaybe<String_Comparison_Exp>;
  profile_picture_file_name?: InputMaybe<String_Comparison_Exp>;
  profile_picture_file_size?: InputMaybe<Int_Comparison_Exp>;
  profile_picture_path?: InputMaybe<String_Comparison_Exp>;
  profile_picture_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  remote_id?: InputMaybe<String_Comparison_Exp>;
  stats?: InputMaybe<Team_Stats_Bool_Exp>;
  subheading?: InputMaybe<String_Comparison_Exp>;
  team_codes?: InputMaybe<String_Comparison_Exp>;
  v_alias?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams" */
export enum Teams_Constraint {
  /** unique or primary key constraint on columns "v_alias" */
  AliasBkTeams = 'alias_bk_teams',
  /** unique or primary key constraint on columns "alias" */
  IdxTeamsAlias = 'idx_teams_alias',
  /** unique or primary key constraint on columns "v_alias" */
  IdxTeamsVAlias = 'idx_teams_v_alias',
  /** unique or primary key constraint on columns "alias" */
  TeamsAliasKey = 'teams_alias_key',
  /** unique or primary key constraint on columns "id" */
  TeamsPkey = 'teams_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Teams_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Teams_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Teams_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "teams" */
export type Teams_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  profile_picture_file_size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "teams" */
export type Teams_Insert_Input = {
  alias?: InputMaybe<Scalars['String']['input']>;
  avatars?: InputMaybe<Team_Avatars_Arr_Rel_Insert_Input>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_corporate?: InputMaybe<Scalars['Boolean']['input']>;
  list?: InputMaybe<Lists_Obj_Rel_Insert_Input>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  memberships?: InputMaybe<Team_Memberships_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  needs_met?: InputMaybe<Team_Needs_Arr_Rel_Insert_Input>;
  needs_stats?: InputMaybe<Team_Needs_Stats_Obj_Rel_Insert_Input>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  profile_picture_content_type?: InputMaybe<Scalars['String']['input']>;
  profile_picture_file_name?: InputMaybe<Scalars['String']['input']>;
  profile_picture_file_size?: InputMaybe<Scalars['Int']['input']>;
  profile_picture_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  remote_id?: InputMaybe<Scalars['String']['input']>;
  stats?: InputMaybe<Team_Stats_Obj_Rel_Insert_Input>;
  subheading?: InputMaybe<Scalars['String']['input']>;
  team_codes?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Teams_Max_Fields = {
  alias?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profile_picture_content_type?: Maybe<Scalars['String']['output']>;
  profile_picture_file_name?: Maybe<Scalars['String']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "team_profile_picture_path" */
  profile_picture_path?: Maybe<Scalars['String']['output']>;
  profile_picture_updated_at?: Maybe<Scalars['timestamp']['output']>;
  remote_id?: Maybe<Scalars['String']['output']>;
  subheading?: Maybe<Scalars['String']['output']>;
  team_codes?: Maybe<Scalars['String']['output']>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "teams" */
export type Teams_Max_Order_By = {
  alias?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profile_picture_content_type?: InputMaybe<Order_By>;
  profile_picture_file_name?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
  profile_picture_updated_at?: InputMaybe<Order_By>;
  remote_id?: InputMaybe<Order_By>;
  subheading?: InputMaybe<Order_By>;
  team_codes?: InputMaybe<Order_By>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Min_Fields = {
  alias?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  profile_picture_content_type?: Maybe<Scalars['String']['output']>;
  profile_picture_file_name?: Maybe<Scalars['String']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "team_profile_picture_path" */
  profile_picture_path?: Maybe<Scalars['String']['output']>;
  profile_picture_updated_at?: Maybe<Scalars['timestamp']['output']>;
  remote_id?: Maybe<Scalars['String']['output']>;
  subheading?: Maybe<Scalars['String']['output']>;
  team_codes?: Maybe<Scalars['String']['output']>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "teams" */
export type Teams_Min_Order_By = {
  alias?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profile_picture_content_type?: InputMaybe<Order_By>;
  profile_picture_file_name?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
  profile_picture_updated_at?: InputMaybe<Order_By>;
  remote_id?: InputMaybe<Order_By>;
  subheading?: InputMaybe<Order_By>;
  team_codes?: InputMaybe<Order_By>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams" */
export type Teams_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams>;
};

/** input type for inserting object relation for remote table "teams" */
export type Teams_Obj_Rel_Insert_Input = {
  data: Teams_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};

/** on_conflict condition type for table "teams" */
export type Teams_On_Conflict = {
  constraint: Teams_Constraint;
  update_columns?: Array<Teams_Update_Column>;
  where?: InputMaybe<Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "teams". */
export type Teams_Order_By = {
  alias?: InputMaybe<Order_By>;
  avatars_aggregate?: InputMaybe<Team_Avatars_Aggregate_Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  is_corporate?: InputMaybe<Order_By>;
  is_member?: InputMaybe<Order_By>;
  list?: InputMaybe<Lists_Order_By>;
  list_id?: InputMaybe<Order_By>;
  memberships_aggregate?: InputMaybe<Team_Memberships_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  needs_met_aggregate?: InputMaybe<Team_Needs_Aggregate_Order_By>;
  needs_stats?: InputMaybe<Team_Needs_Stats_Order_By>;
  private?: InputMaybe<Order_By>;
  profile_picture_content_type?: InputMaybe<Order_By>;
  profile_picture_file_name?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
  profile_picture_path?: InputMaybe<Order_By>;
  profile_picture_updated_at?: InputMaybe<Order_By>;
  remote_id?: InputMaybe<Order_By>;
  stats?: InputMaybe<Team_Stats_Order_By>;
  subheading?: InputMaybe<Order_By>;
  team_codes?: InputMaybe<Order_By>;
  v_alias?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams */
export type Teams_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Teams_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "teams" */
export enum Teams_Select_Column {
  /** column name */
  Alias = 'alias',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  IsCorporate = 'is_corporate',
  /** column name */
  ListId = 'list_id',
  /** column name */
  Name = 'name',
  /** column name */
  Private = 'private',
  /** column name */
  ProfilePictureContentType = 'profile_picture_content_type',
  /** column name */
  ProfilePictureFileName = 'profile_picture_file_name',
  /** column name */
  ProfilePictureFileSize = 'profile_picture_file_size',
  /** column name */
  ProfilePictureUpdatedAt = 'profile_picture_updated_at',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  Subheading = 'subheading',
  /** column name */
  TeamCodes = 'team_codes',
  /** column name */
  VAlias = 'v_alias'
}

/** select "teams_aggregate_bool_exp_bool_and_arguments_columns" columns of table "teams" */
export enum Teams_Select_Column_Teams_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCorporate = 'is_corporate',
  /** column name */
  Private = 'private'
}

/** select "teams_aggregate_bool_exp_bool_or_arguments_columns" columns of table "teams" */
export enum Teams_Select_Column_Teams_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCorporate = 'is_corporate',
  /** column name */
  Private = 'private'
}

/** input type for updating data in table "teams" */
export type Teams_Set_Input = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_corporate?: InputMaybe<Scalars['Boolean']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  profile_picture_content_type?: InputMaybe<Scalars['String']['input']>;
  profile_picture_file_name?: InputMaybe<Scalars['String']['input']>;
  profile_picture_file_size?: InputMaybe<Scalars['Int']['input']>;
  profile_picture_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  remote_id?: InputMaybe<Scalars['String']['input']>;
  subheading?: InputMaybe<Scalars['String']['input']>;
  team_codes?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Teams_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "teams" */
export type Teams_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Teams_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "teams" */
export type Teams_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Teams_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "teams" */
export type Teams_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams" */
export type Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Stream_Cursor_Value_Input = {
  alias?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  is_corporate?: InputMaybe<Scalars['Boolean']['input']>;
  list_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  profile_picture_content_type?: InputMaybe<Scalars['String']['input']>;
  profile_picture_file_name?: InputMaybe<Scalars['String']['input']>;
  profile_picture_file_size?: InputMaybe<Scalars['Int']['input']>;
  profile_picture_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  remote_id?: InputMaybe<Scalars['String']['input']>;
  subheading?: InputMaybe<Scalars['String']['input']>;
  team_codes?: InputMaybe<Scalars['String']['input']>;
  /** Generated. Trimmed and lowercase `alias`. */
  v_alias?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Teams_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  list_id?: Maybe<Scalars['Int']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "teams" */
export type Teams_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
};

/** update columns of table "teams" */
export enum Teams_Update_Column {
  /** column name */
  Alias = 'alias',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsCorporate = 'is_corporate',
  /** column name */
  ListId = 'list_id',
  /** column name */
  Name = 'name',
  /** column name */
  Private = 'private',
  /** column name */
  ProfilePictureContentType = 'profile_picture_content_type',
  /** column name */
  ProfilePictureFileName = 'profile_picture_file_name',
  /** column name */
  ProfilePictureFileSize = 'profile_picture_file_size',
  /** column name */
  ProfilePictureUpdatedAt = 'profile_picture_updated_at',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  Subheading = 'subheading',
  /** column name */
  TeamCodes = 'team_codes'
}

export type Teams_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Teams_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Teams_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Teams_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Teams_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Teams_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Teams_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Teams_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "teams" */
export type Teams_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Teams_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "teams" */
export type Teams_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Teams_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  list_id?: Maybe<Scalars['Float']['output']>;
  profile_picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "teams" */
export type Teams_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  list_id?: InputMaybe<Order_By>;
  profile_picture_file_size?: InputMaybe<Order_By>;
};

/** columns and relationships of "thank_yous" */
export type Thank_Yous = {
  body: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  feed_items: Array<Feed_Items>;
  /** An aggregate relationship */
  feed_items_aggregate: Feed_Items_Aggregate;
  id: Scalars['Int']['output'];
  id_canonical: Scalars['Int']['output'];
  /** An object relationship */
  need?: Maybe<Needs>;
  need_id: Scalars['Int']['output'];
  picture_content_type?: Maybe<Scalars['String']['output']>;
  picture_file_name?: Maybe<Scalars['String']['output']>;
  picture_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "thank_you_picture_path" */
  picture_path?: Maybe<Scalars['String']['output']>;
  picture_updated_at?: Maybe<Scalars['timestamp']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "thank_yous" */
export type Thank_YousFeed_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


/** columns and relationships of "thank_yous" */
export type Thank_YousFeed_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};

/** aggregated selection of "thank_yous" */
export type Thank_Yous_Aggregate = {
  aggregate?: Maybe<Thank_Yous_Aggregate_Fields>;
  nodes: Array<Thank_Yous>;
};

export type Thank_Yous_Aggregate_Bool_Exp = {
  count?: InputMaybe<Thank_Yous_Aggregate_Bool_Exp_Count>;
};

export type Thank_Yous_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Thank_Yous_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Thank_Yous_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "thank_yous" */
export type Thank_Yous_Aggregate_Fields = {
  avg?: Maybe<Thank_Yous_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Thank_Yous_Max_Fields>;
  min?: Maybe<Thank_Yous_Min_Fields>;
  stddev?: Maybe<Thank_Yous_Stddev_Fields>;
  stddev_pop?: Maybe<Thank_Yous_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Thank_Yous_Stddev_Samp_Fields>;
  sum?: Maybe<Thank_Yous_Sum_Fields>;
  var_pop?: Maybe<Thank_Yous_Var_Pop_Fields>;
  var_samp?: Maybe<Thank_Yous_Var_Samp_Fields>;
  variance?: Maybe<Thank_Yous_Variance_Fields>;
};


/** aggregate fields of "thank_yous" */
export type Thank_Yous_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Thank_Yous_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "thank_yous" */
export type Thank_Yous_Aggregate_Order_By = {
  avg?: InputMaybe<Thank_Yous_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Thank_Yous_Max_Order_By>;
  min?: InputMaybe<Thank_Yous_Min_Order_By>;
  stddev?: InputMaybe<Thank_Yous_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Thank_Yous_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Thank_Yous_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Thank_Yous_Sum_Order_By>;
  var_pop?: InputMaybe<Thank_Yous_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Thank_Yous_Var_Samp_Order_By>;
  variance?: InputMaybe<Thank_Yous_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "thank_yous" */
export type Thank_Yous_Arr_Rel_Insert_Input = {
  data: Array<Thank_Yous_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Thank_Yous_On_Conflict>;
};

/** aggregate avg on columns */
export type Thank_Yous_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "thank_yous" */
export type Thank_Yous_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "thank_yous". All fields are combined with a logical 'AND'. */
export type Thank_Yous_Bool_Exp = {
  _and?: InputMaybe<Array<Thank_Yous_Bool_Exp>>;
  _not?: InputMaybe<Thank_Yous_Bool_Exp>;
  _or?: InputMaybe<Array<Thank_Yous_Bool_Exp>>;
  body?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feed_items?: InputMaybe<Feed_Items_Bool_Exp>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Int_Comparison_Exp>;
  need?: InputMaybe<Needs_Bool_Exp>;
  need_id?: InputMaybe<Int_Comparison_Exp>;
  picture_content_type?: InputMaybe<String_Comparison_Exp>;
  picture_file_name?: InputMaybe<String_Comparison_Exp>;
  picture_file_size?: InputMaybe<Int_Comparison_Exp>;
  picture_path?: InputMaybe<String_Comparison_Exp>;
  picture_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "thank_yous" */
export enum Thank_Yous_Constraint {
  /** unique or primary key constraint on columns "need_id" */
  IndexThankYousOnNeedId = 'index_thank_yous_on_need_id',
  /** unique or primary key constraint on columns "id" */
  ThankYousPkey = 'thank_yous_pkey'
}

/** input type for incrementing numeric columns in table "thank_yous" */
export type Thank_Yous_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  picture_file_size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "thank_yous" */
export type Thank_Yous_Insert_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feed_items?: InputMaybe<Feed_Items_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need?: InputMaybe<Needs_Obj_Rel_Insert_Input>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  picture_content_type?: InputMaybe<Scalars['String']['input']>;
  picture_file_name?: InputMaybe<Scalars['String']['input']>;
  picture_file_size?: InputMaybe<Scalars['Int']['input']>;
  picture_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Thank_Yous_Max_Fields = {
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  picture_content_type?: Maybe<Scalars['String']['output']>;
  picture_file_name?: Maybe<Scalars['String']['output']>;
  picture_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "thank_you_picture_path" */
  picture_path?: Maybe<Scalars['String']['output']>;
  picture_updated_at?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "thank_yous" */
export type Thank_Yous_Max_Order_By = {
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_content_type?: InputMaybe<Order_By>;
  picture_file_name?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
  picture_updated_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Thank_Yous_Min_Fields = {
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  picture_content_type?: Maybe<Scalars['String']['output']>;
  picture_file_name?: Maybe<Scalars['String']['output']>;
  picture_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "thank_you_picture_path" */
  picture_path?: Maybe<Scalars['String']['output']>;
  picture_updated_at?: Maybe<Scalars['timestamp']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "thank_yous" */
export type Thank_Yous_Min_Order_By = {
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_content_type?: InputMaybe<Order_By>;
  picture_file_name?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
  picture_updated_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "thank_yous" */
export type Thank_Yous_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Thank_Yous>;
};

/** input type for inserting object relation for remote table "thank_yous" */
export type Thank_Yous_Obj_Rel_Insert_Input = {
  data: Thank_Yous_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Thank_Yous_On_Conflict>;
};

/** on_conflict condition type for table "thank_yous" */
export type Thank_Yous_On_Conflict = {
  constraint: Thank_Yous_Constraint;
  update_columns?: Array<Thank_Yous_Update_Column>;
  where?: InputMaybe<Thank_Yous_Bool_Exp>;
};

/** Ordering options when selecting data from "thank_yous". */
export type Thank_Yous_Order_By = {
  body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need?: InputMaybe<Needs_Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_content_type?: InputMaybe<Order_By>;
  picture_file_name?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
  picture_path?: InputMaybe<Order_By>;
  picture_updated_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: thank_yous */
export type Thank_Yous_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "thank_yous" */
export enum Thank_Yous_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  PictureContentType = 'picture_content_type',
  /** column name */
  PictureFileName = 'picture_file_name',
  /** column name */
  PictureFileSize = 'picture_file_size',
  /** column name */
  PictureUpdatedAt = 'picture_updated_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "thank_yous" */
export type Thank_Yous_Set_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  picture_content_type?: InputMaybe<Scalars['String']['input']>;
  picture_file_name?: InputMaybe<Scalars['String']['input']>;
  picture_file_size?: InputMaybe<Scalars['Int']['input']>;
  picture_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Thank_Yous_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "thank_yous" */
export type Thank_Yous_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Thank_Yous_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "thank_yous" */
export type Thank_Yous_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Thank_Yous_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "thank_yous" */
export type Thank_Yous_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "thank_yous" */
export type Thank_Yous_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Thank_Yous_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Thank_Yous_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['Int']['input']>;
  need_id?: InputMaybe<Scalars['Int']['input']>;
  picture_content_type?: InputMaybe<Scalars['String']['input']>;
  picture_file_name?: InputMaybe<Scalars['String']['input']>;
  picture_file_size?: InputMaybe<Scalars['Int']['input']>;
  picture_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Thank_Yous_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['Int']['output']>;
  need_id?: Maybe<Scalars['Int']['output']>;
  picture_file_size?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "thank_yous" */
export type Thank_Yous_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
};

/** update columns of table "thank_yous" */
export enum Thank_Yous_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NeedId = 'need_id',
  /** column name */
  PictureContentType = 'picture_content_type',
  /** column name */
  PictureFileName = 'picture_file_name',
  /** column name */
  PictureFileSize = 'picture_file_size',
  /** column name */
  PictureUpdatedAt = 'picture_updated_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Thank_Yous_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Thank_Yous_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Thank_Yous_Set_Input>;
  /** filter the rows which have to be updated */
  where: Thank_Yous_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Thank_Yous_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "thank_yous" */
export type Thank_Yous_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Thank_Yous_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "thank_yous" */
export type Thank_Yous_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Thank_Yous_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  need_id?: Maybe<Scalars['Float']['output']>;
  picture_file_size?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "thank_yous" */
export type Thank_Yous_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  need_id?: InputMaybe<Order_By>;
  picture_file_size?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "user_goals" */
export type User_Goals = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  goal_period?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  target_value?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "user_goals" */
export type User_Goals_Aggregate = {
  aggregate?: Maybe<User_Goals_Aggregate_Fields>;
  nodes: Array<User_Goals>;
};

/** aggregate fields of "user_goals" */
export type User_Goals_Aggregate_Fields = {
  avg?: Maybe<User_Goals_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Goals_Max_Fields>;
  min?: Maybe<User_Goals_Min_Fields>;
  stddev?: Maybe<User_Goals_Stddev_Fields>;
  stddev_pop?: Maybe<User_Goals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Goals_Stddev_Samp_Fields>;
  sum?: Maybe<User_Goals_Sum_Fields>;
  var_pop?: Maybe<User_Goals_Var_Pop_Fields>;
  var_samp?: Maybe<User_Goals_Var_Samp_Fields>;
  variance?: Maybe<User_Goals_Variance_Fields>;
};


/** aggregate fields of "user_goals" */
export type User_Goals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Goals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type User_Goals_Avg_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  target_value?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_goals". All fields are combined with a logical 'AND'. */
export type User_Goals_Bool_Exp = {
  _and?: InputMaybe<Array<User_Goals_Bool_Exp>>;
  _not?: InputMaybe<User_Goals_Bool_Exp>;
  _or?: InputMaybe<Array<User_Goals_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  goal_period?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  target_value?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "user_goals" */
export type User_Goals_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  target_value?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_goals" */
export type User_Goals_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  goal_period?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  target_value?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type User_Goals_Max_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  goal_period?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  target_value?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type User_Goals_Min_Fields = {
  created_at?: Maybe<Scalars['timestamp']['output']>;
  goal_period?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  target_value?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "user_goals" */
export type User_Goals_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Goals>;
};

/** input type for inserting object relation for remote table "user_goals" */
export type User_Goals_Obj_Rel_Insert_Input = {
  data: User_Goals_Insert_Input;
};

/** Ordering options when selecting data from "user_goals". */
export type User_Goals_Order_By = {
  created_at?: InputMaybe<Order_By>;
  goal_period?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  target_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_goals" */
export enum User_Goals_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GoalPeriod = 'goal_period',
  /** column name */
  Id = 'id',
  /** column name */
  TargetValue = 'target_value',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_goals" */
export type User_Goals_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  goal_period?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  target_value?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type User_Goals_Stddev_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  target_value?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type User_Goals_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  target_value?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type User_Goals_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  target_value?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_goals" */
export type User_Goals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Goals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Goals_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  goal_period?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  target_value?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type User_Goals_Sum_Fields = {
  id?: Maybe<Scalars['Int']['output']>;
  target_value?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type User_Goals_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Goals_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Goals_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Goals_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Goals_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  target_value?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type User_Goals_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  target_value?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type User_Goals_Variance_Fields = {
  id?: Maybe<Scalars['Float']['output']>;
  target_value?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "user_needs_met_stats" */
export type User_Needs_Met_Stats = {
  all_time?: Maybe<Scalars['Int']['output']>;
  all_time_value?: Maybe<Scalars['numeric']['output']>;
  this_month?: Maybe<Scalars['Int']['output']>;
  this_year?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "user_needs_met_stats" */
export type User_Needs_Met_Stats_Aggregate = {
  aggregate?: Maybe<User_Needs_Met_Stats_Aggregate_Fields>;
  nodes: Array<User_Needs_Met_Stats>;
};

/** aggregate fields of "user_needs_met_stats" */
export type User_Needs_Met_Stats_Aggregate_Fields = {
  avg?: Maybe<User_Needs_Met_Stats_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Needs_Met_Stats_Max_Fields>;
  min?: Maybe<User_Needs_Met_Stats_Min_Fields>;
  stddev?: Maybe<User_Needs_Met_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<User_Needs_Met_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Needs_Met_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<User_Needs_Met_Stats_Sum_Fields>;
  var_pop?: Maybe<User_Needs_Met_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<User_Needs_Met_Stats_Var_Samp_Fields>;
  variance?: Maybe<User_Needs_Met_Stats_Variance_Fields>;
};


/** aggregate fields of "user_needs_met_stats" */
export type User_Needs_Met_Stats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Needs_Met_Stats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type User_Needs_Met_Stats_Avg_Fields = {
  all_time?: Maybe<Scalars['Float']['output']>;
  all_time_value?: Maybe<Scalars['Float']['output']>;
  this_month?: Maybe<Scalars['Float']['output']>;
  this_year?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_needs_met_stats". All fields are combined with a logical 'AND'. */
export type User_Needs_Met_Stats_Bool_Exp = {
  _and?: InputMaybe<Array<User_Needs_Met_Stats_Bool_Exp>>;
  _not?: InputMaybe<User_Needs_Met_Stats_Bool_Exp>;
  _or?: InputMaybe<Array<User_Needs_Met_Stats_Bool_Exp>>;
  all_time?: InputMaybe<Int_Comparison_Exp>;
  all_time_value?: InputMaybe<Numeric_Comparison_Exp>;
  this_month?: InputMaybe<Int_Comparison_Exp>;
  this_year?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "user_needs_met_stats" */
export type User_Needs_Met_Stats_Insert_Input = {
  all_time?: InputMaybe<Scalars['Int']['input']>;
  all_time_value?: InputMaybe<Scalars['numeric']['input']>;
  this_month?: InputMaybe<Scalars['Int']['input']>;
  this_year?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type User_Needs_Met_Stats_Max_Fields = {
  all_time?: Maybe<Scalars['Int']['output']>;
  all_time_value?: Maybe<Scalars['numeric']['output']>;
  this_month?: Maybe<Scalars['Int']['output']>;
  this_year?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type User_Needs_Met_Stats_Min_Fields = {
  all_time?: Maybe<Scalars['Int']['output']>;
  all_time_value?: Maybe<Scalars['numeric']['output']>;
  this_month?: Maybe<Scalars['Int']['output']>;
  this_year?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "user_needs_met_stats" */
export type User_Needs_Met_Stats_Obj_Rel_Insert_Input = {
  data: User_Needs_Met_Stats_Insert_Input;
};

/** Ordering options when selecting data from "user_needs_met_stats". */
export type User_Needs_Met_Stats_Order_By = {
  all_time?: InputMaybe<Order_By>;
  all_time_value?: InputMaybe<Order_By>;
  this_month?: InputMaybe<Order_By>;
  this_year?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_needs_met_stats" */
export enum User_Needs_Met_Stats_Select_Column {
  /** column name */
  AllTime = 'all_time',
  /** column name */
  AllTimeValue = 'all_time_value',
  /** column name */
  ThisMonth = 'this_month',
  /** column name */
  ThisYear = 'this_year',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type User_Needs_Met_Stats_Stddev_Fields = {
  all_time?: Maybe<Scalars['Float']['output']>;
  all_time_value?: Maybe<Scalars['Float']['output']>;
  this_month?: Maybe<Scalars['Float']['output']>;
  this_year?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type User_Needs_Met_Stats_Stddev_Pop_Fields = {
  all_time?: Maybe<Scalars['Float']['output']>;
  all_time_value?: Maybe<Scalars['Float']['output']>;
  this_month?: Maybe<Scalars['Float']['output']>;
  this_year?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type User_Needs_Met_Stats_Stddev_Samp_Fields = {
  all_time?: Maybe<Scalars['Float']['output']>;
  all_time_value?: Maybe<Scalars['Float']['output']>;
  this_month?: Maybe<Scalars['Float']['output']>;
  this_year?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_needs_met_stats" */
export type User_Needs_Met_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Needs_Met_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Needs_Met_Stats_Stream_Cursor_Value_Input = {
  all_time?: InputMaybe<Scalars['Int']['input']>;
  all_time_value?: InputMaybe<Scalars['numeric']['input']>;
  this_month?: InputMaybe<Scalars['Int']['input']>;
  this_year?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type User_Needs_Met_Stats_Sum_Fields = {
  all_time?: Maybe<Scalars['Int']['output']>;
  all_time_value?: Maybe<Scalars['numeric']['output']>;
  this_month?: Maybe<Scalars['Int']['output']>;
  this_year?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type User_Needs_Met_Stats_Var_Pop_Fields = {
  all_time?: Maybe<Scalars['Float']['output']>;
  all_time_value?: Maybe<Scalars['Float']['output']>;
  this_month?: Maybe<Scalars['Float']['output']>;
  this_year?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type User_Needs_Met_Stats_Var_Samp_Fields = {
  all_time?: Maybe<Scalars['Float']['output']>;
  all_time_value?: Maybe<Scalars['Float']['output']>;
  this_month?: Maybe<Scalars['Float']['output']>;
  this_year?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type User_Needs_Met_Stats_Variance_Fields = {
  all_time?: Maybe<Scalars['Float']['output']>;
  all_time_value?: Maybe<Scalars['Float']['output']>;
  this_month?: Maybe<Scalars['Float']['output']>;
  this_year?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "user_stats" */
export type User_Stats = {
  count?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "user_stats" */
export type User_Stats_Aggregate = {
  aggregate?: Maybe<User_Stats_Aggregate_Fields>;
  nodes: Array<User_Stats>;
};

export type User_Stats_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Stats_Aggregate_Bool_Exp_Count>;
};

export type User_Stats_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Stats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Stats_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_stats" */
export type User_Stats_Aggregate_Fields = {
  avg?: Maybe<User_Stats_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Stats_Max_Fields>;
  min?: Maybe<User_Stats_Min_Fields>;
  stddev?: Maybe<User_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<User_Stats_Sum_Fields>;
  var_pop?: Maybe<User_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<User_Stats_Var_Samp_Fields>;
  variance?: Maybe<User_Stats_Variance_Fields>;
};


/** aggregate fields of "user_stats" */
export type User_Stats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Stats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_stats" */
export type User_Stats_Aggregate_Order_By = {
  avg?: InputMaybe<User_Stats_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Stats_Max_Order_By>;
  min?: InputMaybe<User_Stats_Min_Order_By>;
  stddev?: InputMaybe<User_Stats_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Stats_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Stats_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Stats_Sum_Order_By>;
  var_pop?: InputMaybe<User_Stats_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Stats_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Stats_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_stats" */
export type User_Stats_Arr_Rel_Insert_Input = {
  data: Array<User_Stats_Insert_Input>;
};

/** aggregate avg on columns */
export type User_Stats_Avg_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_stats" */
export type User_Stats_Avg_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_stats". All fields are combined with a logical 'AND'. */
export type User_Stats_Bool_Exp = {
  _and?: InputMaybe<Array<User_Stats_Bool_Exp>>;
  _not?: InputMaybe<User_Stats_Bool_Exp>;
  _or?: InputMaybe<Array<User_Stats_Bool_Exp>>;
  count?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "user_stats" */
export type User_Stats_Insert_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type User_Stats_Max_Fields = {
  count?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "user_stats" */
export type User_Stats_Max_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Stats_Min_Fields = {
  count?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "user_stats" */
export type User_Stats_Min_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "user_stats". */
export type User_Stats_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "user_stats" */
export enum User_Stats_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type User_Stats_Stddev_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_stats" */
export type User_Stats_Stddev_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Stats_Stddev_Pop_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_stats" */
export type User_Stats_Stddev_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Stats_Stddev_Samp_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_stats" */
export type User_Stats_Stddev_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_stats" */
export type User_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stats_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type User_Stats_Sum_Fields = {
  count?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_stats" */
export type User_Stats_Sum_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type User_Stats_Var_Pop_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_stats" */
export type User_Stats_Var_Pop_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Stats_Var_Samp_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_stats" */
export type User_Stats_Var_Samp_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Stats_Variance_Fields = {
  count?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_stats" */
export type User_Stats_Variance_Order_By = {
  count?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  /** An array relationship */
  addresses: Array<Addresses_Users>;
  /** An aggregate relationship */
  addresses_aggregate: Addresses_Users_Aggregate;
  app_version?: Maybe<Scalars['String']['output']>;
  avatar_content_type?: Maybe<Scalars['String']['output']>;
  avatar_file_name?: Maybe<Scalars['String']['output']>;
  avatar_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "user_avatar_path" */
  avatar_path?: Maybe<Scalars['String']['output']>;
  avatar_updated_at?: Maybe<Scalars['timestamp']['output']>;
  birthdate?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  checkout_intents: Array<Checkout_Intents>;
  /** An aggregate relationship */
  checkout_intents_aggregate: Checkout_Intents_Aggregate;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at: Scalars['timestamp']['output'];
  current_sign_in_at?: Maybe<Scalars['timestamp']['output']>;
  current_sign_in_ip?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password: Scalars['String']['output'];
  /** @deprecated */
  fb_access_token?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  fb_user_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  feed_items: Array<Feed_Items>;
  /** An aggregate relationship */
  feed_items_aggregate: Feed_Items_Aggregate;
  first_name: Scalars['String']['output'];
  /** An object relationship */
  goal?: Maybe<User_Goals>;
  id: Scalars['Int']['output'];
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  last_name: Scalars['String']['output'];
  last_sign_in_at?: Maybe<Scalars['timestamp']['output']>;
  last_sign_in_ip?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  list_subscriptions: Array<List_Subscriptions>;
  /** An aggregate relationship */
  list_subscriptions_aggregate: List_Subscriptions_Aggregate;
  locked: Scalars['Boolean']['output'];
  longitude?: Maybe<Scalars['float8']['output']>;
  mailchimp_id?: Maybe<Scalars['String']['output']>;
  migrated_at?: Maybe<Scalars['timestamp']['output']>;
  mobile_os?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  need_fulfill_intents: Array<Need_Fulfill_Intents>;
  /** An aggregate relationship */
  need_fulfill_intents_aggregate: Need_Fulfill_Intents_Aggregate;
  /** An array relationship */
  needs_created: Array<Needs>;
  /** An aggregate relationship */
  needs_created_aggregate: Needs_Aggregate;
  /** An array relationship */
  needs_met: Array<Needs>;
  /** An aggregate relationship */
  needs_met_aggregate: Needs_Aggregate;
  /** An object relationship */
  needs_met_stats?: Maybe<User_Needs_Met_Stats>;
  opted_out: Scalars['Boolean']['output'];
  /** An object relationship */
  org?: Maybe<Organizations>;
  /** @deprecated */
  organization?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  organization_subscriptions: Array<Organization_Subscriptions>;
  /** An aggregate relationship */
  organization_subscriptions_aggregate: Organization_Subscriptions_Aggregate;
  /** @deprecated - tracks timestamp when encrypted_password was stored in `otc_existing_encrypted_password` and replaced by a temporary code */
  otc_created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** @deprecated - see `otc_created_at` */
  otc_existing_encrypted_password?: Maybe<Scalars['String']['output']>;
  phone_device_type: Scalars['String']['output'];
  /** OneSignal Player ID. @deprecated: use OneSignal's external_user_id */
  player_id?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<Scalars['jsonb']['output']>;
  profile_public: Scalars['Boolean']['output'];
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  roles_mask: Scalars['Int']['output'];
  sign_in_count: Scalars['Int']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  stats: Array<User_Stats>;
  /** An aggregate relationship */
  stats_aggregate: User_Stats_Aggregate;
  stripe_customer?: Maybe<Stripe_Customers>;
  /** @deprecated - will migrate to user_stripe_customer table */
  stripe_token?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  tax_receipts: Array<Tax_Receipts>;
  /** An aggregate relationship */
  tax_receipts_aggregate: Tax_Receipts_Aggregate;
  /** An array relationship */
  team_memberships: Array<Team_Memberships>;
  /** An aggregate relationship */
  team_memberships_aggregate: Team_Memberships_Aggregate;
  token?: Maybe<Scalars['String']['output']>;
  twilio_number_id?: Maybe<Scalars['Int']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamp']['output'];
  username: Scalars['String']['output'];
  validated_at?: Maybe<Scalars['timestamp']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "users" */
export type UsersAddressesArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Users_Order_By>>;
  where?: InputMaybe<Addresses_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Addresses_Users_Order_By>>;
  where?: InputMaybe<Addresses_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCheckout_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Checkout_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Checkout_Intents_Order_By>>;
  where?: InputMaybe<Checkout_Intents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCheckout_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Checkout_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Checkout_Intents_Order_By>>;
  where?: InputMaybe<Checkout_Intents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFeed_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFeed_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feed_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Feed_Items_Order_By>>;
  where?: InputMaybe<Feed_Items_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersList_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<List_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Subscriptions_Order_By>>;
  where?: InputMaybe<List_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersList_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<List_Subscriptions_Order_By>>;
  where?: InputMaybe<List_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNeed_Fulfill_IntentsArgs = {
  distinct_on?: InputMaybe<Array<Need_Fulfill_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Fulfill_Intents_Order_By>>;
  where?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNeed_Fulfill_Intents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Need_Fulfill_Intents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Need_Fulfill_Intents_Order_By>>;
  where?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNeeds_CreatedArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNeeds_Created_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNeeds_MetArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNeeds_Met_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Needs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Needs_Order_By>>;
  where?: InputMaybe<Needs_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOrganization_SubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Subscriptions_Order_By>>;
  where?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersOrganization_Subscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Subscriptions_Order_By>>;
  where?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPreferencesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users" */
export type UsersStatsArgs = {
  distinct_on?: InputMaybe<Array<User_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Stats_Order_By>>;
  where?: InputMaybe<User_Stats_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersStats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Stats_Order_By>>;
  where?: InputMaybe<User_Stats_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTax_ReceiptsArgs = {
  distinct_on?: InputMaybe<Array<Tax_Receipts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tax_Receipts_Order_By>>;
  where?: InputMaybe<Tax_Receipts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTax_Receipts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tax_Receipts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tax_Receipts_Order_By>>;
  where?: InputMaybe<Tax_Receipts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeam_MembershipsArgs = {
  distinct_on?: InputMaybe<Array<Team_Memberships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Memberships_Order_By>>;
  where?: InputMaybe<Team_Memberships_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeam_Memberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Memberships_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Team_Memberships_Order_By>>;
  where?: InputMaybe<Team_Memberships_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Users_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Users_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Users_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Users_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Users_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Users_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Users_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Users_Aggregate_Bool_Exp_Var_Samp>;
};

export type Users_Aggregate_Bool_Exp_Avg = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Corr = {
  arguments: Users_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Users_Select_Column_Users_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Users_Select_Column_Users_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Users_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Users_Select_Column_Users_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Users_Select_Column_Users_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Users_Aggregate_Bool_Exp_Max = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Min = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Sum = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  preferences?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  avatar_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  roles_mask?: Maybe<Scalars['Float']['output']>;
  sign_in_count?: Maybe<Scalars['Float']['output']>;
  twilio_number_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  avatar_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  addresses?: InputMaybe<Addresses_Users_Bool_Exp>;
  addresses_aggregate?: InputMaybe<Addresses_Users_Aggregate_Bool_Exp>;
  app_version?: InputMaybe<String_Comparison_Exp>;
  avatar_content_type?: InputMaybe<String_Comparison_Exp>;
  avatar_file_name?: InputMaybe<String_Comparison_Exp>;
  avatar_file_size?: InputMaybe<Int_Comparison_Exp>;
  avatar_path?: InputMaybe<String_Comparison_Exp>;
  avatar_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  birthdate?: InputMaybe<Date_Comparison_Exp>;
  checkout_intents?: InputMaybe<Checkout_Intents_Bool_Exp>;
  checkout_intents_aggregate?: InputMaybe<Checkout_Intents_Aggregate_Bool_Exp>;
  confirmation_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  current_sign_in_at?: InputMaybe<Timestamp_Comparison_Exp>;
  current_sign_in_ip?: InputMaybe<String_Comparison_Exp>;
  devices?: InputMaybe<Devices_Bool_Exp>;
  devices_aggregate?: InputMaybe<Devices_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  encrypted_password?: InputMaybe<String_Comparison_Exp>;
  fb_access_token?: InputMaybe<String_Comparison_Exp>;
  fb_user_id?: InputMaybe<String_Comparison_Exp>;
  feed_items?: InputMaybe<Feed_Items_Bool_Exp>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  goal?: InputMaybe<User_Goals_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  id_canonical?: InputMaybe<Numeric_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  last_sign_in_at?: InputMaybe<Timestamp_Comparison_Exp>;
  last_sign_in_ip?: InputMaybe<String_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  list_subscriptions?: InputMaybe<List_Subscriptions_Bool_Exp>;
  list_subscriptions_aggregate?: InputMaybe<List_Subscriptions_Aggregate_Bool_Exp>;
  locked?: InputMaybe<Boolean_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  mailchimp_id?: InputMaybe<String_Comparison_Exp>;
  migrated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  mobile_os?: InputMaybe<String_Comparison_Exp>;
  need_fulfill_intents?: InputMaybe<Need_Fulfill_Intents_Bool_Exp>;
  need_fulfill_intents_aggregate?: InputMaybe<Need_Fulfill_Intents_Aggregate_Bool_Exp>;
  needs_created?: InputMaybe<Needs_Bool_Exp>;
  needs_created_aggregate?: InputMaybe<Needs_Aggregate_Bool_Exp>;
  needs_met?: InputMaybe<Needs_Bool_Exp>;
  needs_met_aggregate?: InputMaybe<Needs_Aggregate_Bool_Exp>;
  needs_met_stats?: InputMaybe<User_Needs_Met_Stats_Bool_Exp>;
  opted_out?: InputMaybe<Boolean_Comparison_Exp>;
  org?: InputMaybe<Organizations_Bool_Exp>;
  organization?: InputMaybe<String_Comparison_Exp>;
  organization_id?: InputMaybe<Int_Comparison_Exp>;
  organization_subscriptions?: InputMaybe<Organization_Subscriptions_Bool_Exp>;
  organization_subscriptions_aggregate?: InputMaybe<Organization_Subscriptions_Aggregate_Bool_Exp>;
  otc_created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  otc_existing_encrypted_password?: InputMaybe<String_Comparison_Exp>;
  phone_device_type?: InputMaybe<String_Comparison_Exp>;
  player_id?: InputMaybe<String_Comparison_Exp>;
  preferences?: InputMaybe<Jsonb_Comparison_Exp>;
  profile_public?: InputMaybe<Boolean_Comparison_Exp>;
  remember_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reset_password_token?: InputMaybe<String_Comparison_Exp>;
  roles_mask?: InputMaybe<Int_Comparison_Exp>;
  sign_in_count?: InputMaybe<Int_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  stats?: InputMaybe<User_Stats_Bool_Exp>;
  stats_aggregate?: InputMaybe<User_Stats_Aggregate_Bool_Exp>;
  stripe_token?: InputMaybe<String_Comparison_Exp>;
  tax_receipts?: InputMaybe<Tax_Receipts_Bool_Exp>;
  tax_receipts_aggregate?: InputMaybe<Tax_Receipts_Aggregate_Bool_Exp>;
  team_memberships?: InputMaybe<Team_Memberships_Bool_Exp>;
  team_memberships_aggregate?: InputMaybe<Team_Memberships_Aggregate_Bool_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  twilio_number_id?: InputMaybe<Int_Comparison_Exp>;
  unconfirmed_email?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  validated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  zipcode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "confirmation_token" */
  IndexUsersOnConfirmationToken = 'index_users_on_confirmation_token',
  /** unique or primary key constraint on columns "reset_password_token" */
  IndexUsersOnResetPasswordToken = 'index_users_on_reset_password_token',
  /** unique or primary key constraint on columns "slug" */
  IndexUsersOnSlug = 'index_users_on_slug',
  /** unique or primary key constraint on columns "username" */
  IndexUsersOnUsername = 'index_users_on_username',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "stripe_token" */
  UsersStripeTokenIdx = 'users_stripe_token_idx',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  preferences?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  preferences?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  preferences?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  avatar_file_size?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  roles_mask?: InputMaybe<Scalars['Int']['input']>;
  sign_in_count?: InputMaybe<Scalars['Int']['input']>;
  twilio_number_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  addresses?: InputMaybe<Addresses_Users_Arr_Rel_Insert_Input>;
  app_version?: InputMaybe<Scalars['String']['input']>;
  avatar_content_type?: InputMaybe<Scalars['String']['input']>;
  avatar_file_name?: InputMaybe<Scalars['String']['input']>;
  avatar_file_size?: InputMaybe<Scalars['Int']['input']>;
  avatar_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  birthdate?: InputMaybe<Scalars['date']['input']>;
  checkout_intents?: InputMaybe<Checkout_Intents_Arr_Rel_Insert_Input>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  current_sign_in_at?: InputMaybe<Scalars['timestamp']['input']>;
  current_sign_in_ip?: InputMaybe<Scalars['String']['input']>;
  devices?: InputMaybe<Devices_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  fb_access_token?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  fb_user_id?: InputMaybe<Scalars['String']['input']>;
  feed_items?: InputMaybe<Feed_Items_Arr_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  goal?: InputMaybe<User_Goals_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sign_in_at?: InputMaybe<Scalars['timestamp']['input']>;
  last_sign_in_ip?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  list_subscriptions?: InputMaybe<List_Subscriptions_Arr_Rel_Insert_Input>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  migrated_at?: InputMaybe<Scalars['timestamp']['input']>;
  mobile_os?: InputMaybe<Scalars['String']['input']>;
  need_fulfill_intents?: InputMaybe<Need_Fulfill_Intents_Arr_Rel_Insert_Input>;
  needs_created?: InputMaybe<Needs_Arr_Rel_Insert_Input>;
  needs_met?: InputMaybe<Needs_Arr_Rel_Insert_Input>;
  needs_met_stats?: InputMaybe<User_Needs_Met_Stats_Obj_Rel_Insert_Input>;
  opted_out?: InputMaybe<Scalars['Boolean']['input']>;
  org?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  /** @deprecated */
  organization?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  organization_subscriptions?: InputMaybe<Organization_Subscriptions_Arr_Rel_Insert_Input>;
  /** @deprecated - tracks timestamp when encrypted_password was stored in `otc_existing_encrypted_password` and replaced by a temporary code */
  otc_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  /** @deprecated - see `otc_created_at` */
  otc_existing_encrypted_password?: InputMaybe<Scalars['String']['input']>;
  phone_device_type?: InputMaybe<Scalars['String']['input']>;
  /** OneSignal Player ID. @deprecated: use OneSignal's external_user_id */
  player_id?: InputMaybe<Scalars['String']['input']>;
  preferences?: InputMaybe<Scalars['jsonb']['input']>;
  profile_public?: InputMaybe<Scalars['Boolean']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  roles_mask?: InputMaybe<Scalars['Int']['input']>;
  sign_in_count?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  stats?: InputMaybe<User_Stats_Arr_Rel_Insert_Input>;
  /** @deprecated - will migrate to user_stripe_customer table */
  stripe_token?: InputMaybe<Scalars['String']['input']>;
  tax_receipts?: InputMaybe<Tax_Receipts_Arr_Rel_Insert_Input>;
  team_memberships?: InputMaybe<Team_Memberships_Arr_Rel_Insert_Input>;
  token?: InputMaybe<Scalars['String']['input']>;
  twilio_number_id?: InputMaybe<Scalars['Int']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  validated_at?: InputMaybe<Scalars['timestamp']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  app_version?: Maybe<Scalars['String']['output']>;
  avatar_content_type?: Maybe<Scalars['String']['output']>;
  avatar_file_name?: Maybe<Scalars['String']['output']>;
  avatar_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "user_avatar_path" */
  avatar_path?: Maybe<Scalars['String']['output']>;
  avatar_updated_at?: Maybe<Scalars['timestamp']['output']>;
  birthdate?: Maybe<Scalars['date']['output']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  current_sign_in_at?: Maybe<Scalars['timestamp']['output']>;
  current_sign_in_ip?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  fb_access_token?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  fb_user_id?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  last_sign_in_at?: Maybe<Scalars['timestamp']['output']>;
  last_sign_in_ip?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  mailchimp_id?: Maybe<Scalars['String']['output']>;
  migrated_at?: Maybe<Scalars['timestamp']['output']>;
  mobile_os?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  organization?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  /** @deprecated - tracks timestamp when encrypted_password was stored in `otc_existing_encrypted_password` and replaced by a temporary code */
  otc_created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** @deprecated - see `otc_created_at` */
  otc_existing_encrypted_password?: Maybe<Scalars['String']['output']>;
  phone_device_type?: Maybe<Scalars['String']['output']>;
  /** OneSignal Player ID. @deprecated: use OneSignal's external_user_id */
  player_id?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  roles_mask?: Maybe<Scalars['Int']['output']>;
  sign_in_count?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** @deprecated - will migrate to user_stripe_customer table */
  stripe_token?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  twilio_number_id?: Maybe<Scalars['Int']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  validated_at?: Maybe<Scalars['timestamp']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  app_version?: InputMaybe<Order_By>;
  avatar_content_type?: InputMaybe<Order_By>;
  avatar_file_name?: InputMaybe<Order_By>;
  avatar_file_size?: InputMaybe<Order_By>;
  avatar_updated_at?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_sign_in_at?: InputMaybe<Order_By>;
  current_sign_in_ip?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  /** @deprecated */
  fb_access_token?: InputMaybe<Order_By>;
  /** @deprecated */
  fb_user_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_sign_in_at?: InputMaybe<Order_By>;
  last_sign_in_ip?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  mailchimp_id?: InputMaybe<Order_By>;
  migrated_at?: InputMaybe<Order_By>;
  mobile_os?: InputMaybe<Order_By>;
  /** @deprecated */
  organization?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** @deprecated - tracks timestamp when encrypted_password was stored in `otc_existing_encrypted_password` and replaced by a temporary code */
  otc_created_at?: InputMaybe<Order_By>;
  /** @deprecated - see `otc_created_at` */
  otc_existing_encrypted_password?: InputMaybe<Order_By>;
  phone_device_type?: InputMaybe<Order_By>;
  /** OneSignal Player ID. @deprecated: use OneSignal's external_user_id */
  player_id?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  /** @deprecated - will migrate to user_stripe_customer table */
  stripe_token?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  validated_at?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  app_version?: Maybe<Scalars['String']['output']>;
  avatar_content_type?: Maybe<Scalars['String']['output']>;
  avatar_file_name?: Maybe<Scalars['String']['output']>;
  avatar_file_size?: Maybe<Scalars['Int']['output']>;
  /** A computed field, executes function "user_avatar_path" */
  avatar_path?: Maybe<Scalars['String']['output']>;
  avatar_updated_at?: Maybe<Scalars['timestamp']['output']>;
  birthdate?: Maybe<Scalars['date']['output']>;
  confirmation_sent_at?: Maybe<Scalars['timestamp']['output']>;
  confirmation_token?: Maybe<Scalars['String']['output']>;
  confirmed_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  current_sign_in_at?: Maybe<Scalars['timestamp']['output']>;
  current_sign_in_ip?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  encrypted_password?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  fb_access_token?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  fb_user_id?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  last_sign_in_at?: Maybe<Scalars['timestamp']['output']>;
  last_sign_in_ip?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  mailchimp_id?: Maybe<Scalars['String']['output']>;
  migrated_at?: Maybe<Scalars['timestamp']['output']>;
  mobile_os?: Maybe<Scalars['String']['output']>;
  /** @deprecated */
  organization?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  /** @deprecated - tracks timestamp when encrypted_password was stored in `otc_existing_encrypted_password` and replaced by a temporary code */
  otc_created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** @deprecated - see `otc_created_at` */
  otc_existing_encrypted_password?: Maybe<Scalars['String']['output']>;
  phone_device_type?: Maybe<Scalars['String']['output']>;
  /** OneSignal Player ID. @deprecated: use OneSignal's external_user_id */
  player_id?: Maybe<Scalars['String']['output']>;
  remember_created_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_sent_at?: Maybe<Scalars['timestamp']['output']>;
  reset_password_token?: Maybe<Scalars['String']['output']>;
  roles_mask?: Maybe<Scalars['Int']['output']>;
  sign_in_count?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** @deprecated - will migrate to user_stripe_customer table */
  stripe_token?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  twilio_number_id?: Maybe<Scalars['Int']['output']>;
  unconfirmed_email?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  validated_at?: Maybe<Scalars['timestamp']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  app_version?: InputMaybe<Order_By>;
  avatar_content_type?: InputMaybe<Order_By>;
  avatar_file_name?: InputMaybe<Order_By>;
  avatar_file_size?: InputMaybe<Order_By>;
  avatar_updated_at?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_sign_in_at?: InputMaybe<Order_By>;
  current_sign_in_ip?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  /** @deprecated */
  fb_access_token?: InputMaybe<Order_By>;
  /** @deprecated */
  fb_user_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_sign_in_at?: InputMaybe<Order_By>;
  last_sign_in_ip?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  mailchimp_id?: InputMaybe<Order_By>;
  migrated_at?: InputMaybe<Order_By>;
  mobile_os?: InputMaybe<Order_By>;
  /** @deprecated */
  organization?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  /** @deprecated - tracks timestamp when encrypted_password was stored in `otc_existing_encrypted_password` and replaced by a temporary code */
  otc_created_at?: InputMaybe<Order_By>;
  /** @deprecated - see `otc_created_at` */
  otc_existing_encrypted_password?: InputMaybe<Order_By>;
  phone_device_type?: InputMaybe<Order_By>;
  /** OneSignal Player ID. @deprecated: use OneSignal's external_user_id */
  player_id?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  /** @deprecated - will migrate to user_stripe_customer table */
  stripe_token?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  validated_at?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  addresses_aggregate?: InputMaybe<Addresses_Users_Aggregate_Order_By>;
  app_version?: InputMaybe<Order_By>;
  avatar_content_type?: InputMaybe<Order_By>;
  avatar_file_name?: InputMaybe<Order_By>;
  avatar_file_size?: InputMaybe<Order_By>;
  avatar_path?: InputMaybe<Order_By>;
  avatar_updated_at?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  checkout_intents_aggregate?: InputMaybe<Checkout_Intents_Aggregate_Order_By>;
  confirmation_sent_at?: InputMaybe<Order_By>;
  confirmation_token?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_sign_in_at?: InputMaybe<Order_By>;
  current_sign_in_ip?: InputMaybe<Order_By>;
  devices_aggregate?: InputMaybe<Devices_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  encrypted_password?: InputMaybe<Order_By>;
  fb_access_token?: InputMaybe<Order_By>;
  fb_user_id?: InputMaybe<Order_By>;
  feed_items_aggregate?: InputMaybe<Feed_Items_Aggregate_Order_By>;
  first_name?: InputMaybe<Order_By>;
  goal?: InputMaybe<User_Goals_Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_sign_in_at?: InputMaybe<Order_By>;
  last_sign_in_ip?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  list_subscriptions_aggregate?: InputMaybe<List_Subscriptions_Aggregate_Order_By>;
  locked?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  mailchimp_id?: InputMaybe<Order_By>;
  migrated_at?: InputMaybe<Order_By>;
  mobile_os?: InputMaybe<Order_By>;
  need_fulfill_intents_aggregate?: InputMaybe<Need_Fulfill_Intents_Aggregate_Order_By>;
  needs_created_aggregate?: InputMaybe<Needs_Aggregate_Order_By>;
  needs_met_aggregate?: InputMaybe<Needs_Aggregate_Order_By>;
  needs_met_stats?: InputMaybe<User_Needs_Met_Stats_Order_By>;
  opted_out?: InputMaybe<Order_By>;
  org?: InputMaybe<Organizations_Order_By>;
  organization?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_subscriptions_aggregate?: InputMaybe<Organization_Subscriptions_Aggregate_Order_By>;
  otc_created_at?: InputMaybe<Order_By>;
  otc_existing_encrypted_password?: InputMaybe<Order_By>;
  phone_device_type?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
  preferences?: InputMaybe<Order_By>;
  profile_public?: InputMaybe<Order_By>;
  remember_created_at?: InputMaybe<Order_By>;
  reset_password_sent_at?: InputMaybe<Order_By>;
  reset_password_token?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  stats_aggregate?: InputMaybe<User_Stats_Aggregate_Order_By>;
  stripe_token?: InputMaybe<Order_By>;
  tax_receipts_aggregate?: InputMaybe<Tax_Receipts_Aggregate_Order_By>;
  team_memberships_aggregate?: InputMaybe<Team_Memberships_Aggregate_Order_By>;
  token?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
  unconfirmed_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  validated_at?: InputMaybe<Order_By>;
  zipcode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  preferences?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AppVersion = 'app_version',
  /** column name */
  AvatarContentType = 'avatar_content_type',
  /** column name */
  AvatarFileName = 'avatar_file_name',
  /** column name */
  AvatarFileSize = 'avatar_file_size',
  /** column name */
  AvatarUpdatedAt = 'avatar_updated_at',
  /** column name */
  Birthdate = 'birthdate',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentSignInAt = 'current_sign_in_at',
  /** column name */
  CurrentSignInIp = 'current_sign_in_ip',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FbAccessToken = 'fb_access_token',
  /** column name */
  FbUserId = 'fb_user_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdCanonical = 'id_canonical',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSignInAt = 'last_sign_in_at',
  /** column name */
  LastSignInIp = 'last_sign_in_ip',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Locked = 'locked',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  MailchimpId = 'mailchimp_id',
  /** column name */
  MigratedAt = 'migrated_at',
  /** column name */
  MobileOs = 'mobile_os',
  /** column name */
  OptedOut = 'opted_out',
  /** column name */
  Organization = 'organization',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OtcCreatedAt = 'otc_created_at',
  /** column name */
  OtcExistingEncryptedPassword = 'otc_existing_encrypted_password',
  /** column name */
  PhoneDeviceType = 'phone_device_type',
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  ProfilePublic = 'profile_public',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  RolesMask = 'roles_mask',
  /** column name */
  SignInCount = 'sign_in_count',
  /** column name */
  Slug = 'slug',
  /** column name */
  StripeToken = 'stripe_token',
  /** column name */
  Token = 'token',
  /** column name */
  TwilioNumberId = 'twilio_number_id',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  ValidatedAt = 'validated_at',
  /** column name */
  Zipcode = 'zipcode'
}

/** select "users_aggregate_bool_exp_avg_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Locked = 'locked',
  /** column name */
  OptedOut = 'opted_out',
  /** column name */
  ProfilePublic = 'profile_public'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Locked = 'locked',
  /** column name */
  OptedOut = 'opted_out',
  /** column name */
  ProfilePublic = 'profile_public'
}

/** select "users_aggregate_bool_exp_corr_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "users_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "users_aggregate_bool_exp_max_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "users_aggregate_bool_exp_min_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "users_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "users_aggregate_bool_exp_sum_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "users_aggregate_bool_exp_var_samp_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  app_version?: InputMaybe<Scalars['String']['input']>;
  avatar_content_type?: InputMaybe<Scalars['String']['input']>;
  avatar_file_name?: InputMaybe<Scalars['String']['input']>;
  avatar_file_size?: InputMaybe<Scalars['Int']['input']>;
  avatar_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  birthdate?: InputMaybe<Scalars['date']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  current_sign_in_at?: InputMaybe<Scalars['timestamp']['input']>;
  current_sign_in_ip?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  fb_access_token?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  fb_user_id?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sign_in_at?: InputMaybe<Scalars['timestamp']['input']>;
  last_sign_in_ip?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  migrated_at?: InputMaybe<Scalars['timestamp']['input']>;
  mobile_os?: InputMaybe<Scalars['String']['input']>;
  opted_out?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated */
  organization?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated - tracks timestamp when encrypted_password was stored in `otc_existing_encrypted_password` and replaced by a temporary code */
  otc_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  /** @deprecated - see `otc_created_at` */
  otc_existing_encrypted_password?: InputMaybe<Scalars['String']['input']>;
  phone_device_type?: InputMaybe<Scalars['String']['input']>;
  /** OneSignal Player ID. @deprecated: use OneSignal's external_user_id */
  player_id?: InputMaybe<Scalars['String']['input']>;
  preferences?: InputMaybe<Scalars['jsonb']['input']>;
  profile_public?: InputMaybe<Scalars['Boolean']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  roles_mask?: InputMaybe<Scalars['Int']['input']>;
  sign_in_count?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated - will migrate to user_stripe_customer table */
  stripe_token?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  twilio_number_id?: InputMaybe<Scalars['Int']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  validated_at?: InputMaybe<Scalars['timestamp']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  avatar_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  roles_mask?: Maybe<Scalars['Float']['output']>;
  sign_in_count?: Maybe<Scalars['Float']['output']>;
  twilio_number_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  avatar_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  avatar_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  roles_mask?: Maybe<Scalars['Float']['output']>;
  sign_in_count?: Maybe<Scalars['Float']['output']>;
  twilio_number_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  avatar_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  avatar_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  roles_mask?: Maybe<Scalars['Float']['output']>;
  sign_in_count?: Maybe<Scalars['Float']['output']>;
  twilio_number_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  avatar_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  app_version?: InputMaybe<Scalars['String']['input']>;
  avatar_content_type?: InputMaybe<Scalars['String']['input']>;
  avatar_file_name?: InputMaybe<Scalars['String']['input']>;
  avatar_file_size?: InputMaybe<Scalars['Int']['input']>;
  avatar_updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  birthdate?: InputMaybe<Scalars['date']['input']>;
  confirmation_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  confirmation_token?: InputMaybe<Scalars['String']['input']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  current_sign_in_at?: InputMaybe<Scalars['timestamp']['input']>;
  current_sign_in_ip?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  encrypted_password?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  fb_access_token?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated */
  fb_user_id?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_canonical?: InputMaybe<Scalars['numeric']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  last_sign_in_at?: InputMaybe<Scalars['timestamp']['input']>;
  last_sign_in_ip?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  mailchimp_id?: InputMaybe<Scalars['String']['input']>;
  migrated_at?: InputMaybe<Scalars['timestamp']['input']>;
  mobile_os?: InputMaybe<Scalars['String']['input']>;
  opted_out?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated */
  organization?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated - tracks timestamp when encrypted_password was stored in `otc_existing_encrypted_password` and replaced by a temporary code */
  otc_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  /** @deprecated - see `otc_created_at` */
  otc_existing_encrypted_password?: InputMaybe<Scalars['String']['input']>;
  phone_device_type?: InputMaybe<Scalars['String']['input']>;
  /** OneSignal Player ID. @deprecated: use OneSignal's external_user_id */
  player_id?: InputMaybe<Scalars['String']['input']>;
  preferences?: InputMaybe<Scalars['jsonb']['input']>;
  profile_public?: InputMaybe<Scalars['Boolean']['input']>;
  remember_created_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_sent_at?: InputMaybe<Scalars['timestamp']['input']>;
  reset_password_token?: InputMaybe<Scalars['String']['input']>;
  roles_mask?: InputMaybe<Scalars['Int']['input']>;
  sign_in_count?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated - will migrate to user_stripe_customer table */
  stripe_token?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  twilio_number_id?: InputMaybe<Scalars['Int']['input']>;
  unconfirmed_email?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  validated_at?: InputMaybe<Scalars['timestamp']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  avatar_file_size?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  id_canonical?: Maybe<Scalars['numeric']['output']>;
  latitude?: Maybe<Scalars['float8']['output']>;
  longitude?: Maybe<Scalars['float8']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  roles_mask?: Maybe<Scalars['Int']['output']>;
  sign_in_count?: Maybe<Scalars['Int']['output']>;
  twilio_number_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  avatar_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AppVersion = 'app_version',
  /** column name */
  AvatarContentType = 'avatar_content_type',
  /** column name */
  AvatarFileName = 'avatar_file_name',
  /** column name */
  AvatarFileSize = 'avatar_file_size',
  /** column name */
  AvatarUpdatedAt = 'avatar_updated_at',
  /** column name */
  Birthdate = 'birthdate',
  /** column name */
  ConfirmationSentAt = 'confirmation_sent_at',
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentSignInAt = 'current_sign_in_at',
  /** column name */
  CurrentSignInIp = 'current_sign_in_ip',
  /** column name */
  Email = 'email',
  /** column name */
  EncryptedPassword = 'encrypted_password',
  /** column name */
  FbAccessToken = 'fb_access_token',
  /** column name */
  FbUserId = 'fb_user_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSignInAt = 'last_sign_in_at',
  /** column name */
  LastSignInIp = 'last_sign_in_ip',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Locked = 'locked',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  MigratedAt = 'migrated_at',
  /** column name */
  MobileOs = 'mobile_os',
  /** column name */
  OptedOut = 'opted_out',
  /** column name */
  Organization = 'organization',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OtcCreatedAt = 'otc_created_at',
  /** column name */
  OtcExistingEncryptedPassword = 'otc_existing_encrypted_password',
  /** column name */
  PhoneDeviceType = 'phone_device_type',
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  ProfilePublic = 'profile_public',
  /** column name */
  RememberCreatedAt = 'remember_created_at',
  /** column name */
  ResetPasswordSentAt = 'reset_password_sent_at',
  /** column name */
  ResetPasswordToken = 'reset_password_token',
  /** column name */
  RolesMask = 'roles_mask',
  /** column name */
  SignInCount = 'sign_in_count',
  /** column name */
  Slug = 'slug',
  /** column name */
  StripeToken = 'stripe_token',
  /** column name */
  Token = 'token',
  /** column name */
  TwilioNumberId = 'twilio_number_id',
  /** column name */
  UnconfirmedEmail = 'unconfirmed_email',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  ValidatedAt = 'validated_at',
  /** column name */
  Zipcode = 'zipcode'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  avatar_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  roles_mask?: Maybe<Scalars['Float']['output']>;
  sign_in_count?: Maybe<Scalars['Float']['output']>;
  twilio_number_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  avatar_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  avatar_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  roles_mask?: Maybe<Scalars['Float']['output']>;
  sign_in_count?: Maybe<Scalars['Float']['output']>;
  twilio_number_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  avatar_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  avatar_file_size?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  id_canonical?: Maybe<Scalars['Float']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  roles_mask?: Maybe<Scalars['Float']['output']>;
  sign_in_count?: Maybe<Scalars['Float']['output']>;
  twilio_number_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  avatar_file_size?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_canonical?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  roles_mask?: InputMaybe<Order_By>;
  sign_in_count?: InputMaybe<Order_By>;
  twilio_number_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type AddressesQueryVariables = Exact<{
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type AddressesQuery = { addresses: Array<{ addressable_id: number, addressable_type: string, attention_to: string, city: string, id: number, state: string, street_1: string, street_2: string, zipcode: string }> };

export type AddressQueryVariables = Exact<{
  addressId: Scalars['Int']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type AddressQuery = { address?: { addressable_id: number, addressable_type: string, attention_to: string, city: string, id: number, state: string, street_1: string, street_2: string, zipcode: string } | null };

export type NeedIdsQueryQueryVariables = Exact<{
  count?: Scalars['Int']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type NeedIdsQueryQuery = { needs: Array<{ id: number }> };

export type AliasesQueryVariables = Exact<{ [key: string]: never; }>;


export type AliasesQuery = { organization: Array<{ id: number, alias: string, v_alias: string }>, team: Array<{ id: number, alias: string, v_alias: string }> };

export type ListsQueryVariables = Exact<{
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ListsQuery = { lists: Array<{ id: number, slug?: string | null, name?: string | null, state_code?: string | null, is_state?: boolean | null }> };

export type ListQueryVariables = Exact<{
  listId: Scalars['Int']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ListQuery = { list?: { id: number, slug?: string | null, name?: string | null, state_code?: string | null, about?: string | null, active: boolean, is_state?: boolean | null, is_team?: boolean | null } | null };

export type NeedQueryVariables = Exact<{
  needId: Scalars['Int']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type NeedQuery = { need?: { short_description?: string | null, full_description?: string | null, price_including_sales_tax?: number | null, title?: string | null, state?: string | null, rank_distance?: number | null, direct_checkout: boolean, updated_at: string, completed_at?: string | null, is_met_by_current_user?: boolean | null, data?: JSON | null, has_tags?: any | null, id: number, tags?: JSON | null, location?: { city?: string | null, state?: string | null, latitude?: number | null, longitude?: number | null } | null, need_information?: { direct_checkout_description?: string | null } | null, organization?: { alias: string, v_alias: string, name: string, latitude?: number | null, longitude?: number | null, profile_image_path?: string | null, id: number } | null, list?: { sponsorships?: { sponsor?: { name?: string | null, link?: string | null, primary: boolean, abbreviated_name?: string | null, logo_path?: string | null, id: number } | null } | null } | null, checkout_intent?: { id: number } | null, thank_yous: Array<{ id: number, body: string, picture_path?: string | null }>, need_tag?: { tag: string } | null } | null };

export type NeedStatusQueryVariables = Exact<{
  needId: Scalars['Int']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type NeedStatusQuery = { status?: { is_met_by_current_user?: boolean | null, state?: string | null, completed_at?: string | null, checkout_intent?: { id: number, updated_at: string } | null, checkout_intents: Array<{ id: number, updated_at: string }> } | null };

export type NeedStatusLiveSubscriptionVariables = Exact<{
  needId: Scalars['Int']['input'];
}>;


export type NeedStatusLiveSubscription = { status?: { is_met_by_current_user?: boolean | null, state?: string | null, completed_at?: string | null, checkout_intent?: { id: number, updated_at: string } | null, checkout_intents: Array<{ id: number, updated_at: string }> } | null };

export type NeedsQueryVariables = Exact<{
  where?: InputMaybe<Needs_Bool_Exp>;
  order_by?: InputMaybe<Array<Needs_Order_By> | Needs_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  distinct_on?: InputMaybe<Array<Needs_Select_Column> | Needs_Select_Column>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type NeedsQuery = { needs: Array<{ short_description?: string | null, full_description?: string | null, price_including_sales_tax?: number | null, title?: string | null, state?: string | null, rank_distance?: number | null, direct_checkout: boolean, updated_at: string, completed_at?: string | null, is_met_by_current_user?: boolean | null, data?: JSON | null, has_tags?: any | null, id: number, tags?: JSON | null, location?: { city?: string | null, state?: string | null, latitude?: number | null, longitude?: number | null } | null, need_information?: { direct_checkout_description?: string | null } | null, organization?: { alias: string, v_alias: string, name: string, latitude?: number | null, longitude?: number | null, profile_image_path?: string | null, id: number } | null, list?: { sponsorships?: { sponsor?: { name?: string | null, link?: string | null, primary: boolean, abbreviated_name?: string | null, logo_path?: string | null, id: number } | null } | null } | null, checkout_intent?: { id: number } | null, thank_yous: Array<{ id: number, body: string, picture_path?: string | null }>, need_tag?: { tag: string } | null }> };

export type NeedsGeoQueryVariables = Exact<{
  where?: InputMaybe<Needs_Bool_Exp>;
  order_by?: InputMaybe<Array<Needs_Order_By> | Needs_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  distinct_on?: InputMaybe<Array<Needs_Select_Column> | Needs_Select_Column>;
  latitude?: InputMaybe<Scalars['float8']['input']>;
  longitude?: InputMaybe<Scalars['float8']['input']>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type NeedsGeoQuery = { needs: Array<{ short_description?: string | null, full_description?: string | null, price_including_sales_tax?: number | null, title?: string | null, state?: string | null, rank_distance?: number | null, direct_checkout: boolean, updated_at: string, completed_at?: string | null, is_met_by_current_user?: boolean | null, data?: JSON | null, has_tags?: any | null, id: number, tags?: JSON | null, location?: { city?: string | null, state?: string | null, latitude?: number | null, longitude?: number | null } | null, need_information?: { direct_checkout_description?: string | null } | null, organization?: { alias: string, v_alias: string, name: string, latitude?: number | null, longitude?: number | null, profile_image_path?: string | null, id: number } | null, list?: { sponsorships?: { sponsor?: { name?: string | null, link?: string | null, primary: boolean, abbreviated_name?: string | null, logo_path?: string | null, id: number } | null } | null } | null, checkout_intent?: { id: number } | null, thank_yous: Array<{ id: number, body: string, picture_path?: string | null }>, need_tag?: { tag: string } | null }> };

export type OrganizationQueryVariables = Exact<{
  orgId: Scalars['Int']['input'];
  withStats?: InputMaybe<Scalars['Boolean']['input']>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OrganizationQuery = { organization?: { id: number, alias: string, v_alias: string, name: string, list_id: number, created_at: string, updated_at: string, description?: string | null, url?: string | null, profile_image_path?: string | null, hero_image_path?: string | null, latitude?: number | null, longitude?: number | null, inactive: boolean, private?: boolean | null, parent_organization_id?: number | null, data: JSON, tags: JSON, give_now: JSON, stats?: { needs_available_count?: number | null, follower_count?: number | null } | null, location?: { city?: string | null, state?: string | null } | null } | null };

export type OrganizationByAliasQueryVariables = Exact<{
  v_alias: Scalars['String']['input'];
  withStats?: InputMaybe<Scalars['Boolean']['input']>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OrganizationByAliasQuery = { organization: Array<{ id: number, alias: string, v_alias: string, name: string, list_id: number, created_at: string, updated_at: string, description?: string | null, url?: string | null, profile_image_path?: string | null, hero_image_path?: string | null, latitude?: number | null, longitude?: number | null, inactive: boolean, private?: boolean | null, parent_organization_id?: number | null, data: JSON, tags: JSON, give_now: JSON, stats?: { needs_available_count?: number | null, follower_count?: number | null } | null, location?: { city?: string | null, state?: string | null } | null }> };

export type OrganizationsPreviewQueryVariables = Exact<{
  filter?: InputMaybe<Organizations_Bool_Exp>;
  order_by?: InputMaybe<Array<Organizations_Order_By> | Organizations_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OrganizationsPreviewQuery = { organizations: Array<{ id: number, alias: string, v_alias: string, name: string, profile_image_path?: string | null, stats?: { needs_available_count?: number | null } | null }> };

export type OrganizationsQueryVariables = Exact<{
  filter?: InputMaybe<Organizations_Bool_Exp>;
  order_by?: InputMaybe<Array<Organizations_Order_By> | Organizations_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  withStats?: InputMaybe<Scalars['Boolean']['input']>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OrganizationsQuery = { organizations: Array<{ id: number, alias: string, v_alias: string, name: string, list_id: number, created_at: string, updated_at: string, description?: string | null, url?: string | null, profile_image_path?: string | null, hero_image_path?: string | null, latitude?: number | null, longitude?: number | null, inactive: boolean, private?: boolean | null, parent_organization_id?: number | null, data: JSON, tags: JSON, give_now: JSON, stats?: { needs_available_count?: number | null, follower_count?: number | null } | null, location?: { city?: string | null, state?: string | null } | null }> };

export type OrganizationNeedsQueryVariables = Exact<{
  orgId: Scalars['Int']['input'];
  filter?: InputMaybe<Needs_Bool_Exp>;
  order_by?: InputMaybe<Array<Needs_Order_By> | Needs_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  withStats?: InputMaybe<Scalars['Boolean']['input']>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OrganizationNeedsQuery = { organization?: { id: number, alias: string, v_alias: string, name: string, list_id: number, created_at: string, updated_at: string, description?: string | null, url?: string | null, profile_image_path?: string | null, hero_image_path?: string | null, latitude?: number | null, longitude?: number | null, inactive: boolean, private?: boolean | null, parent_organization_id?: number | null, data: JSON, tags: JSON, give_now: JSON, stats?: { needs_available_count?: number | null, follower_count?: number | null } | null, location?: { city?: string | null, state?: string | null } | null, needs: Array<{ id: number }> } | null };

export type NeedsByOrgQueryVariables = Exact<{
  orgId: Scalars['Int']['input'];
  filter?: InputMaybe<Needs_Bool_Exp>;
  order_by?: InputMaybe<Array<Needs_Order_By> | Needs_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type NeedsByOrgQuery = { organization?: { needs: Array<{ short_description?: string | null, full_description?: string | null, price_including_sales_tax?: number | null, title?: string | null, state?: string | null, rank_distance?: number | null, direct_checkout: boolean, updated_at: string, completed_at?: string | null, is_met_by_current_user?: boolean | null, data?: JSON | null, has_tags?: any | null, id: number, tags?: JSON | null, location?: { city?: string | null, state?: string | null, latitude?: number | null, longitude?: number | null } | null, need_information?: { direct_checkout_description?: string | null } | null, organization?: { alias: string, v_alias: string, name: string, latitude?: number | null, longitude?: number | null, profile_image_path?: string | null, id: number } | null, list?: { sponsorships?: { sponsor?: { name?: string | null, link?: string | null, primary: boolean, abbreviated_name?: string | null, logo_path?: string | null, id: number } | null } | null } | null, checkout_intent?: { id: number } | null, thank_yous: Array<{ id: number, body: string, picture_path?: string | null }>, need_tag?: { tag: string } | null }> } | null };

export type NeedsByOrgAliasQueryVariables = Exact<{
  v_alias: Scalars['String']['input'];
  filter?: InputMaybe<Needs_Bool_Exp>;
  order_by?: InputMaybe<Array<Needs_Order_By> | Needs_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type NeedsByOrgAliasQuery = { organization: Array<{ needs: Array<{ short_description?: string | null, full_description?: string | null, price_including_sales_tax?: number | null, title?: string | null, state?: string | null, rank_distance?: number | null, direct_checkout: boolean, updated_at: string, completed_at?: string | null, is_met_by_current_user?: boolean | null, data?: JSON | null, has_tags?: any | null, id: number, tags?: JSON | null, location?: { city?: string | null, state?: string | null, latitude?: number | null, longitude?: number | null } | null, need_information?: { direct_checkout_description?: string | null } | null, organization?: { alias: string, v_alias: string, name: string, latitude?: number | null, longitude?: number | null, profile_image_path?: string | null, id: number } | null, list?: { sponsorships?: { sponsor?: { name?: string | null, link?: string | null, primary: boolean, abbreviated_name?: string | null, logo_path?: string | null, id: number } | null } | null } | null, checkout_intent?: { id: number } | null, thank_yous: Array<{ id: number, body: string, picture_path?: string | null }>, need_tag?: { tag: string } | null }> }> };

export type StoriesQueryVariables = Exact<{
  filter?: InputMaybe<Stories_Bool_Exp>;
  order_by?: InputMaybe<Array<Stories_Order_By> | Stories_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type StoriesQuery = { stories: Array<{ body: string, created_at: string, hero_image_content_type?: string | null, hero_image_file_name?: string | null, hero_image_file_size?: number | null, hero_image_path?: string | null, hero_image_updated_at?: string | null, id: number, published_date?: string | null, title: string, updated_at: string }> };

export type StoryQueryVariables = Exact<{
  storyId: Scalars['Int']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type StoryQuery = { story?: { body: string, created_at: string, hero_image_content_type?: string | null, hero_image_file_name?: string | null, hero_image_file_size?: number | null, hero_image_path?: string | null, hero_image_updated_at?: string | null, id: number, published_date?: string | null, title: string, updated_at: string } | null };

export type TeamsQueryVariables = Exact<{
  filter?: InputMaybe<Teams_Bool_Exp>;
  order_by?: InputMaybe<Array<Teams_Order_By> | Teams_Order_By>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  includeConnection?: Scalars['Boolean']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type TeamsQuery = { teams: Array<{ id: number, alias: string, v_alias: string, name: string, profile_picture_path?: string | null, is_member?: boolean | null, needs_stats?: { count?: number | null } | null }> };

export type TeamDetailsQueryVariables = Exact<{
  teamId: Scalars['Int']['input'];
  withCampaigns?: Scalars['Boolean']['input'];
  withNeedsMet?: Scalars['Boolean']['input'];
  withUpdates?: Scalars['Boolean']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type TeamDetailsQuery = { team?: { id: number, name: string, alias: string, v_alias: string, subheading?: string | null, profile_picture_path?: string | null, query_tags: JSON, need_tags: JSON, give_now: JSON, avatars: Array<{ membership_id?: number | null, user_avatar_path?: string | null }>, stats?: { members_count?: number | null } | null, needs_stats?: { count?: number | null, sum?: number | null } | null, campaigns?: Array<{ _id?: string | null, title?: string | null, description?: string | null, goal?: number | null, goalAchievedMessage?: string | null }>, needs_met?: Array<{ need?: { id: number, title?: string | null, short_description?: string | null, price_including_sales_tax?: number | null, location?: { city?: string | null, state?: string | null, latitude?: number | null, longitude?: number | null } | null, organization?: { id: number, alias: string, v_alias: string, name: string, profile_image_path?: string | null } | null } | null }>, updates?: Array<{ _id?: string | null, bodyRaw?: JSON | null, publishedAt?: string | null, author?: { _id?: string | null, name?: string | null, image?: { asset?: { _id?: string | null, path?: string | null, url?: string | null } | null, crop?: { _key?: string | null, _type?: string | null, bottom?: number | null, left?: number | null, right?: number | null, top?: number | null } | null, hotspot?: { _key?: string | null, _type?: string | null, height?: number | null, width?: number | null, x?: number | null, y?: number | null } | null } | null } | null }> } | null };

export type TeamDetailsByAliasQueryVariables = Exact<{
  v_alias: Scalars['String']['input'];
  withCampaigns?: Scalars['Boolean']['input'];
  withNeedsMet?: Scalars['Boolean']['input'];
  withUpdates?: Scalars['Boolean']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type TeamDetailsByAliasQuery = { team: Array<{ id: number, name: string, alias: string, v_alias: string, subheading?: string | null, profile_picture_path?: string | null, query_tags: JSON, need_tags: JSON, give_now: JSON, avatars: Array<{ membership_id?: number | null, user_avatar_path?: string | null }>, stats?: { members_count?: number | null } | null, needs_stats?: { count?: number | null, sum?: number | null } | null, campaigns?: Array<{ _id?: string | null, title?: string | null, description?: string | null, goal?: number | null, goalAchievedMessage?: string | null }>, needs_met?: Array<{ need?: { id: number, title?: string | null, short_description?: string | null, price_including_sales_tax?: number | null, location?: { city?: string | null, state?: string | null, latitude?: number | null, longitude?: number | null } | null, organization?: { id: number, alias: string, v_alias: string, name: string, profile_image_path?: string | null } | null } | null }>, updates?: Array<{ _id?: string | null, bodyRaw?: JSON | null, publishedAt?: string | null, author?: { _id?: string | null, name?: string | null, image?: { asset?: { _id?: string | null, path?: string | null, url?: string | null } | null, crop?: { _key?: string | null, _type?: string | null, bottom?: number | null, left?: number | null, right?: number | null, top?: number | null } | null, hotspot?: { _key?: string | null, _type?: string | null, height?: number | null, width?: number | null, x?: number | null, y?: number | null } | null } | null } | null }> }> };

export type _CreateCheckoutSessionMutationVariables = Exact<{
  need_ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type _CreateCheckoutSessionMutation = { session?: { checkout_session_id: string, checkout_url: string, transaction?: { line_items?: Array<{ type: LineItemType, id: number, amount: number, metadata?: JSON | null } | null> | null, summary?: { count: number, sum: number } | null } | null } | null };

export type _ExpireCheckoutSessionMutationVariables = Exact<{
  checkout_session_id: Scalars['String']['input'];
}>;


export type _ExpireCheckoutSessionMutation = { session?: { checkout_session_id: string } | null };

export type UserConnectionsQueryVariables = Exact<{
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UserConnectionsQuery = { me?: { teams: Array<{ team?: { id: number, alias: string, v_alias: string } | null }>, orgs: Array<{ org?: { id: number, alias: string, v_alias: string } | null }>, lists: Array<{ list?: { id: number } | null }> } | null };

export type JoinTeamMutationVariables = Exact<{
  teamId: Scalars['Int']['input'];
}>;


export type JoinTeamMutation = { insert_team_memberships_one?: { team_id: number } | null };

export type LeaveTeamMutationVariables = Exact<{
  teamId: Scalars['Int']['input'];
}>;


export type LeaveTeamMutation = { delete_team_memberships?: { affected_rows: number, returning: Array<{ team_id: number }> } | null };

export type FollowOrganizationMutationVariables = Exact<{
  orgId: Scalars['Int']['input'];
}>;


export type FollowOrganizationMutation = { insert_organization_subscriptions_one?: { id: number, created_at?: string | null, updated_at?: string | null, organization_id: number, user_id: number } | null };

export type UnfollowOrganizationMutationVariables = Exact<{
  orgId: Scalars['Int']['input'];
}>;


export type UnfollowOrganizationMutation = { delete_organization_subscriptions?: { returning: Array<{ id: number }> } | null };

export type SubscribeToListMutationVariables = Exact<{
  listId: Scalars['Int']['input'];
}>;


export type SubscribeToListMutation = { insert_list_subscriptions_one?: { id: number, created_at: string, updated_at: string, list_id: number, user_id: number } | null };

export type UnsubscribeToListMutationVariables = Exact<{
  listId: Scalars['Int']['input'];
}>;


export type UnsubscribeToListMutation = { delete_list_subscriptions?: { returning: Array<{ id: number }> } | null };

export type ExchangeLegacyTokenMutationVariables = Exact<{
  params: ExchangeLegacyTokenParams;
}>;


export type ExchangeLegacyTokenMutation = { exchange_legacy_token?: { clerk_ticket: string } | null };

export type FeedItemsQueryVariables = Exact<{
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type FeedItemsQuery = { feed_items: Array<{ created_at: string, feedable_id?: number | null, feedable_type: string, id: number, state: string, updated_at: string, user_id?: number | null }> };

export type FeedItemQueryVariables = Exact<{
  feedItemId: Scalars['Int']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type FeedItemQuery = { feed_item?: { created_at: string, feedable_id?: number | null, feedable_type: string, id: number, state: string, updated_at: string, user_id?: number | null } | null };

export type FeedPostsQueryVariables = Exact<{
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type FeedPostsQuery = { feed_posts: Array<{ content: string, created_at: string, id: number, list_ids?: string | null, notification_type: number, provider_job_id?: string | null, send_at: string, story_id?: number | null, title: string, type_label: string, updated_at: string }> };

export type FeedPostQueryVariables = Exact<{
  feedPostId: Scalars['Int']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type FeedPostQuery = { feed_post?: { content: string, created_at: string, id: number, list_ids?: string | null, notification_type: number, provider_job_id?: string | null, send_at: string, story_id?: number | null, title: string, type_label: string, updated_at: string } | null };

export type PostsQueryVariables = Exact<{
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type PostsQuery = { posts: Array<{ content: string, created_at: string, id: number, title: string, updated_at: string }> };

export type PostQueryVariables = Exact<{
  postId: Scalars['Int']['input'];
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type PostQuery = { post?: { content: string, created_at: string, id: number, title: string, updated_at: string } | null };

export type GoalsQueryVariables = Exact<{
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GoalsQuery = { me?: { goal?: { id?: number | null, created_at?: string | null, updated_at?: string | null, goal_period?: string | null, target_value?: number | null } | null } | null };

export type UpdateGoalMutationVariables = Exact<{
  number: Scalars['String']['input'];
  regularity: Scalars['Int']['input'];
}>;


export type UpdateGoalMutation = { insert_goals_one?: { id: number, updated_at: string, number: string, regularity?: number | null } | null };

export type LegacyAuthTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type LegacyAuthTokenQuery = { current_user?: { legacy_token: string } | null };

export type UserProfileQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  cacheTTL?: InputMaybe<Scalars['Int']['input']>;
  cacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UserProfileQuery = { me?: { id: number, first_name: string, last_name: string, username: string, avatar_path?: string | null, email?: string | null, is_public: boolean, stats: Array<{ year?: number | null, count?: number | null }>, goal?: { target_value?: number | null, goal_period?: string | null } | null, needs_met: Array<{ short_description?: string | null, full_description?: string | null, price_including_sales_tax?: number | null, title?: string | null, state?: string | null, rank_distance?: number | null, direct_checkout: boolean, updated_at: string, completed_at?: string | null, is_met_by_current_user?: boolean | null, data?: JSON | null, has_tags?: any | null, id: number, tags?: JSON | null, location?: { city?: string | null, state?: string | null, latitude?: number | null, longitude?: number | null } | null, need_information?: { direct_checkout_description?: string | null } | null, organization?: { alias: string, v_alias: string, name: string, latitude?: number | null, longitude?: number | null, profile_image_path?: string | null, id: number } | null, list?: { sponsorships?: { sponsor?: { name?: string | null, link?: string | null, primary: boolean, abbreviated_name?: string | null, logo_path?: string | null, id: number } | null } | null } | null, checkout_intent?: { id: number } | null, thank_yous: Array<{ id: number, body: string, picture_path?: string | null }>, need_tag?: { tag: string } | null }>, needs_met_stats?: { all_time?: number | null, this_year?: number | null, this_month?: number | null, all_time_value?: number | null } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  updates: Users_Set_Input;
}>;


export type UpdateUserMutation = { user?: { id: number } | null };

export type RequestLoginTicketMutationVariables = Exact<{
  params: RequestLoginTicketParams;
}>;


export type RequestLoginTicketMutation = { request_login_ticket?: { login_ticket: string } | null };

export type _CreateUniversalCheckoutMutationVariables = Exact<{
  params: CreateUniversalCheckoutParams;
}>;


export type _CreateUniversalCheckoutMutation = { checkout?: { checkout_intent_id: number, payment_intent_client_secret: string, ephemeral_key_secret: string, transaction?: { line_items?: Array<{ type: LineItemType, id: number, amount: number, metadata?: JSON | null } | null> | null, summary?: { count: number, sum: number } | null } | null } | null };

export type _CancelUniversalCheckoutMutationVariables = Exact<{
  params: CancelUniversalCheckoutParams;
}>;


export type _CancelUniversalCheckoutMutation = { checkout?: { checkout_intent_id: number, result: string } | null };
