import type { Products } from '@purposity/charges-types'
import * as z from 'zod'

export namespace TransactionItem {
  type Types = 'need' | 'tip' | 'donation' | 'designated'

  interface Base<
    TType extends Types,
    TMetadata extends Record<string, any>,
    TId extends string | number = number,
  > {
    type: TType
    id: TId
    amount: number
    metadata: TMetadata
  }

  export interface Tip
    extends Base<
      Products.Tip.TYPE,
      {
        line_item_id?: string
        price_id?: string
        product_id?: Products.Tip.Product['id']
        line_item_description?: Products.Tip.LineItem['description']
      }
    > {}

  export const Tip = z.custom<Tip>((v) => (v as Tip)?.type === 'tip')

  export function createTip(params: {
    /**
     * `Math.round((currency(charge.metadata.tip_amount).intValue / currency(charge.metadata.need_amount).intValue) * 100)`
     * or
     * `15`
     */
    id: number
    /**
     * `currency(charge.metadata.tip_amount).intValue`
     * or
     * `line_item.amount_total`
     */
    amount: number
    /** line_item.id */
    line_item_id?: Products.Tip.LineItem['id']
    /** line_item.description */
    line_item_description?: Products.Tip.LineItem['description']
    /** line_item.price.id */
    price_id?: Products.Tip.Price['id']
    /** line_item.price.product.id */
    product_id?: Products.Tip.Product['id']
  }): Tip {
    return {
      type: 'tip',
      id: params.id,
      amount: params.amount,
      metadata: {
        line_item_id: params.line_item_id,
        price_id: params.price_id,
        product_id: params.product_id,
        line_item_description: params.line_item_description,
      },
    }
  }

  export interface Need
    extends Base<
      Products.Need.TYPE,
      {
        line_item_id?: string
      }
    > {}

  export const Need = z
    .custom<Need>((v) => (v as Need)?.type === 'need')
    .brand('Need')

  export function createNeed(params: {
    /** JSON.parse(metadata.need_ids)[0] */
    id: number
    /** line_item.amount_total */
    amount: number
    /** undefined only if from LegacyWebStripeJs */
    line_item_id?: string
  }): Need {
    return {
      type: 'need',
      id: params.id,
      amount: params.amount,
      metadata: {
        line_item_id: params.line_item_id,
      },
    }
  }

  export interface Designated
    extends Base<
      Products.Designated.TYPE,
      {
        line_item_id?: string
        description: Products.Designated.Product['description']
      } & Products.Designated.Price['metadata'],
      string
    > {}

  export const Designated = z
    .custom<Designated>((v) => (v as Designated)?.type === 'designated')
    .brand('Designated')

  export function createDesignated(params: {
    /** line_item.price.metadata.target */
    id: string
    /** line_item.amount_total */
    amount: number
    /** line_item.id */
    line_item_id: Products.Designated.LineItem['id']
    /** line_item.price.product.description */
    description: Products.Designated.Product['description']
    /** line_item.price.metadata */
    price_metadata: Products.Designated.Price['metadata']
  }): Designated {
    return {
      type: 'designated',
      id: params.id,
      amount: params.amount,
      metadata: {
        ...params.price_metadata,
        description: params.description,
        line_item_id: params.line_item_id,
      },
    }
  }

  export interface Donation
    extends Base<
      'donation',
      {
        line_item_id?: string
      },
      string
    > {}

  export const Donation = z
    .custom<Donation>((v) => (v as Donation)?.type === 'donation')
    .brand('Donation')

  export function createDonation(params: {
    /**  /Donation \((\d+)\) of/.exec(charge.description)?.[1] ?? 'unknown_id' */
    id: string
    /** amount: charge.amount */
    amount: number
  }): Donation {
    return {
      type: 'donation',
      id: params.id ?? 'unknown_id',
      amount: params.amount,
      metadata: {},
    }
  }

  export type Any = Tip | Need | Designated | Donation
  export const Any = z.union([Tip, Need, Designated, Donation])
}

export interface Transaction {
  /** stripe: charge_id */
  id: string
  created_at: Date
  paid: boolean
  amount: number
  receipt: {
    number?: string
    url?: string
  }
  line_items: TransactionItem.Any[]
  metadata: {
    charge_type: 'original' | 'ios2020' | 'web2021' | 'raisely' | 'prelaunch'
    payment_intent_id?: string
    checkout_session_id?: string
  }
}
export const Transaction = z.object({
  id: z.string(),
  created_at: z.date(),
  paid: z.boolean(),
  amount: z.number(),
  receipt: z.object({
    number: z.string().optional(),
    url: z.string().url().optional(),
  }),
  line_items: TransactionItem.Any.array(),
  metadata: z.object({
    charge_type: z.enum([
      'original',
      'ios2020',
      'web2021',
      'raisely',
      'prelaunch',
    ]),
    payment_intent_id: z.string().optional(),
    checkout_session_id: z.string().optional(),
  }),
})

export const ErrorCodes = z.nativeEnum({
  DUPLICATE_CHECKOUT_INTENT: 'duplicate-checkout-intent',
  NEED_NOT_AVAIABLE: 'need-not-available',
})

export const CheckoutSessionId = z
  .string()
  .startsWith('cs_', 'must start with cs_')
  .or(z.string().startsWith('pi_', 'must start with pi_'))

export const CheckoutSession = z.object({
  checkout_session_id: CheckoutSessionId,
  checkout_url: z.string().url(),
  transaction: Transaction.pick({
    line_items: true,
  }).extend({
    summary: z.object({
      count: z.number().int(),
      sum: z.number(),
    }),
  }),
})
